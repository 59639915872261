import React from 'react';
import { SplitFlags } from '../../../hooks/featureFlags';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { EventLabels } from '../../../utils/trackingEvents';
import { NavigationItem, NavigationWrapper } from './Navigation';

export const DieticianNavigation = () => {
  const { flags } = useFeatureFlags();
  const isHomeEnabledForNs = flags[SplitFlags.NS_HOME_PAGE];

  return (
    <NavigationWrapper>
      {isHomeEnabledForNs && (
        <NavigationItem
          to="/app"
          testId="NavigationHomeLink-Fit"
          eventLabel={EventLabels.Home}
        >
          Home
        </NavigationItem>
      )}
      <NavigationItem
        to="/app/sessions"
        testId="NavigationSessionsLink-Fit"
        eventLabel={EventLabels.SessionsTab}
      >
        Sessions
      </NavigationItem>
    </NavigationWrapper>
  );
};
