import { useContext } from 'react';
import { SplitContext } from '@splitsoftware/splitio-react';
import { useSelfQuery } from '../graphql/types';
import { SplitFlags } from './featureFlags';
import useLocalStorage from './useLocalStorage';

export type FeatureFlags = Record<SplitFlags, boolean>;

export const useFeatureFlags = () => {
  const { client, isReady } = useContext(SplitContext);
  const { data } = useSelfQuery({
    fetchPolicy: 'cache-first',
  });
  const {
    id: userId = '',
    isAdmin = false,
    email = '',
    coachData,
  } = data?.self ?? {};
  const [flagOverrides] = useLocalStorage<Partial<FeatureFlags>>(
    `${userId}-featureFlag-overrides`,
    {},
  );

  const clientIds = coachData?.clients?.edges.map(({ node }) => node.id) ?? [];
  const attributes = {
    userId,
    isAdmin,
    email,
    clientIds,
  };

  const treatments =
    client?.getTreatmentsWithConfig(Object.values(SplitFlags), attributes) ??
    {};
  const normalizedTreatments = {} as FeatureFlags;

  Object.keys(treatments).forEach((id) => {
    normalizedTreatments[id as SplitFlags] = treatments[id].treatment === 'on';
  });

  const flagsWithOverrides = {
    ...normalizedTreatments,
    ...flagOverrides,
  };

  return {
    isReady,
    flags: flagsWithOverrides,
    rawFlags: normalizedTreatments,
    flagOverrides,
  };
};

export const useGetFlagFromEmail = (): ((
  flag: string,
  email: string,
) => Promise<Boolean>) => {
  const { client } = useContext(SplitContext);
  return async (flag, email) =>
    (await client?.getTreatment(flag, {
      email,
    })) === 'on';
};
