import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Duration: { input: any; output: any };
  JSONObject: {
    input: Record<string, unknown>;
    output: Record<string, unknown>;
  };
  LocalDate: { input: string; output: string };
  LocalDateTime: { input: string; output: string };
  LocalTime: { input: string; output: string };
  ZoneId: { input: string; output: string };
  ZonedDateTime: { input: string; output: string };
};

export type ActiveCustomPlanDay = {
  __typename?: 'ActiveCustomPlanDay';
  date: Scalars['LocalDate']['output'];
  itemCompletedCount: Scalars['Int']['output'];
  itemCount: Scalars['Int']['output'];
};

export type Activity = {
  date: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  program?: Maybe<Program>;
  progress: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  trendType?: Maybe<ActivityTrendType>;
  type: ActivityType;
};

export type ActivityAggregates = {
  __typename?: 'ActivityAggregates';
  /** count of activities completed excluding sleep */
  activities: Scalars['Int']['output'];
  /** selected date range */
  date?: Maybe<ZonedDateTimeRange>;
  /** count of days that had at least 1 activity */
  days: Scalars['Int']['output'];
  /** count of real activity minutes excluding sleep */
  minutes: Scalars['Int']['output'];
  minutesAsleepPerDayAverage: Scalars['Int']['output'];
  restingHeartRatePerDayAverage: Scalars['Int']['output'];
};

export type ActivityAttribution = AssignedPractice | AssignedProgram;

export type ActivityConnection = Connection & {
  __typename?: 'ActivityConnection';
  count: Scalars['Int']['output'];
  edges: Array<ActivityEdge>;
  nodes: Array<Activity>;
  pageInfo: PageInfo;
};

export type ActivityEdge = Edge & {
  __typename?: 'ActivityEdge';
  cursor: Scalars['String']['output'];
  node: Activity;
};

export type ActivityFilter = {
  includeIncomplete?: InputMaybe<Scalars['Boolean']['input']>;
  trendType?: InputMaybe<Array<InputMaybe<ActivityTrendType>>>;
  type?: InputMaybe<Array<InputMaybe<ActivityType>>>;
};

export type ActivityGenre = {
  __typename?: 'ActivityGenre';
  canBeAddedManually: Scalars['Boolean']['output'];
  canHaveDistance: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ActivityGenresFilter = {
  canBeAddedManually?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityPayload = {
  __typename?: 'ActivityPayload';
  activity: Activity;
  user: User;
};

export type ActivitySource =
  | Article
  | Audio
  | MboClass
  | PhysicalActivity
  | Survey
  | Video
  | WearableActivity;

export enum ActivityTrendType {
  Classes = 'classes',
  ManualEntry = 'manual_entry',
  Practice = 'practice',
  Program = 'program',
  Sleep = 'sleep',
  /** @deprecated training plans have been retired */
  TrainingPlan = 'training_plan',
  Wearables = 'wearables',
}

export enum ActivityType {
  Aod = 'Aod',
  Article = 'Article',
  Encore = 'Encore',
  InPerson = 'InPerson',
  ManualEntry = 'ManualEntry',
  Virtual = 'Virtual',
  Vod = 'Vod',
  /** @deprecated use wearable sdk specific type */
  Wearable = 'Wearable',
  WearableGoogleFit = 'WearableGoogleFit',
  WearableHealthConnectActivity = 'WearableHealthConnectActivity',
  WearableHealthConnectSleep = 'WearableHealthConnectSleep',
  WearableHealthKit = 'WearableHealthKit',
  WearableHealthKitSleep = 'WearableHealthKitSleep',
}

export type AddPerformanceQualityScoresPayload = {
  __typename?: 'AddPerformanceQualityScoresPayload';
  performanceQualityScores: Array<PerformanceQualityScore>;
};

export type AddReactionInput = {
  kind: ReactionKind;
  messageId: Scalars['ID']['input'];
};

export type AddUserToStudioPayload = {
  __typename?: 'AddUserToStudioPayload';
  studio: Studio;
  user: User;
};

export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  addressLine4?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  localityOrCity?: Maybe<Scalars['String']['output']>;
  postalOrZipCode?: Maybe<Scalars['String']['output']>;
  stateOrDistrictOrProvince?: Maybe<Scalars['String']['output']>;
};

export type AdminBackfillAssessmentAttributionTypeInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminChangeAuthProviderInput = {
  currentEmail: Scalars['String']['input'];
  idpUsername: Scalars['String']['input'];
  provider?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateUserInput = {
  createdAt?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  id: Scalars['ID']['input'];
  setTestPassword?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Answer = EnumAnswer | NumberAnswer | PhotoAnswer | StringAnswer;

export type AnswerSurveyInput = {
  answers: Array<SurveyResponseInput>;
  assessmentId?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  surveyIdentifier: Scalars['String']['input'];
  surveyName?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum App {
  Heart = 'heart',
  Prince = 'prince',
}

export type Article = Content &
  Node & {
    __typename?: 'Article';
    author?: Maybe<Scalars['String']['output']>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    /** @deprecated use contentEquipment */
    equipment: Array<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    gameplanMetadata?: Maybe<GameplanMetadata>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    intensity?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    mediaType?: Maybe<Scalars['String']['output']>;
    mnmrType?: Maybe<Scalars['String']['output']>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    split?: Maybe<Split>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ArticleRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ArticleActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'ArticleActivity';
    brand?: Maybe<Scalars['String']['output']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Article>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type ArticlesConnection = Connection & {
  __typename?: 'ArticlesConnection';
  count: Scalars['Int']['output'];
  edges: Array<ArticlesEdge>;
  nodes: Array<Article>;
  pageInfo: PageInfo;
};

export type ArticlesEdge = Edge & {
  __typename?: 'ArticlesEdge';
  cursor: Scalars['String']['output'];
  node: Article;
};

export type AssessmentAssignment = Node & {
  __typename?: 'AssessmentAssignment';
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  survey?: Maybe<Survey>;
  surveyContent: InAppSurvey;
  template: SurveyTemplate;
};

export type AssessmentSurvey = Node & {
  __typename?: 'AssessmentSurvey';
  id: Scalars['ID']['output'];
  /**
   * whether or not this survey is shown to members
   * these are not directly assignable and are completed by coaches
   * @deprecated Use type instead
   */
  isMemberFacing: Scalars['Boolean']['output'];
  mnmrType?: Maybe<Scalars['String']['output']>;
  template: SurveyTemplate;
  title?: Maybe<Scalars['String']['output']>;
  type: AssessmentType;
};

export type AssessmentSurveyEdge = Edge & {
  __typename?: 'AssessmentSurveyEdge';
  cursor: Scalars['String']['output'];
  node: AssessmentSurvey;
};

export type AssessmentSurveysConnection = Connection & {
  __typename?: 'AssessmentSurveysConnection';
  count: Scalars['Int']['output'];
  edges: Array<AssessmentSurveyEdge>;
  nodes: Array<AssessmentSurvey>;
  pageInfo: PageInfo;
};

export enum AssessmentType {
  Coach = 'Coach',
  Member = 'Member',
  NutritionSpecialist = 'NutritionSpecialist',
}

export type AssignAssessmentsInput = {
  createdAt?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  /** If true, will allow admin users in the dev environment to override assignment date */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  memberId: Scalars['ID']['input'];
  surveyContentIds: Array<Scalars['ID']['input']>;
};

export type AssignAssessmentsPayload = {
  __typename?: 'AssignAssessmentsPayload';
  assessments: Array<AssessmentAssignment>;
};

export type AssignFitCoachPayload = {
  __typename?: 'AssignFitCoachPayload';
  coach: User;
  user: User;
};

export type AssignPracticePayload = {
  __typename?: 'AssignPracticePayload';
  assignedPractice: AssignedPractice;
};

export type AssignProgramPayload = {
  __typename?: 'AssignProgramPayload';
  assignedProgram: AssignedProgram;
};

export type AssignedPractice = {
  __typename?: 'AssignedPractice';
  assigningCoach?: Maybe<User>;
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  completionNotified: Scalars['Boolean']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  isUnenrolled: Scalars['Boolean']['output'];
  practiceProgram?: Maybe<PracticeProgram>;
  user: User;
};

export type AssignedProgram = Node & {
  __typename?: 'AssignedProgram';
  assigningCoach?: Maybe<User>;
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  isUnenrolled: Scalars['Boolean']['output'];
  program?: Maybe<Program>;
  user: User;
};

export type Audio = Content &
  Node & {
    __typename?: 'Audio';
    audio: Scalars['String']['output'];
    author?: Maybe<Scalars['String']['output']>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    /** @deprecated use contentEquipment */
    equipment: Array<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    gameplanMetadata?: Maybe<GameplanMetadata>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    intensity?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    split?: Maybe<Split>;
    subGenre?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
    transcript?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['String']['output']>;
  };

export type AudioRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type AudioActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'AudioActivity';
    brand?: Maybe<Scalars['String']['output']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Audio>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type AuditLog = Node & {
  __typename?: 'AuditLog';
  action: Scalars['String']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  data?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  principal: Principal;
};

export type AuditLogEdge = Edge & {
  __typename?: 'AuditLogEdge';
  cursor: Scalars['String']['output'];
  node: AuditLog;
};

export type AuditLogsConnection = Connection & {
  __typename?: 'AuditLogsConnection';
  count: Scalars['Int']['output'];
  edges: Array<AuditLogEdge>;
  nodes: Array<AuditLog>;
  pageInfo: PageInfo;
};

export type BackfillUserProfileFieldsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Banner = Content &
  Node & {
    __typename?: 'Banner';
    author?: Maybe<Scalars['String']['output']>;
    bannerPhoto?: Maybe<Photo>;
    buttonText?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    link: Scalars['String']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    snackbarText?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type BannerRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type BlockUserInput = {
  isBlocked: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type BlockUserPayload = {
  __typename?: 'BlockUserPayload';
  user?: Maybe<User>;
};

export type BookClassInput = {
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  mboClassId: Scalars['Int']['input'];
  mboStudioId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type BookClassPayload = {
  __typename?: 'BookClassPayload';
  mboClass: MboClass;
};

export type BookSessionInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  calendlyId?: InputMaybe<Scalars['ID']['input']>;
  chatRoomId?: InputMaybe<Scalars['ID']['input']>;
  coachId: Scalars['ID']['input'];
  duration: Scalars['Int']['input'];
  eventId?: InputMaybe<Scalars['ID']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  joinURL?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  sessionStyle?: InputMaybe<CoachSessionStyle>;
  sessionSubtype?: InputMaybe<CoachSessionSubtype>;
  sessionType?: InputMaybe<CoachSessionType>;
  start: Scalars['ZonedDateTime']['input'];
  useCronofy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookSessionPayload = {
  __typename?: 'BookSessionPayload';
  session: CoachSession;
};

export enum BotKind {
  Automessages = 'automessages',
  Methodology = 'methodology',
}

export type BulkReassignCoachMembersPayload = {
  __typename?: 'BulkReassignCoachMembersPayload';
  userCountReassigned: Scalars['Int']['output'];
  userIdsNotReassigned: Array<Scalars['String']['output']>;
};

export type Calendar = Node & {
  __typename?: 'Calendar';
  duration: Scalars['Int']['output'];
  end: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  start: Scalars['ZonedDateTime']['output'];
};

export type CalendarBuffer = {
  __typename?: 'CalendarBuffer';
  afterInMinutes: Scalars['Int']['output'];
  beforeInMinutes: Scalars['Int']['output'];
};

export type CalendarProfile = {
  __typename?: 'CalendarProfile';
  platform?: Maybe<ClientCalendarPlatform>;
  profileConnected: Scalars['Boolean']['output'];
  profileId: Scalars['String']['output'];
  profileInitialSyncRequired: Scalars['Boolean']['output'];
  profileName: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  providerService?: Maybe<Scalars['String']['output']>;
};

export type CancelClassInput = {
  mboClassId: Scalars['Int']['input'];
  mboStudioId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CancelClassPayload = {
  __typename?: 'CancelClassPayload';
  mboClass: MboClass;
};

export type Challenge = Node & {
  __typename?: 'Challenge';
  activity: ChallengesActivityConnection;
  auditLogs: AuditLogsConnection;
  background?: Maybe<Photo>;
  badge?: Maybe<Photo>;
  challengeType: ChallengeType;
  challenger?: Maybe<User>;
  clientSpecific: Scalars['Boolean']['output'];
  clients: ChallengeClientsConnection;
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  /** @deprecated Use endDateTime */
  endDate: Scalars['LocalDate']['output'];
  endDateTime: Scalars['ZonedDateTime']['output'];
  enrolled: Scalars['Boolean']['output'];
  featured: Scalars['Boolean']['output'];
  goal: Scalars['Float']['output'];
  hasSeenEarnedBadge: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isChallenger: Scalars['Boolean']['output'];
  participants: ChallengeParticipantConnection;
  place: Scalars['String']['output'];
  qualifyingContentGenres: Array<Genre>;
  /** @deprecated Use qualifyingContentGenres */
  qualifyingGenres: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  scoreUnit: ScoreUnit;
  share?: Maybe<Share>;
  /** @deprecated Use startDateTime */
  startDate: Scalars['LocalDate']['output'];
  startDateTime: Scalars['ZonedDateTime']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  team: Scalars['Boolean']['output'];
  teamScore: Scalars['Float']['output'];
  teams: ChallengeTeamConnection;
  title: Scalars['String']['output'];
  /** @deprecated Use scoreUnit */
  unit: Scalars['String']['output'];
};

export type ChallengeActivityArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeClientsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeParticipantsArgs = {
  filter?: InputMaybe<ParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ParticipantsOrderBy>>;
};

export type ChallengeTeamsArgs = {
  filter?: InputMaybe<ChallengeTeamsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeClientsConnection = Connection & {
  __typename?: 'ChallengeClientsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengeClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type ChallengeClientsEdge = Edge & {
  __typename?: 'ChallengeClientsEdge';
  cursor: Scalars['String']['output'];
  node: Client;
};

export type ChallengeEdge = Edge & {
  __typename?: 'ChallengeEdge';
  cursor: Scalars['String']['output'];
  node: Challenge;
};

export type ChallengeFilter = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum ChallengeOrder {
  Enabled = 'enabled',
  EndDate = 'endDate',
  Featured = 'featured',
  StartDate = 'startDate',
  Title = 'title',
}

export type ChallengeParticipantConnection = Connection & {
  __typename?: 'ChallengeParticipantConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengeParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ChallengeParticipantsEdge = Edge & {
  __typename?: 'ChallengeParticipantsEdge';
  challengeTeam?: Maybe<ChallengeTeam>;
  completed: Scalars['Boolean']['output'];
  cursor: Scalars['String']['output'];
  node: User;
  place: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type ChallengeSort = {
  field: ChallengeOrder;
  order: OrderDirection;
};

export type ChallengeTeam = Node & {
  __typename?: 'ChallengeTeam';
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  id: Scalars['ID']['output'];
  joined: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  participants: ChallengeParticipantConnection;
};

export type ChallengeTeamParticipantsArgs = {
  filter?: InputMaybe<ParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ParticipantsOrderBy>>;
};

export type ChallengeTeamConnection = Connection & {
  __typename?: 'ChallengeTeamConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengeTeamsEdge>;
  nodes: Array<ChallengeTeam>;
  pageInfo: PageInfo;
};

export type ChallengeTeamsEdge = Edge & {
  __typename?: 'ChallengeTeamsEdge';
  completed: Scalars['Boolean']['output'];
  cursor: Scalars['String']['output'];
  node: ChallengeTeam;
  place: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type ChallengeTeamsFilter = {
  appendSelf?: InputMaybe<Scalars['Boolean']['input']>;
  excludeTeamsWithoutScore?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ChallengeType {
  ClassCount = 'ClassCount',
  Distance = 'Distance',
  Time = 'Time',
}

export type ChallengesActivityConnection = Connection & {
  __typename?: 'ChallengesActivityConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengesActivityEdge>;
  nodes: Array<Activity>;
  pageInfo: PageInfo;
};

export type ChallengesActivityEdge = Edge & {
  __typename?: 'ChallengesActivityEdge';
  cursor: Scalars['String']['output'];
  node: Activity;
};

export type ChallengesFilter = {
  excludeActiveChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  excludeEarnedBadgesSeen?: InputMaybe<Scalars['Boolean']['input']>;
  excludeFutureChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  excludeIncompleteChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  excludePastChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  includeCustomChallenges?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChatRoom = Node & {
  __typename?: 'ChatRoom';
  app?: Maybe<App>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  /** @deprecated No longer supported */
  isStarred: Scalars['Boolean']['output'];
  kind: ChatRoomKind;
  lastMessageRead?: Maybe<Scalars['ZonedDateTime']['output']>;
  lastMessageReceivedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  messages: ChatRoomMessagesConnection;
  name?: Maybe<Scalars['String']['output']>;
  users: ChatRoomUsersConnection;
};

export type ChatRoomMessagesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export enum ChatRoomKind {
  Bot = 'bot',
  Direct = 'direct',
  Generic = 'generic',
  /** @deprecated No longer supported */
  Team = 'team',
}

export type ChatRoomMessagesConnection = Connection & {
  __typename?: 'ChatRoomMessagesConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChatRoomMessagesEdge>;
  nodes: Array<Message>;
  pageInfo: PageInfo;
  unreadMessageCount: Scalars['Int']['output'];
};

export type ChatRoomMessagesEdge = Edge & {
  __typename?: 'ChatRoomMessagesEdge';
  cursor: Scalars['String']['output'];
  node: Message;
};

export type ChatRoomUsersConnection = Connection & {
  __typename?: 'ChatRoomUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChatRoomUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ChatRoomUsersEdge = Edge & {
  __typename?: 'ChatRoomUsersEdge';
  cursor: Scalars['String']['output'];
  lastMessageRead?: Maybe<Scalars['ZonedDateTime']['output']>;
  node: User;
};

export type ChatRoomsFilter = {
  apps?: InputMaybe<Array<App>>;
  createdAtDateRange?: InputMaybe<DateRange>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  kinds?: InputMaybe<Array<ChatRoomKind>>;
  name?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export enum ChatRoomsOrder {
  LastFitActivity = 'lastFitActivity',
  LastMessageReceivedAt = 'lastMessageReceivedAt',
  LastPerformActivity = 'lastPerformActivity',
  UserJoinedAt = 'userJoinedAt',
  /** @deprecated use lastFitActivity or lastPerformActivity */
  UserLastActivity = 'userLastActivity',
}

export type ChatRoomsOrderBy = {
  direction: OrderDirection;
  order: ChatRoomsOrder;
};

export type ClickThroughMedia = Content &
  Node & {
    __typename?: 'ClickThroughMedia';
    author?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    icon?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    pages: ContentConnection;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ClickThroughMediaPagesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClickThroughMediaRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type Client = Node & {
  __typename?: 'Client';
  auditLogs: AuditLogsConnection;
  calendarPlatform?: Maybe<ClientCalendarPlatform>;
  coaches: ClientUsersConnection;
  collectsMemberData: Scalars['Boolean']['output'];
  collectsMemberDataForEEP: Scalars['Boolean']['output'];
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  cronofyConferencingUrl?: Maybe<Scalars['String']['output']>;
  cronofyExternalCalendarUrl?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  domains: DomainConnection;
  id: Scalars['ID']['output'];
  inactivityDuration?: Maybe<Scalars['Duration']['output']>;
  isArchived: Scalars['Boolean']['output'];
  logo?: Maybe<Photo>;
  maxFamilyMembers: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  needsUsToFixTheirUserNames: Scalars['Boolean']['output'];
  ssoIdentity?: Maybe<SsoIdentity>;
  /** if true, members from this client will be subject to stricter app check enforcement than other clients */
  strictAppCheck: Scalars['Boolean']['output'];
  studios: Array<Studio>;
  users: ClientUsersConnection;
  usesCustomChallenges: Scalars['Boolean']['output'];
  usesEligibility: Scalars['Boolean']['output'];
  usesEligibilityServiceType: Scalars['Boolean']['output'];
  usesFitnessCenterForEligibility: Scalars['Boolean']['output'];
  usesGlobalSignOut: Scalars['Boolean']['output'];
  usesHighPerformanceCoaching: Scalars['Boolean']['output'];
  usesPersonalTraining: Scalars['Boolean']['output'];
  usesSSO: Scalars['Boolean']['output'];
  /** validLocations are locations with coach assignments */
  validLocations: CoachLocationsConnection;
  videoPlatform?: Maybe<ClientVideoPlatform>;
};

export type ClientAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClientCoachesArgs = {
  coachName?: InputMaybe<Scalars['String']['input']>;
  excludeInvalidCoaches?: InputMaybe<Scalars['Boolean']['input']>;
  input?: InputMaybe<ConnectionInput>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  random?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<CoachType>;
};

export type ClientCronofyConferencingUrlArgs = {
  returnUrl: Scalars['String']['input'];
};

export type ClientCronofyExternalCalendarUrlArgs = {
  returnUrl: Scalars['String']['input'];
};

export type ClientDomainsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClientStudiosArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  includeCentralStudio?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ClientValidLocationsArgs = {
  filter?: InputMaybe<LocationsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export enum ClientCalendarPlatform {
  Apple = 'apple',
  Exchange = 'exchange',
  ExosOnly = 'exos_only',
  Google = 'google',
  LiveConnect = 'live_connect',
  Office365 = 'office365',
}

export type ClientUsersConnection = Connection & {
  __typename?: 'ClientUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<ClientUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ClientUsersEdge = Edge & {
  __typename?: 'ClientUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export enum ClientVideoPlatform {
  EightByEight = 'eight_by_eight',
  GoTo = 'go_to',
  Indeed = 'indeed',
  Integrated = 'integrated',
  MsTeams = 'ms_teams',
  Zoom = 'zoom',
}

export type ClientsFilter = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CoachBasedActivity = {
  coachAvatar?: Maybe<Photo>;
  coachFirstName?: Maybe<Scalars['String']['output']>;
  coachLastName?: Maybe<Scalars['String']['output']>;
};

export type CoachData = {
  __typename?: 'CoachData';
  availability: UserAvailabilityConnection;
  availableForMemberAssignment: Scalars['Boolean']['output'];
  bio: Scalars['String']['output'];
  bodyPhoto?: Maybe<Photo>;
  calendarBuffer: CalendarBuffer;
  calendarConnected: Scalars['Boolean']['output'];
  calendarProfiles: Array<CalendarProfile>;
  calendlyUrl?: Maybe<Scalars['String']['output']>;
  clients: RootClientsConnection;
  coachUsers: CoachUsersConnection;
  coachingStyle?: Maybe<CoachingStyle>;
  conferencingProfiles: Array<ConferencingProfile>;
  cronofyAuthUrl?: Maybe<Scalars['String']['output']>;
  cronofyConferencingUrl?: Maybe<Scalars['String']['output']>;
  cronofyElementToken?: Maybe<Scalars['String']['output']>;
  cronofyExternalCalendarUrl?: Maybe<Scalars['String']['output']>;
  cronofySub?: Maybe<Scalars['String']['output']>;
  cronofyTimezone?: Maybe<Scalars['ZoneId']['output']>;
  dailySessionLimit?: Maybe<Scalars['Int']['output']>;
  degrees: Array<Scalars['String']['output']>;
  externalCalendarConnected?: Maybe<ClientCalendarPlatform>;
  fitCoachId?: Maybe<Scalars['String']['output']>;
  headshot?: Maybe<Photo>;
  id: Scalars['ID']['output'];
  inOfficeDays: Array<Scalars['Int']['output']>;
  /** @deprecated Use calendlyUrl instead */
  inPersonCalendlyUrl?: Maybe<Scalars['String']['output']>;
  locations: CoachLocationsConnection;
  mboStaffId?: Maybe<Scalars['String']['output']>;
  passions: Array<Scalars['String']['output']>;
  sessions: UserCoachSessionsConnection;
  supportedCountries: Array<Country>;
  supportedGoals: Array<Goal>;
  titles: Array<Scalars['String']['output']>;
  totalUnreadMessageCount: Scalars['Int']['output'];
  touchPoints: CoachTouchPointConnection;
  type?: Maybe<CoachType>;
  videoPlatformConnected?: Maybe<ClientVideoPlatform>;
  /** @deprecated We do not use Zoom API directly anymore */
  zoomId?: Maybe<Scalars['String']['output']>;
};

export type CoachDataAvailabilityArgs = {
  bypassOneDayDelay?: InputMaybe<Scalars['Boolean']['input']>;
  duration: Scalars['Int']['input'];
  interval: Scalars['Int']['input'];
  sessionStyle?: InputMaybe<CoachSessionStyle>;
  start: Scalars['ZonedDateTime']['input'];
};

export type CoachDataClientsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachDataCoachUsersArgs = {
  filter?: InputMaybe<CoachUsersFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachUsersOrderBy>>;
};

export type CoachDataCronofyAuthUrlArgs = {
  returnUrl: Scalars['String']['input'];
};

export type CoachDataCronofyConferencingUrlArgs = {
  returnUrl: Scalars['String']['input'];
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type CoachDataCronofyElementTokenArgs = {
  permissions?: InputMaybe<Array<CronofyElementPermission>>;
};

export type CoachDataCronofyExternalCalendarUrlArgs = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  returnUrl: Scalars['String']['input'];
};

export type CoachDataLocationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachDataSessionsArgs = {
  filter?: InputMaybe<CoachSessionFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachSessionOrderBy>>;
};

export type CoachDataTouchPointsArgs = {
  filter?: InputMaybe<TouchPointFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<TouchPointsOrderBy>>;
};

export type CoachLocation = Node & {
  __typename?: 'CoachLocation';
  auditLogs: AuditLogsConnection;
  coaches: LocationCoachesConnection;
  id: Scalars['ID']['output'];
  isHeadquarters: Scalars['Boolean']['output'];
  mboLocationId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  studio?: Maybe<Studio>;
};

export type CoachLocationAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachLocationCoachesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachLocationsConnection = Connection & {
  __typename?: 'CoachLocationsConnection';
  count: Scalars['Int']['output'];
  edges: Array<CoachLocationsEdge>;
  nodes: Array<CoachLocation>;
  pageInfo: PageInfo;
};

export type CoachLocationsEdge = Edge & {
  __typename?: 'CoachLocationsEdge';
  cursor: Scalars['String']['output'];
  node: CoachLocation;
};

export type CoachLocationsFilter = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  studioIsArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoachSession = Node & {
  __typename?: 'CoachSession';
  additionalInfo?: Maybe<Scalars['String']['output']>;
  attendance?: Maybe<CoachSessionAttendance>;
  attended?: Maybe<Scalars['Boolean']['output']>;
  calendar?: Maybe<Calendar>;
  cancelUrl?: Maybe<Scalars['String']['output']>;
  cancellableUntil: Scalars['ZonedDateTime']['output'];
  chatRoom?: Maybe<ChatRoom>;
  coach: User;
  id: Scalars['ID']['output'];
  joinableTime: Scalars['ZonedDateTime']['output'];
  location?: Maybe<CoachLocation>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  member: User;
  reason?: Maybe<Scalars['String']['output']>;
  rescheduleUrl?: Maybe<Scalars['String']['output']>;
  sessionStyle: CoachSessionStyle;
  sessionSubtype?: Maybe<CoachSessionSubtype>;
  sessionType?: Maybe<CoachSessionType>;
  /** @deprecated Use sessionType instead */
  type: Scalars['String']['output'];
};

export enum CoachSessionAttendance {
  Attended = 'Attended',
  Excused = 'Excused',
  NoShow = 'NoShow',
}

export type CoachSessionCredits = {
  __typename?: 'CoachSessionCredits';
  count: Scalars['Int']['output'];
  rowVersion: Scalars['Int']['output'];
};

export type CoachSessionFilter = {
  attendance?: InputMaybe<CoachSessionAttendance>;
  attendances?: InputMaybe<Array<InputMaybe<CoachSessionAttendance>>>;
  coachId?: InputMaybe<Scalars['ID']['input']>;
  coachSessionStyle?: InputMaybe<Array<CoachSessionStyle>>;
  dateRange?: InputMaybe<DateRange>;
  memberName?: InputMaybe<Scalars['String']['input']>;
  sessionType?: InputMaybe<CoachSessionType>;
  sessionTypes?: InputMaybe<Array<CoachSessionType>>;
};

export enum CoachSessionOrder {
  SessionTime = 'sessionTime',
}

export type CoachSessionOrderBy = {
  direction: OrderDirection;
  order: CoachSessionOrder;
};

export enum CoachSessionStyle {
  InPerson = 'InPerson',
  Virtual = 'Virtual',
}

export enum CoachSessionSubtype {
  BodyCompositionAssessment = 'BodyCompositionAssessment',
  ErgonomicEvaluation = 'ErgonomicEvaluation',
  MovementAssessment = 'MovementAssessment',
  NewMemberOrientation = 'NewMemberOrientation',
}

export enum CoachSessionType {
  CoachingSession = 'CoachingSession',
  PersonalTraining = 'PersonalTraining',
  UserCoachingConsult = 'UserCoachingConsult',
  UserNutritionConsult = 'UserNutritionConsult',
}

export type CoachTouchPoint = Node & {
  __typename?: 'CoachTouchPoint';
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  description: Scalars['String']['output'];
  due: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  member: User;
  metadata?: Maybe<CoachTouchPointMetadata>;
  status: CoachTouchPointStatus;
  title: Scalars['String']['output'];
  type: CoachTouchPointType;
};

export type CoachTouchPointConnection = Connection & {
  __typename?: 'CoachTouchPointConnection';
  count: Scalars['Int']['output'];
  edges: Array<CoachTouchPointEdge>;
  nodes: Array<CoachTouchPoint>;
  pageInfo: PageInfo;
};

export type CoachTouchPointEdge = Edge & {
  __typename?: 'CoachTouchPointEdge';
  cursor: Scalars['String']['output'];
  node: CoachTouchPoint;
};

export type CoachTouchPointMetadata = CoachTouchPointSessionMetadata;

export enum CoachTouchPointOrder {
  Due = 'due',
}

export type CoachTouchPointSessionMetadata = {
  __typename?: 'CoachTouchPointSessionMetadata';
  isCancelled: Scalars['Boolean']['output'];
  isRebooked: Scalars['Boolean']['output'];
  session?: Maybe<CoachSession>;
};

export enum CoachTouchPointStatus {
  Completed = 'Completed',
  Incomplete = 'Incomplete',
}

export enum CoachTouchPointType {
  AssessmentNotCompleted = 'AssessmentNotCompleted',
  CompleteFirstDailyCheckIn = 'CompleteFirstDailyCheckIn',
  Custom = 'Custom',
  CustomPlanAdherence = 'CustomPlanAdherence',
  CustomPlanCheckInNote = 'CustomPlanCheckInNote',
  CustomPlanExpired = 'CustomPlanExpired',
  CustomPlanExpiring = 'CustomPlanExpiring',
  DailyCheckInNotCompleted = 'DailyCheckInNotCompleted',
  NewMemberActivationAtRisk = 'NewMemberActivationAtRisk',
  OneWeekPostSessionCheckIn = 'OneWeekPostSessionCheckIn',
  ReassessmentReminder = 'ReassessmentReminder',
  SendMessageReminder = 'SendMessageReminder',
  SessionReminder = 'SessionReminder',
  TrackAttendance = 'TrackAttendance',
}

export enum CoachType {
  Coach = 'Coach',
  Dietitian = 'Dietitian',
  PerformCoach = 'PerformCoach',
}

export enum CoachUserCoachingExperienceTypeFilter {
  HighPerformance = 'highPerformance',
  Wellbeing = 'wellbeing',
}

export enum CoachUserGeneralFilter {
  HasUnreadMessages = 'hasUnreadMessages',
  IsStarred = 'isStarred',
}

export enum CoachUserSessionFilter {
  HasAttendedSession = 'hasAttendedSession',
  HasUpcomingSession = 'hasUpcomingSession',
  NoAttendedSession = 'noAttendedSession',
  NoUpcomingSession = 'noUpcomingSession',
}

export enum CoachUserStatusFilter {
  IsActive = 'isActive',
  IsInactive = 'isInactive',
  IsNew = 'isNew',
}

export type CoachUsersConnection = Connection & {
  __typename?: 'CoachUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<CoachUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type CoachUsersEdge = Edge & {
  __typename?: 'CoachUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type CoachUsersFilter = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  coachingExperienceType?: InputMaybe<
    Array<CoachUserCoachingExperienceTypeFilter>
  >;
  createdAtDateRange?: InputMaybe<DateRange>;
  general?: InputMaybe<Array<CoachUserGeneralFilter>>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  recentActivityDateRange?: InputMaybe<DateRange>;
  session?: InputMaybe<Array<CoachUserSessionFilter>>;
  status?: InputMaybe<Array<CoachUserStatusFilter>>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export enum CoachUsersOrder {
  /** @deprecated No longer supported */
  LastFitActivity = 'lastFitActivity',
  LastMessageReceivedAt = 'lastMessageReceivedAt',
  LatestUserActivityStartTime = 'latestUserActivityStartTime',
}

export type CoachUsersOrderBy = {
  direction: OrderDirection;
  order: CoachUsersOrder;
};

export enum CoachingStyle {
  Hybrid = 'Hybrid',
  Virtual = 'Virtual',
}

export type ConferencingProfile = {
  __typename?: 'ConferencingProfile';
  platform?: Maybe<ClientVideoPlatform>;
  profileConnected: Scalars['Boolean']['output'];
  profileId: Scalars['String']['output'];
  profileName: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
};

export type Connection = {
  count: Scalars['Int']['output'];
  edges: Array<Edge>;
  nodes: Array<Node>;
  pageInfo: PageInfo;
};

export type ConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Content = {
  author?: Maybe<Scalars['String']['output']>;
  contentEquipment: Array<Equipment>;
  contentGenre?: Maybe<Genre>;
  contentLevel?: Maybe<Level>;
  contentSplit?: Maybe<Split>;
  contentTime?: Maybe<ContentTime>;
  feedback?: Maybe<FeedbackKind>;
  heroPhoto?: Maybe<Photo>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  /** @deprecated use contentLevel */
  level?: Maybe<Scalars['String']['output']>;
  recommendations: ContentRecommendationsConnection;
  saved?: Maybe<Scalars['Boolean']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  /** @deprecated use contentTime */
  time?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  trainingCommitment?: Maybe<Scalars['String']['output']>;
};

export type ContentRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentAttribute = {
  __typename?: 'ContentAttribute';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ContentCollection = {
  __typename?: 'ContentCollection';
  id: Scalars['ID']['output'];
  sections: ContentCollectionSectionsConnection;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ContentCollectionSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentCollectionSectionsConnection = Connection & {
  __typename?: 'ContentCollectionSectionsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContentCollectionSectionsEdge>;
  nodes: Array<ContentSection>;
  pageInfo: PageInfo;
};

export type ContentCollectionSectionsEdge = Edge & {
  __typename?: 'ContentCollectionSectionsEdge';
  cursor: Scalars['String']['output'];
  node: ContentSection;
};

export type ContentConnection = SimpleContentConnection & {
  __typename?: 'ContentConnection';
  completedCount: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  edges: Array<ContentEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentEdge = SimpleContentEdge & {
  __typename?: 'ContentEdge';
  completed: Scalars['Boolean']['output'];
  cursor: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  node: Content;
  progress?: Maybe<Scalars['Float']['output']>;
};

export type ContentFeedbackPayload = {
  __typename?: 'ContentFeedbackPayload';
  content: Content;
};

export type ContentFilter = {
  associatedProject?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  component?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Array<Scalars['Int']['input']>>;
  equipment?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanPrimaryComponent?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanPrimarySubcomponents?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanSecondaryComponent?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanSecondarySubcomponents?: InputMaybe<
    Array<Scalars['String']['input']>
  >;
  genre?: InputMaybe<Array<Scalars['String']['input']>>;
  intensity?: InputMaybe<Array<Scalars['String']['input']>>;
  isExperiential?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Array<Scalars['String']['input']>>;
  performanceQuality?: InputMaybe<Array<Scalars['String']['input']>>;
  split?: InputMaybe<Array<Scalars['String']['input']>>;
  subGenre?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ContentKind {
  Article = 'article',
  Inappsurvey = 'inappsurvey',
  Link = 'link',
  Video = 'video',
}

export enum ContentOrder {
  PublishedAt = 'published_at',
  Title = 'title',
}

export type ContentOrderBy = {
  direction: OrderDirection;
  order?: InputMaybe<ContentOrder>;
};

export type ContentRecommendationsConnection = SimpleContentConnection & {
  __typename?: 'ContentRecommendationsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContentRecommendationsEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentRecommendationsEdge = SimpleContentEdge & {
  __typename?: 'ContentRecommendationsEdge';
  cursor: Scalars['String']['output'];
  node: Content;
};

export type ContentSavedConnection = SimpleContentConnection & {
  __typename?: 'ContentSavedConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContentSavedEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentSavedEdge = SimpleContentEdge & {
  __typename?: 'ContentSavedEdge';
  cursor: Scalars['String']['output'];
  node: Content;
};

export type ContentSection = Content &
  Node & {
    __typename?: 'ContentSection';
    author?: Maybe<Scalars['String']['output']>;
    bannerPhoto?: Maybe<Photo>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    contents: ContentConnection;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    logoPhoto?: Maybe<Photo>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ContentSectionContentsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentSectionRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentTime = {
  __typename?: 'ContentTime';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Country = {
  __typename?: 'Country';
  alpha2: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CreateChallengeInput = {
  backgroundId?: InputMaybe<Scalars['String']['input']>;
  badgeId?: InputMaybe<Scalars['String']['input']>;
  challengeType: ChallengeType;
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  clientSpecific?: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  distanceUnit?: InputMaybe<DistanceUnit>;
  enabled: Scalars['Boolean']['input'];
  endDate: Scalars['LocalDate']['input'];
  featured: Scalars['Boolean']['input'];
  goal: Scalars['Float']['input'];
  qualifyingGenres: Array<Scalars['String']['input']>;
  startDate: Scalars['LocalDate']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['Boolean']['input']>;
  teams?: InputMaybe<Array<CreateChallengeTeamInput>>;
  title: Scalars['String']['input'];
};

export type CreateChallengePayload = {
  __typename?: 'CreateChallengePayload';
  challenge: Challenge;
};

export type CreateChallengeTeamInput = {
  name: Scalars['String']['input'];
};

export type CreateChatRoomInput = {
  app?: InputMaybe<App>;
  initialMessage?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<ChatRoomKind>;
  name: Scalars['String']['input'];
  users: Array<Scalars['String']['input']>;
};

export type CreateClientInput = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  collectsMemberData?: InputMaybe<Scalars['Boolean']['input']>;
  collectsMemberDataForEEP?: InputMaybe<Scalars['Boolean']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  domainIds?: InputMaybe<Array<Scalars['String']['input']>>;
  inactivityDuration?: InputMaybe<Scalars['Duration']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  maxFamilyMembers?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needsUsToFixTheirUserNames?: InputMaybe<Scalars['Boolean']['input']>;
  ssoConnection?: InputMaybe<Scalars['String']['input']>;
  strictAppCheck?: InputMaybe<Scalars['Boolean']['input']>;
  usesEligibilityServiceType?: InputMaybe<Scalars['Boolean']['input']>;
  usesFitnessCenterForEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  usesGlobalSignOut?: InputMaybe<Scalars['Boolean']['input']>;
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type CreateCoachLocationInput = {
  isHeadquarters: Scalars['Boolean']['input'];
  mboLocationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  stateCode: Scalars['String']['input'];
  studioId: Scalars['String']['input'];
};

export type CreateCoachTouchPointInput = {
  coachId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  due?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  memberId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  sessionTime?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: CoachTouchPointType;
};

export type CreateCustomChallengeInput = {
  challengeType: ChallengeType;
  endDate: Scalars['LocalDate']['input'];
  goal: Scalars['Int']['input'];
  qualifyingGenres: Array<Scalars['String']['input']>;
  startDate: Scalars['LocalDate']['input'];
};

export type CreateDomainInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
};

export type CreateEligibleEmailAddressInput = {
  clientId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hpcEligibilityEnd?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  hpcEligibilityStart?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateNoteInput = {
  content: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateNotificationOptionInput = {
  app: App;
  description: Scalars['String']['input'];
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  iterableId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  visible: Scalars['Boolean']['input'];
};

export type CreatePerformanceQualityScoreInput = {
  component: Scalars['String']['input'];
  performanceQuality: Scalars['String']['input'];
  score: Scalars['Int']['input'];
  subComponent: Scalars['String']['input'];
};

export type CreatePlanInput = {
  /** If true, will allow admin users in the dev environment to override start date restrictions */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  planItems?: InputMaybe<Array<CreatePlanItemInput>>;
  startDate: Scalars['LocalDate']['input'];
  targetedPerformanceQualities?: InputMaybe<Array<PerformanceQuality>>;
  userId: Scalars['ID']['input'];
};

export type CreatePlanItemInput = {
  component?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['ID']['input']>;
  date: Scalars['LocalDate']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type: PlanItemType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePlanPayload = {
  __typename?: 'CreatePlanPayload';
  plan: Plan;
};

export type CreateStudioInput = {
  clientId: Scalars['String']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isCentral?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual: Scalars['Boolean']['input'];
  mboServiceId: Scalars['String']['input'];
  mboStudioId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

export type CreateUserGoalInput = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  coachNote?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  memberId?: InputMaybe<Scalars['ID']['input']>;
  type: UserGoalType;
};

export type CreateUserGoalPayload = {
  __typename?: 'CreateUserGoalPayload';
  user: User;
};

export type CreateUserInput = {
  adminCreate?: InputMaybe<Scalars['Boolean']['input']>;
  app?: InputMaybe<App>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  isCoach?: InputMaybe<Scalars['Boolean']['input']>;
  isTestUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']['input']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  user: User;
};

export type CreateUsersInput = {
  users: Array<CreateUserInput>;
};

export type CreateUsersPayload = {
  __typename?: 'CreateUsersPayload';
  users: Array<User>;
};

export enum CronofyElementPermission {
  Agenda = 'agenda',
  Availability = 'availability',
  ManagedAvailability = 'managed_availability',
}

export type CustomPlanActiveDaysFilter = {
  localDateRange: RequiredLocalDateRange;
};

export type CustomPlansFilter = {
  localDateRange?: InputMaybe<LocalDateRange>;
};

export type CustomTaskPlanItem = Node &
  PlanItem & {
    __typename?: 'CustomTaskPlanItem';
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated Use contentComponent instead */
    component?: Maybe<Scalars['String']['output']>;
    contentComponent?: Maybe<ContentAttribute>;
    date: Scalars['LocalDate']['output'];
    description: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    order: Scalars['Int']['output'];
    title: Scalars['String']['output'];
    type: PlanItemType;
    url?: Maybe<Scalars['String']['output']>;
  };

export enum DateFilter {
  Future = 'Future',
  Past = 'Past',
}

export type DateRange = {
  end?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  start?: InputMaybe<Scalars['ZonedDateTime']['input']>;
};

export type DeleteFitCoachPayload = {
  __typename?: 'DeleteFitCoachPayload';
  user: User;
};

export type DeletePlanPayload = {
  __typename?: 'DeletePlanPayload';
  plan: Plan;
};

export type DeleteUserGoalPayload = {
  __typename?: 'DeleteUserGoalPayload';
  user: User;
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  userId: Scalars['ID']['output'];
};

export type Device = {
  __typename?: 'Device';
  app?: Maybe<App>;
  createdAt: Scalars['ZonedDateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updatedAt: Scalars['ZonedDateTime']['output'];
  user: User;
};

export type Distance = {
  __typename?: 'Distance';
  unit?: Maybe<DistanceUnit>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DistanceInput = {
  unit: DistanceUnit;
  value: Scalars['Float']['input'];
};

export enum DistanceUnit {
  Kilometers = 'Kilometers',
  Meters = 'Meters',
  Miles = 'Miles',
  Yards = 'Yards',
}

export type Domain = Node & {
  __typename?: 'Domain';
  client?: Maybe<Client>;
  createdAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DomainConnection = {
  __typename?: 'DomainConnection';
  count: Scalars['Int']['output'];
  edges: Array<DomainEdge>;
  nodes: Array<Domain>;
  pageInfo: PageInfo;
};

export type DomainEdge = Edge & {
  __typename?: 'DomainEdge';
  cursor: Scalars['String']['output'];
  node: Domain;
};

export type DomainsFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Edge = {
  cursor: Scalars['String']['output'];
  node: Node;
};

export type EditActivityInput = {
  distance?: InputMaybe<DistanceInput>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['ZonedDateTime']['input']>;
};

export type EligibleEmailAddress = Node & {
  __typename?: 'EligibleEmailAddress';
  archivedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  auditLogs: AuditLogsConnection;
  client?: Maybe<Client>;
  createdAt: Scalars['ZonedDateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['String']['output']>;
  /** @deprecated firstName is no longer required */
  firstName?: Maybe<Scalars['String']['output']>;
  hasHpcEligibility: Scalars['Boolean']['output'];
  hpcEligibilityEnd?: Maybe<Scalars['ZonedDateTime']['output']>;
  hpcEligibilityStart?: Maybe<Scalars['ZonedDateTime']['output']>;
  id: Scalars['ID']['output'];
  invitingRelationship?: Maybe<FamilyRelationship>;
  invitingUser?: Maybe<User>;
  isArchived: Scalars['Boolean']['output'];
  isStaff: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  shareInvitation?: Maybe<Share>;
  updatedAt: Scalars['ZonedDateTime']['output'];
  user?: Maybe<User>;
};

export type EligibleEmailAddressAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type EligibleEmailAddressDetailsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type EligibleEmailAddressesFilter = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['String']['input']>;
  hasHpcEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type EmergencyContactDetails = {
  __typename?: 'EmergencyContactDetails';
  address?: Maybe<Address>;
  emergencyContact?: Maybe<EmergencyContact>;
  updatedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type EnumAnswer = {
  __typename?: 'EnumAnswer';
  choices: Array<Scalars['String']['output']>;
  enumValue: Array<Scalars['Int']['output']>;
};

export type Equipment = {
  __typename?: 'Equipment';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ExosContentPlanItem = Node &
  PlanItem & {
    __typename?: 'ExosContentPlanItem';
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated Use contentComponent instead */
    component?: Maybe<Scalars['String']['output']>;
    content: Content;
    contentComponent?: Maybe<ContentAttribute>;
    date: Scalars['LocalDate']['output'];
    id: Scalars['ID']['output'];
    order: Scalars['Int']['output'];
    type: PlanItemType;
  };

export enum FamilyRelationship {
  Dependent = 'dependent',
  Spouse = 'spouse',
}

export enum FeedbackContextKind {
  Application = 'application',
  Challenge = 'challenge',
  Practice = 'practice',
  PracticeProgram = 'practiceProgram',
  Program = 'program',
}

export enum FeedbackKind {
  Negative = 'negative',
  Positive = 'positive',
  Undecided = 'undecided',
}

export type FeedbackPayload = {
  __typename?: 'FeedbackPayload';
  challenge?: Maybe<Challenge>;
  content?: Maybe<Content>;
};

export type GameplanMetadata = {
  __typename?: 'GameplanMetadata';
  associatedProject?: Maybe<ContentAttribute>;
  isExperiential?: Maybe<Scalars['Boolean']['output']>;
  primaryComponent?: Maybe<ContentAttribute>;
  primarySubcomponents: Array<Maybe<ContentAttribute>>;
  secondaryComponent?: Maybe<ContentAttribute>;
  secondarySubcomponents: Array<Maybe<ContentAttribute>>;
};

export type Genre = {
  __typename?: 'Genre';
  children: Array<Genre>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Genre>;
  value: Scalars['String']['output'];
};

export type Goal = {
  __typename?: 'Goal';
  description: Scalars['String']['output'];
  type: UserGoalType;
};

export type HeartRate = {
  __typename?: 'HeartRate';
  average: Scalars['Int']['output'];
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type InAppSurvey = Content &
  Node & {
    __typename?: 'InAppSurvey';
    author?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    feedback?: Maybe<FeedbackKind>;
    finishedBody?: Maybe<Scalars['String']['output']>;
    finishedCardSubtitle?: Maybe<Scalars['String']['output']>;
    finishedCardTitle?: Maybe<Scalars['String']['output']>;
    finishedTitle?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    initialCardSubtitle?: Maybe<Scalars['String']['output']>;
    initialCardTitle?: Maybe<Scalars['String']['output']>;
    introBody?: Maybe<Scalars['String']['output']>;
    introTitle?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    link: Scalars['String']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    /** @deprecated User Question.savedAnswer now */
    survey?: Maybe<Survey>;
    surveyTemplate?: Maybe<SurveyTemplate>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type InAppSurveyRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type InviteFamilyMemberInput = {
  email: Scalars['String']['input'];
  relationship: FamilyRelationship;
};

export type InviteFamilyMemberPayload = {
  __typename?: 'InviteFamilyMemberPayload';
  familyInvitation: EligibleEmailAddress;
  user: User;
};

export type JoinChallengePayload = {
  __typename?: 'JoinChallengePayload';
  challenge?: Maybe<Challenge>;
};

export type LastActivity = {
  __typename?: 'LastActivity';
  heart?: Maybe<Scalars['ZonedDateTime']['output']>;
  heartWeb?: Maybe<Scalars['ZonedDateTime']['output']>;
  /** @deprecated prince is deprecated */
  prince?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type Level = {
  __typename?: 'Level';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Link = Content &
  Node & {
    __typename?: 'Link';
    author?: Maybe<Scalars['String']['output']>;
    bannerPhoto?: Maybe<Photo>;
    buttonText?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    link: Scalars['String']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type LinkRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type LocalDateRange = {
  end?: InputMaybe<Scalars['LocalDate']['input']>;
  start?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type LocationCoachesConnection = Connection & {
  __typename?: 'LocationCoachesConnection';
  count: Scalars['Int']['output'];
  edges: Array<LocationCoachesEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type LocationCoachesEdge = Edge & {
  __typename?: 'LocationCoachesEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type LocationsFilter = {
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MachineClient = {
  __typename?: 'MachineClient';
  id: Scalars['ID']['output'];
};

export type ManualActivityInput = {
  distance?: InputMaybe<DistanceInput>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  sourceId: Scalars['String']['input'];
  startTime: Scalars['ZonedDateTime']['input'];
};

export type MboBookingsFilter = {
  dates: DateRange;
};

export type MboClass = Node & {
  __typename?: 'MboClass';
  attendees: MboClassUsersConnection;
  attending: Scalars['Boolean']['output'];
  /** The times at which booking for this class open and close. */
  bookingWindow?: Maybe<ZonedDateTimeRange>;
  cancelled: Scalars['Boolean']['output'];
  category: Scalars['String']['output'];
  client: Client;
  description: Scalars['String']['output'];
  endTime: Scalars['ZonedDateTime']['output'];
  full: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  joinUrl?: Maybe<Scalars['String']['output']>;
  joinableTime: Scalars['ZonedDateTime']['output'];
  location?: Maybe<CoachLocation>;
  mboClassId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  room?: Maybe<Scalars['String']['output']>;
  /** The service category’s schedule type. */
  scheduleType: Scalars['String']['output'];
  staff?: Maybe<User>;
  staffFirstName?: Maybe<Scalars['String']['output']>;
  staffLastName?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['ZonedDateTime']['output'];
  studio: Studio;
  /** The detailed type of the class: in-person, live, or encore */
  subtype: MboClassSubtype;
  /** @deprecated Prefer subtype */
  type: MboClassType;
};

export type MboClassAttendeesArgs = {
  filter?: InputMaybe<MboClassUsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type MboClassActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'MboClassActivity';
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    location?: Maybe<Scalars['String']['output']>;
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    room?: Maybe<Scalars['String']['output']>;
    source?: Maybe<MboClass>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type MboClassConnection = Connection & {
  __typename?: 'MboClassConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootMboClassEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type MboClassDetailsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  mboClassId?: InputMaybe<Scalars['Int']['input']>;
  mboStudioId?: InputMaybe<Scalars['String']['input']>;
};

export type MboClassFilter = {
  classType: MboClassType;
  dates: DateRange;
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum MboClassSubtype {
  Encore = 'Encore',
  InPerson = 'InPerson',
  LiveVirtual = 'LiveVirtual',
}

export enum MboClassType {
  InPerson = 'InPerson',
  Virtual = 'Virtual',
}

export type MboClassUsersConnection = Connection & {
  __typename?: 'MboClassUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<MboClassUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type MboClassUsersEdge = Edge & {
  __typename?: 'MboClassUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type MboClassUsersFilter = {
  excludeAbsentUsers?: InputMaybe<Scalars['Boolean']['input']>;
  includeCancelledUsers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Message = Node & {
  __typename?: 'Message';
  automated: Scalars['Boolean']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  photos: Array<Photo>;
  previews: Array<MessagePreview>;
  reactions: Array<Reaction>;
  text: Scalars['String']['output'];
  user: User;
};

export type MessagePreview = {
  __typename?: 'MessagePreview';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Photo>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ModalStatus = {
  __typename?: 'ModalStatus';
  id: Scalars['ID']['output'];
  modalSeen: Scalars['Boolean']['output'];
};

export type MultipleChoiceOption = {
  __typename?: 'MultipleChoiceOption';
  /**
   * If true, when this option is selected, it should clear all other multiple choice options
   * If another option is selected while this is selected, it should clear this option
   */
  clearMultipleChoice?: Maybe<Scalars['Boolean']['output']>;
  description: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  iconSelected?: Maybe<Scalars['String']['output']>;
  /** if next question is supplied and this option was chosen, this is the next question to show the member */
  nextQuestion?: Maybe<Question>;
  optionIdentifier: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  textEntry: Scalars['Boolean']['output'];
};

export type MutateChatRoomPayload = {
  __typename?: 'MutateChatRoomPayload';
  chatRoom?: Maybe<ChatRoom>;
};

export type MutateClientPayload = {
  __typename?: 'MutateClientPayload';
  client: Client;
};

export type MutateCoachLocationPayload = {
  __typename?: 'MutateCoachLocationPayload';
  coachLocation: CoachLocation;
};

export type MutateCoachPayload = {
  __typename?: 'MutateCoachPayload';
  coachData: CoachData;
};

export type MutateDomainPayload = {
  __typename?: 'MutateDomainPayload';
  domain: Domain;
};

export type MutateEligibleEmailAddressInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  hpcEligibilityEnd?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  hpcEligibilityStart?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  id: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutateEligibleEmailAddressPayload = {
  __typename?: 'MutateEligibleEmailAddressPayload';
  eligibleEmailAddress: EligibleEmailAddress;
};

export type MutateNotePayload = {
  __typename?: 'MutateNotePayload';
  note?: Maybe<Note>;
};

export type MutateNotificationOptionPayload = {
  __typename?: 'MutateNotificationOptionPayload';
  notificationOption: NotificationOption;
};

export type MutateStudioPayload = {
  __typename?: 'MutateStudioPayload';
  studio: Studio;
};

export type Mutation = {
  __typename?: 'Mutation';
  addManualActivity: ActivityPayload;
  addPerformanceQualityScores: AddPerformanceQualityScoresPayload;
  addReaction: Message;
  addSaved: SavedPayload;
  addToChatRoom: MutateChatRoomPayload;
  addToClient: MutateClientPayload;
  addUserToStudio: AddUserToStudioPayload;
  adminBackfillAssessmentAttributionType: Scalars['Int']['output'];
  adminCancelClass: Scalars['Boolean']['output'];
  adminChangeUserAuthProvider: UpdateUserPayload;
  adminConfirmUser: UpdateUserPayload;
  adminCreateClass: Scalars['Boolean']['output'];
  adminUpdateLastActivity?: Maybe<UpdateUserPayload>;
  adminUpdateUser: UpdateUserPayload;
  answerSurvey: Survey;
  assignAssessments: AssignAssessmentsPayload;
  assignFitCoach: AssignFitCoachPayload;
  assignHistoricalFitCoach: AssignFitCoachPayload;
  assignPractice: AssignPracticePayload;
  assignProgram: AssignProgramPayload;
  backFillIterableMarketing: RootUsersConnection;
  backfillUserProfileFields: Scalars['Int']['output'];
  blockUser: BlockUserPayload;
  bookClass: BookClassPayload;
  bookSession: BookSessionPayload;
  bulkReassignCoachMembers: BulkReassignCoachMembersPayload;
  cancelClass: CancelClassPayload;
  cancelSession: Scalars['Boolean']['output'];
  changeEmail: UpdateUserPayload;
  clearCache: Scalars['Boolean']['output'];
  completePractice: UpdatePracticePayload;
  completeProgram: UpdateProgramPayload;
  createChallenge: CreateChallengePayload;
  createChatRoom: MutateChatRoomPayload;
  createClient: MutateClientPayload;
  createCoachLocation: MutateCoachLocationPayload;
  createCoachTouchPoint: CoachTouchPoint;
  /** @deprecated use createFeedback */
  createContentFeedback: ContentFeedbackPayload;
  createCustomChallenge: CreateChallengePayload;
  createDomain: MutateDomainPayload;
  createEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  createFeedback: FeedbackPayload;
  createNote: MutateNotePayload;
  createNotificationOption: MutateNotificationOptionPayload;
  createPlan: CreatePlanPayload;
  createStudio: MutateStudioPayload;
  createUser: CreateUserPayload;
  createUserGoal: CreateUserGoalPayload;
  createUsers: CreateUsersPayload;
  deleteActivity: Scalars['Boolean']['output'];
  deleteAssignedAssessment?: Maybe<AssessmentAssignment>;
  deleteClientEligibleEmails: Scalars['Int']['output'];
  deleteCoachTouchPoint: Scalars['Boolean']['output'];
  deleteCustomChallenge: Scalars['Boolean']['output'];
  deleteFitCoach: DeleteFitCoachPayload;
  deletePerformanceQualityScore: Scalars['Boolean']['output'];
  deletePlan: DeletePlanPayload;
  deleteUser: DeleteUserPayload;
  deleteUserGoal: DeleteUserGoalPayload;
  editActivity: ActivityPayload;
  generateSignedUrl: SignedUrlPayload;
  inviteFamilyMember: InviteFamilyMemberPayload;
  joinChallenge: JoinChallengePayload;
  joinLiveClass: ActivityPayload;
  leaveChallenge: JoinChallengePayload;
  leavePractice: UpdatePracticePayload;
  leaveProgram: UpdateProgramPayload;
  markBadgesSeenForChallenge: Challenge;
  markChatRead: ChatRoom;
  /** @deprecated No longer supported */
  markChatStarred: ChatRoom;
  markPlanItemComplete: PlanItem;
  markSurveyResponseViewed: SurveyResponsePayload;
  markUserStarred: User;
  purgeContents: Scalars['Boolean']['output'];
  purgeWearableData: PurgeWearableDataPayload;
  rateApp: RateAppPayload;
  rebookSession: BookSessionPayload;
  refreshContent: RefreshContentPayload;
  register: RegisterUserPayload;
  registerDevice: Scalars['Boolean']['output'];
  remindFamilyMember: InviteFamilyMemberPayload;
  removeAllUserSurveys: Scalars['Int']['output'];
  removeClient: Scalars['Boolean']['output'];
  removeCoachMembers: Scalars['Boolean']['output'];
  removeDomain: Scalars['Boolean']['output'];
  /** Removes an eligibility row from the database. For special cases only, not to be exposed as a regular operation in Admin without further thought. */
  removeEligibleEmailAddress: Scalars['Boolean']['output'];
  removeFamilyMember: InviteFamilyMemberPayload;
  removeFromChallenge: JoinChallengePayload;
  removeFromChatRoom: MutateChatRoomPayload;
  removeFromClient: MutateClientPayload;
  removeLocation: Scalars['Boolean']['output'];
  removeReaction: Message;
  removeSaved: SavedPayload;
  removeStudio: Scalars['Boolean']['output'];
  removeUserSurveys: Scalars['Int']['output'];
  repairClientUsers: RepairClientUsersPayload;
  reportUser: Scalars['Boolean']['output'];
  resetPreview?: Maybe<Photo>;
  resetProgress: ResetProgressPayload;
  resyncIterableUser?: Maybe<User>;
  /** @deprecated Use syncWearableSamples */
  saveWearableSamples: Scalars['Boolean']['output'];
  sendHistoricalMessage: Message;
  sendMessage: Message;
  sendSupportRequest: Scalars['Boolean']['output'];
  setMinBuildNumber: Scalars['Int']['output'];
  setModalStatus: ModalStatus;
  setNotificationOptions: SetNotificationOptionsPayload;
  setSessionCredits: SessionCreditsPayload;
  setUnitsPreference: UnitsPayload;
  startPractice: StartPracticePayload;
  startProgram: StartProgramPayload;
  /** @deprecated No longer supported */
  syncChatUsers: Scalars['Boolean']['output'];
  syncEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  syncMboMemberships: SyncMboMembershipsPayload;
  syncWearableSamples: Scalars['Boolean']['output'];
  triggerIterablePush?: Maybe<TriggerIterablePushPayload>;
  unforgetIterableUser: Scalars['Boolean']['output'];
  unforgetUser: UnforgetUserPayload;
  updateAttendance: CoachSession;
  /** @deprecated Moved to enum column "updateAttendance" */
  updateAttended: CoachSession;
  updateAvatar: UpdateAvatarPayload;
  updateChallenge: CreateChallengePayload;
  updateChatRoom: MutateChatRoomPayload;
  updateClient: MutateClientPayload;
  updateCoachCronofyTimezone: UpdateCoachCronofyTimezoneResponse;
  updateCoachData: UpdateCoachDataPayload;
  updateCoachLocation: MutateCoachLocationPayload;
  updateCoachTouchPoint: CoachTouchPoint;
  updateDomain: MutateDomainPayload;
  updateEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  updateLastActivity?: Maybe<UpdateUserPayload>;
  updateNote: MutateNotePayload;
  updateNotificationOption: MutateNotificationOptionPayload;
  updatePerformanceQualityScore: PerformanceQualityScore;
  updatePlan: UpdatePlanPayload;
  updatePractice: UpdatePracticePayload;
  updatePrivacyPolicyDate?: Maybe<Scalars['ZonedDateTime']['output']>;
  updateProfileJson: UpdateProfileJsonPayload;
  updateProgram: UpdateProgramPayload;
  updateProgress: ContentEdge;
  updateStudio: MutateStudioPayload;
  updateUnreadCount: Scalars['Int']['output'];
  updateUser: UpdateUserPayload;
  updateUserGoal: UpdateUserGoalPayload;
  /** @deprecated Removed welcome section */
  updateWelcomeSectionDisplay: UpdateUserPayload;
};

export type MutationAddManualActivityArgs = {
  activity: ManualActivityInput;
};

export type MutationAddPerformanceQualityScoresArgs = {
  performanceQualityScores: Array<CreatePerformanceQualityScoreInput>;
};

export type MutationAddReactionArgs = {
  input: AddReactionInput;
};

export type MutationAddSavedArgs = {
  contentId: Scalars['ID']['input'];
};

export type MutationAddToChatRoomArgs = {
  chatRoomId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAddToClientArgs = {
  clientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAddUserToStudioArgs = {
  studioId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAdminBackfillAssessmentAttributionTypeArgs = {
  input: AdminBackfillAssessmentAttributionTypeInput;
};

export type MutationAdminCancelClassArgs = {
  input: CancelClassInput;
};

export type MutationAdminChangeUserAuthProviderArgs = {
  input: AdminChangeAuthProviderInput;
};

export type MutationAdminConfirmUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationAdminCreateClassArgs = {
  input: BookClassInput;
};

export type MutationAdminUpdateLastActivityArgs = {
  app: App;
  date?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  platform?: TokenPlatform;
  userId: Scalars['ID']['input'];
};

export type MutationAdminUpdateUserArgs = {
  input: AdminUpdateUserInput;
};

export type MutationAnswerSurveyArgs = {
  input: AnswerSurveyInput;
};

export type MutationAssignAssessmentsArgs = {
  input: AssignAssessmentsInput;
};

export type MutationAssignFitCoachArgs = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAssignHistoricalFitCoachArgs = {
  coachId: Scalars['ID']['input'];
  createdAt: Scalars['ZonedDateTime']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAssignPracticeArgs = {
  practiceId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAssignProgramArgs = {
  programId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationBackFillIterableMarketingArgs = {
  input?: InputMaybe<ConnectionInput>;
  startDate?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type MutationBackfillUserProfileFieldsArgs = {
  input: BackfillUserProfileFieldsInput;
};

export type MutationBlockUserArgs = {
  input: BlockUserInput;
};

export type MutationBookClassArgs = {
  classId: Scalars['ID']['input'];
};

export type MutationBookSessionArgs = {
  input: BookSessionInput;
};

export type MutationBulkReassignCoachMembersArgs = {
  newCoachId: Scalars['ID']['input'];
  oldCoachId: Scalars['ID']['input'];
  reassignAllUsers?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationCancelClassArgs = {
  classId: Scalars['ID']['input'];
};

export type MutationCancelSessionArgs = {
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  sessionId: Scalars['ID']['input'];
  useCronofy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationChangeEmailArgs = {
  allowSSO?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationCompletePracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationCompleteProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationCreateChallengeArgs = {
  input: CreateChallengeInput;
};

export type MutationCreateChatRoomArgs = {
  input: CreateChatRoomInput;
};

export type MutationCreateClientArgs = {
  input: CreateClientInput;
};

export type MutationCreateCoachLocationArgs = {
  input: CreateCoachLocationInput;
};

export type MutationCreateCoachTouchPointArgs = {
  input: CreateCoachTouchPointInput;
};

export type MutationCreateContentFeedbackArgs = {
  contentId: Scalars['ID']['input'];
  context: FeedbackContextKind;
  feedback: FeedbackKind;
};

export type MutationCreateCustomChallengeArgs = {
  input: CreateCustomChallengeInput;
};

export type MutationCreateDomainArgs = {
  input: CreateDomainInput;
};

export type MutationCreateEligibleEmailAddressArgs = {
  input: CreateEligibleEmailAddressInput;
};

export type MutationCreateFeedbackArgs = {
  context: FeedbackContextKind;
  feedback: FeedbackKind;
  id: Scalars['ID']['input'];
};

export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};

export type MutationCreateNotificationOptionArgs = {
  input: CreateNotificationOptionInput;
};

export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};

export type MutationCreateStudioArgs = {
  input: CreateStudioInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserGoalArgs = {
  input: CreateUserGoalInput;
};

export type MutationCreateUsersArgs = {
  input: CreateUsersInput;
};

export type MutationDeleteActivityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteAssignedAssessmentArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type MutationDeleteClientEligibleEmailsArgs = {
  clientId: Scalars['String']['input'];
};

export type MutationDeleteCoachTouchPointArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCustomChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFitCoachArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeletePerformanceQualityScoreArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePlanArgs = {
  planId: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
  forgetIterable?: Scalars['Boolean']['input'];
  jira?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationDeleteUserGoalArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationEditActivityArgs = {
  activity: EditActivityInput;
};

export type MutationGenerateSignedUrlArgs = {
  input: SignedUrlInput;
};

export type MutationInviteFamilyMemberArgs = {
  input: InviteFamilyMemberInput;
};

export type MutationJoinChallengeArgs = {
  challengeTeamId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationJoinLiveClassArgs = {
  classId: Scalars['String']['input'];
};

export type MutationLeaveChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationLeavePracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationLeaveProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationMarkBadgesSeenForChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMarkChatReadArgs = {
  chatId: Scalars['ID']['input'];
  lastMessageRead: Scalars['ZonedDateTime']['input'];
};

export type MutationMarkChatStarredArgs = {
  chatId: Scalars['ID']['input'];
  isStarred: Scalars['Boolean']['input'];
};

export type MutationMarkPlanItemCompleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMarkSurveyResponseViewedArgs = {
  surveyResponseId: Scalars['ID']['input'];
};

export type MutationMarkUserStarredArgs = {
  isStarred: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationPurgeWearableDataArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationRateAppArgs = {
  feedback: FeedbackKind;
};

export type MutationRebookSessionArgs = {
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  sessionId: Scalars['ID']['input'];
  start: Scalars['ZonedDateTime']['input'];
  update?: InputMaybe<RebookSessionUpdateInput>;
  useCronofy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationRefreshContentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRegisterArgs = {
  input: RegisterUserInput;
};

export type MutationRegisterDeviceArgs = {
  app?: InputMaybe<App>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationRemindFamilyMemberArgs = {
  input: RemindFamilyMemberInput;
};

export type MutationRemoveClientArgs = {
  clientId: Scalars['ID']['input'];
};

export type MutationRemoveCoachMembersArgs = {
  clientId: Scalars['ID']['input'];
  coachId: Scalars['ID']['input'];
};

export type MutationRemoveDomainArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveEligibleEmailAddressArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveFamilyMemberArgs = {
  input: RemoveFamilyMemberInput;
};

export type MutationRemoveFromChallengeArgs = {
  challengeId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRemoveFromChatRoomArgs = {
  chatRoomId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRemoveFromClientArgs = {
  clientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRemoveLocationArgs = {
  locationId: Scalars['ID']['input'];
};

export type MutationRemoveReactionArgs = {
  reactionId: Scalars['ID']['input'];
};

export type MutationRemoveSavedArgs = {
  contentId: Scalars['ID']['input'];
};

export type MutationRemoveStudioArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveUserSurveysArgs = {
  surveyIdentifier: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRepairClientUsersArgs = {
  clientId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationReportUserArgs = {
  input: ReportUserInput;
};

export type MutationResetPreviewArgs = {
  extension?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationResetProgressArgs = {
  contentId?: InputMaybe<Scalars['ID']['input']>;
  sectionId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationResyncIterableUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationSaveWearableSamplesArgs = {
  data: WearableSamplesInput;
};

export type MutationSendHistoricalMessageArgs = {
  chatId: Scalars['ID']['input'];
  createdAt: Scalars['ZonedDateTime']['input'];
  text: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationSendMessageArgs = {
  chatId: Scalars['ID']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['ID']['input']>;
  optimisticId?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type MutationSendSupportRequestArgs = {
  app: App;
  type: SupportRequestType;
};

export type MutationSetMinBuildNumberArgs = {
  input: SetMinBuildNumberInput;
};

export type MutationSetModalStatusArgs = {
  status: SetModalStatus;
};

export type MutationSetNotificationOptionsArgs = {
  input: Array<NotificationOptionInput>;
};

export type MutationSetSessionCreditsArgs = {
  input: SessionCreditsInput;
};

export type MutationSetUnitsPreferenceArgs = {
  input: UnitsInput;
};

export type MutationStartPracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationStartProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationSyncEligibleEmailAddressArgs = {
  input: SyncEligibleEmailAddressInput;
};

export type MutationSyncMboMembershipsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationSyncWearableSamplesArgs = {
  data: WearableSamplesInput;
};

export type MutationTriggerIterablePushArgs = {
  campaignId: Scalars['Int']['input'];
  dataFields?: InputMaybe<Scalars['JSONObject']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationUnforgetIterableUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationUnforgetUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationUpdateAttendanceArgs = {
  attendance: CoachSessionAttendance;
  sessionId: Scalars['ID']['input'];
};

export type MutationUpdateAttendedArgs = {
  attended: Scalars['Boolean']['input'];
  sessionId: Scalars['ID']['input'];
};

export type MutationUpdateAvatarArgs = {
  input: UpdateAvatarInput;
};

export type MutationUpdateChallengeArgs = {
  input: UpdateChallengeInput;
};

export type MutationUpdateChatRoomArgs = {
  input: UpdateChatRoomInput;
};

export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};

export type MutationUpdateCoachCronofyTimezoneArgs = {
  input: UpdateCoachCronofyTimezoneInput;
};

export type MutationUpdateCoachDataArgs = {
  input: UpdateCoachDataInput;
};

export type MutationUpdateCoachLocationArgs = {
  input: UpdateCoachLocationInput;
};

export type MutationUpdateCoachTouchPointArgs = {
  input: UpdateCoachTouchPointInput;
};

export type MutationUpdateDomainArgs = {
  input: UpdateDomainInput;
};

export type MutationUpdateEligibleEmailAddressArgs = {
  input: MutateEligibleEmailAddressInput;
};

export type MutationUpdateLastActivityArgs = {
  app: App;
  platform?: TokenPlatform;
};

export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};

export type MutationUpdateNotificationOptionArgs = {
  input: UpdateNotificationOptionInput;
};

export type MutationUpdatePerformanceQualityScoreArgs = {
  performanceQualityScore: UpdatePerformanceQualityScoreInput;
};

export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};

export type MutationUpdatePracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationUpdatePrivacyPolicyDateArgs = {
  newDate?: InputMaybe<Scalars['ZonedDateTime']['input']>;
};

export type MutationUpdateProfileJsonArgs = {
  input: UpdateProfileJsonInput;
};

export type MutationUpdateProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationUpdateProgressArgs = {
  contentId: Scalars['ID']['input'];
  customPlanItemId?: InputMaybe<Scalars['ID']['input']>;
  progress?: InputMaybe<Scalars['Float']['input']>;
  sectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateStudioArgs = {
  input: UpdateStudioInput;
};

export type MutationUpdateUnreadCountArgs = {
  chatId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserGoalArgs = {
  input: UpdateUserGoalInput;
};

export type MutationUpdateWelcomeSectionDisplayArgs = {
  display: WelcomeSectionDisplay;
};

export type NewMessagePayload = {
  __typename?: 'NewMessagePayload';
  chatRoom: ChatRoom;
  message: Message;
  optimisticId?: Maybe<Scalars['ID']['output']>;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type NotHello = {
  __typename?: 'NotHello';
  not?: Maybe<Scalars['String']['output']>;
};

export type Note = Node & {
  __typename?: 'Note';
  coach?: Maybe<User>;
  content: Scalars['String']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  lastUpdatedBy?: Maybe<User>;
  member: User;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ZonedDateTime']['output'];
};

export enum NotificationKind {
  Push = 'push',
}

export type NotificationOption = Node & {
  __typename?: 'NotificationOption';
  app: App;
  description: Scalars['String']['output'];
  featureFlag?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  iterableId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  push: Scalars['Boolean']['output'];
  visible: Scalars['Boolean']['output'];
};

export type NotificationOptionConnection = Connection & {
  __typename?: 'NotificationOptionConnection';
  count: Scalars['Int']['output'];
  edges: Array<NotificationOptionEdge>;
  nodes: Array<NotificationOption>;
  pageInfo: PageInfo;
};

export type NotificationOptionEdge = Edge & {
  __typename?: 'NotificationOptionEdge';
  cursor: Scalars['String']['output'];
  node: NotificationOption;
};

export type NotificationOptionInput = {
  optionId: Scalars['String']['input'];
  push?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NumberAnswer = {
  __typename?: 'NumberAnswer';
  numberValue: Scalars['Float']['output'];
};

export type Onboarding = {
  __typename?: 'Onboarding';
  isCompleted: Scalars['Boolean']['output'];
  surveys: ContentConnection;
};

export type OnboardingMotivation = {
  __typename?: 'OnboardingMotivation';
  id: Scalars['ID']['output'];
  isSelected: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum Os {
  Android = 'android',
  Ios = 'ios',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

export type ParticipantsFilter = {
  appendSelf?: InputMaybe<Scalars['Boolean']['input']>;
  challengeTeamId?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  excludeUsersWithoutScore?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ParticipantsOrder {
  CreatedAt = 'createdAt',
  FirstName = 'firstName',
  LastName = 'lastName',
  Score = 'score',
}

export type ParticipantsOrderBy = {
  direction: OrderDirection;
  order: ParticipantsOrder;
};

export enum PerformanceQuality {
  BodyComposition = 'BodyComposition',
  Curiosity = 'Curiosity',
  Endurance = 'Endurance',
  FvProfile = 'FVProfile',
  Flowability = 'Flowability',
  FuelUtilization = 'FuelUtilization',
  Grit = 'Grit',
  InnerAwareness = 'InnerAwareness',
  LoadAccumulation = 'LoadAccumulation',
  MovementEfficiency = 'MovementEfficiency',
  NutrientProfile = 'NutrientProfile',
  SelfRegulation = 'SelfRegulation',
  SleepProficiency = 'SleepProficiency',
}

export type PerformanceQualityConnection = Connection & {
  __typename?: 'PerformanceQualityConnection';
  count: Scalars['Int']['output'];
  edges: Array<PerformanceQualityEdge>;
  nodes: Array<PerformanceQualityScore>;
  pageInfo: PageInfo;
};

export type PerformanceQualityEdge = Edge & {
  __typename?: 'PerformanceQualityEdge';
  cursor: Scalars['String']['output'];
  node: PerformanceQualityScore;
};

export type PerformanceQualityScore = Node & {
  __typename?: 'PerformanceQualityScore';
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  performanceQuality: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  subComponent: Scalars['String']['output'];
};

export type Photo = Node & {
  __typename?: 'Photo';
  basePath: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  preview?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type PhotoUrlArgs = {
  circle?: InputMaybe<Scalars['Boolean']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type PhotoAnswer = {
  __typename?: 'PhotoAnswer';
  caption?: Maybe<Scalars['String']['output']>;
  photo: Photo;
};

export type PhysicalActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'PhysicalActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    distance?: Maybe<Distance>;
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<ActivityGenre>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type Plan = Node & {
  __typename?: 'Plan';
  endDate: Scalars['LocalDate']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  planItems: PlanItemConnection;
  startDate: Scalars['LocalDate']['output'];
  targetedPerformanceQualities: Array<PerformanceQuality>;
};

export type PlanPlanItemsArgs = {
  filter?: InputMaybe<PlanItemsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type PlanItem = {
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  date: Scalars['LocalDate']['output'];
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  type: PlanItemType;
};

export type PlanItemConnection = Connection & {
  __typename?: 'PlanItemConnection';
  count: Scalars['Int']['output'];
  edges: Array<PlanItemEdge>;
  nodes: Array<PlanItem>;
  pageInfo: PageInfo;
};

export type PlanItemEdge = Edge & {
  __typename?: 'PlanItemEdge';
  cursor: Scalars['String']['output'];
  node: PlanItem;
};

export enum PlanItemType {
  CustomTask = 'CustomTask',
  ExosContent = 'ExosContent',
}

export type PlanItemsFilter = {
  localDateRange?: InputMaybe<LocalDateRange>;
};

export type PracticeParticipantConnection = Connection & {
  __typename?: 'PracticeParticipantConnection';
  count: Scalars['Int']['output'];
  edges: Array<PracticeParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type PracticeParticipantsEdge = Edge & {
  __typename?: 'PracticeParticipantsEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type PracticeParticipantsFilter = {
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PracticeProgram = Content &
  Node & {
    __typename?: 'PracticeProgram';
    author?: Maybe<Scalars['String']['output']>;
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    congratulations?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    createdAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use subtitle now */
    eyebrow?: Maybe<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    participants: PracticeParticipantConnection;
    progress: Scalars['Float']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    sections: ContentCollectionSectionsConnection;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    skills: Array<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type PracticeProgramParticipantsArgs = {
  filter?: InputMaybe<PracticeParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramFilter = {
  status?: InputMaybe<PracticeProgramStatus>;
};

export enum PracticeProgramStatus {
  All = 'All',
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export enum Prefix {
  Avatar = 'avatar',
  Challenge = 'challenge',
  Client = 'client',
  Coach = 'coach',
  Uploads = 'uploads',
}

export type Principal = MachineClient | User;

export type Program = Content &
  Node & {
    __typename?: 'Program';
    author?: Maybe<Scalars['String']['output']>;
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    congratulations?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    createdAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    currentSection?: Maybe<ContentSection>;
    equipment: Array<Scalars['String']['output']>;
    /** @deprecated use subtitle now */
    eyebrow?: Maybe<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    participants: ProgramParticipantConnection;
    progress: Scalars['Float']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    sections: ContentCollectionSectionsConnection;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    skills: Array<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ProgramParticipantsArgs = {
  filter?: InputMaybe<ProgramParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramParticipantConnection = Connection & {
  __typename?: 'ProgramParticipantConnection';
  count: Scalars['Int']['output'];
  edges: Array<ProgramParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ProgramParticipantsEdge = Edge & {
  __typename?: 'ProgramParticipantsEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type ProgramParticipantsFilter = {
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProgramsConnection = Connection & {
  __typename?: 'ProgramsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ProgramsEdge>;
  nodes: Array<Program>;
  pageInfo: PageInfo;
};

export type ProgramsEdge = Edge & {
  __typename?: 'ProgramsEdge';
  cursor: Scalars['String']['output'];
  node: Program;
};

export type PurgeWearableDataPayload = {
  __typename?: 'PurgeWearableDataPayload';
  user: User;
};

export type Query = {
  __typename?: 'Query';
  activity?: Maybe<Activity>;
  activityGenre?: Maybe<ActivityGenre>;
  activityGenres: Array<ActivityGenre>;
  /** @deprecated No longer supported */
  article?: Maybe<Article>;
  /** @deprecated No longer supported */
  articles: ArticlesConnection;
  assessment?: Maybe<AssessmentAssignment>;
  auditLogs: AuditLogsConnection;
  availableAssessments: AssessmentSurveysConnection;
  challenge?: Maybe<Challenge>;
  challengeTeam?: Maybe<ChallengeTeam>;
  challenges: RootChallengesConnection;
  chatRoom?: Maybe<ChatRoom>;
  chatRooms: RootChatRoomsConnection;
  client?: Maybe<Client>;
  clients: RootClientsConnection;
  coachLocation?: Maybe<CoachLocation>;
  coachLocations: CoachLocationsConnection;
  coachSession?: Maybe<CoachSession>;
  content?: Maybe<Content>;
  contentCollection?: Maybe<ContentCollection>;
  contentEquipment: Array<Equipment>;
  contentGenres: Array<Genre>;
  contentIntensities: Array<ContentAttribute>;
  contentLevels: Array<Level>;
  contentProjects: Array<ContentAttribute>;
  contentSplits: Array<Split>;
  contents: ContentConnection;
  countries: Array<Country>;
  customPlan: Plan;
  domain?: Maybe<Domain>;
  domains: DomainConnection;
  eligibleEmailAddress?: Maybe<EligibleEmailAddress>;
  eligibleEmailAddresses: RootEligibleEmailAddressesConnection;
  gameplanComponents: Array<ContentAttribute>;
  gameplanSubcomponents: Array<ContentAttribute>;
  getModalStatus: ModalStatus;
  hello: Scalars['String']['output'];
  /** @deprecated No longer supported */
  home: ContentConnection;
  manyChallenges: Array<Challenge>;
  manyChatRooms: Array<ChatRoom>;
  manyClients: Array<Client>;
  manyCoachLocations: Array<CoachLocation>;
  manyDomains: Array<Domain>;
  manyEligibleEmailAddresses: Array<EligibleEmailAddress>;
  manyStudios: Array<Studio>;
  manyUsers: Array<User>;
  mboClass?: Maybe<MboClass>;
  /** @deprecated No longer supported */
  notHello?: Maybe<NotHello>;
  note?: Maybe<Note>;
  notificationOption: NotificationOption;
  notificationOptions: NotificationOptionConnection;
  performanceQualities: Array<ContentAttribute>;
  performanceQualityScores: PerformanceQualityConnection;
  planItem: PlanItem;
  programs: ProgramsConnection;
  self?: Maybe<User>;
  studio?: Maybe<Studio>;
  studios: StudiosConnection;
  survey?: Maybe<Survey>;
  surveyTemplate?: Maybe<SurveyTemplate>;
  systemPreferences: SystemPreferences;
  user?: Maybe<User>;
  userActivity?: Maybe<UserActivity>;
  userAssessmentSurvey: UserAssessmentSurvey;
  users: RootUsersConnection;
};

export type QueryActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryActivityGenreArgs = {
  id: Scalars['ID']['input'];
};

export type QueryActivityGenresArgs = {
  filter?: InputMaybe<ActivityGenresFilter>;
};

export type QueryArticleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAssessmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryAvailableAssessmentsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryChallengeTeamArgs = {
  id: Scalars['ID']['input'];
};

export type QueryChallengesArgs = {
  filter?: InputMaybe<ChallengeFilter>;
  input?: InputMaybe<ConnectionInput>;
  sort?: InputMaybe<ChallengeSort>;
};

export type QueryChatRoomArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryChatRoomsArgs = {
  filter?: InputMaybe<ChatRoomsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ChatRoomsOrderBy>>;
};

export type QueryClientArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryCoachLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCoachLocationsArgs = {
  filter?: InputMaybe<CoachLocationsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryCoachSessionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryContentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryContentCollectionArgs = {
  slug: Scalars['String']['input'];
};

export type QueryContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<ContentOrderBy>;
};

export type QueryCustomPlanArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDomainArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDomainsArgs = {
  filter?: InputMaybe<DomainsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryEligibleEmailAddressArgs = {
  details?: InputMaybe<EligibleEmailAddressDetailsInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEligibleEmailAddressesArgs = {
  filter?: InputMaybe<EligibleEmailAddressesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryGetModalStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryManyChallengesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyChatRoomsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyClientsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyCoachLocationsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyDomainsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyEligibleEmailAddressesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyStudiosArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyUsersArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryMboClassArgs = {
  input: MboClassDetailsInput;
};

export type QueryNoteArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNotificationOptionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNotificationOptionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryPerformanceQualityScoresArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryPlanItemArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProgramsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryStudioArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStudiosArgs = {
  filter?: InputMaybe<StudiosFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QuerySurveyArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySurveyTemplateArgs = {
  id: Scalars['ID']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  mboId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUserAssessmentSurveyArgs = {
  surveyContentId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type Question = {
  __typename?: 'Question';
  answer?: Maybe<Answer>;
  backgroundImage?: Maybe<Photo>;
  canGoBack: Scalars['Boolean']['output'];
  choices: Array<MultipleChoiceOption>;
  cta?: Maybe<QuestionCta>;
  description?: Maybe<Scalars['String']['output']>;
  /** Text above question */
  eyebrow?: Maybe<Scalars['String']['output']>;
  /** hint text below choices and above CTA */
  hint?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  layoutType?: Maybe<QuestionLayoutType>;
  nextQuestion?: Maybe<Question>;
  /** input placeholder */
  placeholder?: Maybe<Scalars['String']['output']>;
  progressIndicator?: Maybe<QuestionProgressIndicator>;
  questionSubtype?: Maybe<QuestionSubtype>;
  questionType: QuestionType;
  required: Scalars['Boolean']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type QuestionCta = {
  __typename?: 'QuestionCta';
  text: Scalars['String']['output'];
};

export enum QuestionLayoutType {
  OnColumn = 'OnColumn',
  /** Displays tags and takes the space of the content wrapping when needed */
  Tags = 'Tags',
  TwoColumns = 'TwoColumns',
}

export type QuestionProgressIndicator = {
  __typename?: 'QuestionProgressIndicator';
  current: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum QuestionSubtype {
  Multiselect = 'Multiselect',
  OpenTextBox = 'OpenTextBox',
  PhotoUpload = 'PhotoUpload',
  Radio = 'Radio',
  SingleLine = 'SingleLine',
}

export enum QuestionType {
  Birthday = 'Birthday',
  Height = 'Height',
  Intro = 'Intro',
  MultipleChoice = 'MultipleChoice',
  PhotoUpload = 'PhotoUpload',
  TextEntry = 'TextEntry',
  ValueProp = 'ValueProp',
  Weight = 'Weight',
}

export type RateAppPayload = {
  __typename?: 'RateAppPayload';
  user?: Maybe<User>;
};

export type Reaction = {
  __typename?: 'Reaction';
  id: Scalars['ID']['output'];
  kind: ReactionKind;
  user: User;
};

export enum ReactionKind {
  Clap = 'clap',
  Fire = 'fire',
  Grimacing = 'grimacing',
  Laugh = 'laugh',
  RatingNegative = 'rating_negative',
  RatingPositive = 'rating_positive',
  Smile = 'smile',
  Thumbsup = 'thumbsup',
}

export type RebookSessionUpdateInput = {
  duration: Scalars['Int']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  sessionStyle: CoachSessionStyle;
  sessionType: CoachSessionType;
};

export type RefreshContentPayload = {
  __typename?: 'RefreshContentPayload';
  content?: Maybe<Content>;
};

export type RegisterUserInput = {
  consent?: InputMaybe<Scalars['Boolean']['input']>;
  consentDate?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  marketingOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']['input']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  user?: Maybe<User>;
};

export type RemindFamilyMemberInput = {
  eligibleEmailAddressId: Scalars['ID']['input'];
};

export type RemoveFamilyMemberInput = {
  eligibleEmailAddressId: Scalars['ID']['input'];
};

export type RepairClientUsersPayload = {
  __typename?: 'RepairClientUsersPayload';
  processed: Scalars['Int']['output'];
  remaining: Scalars['Int']['output'];
};

export type ReportUserInput = {
  chatRoomId?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  surveyResponseId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type RequiredLocalDateRange = {
  end: Scalars['LocalDate']['input'];
  start: Scalars['LocalDate']['input'];
};

export type ResetProgressPayload = {
  __typename?: 'ResetProgressPayload';
  contentEdge?: Maybe<ContentEdge>;
  section?: Maybe<ContentSection>;
};

export type RootChallengesConnection = {
  __typename?: 'RootChallengesConnection';
  count: Scalars['Int']['output'];
  edges: Array<Maybe<ChallengeEdge>>;
  nodes: Array<Challenge>;
  pageInfo: PageInfo;
};

export type RootChatRoomsConnection = Connection & {
  __typename?: 'RootChatRoomsConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootChatRoomsEdge>;
  nodes: Array<ChatRoom>;
  pageInfo: PageInfo;
};

export type RootChatRoomsEdge = Edge & {
  __typename?: 'RootChatRoomsEdge';
  cursor: Scalars['String']['output'];
  node: ChatRoom;
};

export type RootClientsConnection = Connection & {
  __typename?: 'RootClientsConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type RootClientsEdge = Edge & {
  __typename?: 'RootClientsEdge';
  cursor: Scalars['String']['output'];
  node: Client;
};

export type RootEligibleEmailAddressesConnection = Connection & {
  __typename?: 'RootEligibleEmailAddressesConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootEligibleEmailAddressesEdge>;
  nodes: Array<EligibleEmailAddress>;
  pageInfo: PageInfo;
};

export type RootEligibleEmailAddressesEdge = Edge & {
  __typename?: 'RootEligibleEmailAddressesEdge';
  cursor: Scalars['String']['output'];
  node: EligibleEmailAddress;
};

export type RootMboClassEdge = Edge & {
  __typename?: 'RootMboClassEdge';
  cursor: Scalars['String']['output'];
  node: MboClass;
};

export type RootUsersConnection = Connection & {
  __typename?: 'RootUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type RootUsersEdge = Edge & {
  __typename?: 'RootUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type SsoIdentity = {
  __typename?: 'SSOIdentity';
  connection?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
};

export type SavedPayload = {
  __typename?: 'SavedPayload';
  content: Content;
  saved: Scalars['Boolean']['output'];
};

export enum ScoreUnit {
  Activities = 'Activities',
  Kilometers = 'Kilometers',
  Miles = 'Miles',
  Minutes = 'Minutes',
}

export type SessionCreditsInput = {
  count: Scalars['Int']['input'];
  rowVersion?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};

export type SessionCreditsPayload = {
  __typename?: 'SessionCreditsPayload';
  count: Scalars['Int']['output'];
  rowVersion: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
};

export type SetMinBuildNumberInput = {
  app: App;
  buildNumber: Scalars['Int']['input'];
  os: Os;
};

export type SetModalStatus = {
  id: Scalars['ID']['input'];
  modalSeen: Scalars['Boolean']['input'];
};

export type SetNotificationOptionsPayload = {
  __typename?: 'SetNotificationOptionsPayload';
  notificationOptions: Array<NotificationOption>;
};

export type Share = {
  __typename?: 'Share';
  message?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SignedUrlInput = {
  contentType: Scalars['String']['input'];
  prefix: Prefix;
};

export type SignedUrlPayload = {
  __typename?: 'SignedUrlPayload';
  key: Scalars['String']['output'];
  photo: Photo;
  signedUrl: Scalars['String']['output'];
};

export type SimpleContentConnection = {
  count: Scalars['Int']['output'];
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type SimpleContentEdge = {
  cursor: Scalars['String']['output'];
  node: Content;
};

export type SleepActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'SleepActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heartRate?: Maybe<HeartRate>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    sleepEfficiency?: Maybe<Scalars['Float']['output']>;
    sleepOverview: SleepOverview;
    source?: Maybe<Scalars['JSONObject']['output']>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type SleepOverview = {
  __typename?: 'SleepOverview';
  asleep: Scalars['Int']['output'];
  awake: Scalars['Int']['output'];
  inbed: Scalars['Int']['output'];
};

export type Split = {
  __typename?: 'Split';
  children: Array<Split>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Split>;
  value: Scalars['String']['output'];
};

export type StartPracticePayload = {
  __typename?: 'StartPracticePayload';
  assignedPractice: AssignedPractice;
};

export type StartProgramPayload = {
  __typename?: 'StartProgramPayload';
  assignedProgram: AssignedProgram;
};

export type StringAnswer = {
  __typename?: 'StringAnswer';
  stringValue: Scalars['String']['output'];
};

export type Studio = Node & {
  __typename?: 'Studio';
  auditLogs: AuditLogsConnection;
  client: Client;
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isCentral: Scalars['Boolean']['output'];
  isVirtual: Scalars['Boolean']['output'];
  locations: CoachLocationsConnection;
  mboAccessToken?: Maybe<Scalars['String']['output']>;
  mboServiceId: Scalars['String']['output'];
  mboStudioId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  requiredFields: Array<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
};

export type StudioAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type StudioLocationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type StudioEdge = Edge & {
  __typename?: 'StudioEdge';
  cursor: Scalars['String']['output'];
  node: Studio;
};

export type StudiosConnection = Connection & {
  __typename?: 'StudiosConnection';
  count: Scalars['Int']['output'];
  edges: Array<StudioEdge>;
  nodes: Array<Studio>;
  pageInfo: PageInfo;
};

export type StudiosFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatRoomUpdate: ChatRoom;
  newMessages: NewMessagePayload;
  newTotalUnreadMessageCount: Scalars['Int']['output'];
  totalUnreadMessageCount: TotalUnreadMessageCountPayload;
};

export type SubscriptionChatRoomUpdateArgs = {
  chatId?: InputMaybe<Scalars['ID']['input']>;
};

export type SubscriptionNewMessagesArgs = {
  chatId?: InputMaybe<Scalars['ID']['input']>;
};

export type SubscriptionNewTotalUnreadMessageCountArgs = {
  apps?: InputMaybe<Array<App>>;
  kinds?: InputMaybe<Array<ChatRoomKind>>;
};

export type SubscriptionTotalUnreadMessageCountArgs = {
  fromCoachedUsers?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SupportRequestType {
  DataAccess = 'DATA_ACCESS',
  DataDeletion = 'DATA_DELETION',
}

export type Survey = Node & {
  __typename?: 'Survey';
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  responses: Array<SurveyResponse>;
  scope?: Maybe<Scalars['String']['output']>;
  surveyIdentifier: Scalars['String']['output'];
  surveyName?: Maybe<Scalars['String']['output']>;
  template?: Maybe<SurveyTemplate>;
  user: User;
};

export type SurveyFilter = {
  surveyGroup?: InputMaybe<SurveyGroup>;
  surveyIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export enum SurveyGroup {
  Assessment = 'assessment',
  Basics = 'basics',
  Motivations = 'motivations',
}

export type SurveyResponse = Node & {
  __typename?: 'SurveyResponse';
  id: Scalars['ID']['output'];
  numericValue?: Maybe<Scalars['Float']['output']>;
  question: Scalars['String']['output'];
  questionIdentifier: Scalars['String']['output'];
  response: Answer;
  subcategory?: Maybe<Scalars['String']['output']>;
  survey: Survey;
  viewedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type SurveyResponseInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  choices?: InputMaybe<Array<Scalars['String']['input']>>;
  numberAnswer?: InputMaybe<Scalars['Float']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
  question: Scalars['String']['input'];
  questionIdentifier: Scalars['String']['input'];
  scores?: InputMaybe<Array<Scalars['Int']['input']>>;
  selectedChoices?: InputMaybe<Array<Scalars['Int']['input']>>;
  stringAnswer?: InputMaybe<Scalars['String']['input']>;
};

export type SurveyResponsePayload = {
  __typename?: 'SurveyResponsePayload';
  surveyResponse: SurveyResponse;
};

export type SurveyTemplate = {
  __typename?: 'SurveyTemplate';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  questions: Array<Question>;
};

export type SyncEligibleEmailAddressInput = {
  clientId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
};

export type SyncMboMembershipsPayload = {
  __typename?: 'SyncMboMembershipsPayload';
  user: User;
};

export type SystemPreferences = {
  __typename?: 'SystemPreferences';
  currentPrivacyPolicyDate?: Maybe<Scalars['ZonedDateTime']['output']>;
  minFitAndroidBuild: Scalars['Int']['output'];
  minFitIosBuild: Scalars['Int']['output'];
  minPerformAndroidBuild: Scalars['Int']['output'];
  minPerformIosBuild: Scalars['Int']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String']['output'];
};

export type TimeBasedActivity = {
  duration?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
  startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String']['output'];
  expiresIn: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export enum TokenPlatform {
  Mobile = 'mobile',
  Web = 'web',
}

export type TotalUnreadMessageCountPayload = {
  __typename?: 'TotalUnreadMessageCountPayload';
  count: Scalars['Int']['output'];
};

export type TouchPointFilter = {
  dueRange?: InputMaybe<DateRange>;
  includeCompletedAfter?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  status?: InputMaybe<Array<CoachTouchPointStatus>>;
};

export type TouchPointsOrderBy = {
  direction: OrderDirection;
  order: CoachTouchPointOrder;
};

export type TriggerIterablePushPayload = {
  __typename?: 'TriggerIterablePushPayload';
  success: Scalars['Boolean']['output'];
};

export type UnforgetUserPayload = {
  __typename?: 'UnforgetUserPayload';
  userId: Scalars['ID']['output'];
};

export type UnitsInput = {
  unitsPreference: UnitsPreference;
};

export type UnitsPayload = {
  __typename?: 'UnitsPayload';
  user: User;
};

export enum UnitsPreference {
  Imperial = 'imperial',
  Metric = 'metric',
}

export type UpdateAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  addressLine4?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  localityOrCity?: InputMaybe<Scalars['String']['input']>;
  postalOrZipCode?: InputMaybe<Scalars['String']['input']>;
  stateOrDistrictOrProvince?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAvatarInput = {
  avatarId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAvatarPayload = {
  __typename?: 'UpdateAvatarPayload';
  user: User;
};

export type UpdateChallengeInput = {
  backgroundId?: InputMaybe<Scalars['String']['input']>;
  badgeId?: InputMaybe<Scalars['String']['input']>;
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  distanceUnit?: InputMaybe<DistanceUnit>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  teams?: InputMaybe<Array<UpdateChallengeTeamInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChallengeTeamInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateChatRoomInput = {
  app?: InputMaybe<App>;
  id: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateClientInput = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  collectsMemberData?: InputMaybe<Scalars['Boolean']['input']>;
  collectsMemberDataForEEP?: InputMaybe<Scalars['Boolean']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  inactivityDuration?: InputMaybe<Scalars['Duration']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  maxFamilyMembers?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needsUsToFixTheirUserNames?: InputMaybe<Scalars['Boolean']['input']>;
  ssoConnection?: InputMaybe<Scalars['String']['input']>;
  strictAppCheck?: InputMaybe<Scalars['Boolean']['input']>;
  usesCustomChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  usesEligibilityServiceType?: InputMaybe<Scalars['Boolean']['input']>;
  usesFitnessCenterForEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  usesGlobalSignOut?: InputMaybe<Scalars['Boolean']['input']>;
  usesHighPerformanceCoaching?: InputMaybe<Scalars['Boolean']['input']>;
  usesPersonalTraining?: InputMaybe<Scalars['Boolean']['input']>;
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type UpdateCoachCronofyTimezoneInput = {
  coachId?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['ZoneId']['input'];
};

export type UpdateCoachCronofyTimezoneResponse = {
  __typename?: 'UpdateCoachCronofyTimezoneResponse';
  user: User;
};

export type UpdateCoachDataInput = {
  availableForMemberAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bodyPhotoId?: InputMaybe<Scalars['String']['input']>;
  calendlyUrl?: InputMaybe<Scalars['String']['input']>;
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  coachingStyle?: InputMaybe<CoachingStyle>;
  dailySessionLimit?: InputMaybe<Scalars['Int']['input']>;
  degrees?: InputMaybe<Array<Scalars['String']['input']>>;
  enableCalendarBuffer?: InputMaybe<Scalars['Boolean']['input']>;
  headshotPhotoId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inOfficeDays?: InputMaybe<Array<Scalars['Int']['input']>>;
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mboStaffId?: InputMaybe<Scalars['String']['input']>;
  passions?: InputMaybe<Array<Scalars['String']['input']>>;
  supportedCountries?: InputMaybe<Array<Scalars['String']['input']>>;
  supportedGoals?: InputMaybe<Array<UserGoalType>>;
  titles?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<CoachType>;
};

export type UpdateCoachDataPayload = {
  __typename?: 'UpdateCoachDataPayload';
  user: User;
};

export type UpdateCoachLocationInput = {
  id: Scalars['ID']['input'];
  isHeadquarters?: InputMaybe<Scalars['Boolean']['input']>;
  mboLocationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  studioId: Scalars['String']['input'];
};

export type UpdateCoachTouchPointInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  due?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  id: Scalars['ID']['input'];
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDomainInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateEmergencyContactInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMboBookingsPayload = {
  __typename?: 'UpdateMboBookingsPayload';
  remainingBookings: Scalars['Int']['output'];
};

export type UpdateNoteInput = {
  content: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNotificationOptionInput = {
  app?: InputMaybe<App>;
  description?: InputMaybe<Scalars['String']['input']>;
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  iterableId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePerformanceQualityScoreInput = {
  component?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  performanceQuality?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  subComponent?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlanInput = {
  /** If true, will allow admin users in the dev environment to override start date restrictions */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['ID']['input'];
  planItems?: InputMaybe<Array<UpdatePlanItemInput>>;
  startDate?: InputMaybe<Scalars['LocalDate']['input']>;
  targetedPerformanceQualities?: InputMaybe<Array<PerformanceQuality>>;
};

export type UpdatePlanItemInput = {
  component?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['ID']['input']>;
  date?: InputMaybe<Scalars['LocalDate']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PlanItemType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlanPayload = {
  __typename?: 'UpdatePlanPayload';
  plan: Plan;
};

export type UpdatePracticePayload = {
  __typename?: 'UpdatePracticePayload';
  assignedPractice: AssignedPractice;
};

export type UpdateProfileJsonInput = {
  profileJson?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type UpdateProfileJsonPayload = {
  __typename?: 'UpdateProfileJsonPayload';
  user: User;
};

export type UpdateProgramPayload = {
  __typename?: 'UpdateProgramPayload';
  assignedProgram: AssignedProgram;
};

export type UpdateStudioInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isCentral?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  mboServiceId?: InputMaybe<Scalars['String']['input']>;
  mboStudioId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserGoalInput = {
  coachNote?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  type: UserGoalType;
};

export type UpdateUserGoalPayload = {
  __typename?: 'UpdateUserGoalPayload';
  user: User;
};

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<UpdateAddressInput>;
  birthday?: InputMaybe<Scalars['LocalDate']['input']>;
  clientRelationship?: InputMaybe<UserClientRelationship>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emergencyContact?: InputMaybe<UpdateEmergencyContactInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fitClientId?: InputMaybe<Scalars['ID']['input']>;
  hasHighPerformanceCoaching?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identity?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isCoach?: InputMaybe<Scalars['Boolean']['input']>;
  isConcierge?: InputMaybe<Scalars['Boolean']['input']>;
  isMegaAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  isReadOnlyAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isSalesTeam?: InputMaybe<Scalars['Boolean']['input']>;
  isTestUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  officeLocation?: InputMaybe<Scalars['ID']['input']>;
  pronoun?: InputMaybe<Scalars['String']['input']>;
  registered?: InputMaybe<Scalars['Boolean']['input']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']['input']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
  workingStyle?: InputMaybe<WorkingStyle>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user: User;
};

export type User = Node & {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  activity: ActivityConnection;
  activityAggregates: ActivityAggregates;
  age?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Performs poorly and doesn't serve our use cases well. Prefer the app field on a chat or other object which is actually related to the interaction you're interested in. */
  app: App;
  assessmentSurveys: UserAssessmentSurveysConnection;
  assignedPrograms: UserAssignedProgramsConnection;
  auditLogs: AuditLogsConnection;
  authHistory: UserAuthHistoryConnection;
  availableClasses: UserAvailableClassesConnection;
  avatar?: Maybe<Photo>;
  birthday?: Maybe<Scalars['LocalDate']['output']>;
  blockedUsers: UserBlockedUsersConnection;
  bookedClasses: UserBookingsConnection;
  /** Creates or returns the chat between the user and the selected bot. Returns null if the user does not have access to the bot in question. */
  botChat?: Maybe<ChatRoom>;
  /** Tells the type of the bot. Returns null if the user is not a bot. */
  botKind?: Maybe<BotKind>;
  canShareData: Scalars['Boolean']['output'];
  challenges: UserChallengesConnection;
  chatRooms: UserChatRoomsConnection;
  clientRelationship?: Maybe<UserClientRelationship>;
  /** @deprecated use fitClient instead */
  clients: UserClientsConnection;
  coachData?: Maybe<CoachData>;
  coachSessionCredits?: Maybe<CoachSessionCredits>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  currentCustomPlan?: Maybe<Plan>;
  currentPractice?: Maybe<AssignedPractice>;
  currentProgram?: Maybe<AssignedProgram>;
  customPlans: UserPlansConnection;
  customPlansActiveDays: Array<ActiveCustomPlanDay>;
  devices: Array<Device>;
  eligibleEmailAddress?: Maybe<EligibleEmailAddress>;
  email: Scalars['String']['output'];
  emergencyContactDetails?: Maybe<EmergencyContactDetails>;
  familyInvitations: Array<EligibleEmailAddress>;
  firstName?: Maybe<Scalars['String']['output']>;
  fitClient?: Maybe<Client>;
  fitCoach?: Maybe<User>;
  fitCoachChat?: Maybe<ChatRoom>;
  goal?: Maybe<UserGoal>;
  hasHighPerformanceCoaching: Scalars['Boolean']['output'];
  hasInPersonMemberships: Scalars['Boolean']['output'];
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  identity?: Maybe<Scalars['String']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  isBlocked: Scalars['Boolean']['output'];
  isCoach: Scalars['Boolean']['output'];
  isConcierge: Scalars['Boolean']['output'];
  isFamilyMember: Scalars['Boolean']['output'];
  isMegaAdmin: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  isReadOnlyAdmin: Scalars['Boolean']['output'];
  isSalesTeam: Scalars['Boolean']['output'];
  isStarred: Scalars['Boolean']['output'];
  isTestUser: Scalars['Boolean']['output'];
  iterableToken: Token;
  lastActivity: LastActivity;
  lastName?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Note>;
  notes: UserNotesConnection;
  notificationOptions: Array<NotificationOption>;
  officeLocation?: Maybe<CoachLocation>;
  onboarding: Onboarding;
  opportunities: Array<OnboardingMotivation>;
  practicePrograms: UserPracticeProgramsConnection;
  profileComplete: Scalars['Boolean']['output'];
  profileJson?: Maybe<Scalars['JSONObject']['output']>;
  pronouns?: Maybe<Scalars['String']['output']>;
  registered: Scalars['Boolean']['output'];
  savedContent: ContentSavedConnection;
  section?: Maybe<ContentSection>;
  sessions: UserCoachSessionsConnection;
  share?: Maybe<Share>;
  shareDataWithEmployer: Scalars['Boolean']['output'];
  shareEEPData: Scalars['Boolean']['output'];
  shouldSeeRateAppModal: Scalars['Boolean']['output'];
  strengths: Array<OnboardingMotivation>;
  studios: UserStudiosConnection;
  surveys: UserSurveysConnection;
  token?: Maybe<Scalars['JSONObject']['output']>;
  unitsPreference: UnitsPreference;
  userActivity: UserActivityConnection;
  userActivityTrend: Array<UserActivitySummary>;
  wearableSamples: WearableSamplesConnection;
  wearableSamplesLastSyncedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Removed welcome section */
  welcomeSectionDisplay: WelcomeSectionDisplay;
  workingStyle?: Maybe<WorkingStyle>;
};

export type UserActivityArgs = {
  filter?: InputMaybe<ActivityFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserActivityAggregatesArgs = {
  dateRange?: InputMaybe<DateRange>;
};

export type UserAssessmentSurveysArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAssignedProgramsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAvailableClassesArgs = {
  filter: MboClassFilter;
  input?: InputMaybe<ConnectionInput>;
};

export type UserBlockedUsersArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserBookedClassesArgs = {
  filter: MboBookingsFilter;
  input?: InputMaybe<ConnectionInput>;
};

export type UserBotChatArgs = {
  bot: BotKind;
};

export type UserChallengesArgs = {
  filter?: InputMaybe<ChallengesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserChatRoomsArgs = {
  filters?: InputMaybe<ChatRoomsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ChatRoomsOrderBy>>;
};

export type UserClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserCustomPlansArgs = {
  filter?: InputMaybe<CustomPlansFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserCustomPlansActiveDaysArgs = {
  filter: CustomPlanActiveDaysFilter;
};

export type UserIterableTokenArgs = {
  tokenType?: InputMaybe<TokenPlatform>;
};

export type UserNotesArgs = {
  filter?: InputMaybe<UserNotesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserNotificationOptionsArgs = {
  app: App;
};

export type UserPracticeProgramsArgs = {
  filter?: InputMaybe<PracticeProgramFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserSavedContentArgs = {
  filter?: InputMaybe<ContentFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserSectionArgs = {
  id: Scalars['String']['input'];
};

export type UserSessionsArgs = {
  dateFilter?: InputMaybe<Array<DateFilter>>;
  filter?: InputMaybe<CoachSessionFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachSessionOrderBy>>;
};

export type UserStudiosArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserSurveysArgs = {
  filter?: InputMaybe<SurveyFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserUserActivityArgs = {
  filter?: InputMaybe<UserActivityFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserWearableSamplesArgs = {
  filter?: InputMaybe<WearableSamplesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserActivity = Node & {
  __typename?: 'UserActivity';
  activity?: Maybe<Activity>;
  attribution?: Maybe<ActivityAttribution>;
  attributionTitle?: Maybe<Scalars['String']['output']>;
  attributionType: UserActivityAttributionType;
  distance?: Maybe<Distance>;
  duration?: Maybe<Scalars['Int']['output']>;
  endTime: Scalars['ZonedDateTime']['output'];
  genre?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use SleepActivity.sleepEfficiency instead */
  sleepEfficiency?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<ActivitySource>;
  startTime: Scalars['ZonedDateTime']['output'];
  title: Scalars['String']['output'];
  type: UserActivityType;
};

export enum UserActivityAttributionType {
  Assessment = 'Assessment',
  Classes = 'Classes',
  Monitoring = 'Monitoring',
  Other = 'Other',
  Practice = 'Practice',
  Program = 'Program',
  Sleep = 'Sleep',
}

export type UserActivityConnection = Connection & {
  __typename?: 'UserActivityConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserActivityEdge>;
  nodes: Array<UserActivity>;
  pageInfo: PageInfo;
};

export type UserActivityEdge = Edge & {
  __typename?: 'UserActivityEdge';
  cursor: Scalars['String']['output'];
  node: UserActivity;
};

export type UserActivityFilter = {
  attributionType?: InputMaybe<Array<InputMaybe<UserActivityAttributionType>>>;
  type?: InputMaybe<Array<InputMaybe<UserActivityType>>>;
};

export type UserActivitySummary = {
  __typename?: 'UserActivitySummary';
  classes: Scalars['Int']['output'];
  date: Scalars['LocalDate']['output'];
  other: Scalars['Int']['output'];
  practice: Scalars['Int']['output'];
  program: Scalars['Int']['output'];
};

export enum UserActivityType {
  Aod = 'Aod',
  Article = 'Article',
  Assessment = 'Assessment',
  Audio = 'Audio',
  Encore = 'Encore',
  InPerson = 'InPerson',
  ManualEntry = 'ManualEntry',
  PracticeSelection = 'PracticeSelection',
  ProgramSelection = 'ProgramSelection',
  Video = 'Video',
  Virtual = 'Virtual',
  Vod = 'Vod',
  WearableGoogleFit = 'WearableGoogleFit',
  WearableHealthConnectActivity = 'WearableHealthConnectActivity',
  WearableHealthKit = 'WearableHealthKit',
  WearableHealthKitSleep = 'WearableHealthKitSleep',
}

export type UserAssessmentAssignmentEdge = Edge & {
  __typename?: 'UserAssessmentAssignmentEdge';
  cursor: Scalars['String']['output'];
  node: AssessmentAssignment;
};

export type UserAssessmentAssignmentsConnection = Connection & {
  __typename?: 'UserAssessmentAssignmentsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAssessmentAssignmentEdge>;
  nodes: Array<AssessmentAssignment>;
  pageInfo: PageInfo;
};

export type UserAssessmentAssignmentsFilter = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserAssessmentSurvey = Node & {
  __typename?: 'UserAssessmentSurvey';
  assignments: UserAssessmentAssignmentsConnection;
  id: Scalars['ID']['output'];
  /**
   * whether or not this survey is shown to members
   * these are not directly assignable and are completed by coaches
   * @deprecated Use type instead
   */
  isMemberFacing: Scalars['Boolean']['output'];
  mnmrType?: Maybe<Scalars['String']['output']>;
  template: SurveyTemplate;
  title?: Maybe<Scalars['String']['output']>;
  type: AssessmentType;
};

export type UserAssessmentSurveyAssignmentsArgs = {
  filter?: InputMaybe<UserAssessmentAssignmentsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserAssessmentSurveyEdge = Edge & {
  __typename?: 'UserAssessmentSurveyEdge';
  cursor: Scalars['String']['output'];
  node: UserAssessmentSurvey;
};

export type UserAssessmentSurveysConnection = Connection & {
  __typename?: 'UserAssessmentSurveysConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAssessmentSurveyEdge>;
  nodes: Array<UserAssessmentSurvey>;
  pageInfo: PageInfo;
};

export type UserAssignedProgramsConnection = Connection & {
  __typename?: 'UserAssignedProgramsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAssignedProgramsEdge>;
  nodes: Array<AssignedProgram>;
  pageInfo: PageInfo;
};

export type UserAssignedProgramsEdge = Edge & {
  __typename?: 'UserAssignedProgramsEdge';
  cursor: Scalars['String']['output'];
  node: AssignedProgram;
};

export type UserAuthHistory = Node & {
  __typename?: 'UserAuthHistory';
  challengeResponses: Array<Scalars['String']['output']>;
  eventDate?: Maybe<Scalars['ZonedDateTime']['output']>;
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type UserAuthHistoryConnection = Connection & {
  __typename?: 'UserAuthHistoryConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAuthHistoryEdge>;
  nodes: Array<UserAuthHistory>;
  pageInfo: PageInfo;
};

export type UserAuthHistoryEdge = Edge & {
  __typename?: 'UserAuthHistoryEdge';
  cursor: Scalars['String']['output'];
  node: UserAuthHistory;
};

export type UserAvailabilityConnection = Connection & {
  __typename?: 'UserAvailabilityConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAvailabilityEdge>;
  nodes: Array<Calendar>;
  pageInfo: PageInfo;
};

export type UserAvailabilityEdge = Edge & {
  __typename?: 'UserAvailabilityEdge';
  cursor: Scalars['String']['output'];
  node: Calendar;
};

export type UserAvailableClassesConnection = Connection & {
  __typename?: 'UserAvailableClassesConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAvailableClassesEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type UserAvailableClassesEdge = Edge & {
  __typename?: 'UserAvailableClassesEdge';
  cursor: Scalars['String']['output'];
  node: MboClass;
};

export type UserBlockedUsersConnection = Connection & {
  __typename?: 'UserBlockedUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserBlockedUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type UserBlockedUsersEdge = Edge & {
  __typename?: 'UserBlockedUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserBookingsConnection = Connection & {
  __typename?: 'UserBookingsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserBookingsEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type UserBookingsEdge = Edge & {
  __typename?: 'UserBookingsEdge';
  cursor: Scalars['String']['output'];
  node: MboClass;
};

export type UserChallengesConnection = Connection & {
  __typename?: 'UserChallengesConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserChallengesEdge>;
  nodes: Array<Challenge>;
  pageInfo: PageInfo;
};

export type UserChallengesEdge = Edge & {
  __typename?: 'UserChallengesEdge';
  cursor: Scalars['String']['output'];
  node: Challenge;
  place?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type UserChatRoomsConnection = Connection & {
  __typename?: 'UserChatRoomsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserChatRoomsEdge>;
  nodes: Array<ChatRoom>;
  pageInfo: PageInfo;
  unreadMessageCount: Scalars['Int']['output'];
};

export type UserChatRoomsEdge = Edge & {
  __typename?: 'UserChatRoomsEdge';
  cursor: Scalars['String']['output'];
  node: ChatRoom;
};

export enum UserClientRelationship {
  Dependent = 'Dependent',
  Employee = 'Employee',
}

export type UserClientsConnection = Connection & {
  __typename?: 'UserClientsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type UserClientsEdge = Edge & {
  __typename?: 'UserClientsEdge';
  cursor: Scalars['String']['output'];
  node: Client;
};

export type UserCoachSessionsConnection = Connection & {
  __typename?: 'UserCoachSessionsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserCoachSessionsEdge>;
  nodes: Array<CoachSession>;
  pageInfo: PageInfo;
};

export type UserCoachSessionsEdge = Edge & {
  __typename?: 'UserCoachSessionsEdge';
  cursor: Scalars['String']['output'];
  node: CoachSession;
};

export type UserGoal = {
  __typename?: 'UserGoal';
  coachNote?: Maybe<Scalars['String']['output']>;
  coachNoteUpdatedBy?: Maybe<User>;
  createdAt: Scalars['ZonedDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: UserGoalType;
  updatedAt: Scalars['ZonedDateTime']['output'];
};

export enum UserGoalType {
  Appearance = 'Appearance',
  EmotionalWellbeing = 'EmotionalWellbeing',
  EnergyLevels = 'EnergyLevels',
  Health = 'Health',
  /** @deprecated Not used */
  NotSure = 'NotSure',
  PainManagement = 'PainManagement',
  /** @deprecated Use 'PainManagement' instead */
  PainReduction = 'PainReduction',
  PhysicalPerformance = 'PhysicalPerformance',
  /** @deprecated Use 'SocialWellbeing' instead */
  Relationships = 'Relationships',
  SocialWellbeing = 'SocialWellbeing',
  /** @deprecated Use 'PhysicalPerformance' instead */
  ToneUp = 'ToneUp',
  /** @deprecated Not used */
  TryingNewThings = 'TryingNewThings',
  WorkPerformance = 'WorkPerformance',
}

export type UserNotesConnection = Connection & {
  __typename?: 'UserNotesConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserNotesEdge>;
  nodes: Array<Note>;
  pageInfo: PageInfo;
};

export type UserNotesEdge = Edge & {
  __typename?: 'UserNotesEdge';
  cursor: Scalars['String']['output'];
  node: Note;
};

export type UserNotesFilter = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserPlansConnection = Connection & {
  __typename?: 'UserPlansConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserPlansEdge>;
  nodes: Array<Plan>;
  pageInfo: PageInfo;
};

export type UserPlansEdge = Edge & {
  __typename?: 'UserPlansEdge';
  cursor: Scalars['String']['output'];
  node: Plan;
};

export type UserPracticeProgramEdge = Edge & {
  __typename?: 'UserPracticeProgramEdge';
  cursor: Scalars['String']['output'];
  node: PracticeProgram;
};

export type UserPracticeProgramsConnection = Connection & {
  __typename?: 'UserPracticeProgramsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserPracticeProgramEdge>;
  nodes: Array<PracticeProgram>;
  pageInfo: PageInfo;
};

export type UserStudiosConnection = Connection & {
  __typename?: 'UserStudiosConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserStudiosEdge>;
  nodes: Array<Studio>;
  pageInfo: PageInfo;
};

export type UserStudiosEdge = Edge & {
  __typename?: 'UserStudiosEdge';
  cursor: Scalars['String']['output'];
  node: Studio;
};

export type UserSurveysConnection = Connection & {
  __typename?: 'UserSurveysConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserSurveysEdge>;
  nodes: Array<Survey>;
  pageInfo: PageInfo;
};

export type UserSurveysEdge = Edge & {
  __typename?: 'UserSurveysEdge';
  cursor: Scalars['String']['output'];
  node: Survey;
};

export type UsersFilter = {
  clientId?: InputMaybe<Array<Scalars['String']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isCoach?: InputMaybe<Scalars['Boolean']['input']>;
  isTestUser?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Video = Content &
  Node & {
    __typename?: 'Video';
    author?: Maybe<Scalars['String']['output']>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    /** @deprecated use contentEquipment */
    equipment: Array<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    gameplanMetadata?: Maybe<GameplanMetadata>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    intensity?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    mediaType?: Maybe<Scalars['String']['output']>;
    mnmrType?: Maybe<Scalars['String']['output']>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    split?: Maybe<Split>;
    subGenre?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['String']['output']>;
    video: Scalars['String']['output'];
  };

export type VideoRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type VideoActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'VideoActivity';
    brand?: Maybe<Scalars['String']['output']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Video>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type WearableActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'WearableActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    distance?: Maybe<Distance>;
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heartRate?: Maybe<HeartRate>;
    id: Scalars['ID']['output'];
    overlappingActivity?: Maybe<Activity>;
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Scalars['JSONObject']['output']>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type WearableSample = Node & {
  __typename?: 'WearableSample';
  id: Scalars['ID']['output'];
  platform: Os;
  platformVersion: Scalars['String']['output'];
  sample: Scalars['JSONObject']['output'];
  sdk: WearableSdk;
  startDate: Scalars['ZonedDateTime']['output'];
};

export type WearableSampleEdge = Edge & {
  __typename?: 'WearableSampleEdge';
  cursor: Scalars['String']['output'];
  node: WearableSample;
};

export type WearableSamplesConnection = Connection & {
  __typename?: 'WearableSamplesConnection';
  count: Scalars['Int']['output'];
  edges: Array<WearableSampleEdge>;
  nodes: Array<WearableSample>;
  pageInfo: PageInfo;
};

export type WearableSamplesFilter = {
  platform?: InputMaybe<Os>;
  startDateRange?: InputMaybe<DateRange>;
};

export type WearableSamplesInput = {
  build: Scalars['String']['input'];
  platform: Os;
  platformVersion: Scalars['String']['input'];
  samples: Array<Scalars['JSONObject']['input']>;
  sdk: WearableSdk;
};

export enum WearableSdk {
  GoogleFit = 'GoogleFit',
  HealthConnect = 'HealthConnect',
  HealthKit = 'HealthKit',
}

export enum WelcomeSectionDisplay {
  Default = 'default',
  Hidden = 'hidden',
  ShowAlways = 'showAlways',
}

export enum WorkingStyle {
  Hybrid = 'hybrid',
  Office = 'office',
  Remote = 'remote',
}

export type ZonedDateTimeRange = {
  __typename?: 'ZonedDateTimeRange';
  end?: Maybe<Scalars['ZonedDateTime']['output']>;
  start?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type UserAvailableAssessmentsQueryVariables = Exact<{
  memberId: Scalars['ID']['input'];
  assessmentAssignmentsFilter?: InputMaybe<UserAssessmentAssignmentsFilter>;
  assessmentAssignmentsInput?: InputMaybe<ConnectionInput>;
}>;

export type UserAvailableAssessmentsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    assessmentSurveys: {
      __typename?: 'UserAssessmentSurveysConnection';
      edges: Array<{
        __typename?: 'UserAssessmentSurveyEdge';
        node: {
          __typename?: 'UserAssessmentSurvey';
          id: string;
          title?: string | null;
          mnmrType?: string | null;
          type: AssessmentType;
          template: {
            __typename?: 'SurveyTemplate';
            id: string;
            name: string;
            questions: Array<{
              __typename?: 'Question';
              id: string;
              text: string;
              questionType: QuestionType;
              questionSubtype?: QuestionSubtype | null;
              required: boolean;
              answer?:
                | {
                    __typename: 'EnumAnswer';
                    choices: Array<string>;
                    enumValue: Array<number>;
                  }
                | { __typename: 'NumberAnswer'; numberValue: number }
                | { __typename: 'PhotoAnswer' }
                | { __typename: 'StringAnswer'; stringValue: string }
                | null;
              choices: Array<{
                __typename?: 'MultipleChoiceOption';
                text: string;
                description: string;
                optionIdentifier: string;
                textEntry: boolean;
                score: number;
              }>;
            }>;
          };
          assignments: {
            __typename?: 'UserAssessmentAssignmentsConnection';
            count: number;
            pageInfo: {
              __typename?: 'PageInfo';
              hasNextPage: boolean;
              endCursor: string;
            };
            edges: Array<{
              __typename?: 'UserAssessmentAssignmentEdge';
              node: {
                __typename?: 'AssessmentAssignment';
                id: string;
                score?: number | null;
                createdAt: string;
                completedAt?: string | null;
                survey?: {
                  __typename?: 'Survey';
                  id: string;
                  surveyIdentifier: string;
                  responses: Array<{
                    __typename?: 'SurveyResponse';
                    id: string;
                    question: string;
                    questionIdentifier: string;
                    subcategory?: string | null;
                    numericValue?: number | null;
                    response:
                      | {
                          __typename?: 'EnumAnswer';
                          choices: Array<string>;
                          enumValue: Array<number>;
                        }
                      | { __typename?: 'NumberAnswer'; numberValue: number }
                      | { __typename?: 'PhotoAnswer' }
                      | { __typename?: 'StringAnswer'; stringValue: string };
                  }>;
                } | null;
                surveyContent: {
                  __typename?: 'InAppSurvey';
                  id: string;
                  title?: string | null;
                  subtitle?: string | null;
                  contentTime?: {
                    __typename?: 'ContentTime';
                    name: string;
                  } | null;
                };
                template: {
                  __typename?: 'SurveyTemplate';
                  id: string;
                  name: string;
                  questions: Array<{
                    __typename?: 'Question';
                    id: string;
                    text: string;
                    questionType: QuestionType;
                    questionSubtype?: QuestionSubtype | null;
                    required: boolean;
                    answer?:
                      | {
                          __typename: 'EnumAnswer';
                          choices: Array<string>;
                          enumValue: Array<number>;
                        }
                      | { __typename: 'NumberAnswer'; numberValue: number }
                      | { __typename: 'PhotoAnswer' }
                      | { __typename: 'StringAnswer'; stringValue: string }
                      | null;
                    choices: Array<{
                      __typename?: 'MultipleChoiceOption';
                      text: string;
                      description: string;
                      optionIdentifier: string;
                      textEntry: boolean;
                      score: number;
                    }>;
                  }>;
                };
              };
            }>;
          };
        };
      }>;
    };
  } | null;
};

export type UserAssessmentSurveyQueryVariables = Exact<{
  surveyContentId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  assessmentAssignmentsInput?: InputMaybe<ConnectionInput>;
  assessmentAssignmentsFilter?: InputMaybe<UserAssessmentAssignmentsFilter>;
}>;

export type UserAssessmentSurveyQuery = {
  __typename?: 'Query';
  userAssessmentSurvey: {
    __typename?: 'UserAssessmentSurvey';
    id: string;
    title?: string | null;
    mnmrType?: string | null;
    type: AssessmentType;
    template: {
      __typename?: 'SurveyTemplate';
      id: string;
      name: string;
      questions: Array<{
        __typename?: 'Question';
        id: string;
        text: string;
        questionType: QuestionType;
        questionSubtype?: QuestionSubtype | null;
        required: boolean;
        answer?:
          | {
              __typename: 'EnumAnswer';
              choices: Array<string>;
              enumValue: Array<number>;
            }
          | { __typename: 'NumberAnswer'; numberValue: number }
          | { __typename: 'PhotoAnswer' }
          | { __typename: 'StringAnswer'; stringValue: string }
          | null;
        choices: Array<{
          __typename?: 'MultipleChoiceOption';
          text: string;
          description: string;
          optionIdentifier: string;
          textEntry: boolean;
          score: number;
        }>;
      }>;
    };
    assignments: {
      __typename?: 'UserAssessmentAssignmentsConnection';
      count: number;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'UserAssessmentAssignmentEdge';
        node: {
          __typename?: 'AssessmentAssignment';
          id: string;
          score?: number | null;
          createdAt: string;
          completedAt?: string | null;
          survey?: {
            __typename?: 'Survey';
            id: string;
            surveyIdentifier: string;
            responses: Array<{
              __typename?: 'SurveyResponse';
              id: string;
              question: string;
              questionIdentifier: string;
              subcategory?: string | null;
              numericValue?: number | null;
              response:
                | {
                    __typename?: 'EnumAnswer';
                    choices: Array<string>;
                    enumValue: Array<number>;
                  }
                | { __typename?: 'NumberAnswer'; numberValue: number }
                | { __typename?: 'PhotoAnswer' }
                | { __typename?: 'StringAnswer'; stringValue: string };
            }>;
          } | null;
          surveyContent: {
            __typename?: 'InAppSurvey';
            id: string;
            title?: string | null;
            subtitle?: string | null;
            contentTime?: { __typename?: 'ContentTime'; name: string } | null;
          };
          template: {
            __typename?: 'SurveyTemplate';
            id: string;
            name: string;
            questions: Array<{
              __typename?: 'Question';
              id: string;
              text: string;
              questionType: QuestionType;
              questionSubtype?: QuestionSubtype | null;
              required: boolean;
              answer?:
                | {
                    __typename: 'EnumAnswer';
                    choices: Array<string>;
                    enumValue: Array<number>;
                  }
                | { __typename: 'NumberAnswer'; numberValue: number }
                | { __typename: 'PhotoAnswer' }
                | { __typename: 'StringAnswer'; stringValue: string }
                | null;
              choices: Array<{
                __typename?: 'MultipleChoiceOption';
                text: string;
                description: string;
                optionIdentifier: string;
                textEntry: boolean;
                score: number;
              }>;
            }>;
          };
        };
      }>;
    };
  };
};

export type AssignAssessmentsMutationVariables = Exact<{
  input: AssignAssessmentsInput;
}>;

export type AssignAssessmentsMutation = {
  __typename?: 'Mutation';
  assignAssessments: {
    __typename?: 'AssignAssessmentsPayload';
    assessments: Array<{
      __typename?: 'AssessmentAssignment';
      id: string;
      score?: number | null;
      createdAt: string;
      completedAt?: string | null;
      survey?: {
        __typename?: 'Survey';
        id: string;
        surveyIdentifier: string;
        responses: Array<{
          __typename?: 'SurveyResponse';
          id: string;
          question: string;
          questionIdentifier: string;
          subcategory?: string | null;
          numericValue?: number | null;
          response:
            | {
                __typename?: 'EnumAnswer';
                choices: Array<string>;
                enumValue: Array<number>;
              }
            | { __typename?: 'NumberAnswer'; numberValue: number }
            | { __typename?: 'PhotoAnswer' }
            | { __typename?: 'StringAnswer'; stringValue: string };
        }>;
      } | null;
      surveyContent: {
        __typename?: 'InAppSurvey';
        id: string;
        title?: string | null;
        subtitle?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      };
      template: {
        __typename?: 'SurveyTemplate';
        id: string;
        name: string;
        questions: Array<{
          __typename?: 'Question';
          id: string;
          text: string;
          questionType: QuestionType;
          questionSubtype?: QuestionSubtype | null;
          required: boolean;
          answer?:
            | {
                __typename: 'EnumAnswer';
                choices: Array<string>;
                enumValue: Array<number>;
              }
            | { __typename: 'NumberAnswer'; numberValue: number }
            | { __typename: 'PhotoAnswer' }
            | { __typename: 'StringAnswer'; stringValue: string }
            | null;
          choices: Array<{
            __typename?: 'MultipleChoiceOption';
            text: string;
            description: string;
            optionIdentifier: string;
            textEntry: boolean;
            score: number;
          }>;
        }>;
      };
    }>;
  };
};

export type DeleteAssignedAssessmentMutationVariables = Exact<{
  assignmentId: Scalars['ID']['input'];
}>;

export type DeleteAssignedAssessmentMutation = {
  __typename?: 'Mutation';
  deleteAssignedAssessment?: {
    __typename?: 'AssessmentAssignment';
    id: string;
  } | null;
};

export type AssessmentSurveyBasicsFragment = {
  __typename?: 'UserAssessmentSurvey';
  id: string;
  title?: string | null;
  mnmrType?: string | null;
  type: AssessmentType;
  template: {
    __typename?: 'SurveyTemplate';
    id: string;
    name: string;
    questions: Array<{
      __typename?: 'Question';
      id: string;
      text: string;
      questionType: QuestionType;
      questionSubtype?: QuestionSubtype | null;
      required: boolean;
      answer?:
        | {
            __typename: 'EnumAnswer';
            choices: Array<string>;
            enumValue: Array<number>;
          }
        | { __typename: 'NumberAnswer'; numberValue: number }
        | { __typename: 'PhotoAnswer' }
        | { __typename: 'StringAnswer'; stringValue: string }
        | null;
      choices: Array<{
        __typename?: 'MultipleChoiceOption';
        text: string;
        description: string;
        optionIdentifier: string;
        textEntry: boolean;
        score: number;
      }>;
    }>;
  };
  assignments: {
    __typename?: 'UserAssessmentAssignmentsConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      __typename?: 'UserAssessmentAssignmentEdge';
      node: {
        __typename?: 'AssessmentAssignment';
        id: string;
        score?: number | null;
        createdAt: string;
        completedAt?: string | null;
        survey?: {
          __typename?: 'Survey';
          id: string;
          surveyIdentifier: string;
          responses: Array<{
            __typename?: 'SurveyResponse';
            id: string;
            question: string;
            questionIdentifier: string;
            subcategory?: string | null;
            numericValue?: number | null;
            response:
              | {
                  __typename?: 'EnumAnswer';
                  choices: Array<string>;
                  enumValue: Array<number>;
                }
              | { __typename?: 'NumberAnswer'; numberValue: number }
              | { __typename?: 'PhotoAnswer' }
              | { __typename?: 'StringAnswer'; stringValue: string };
          }>;
        } | null;
        surveyContent: {
          __typename?: 'InAppSurvey';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          contentTime?: { __typename?: 'ContentTime'; name: string } | null;
        };
        template: {
          __typename?: 'SurveyTemplate';
          id: string;
          name: string;
          questions: Array<{
            __typename?: 'Question';
            id: string;
            text: string;
            questionType: QuestionType;
            questionSubtype?: QuestionSubtype | null;
            required: boolean;
            answer?:
              | {
                  __typename: 'EnumAnswer';
                  choices: Array<string>;
                  enumValue: Array<number>;
                }
              | { __typename: 'NumberAnswer'; numberValue: number }
              | { __typename: 'PhotoAnswer' }
              | { __typename: 'StringAnswer'; stringValue: string }
              | null;
            choices: Array<{
              __typename?: 'MultipleChoiceOption';
              text: string;
              description: string;
              optionIdentifier: string;
              textEntry: boolean;
              score: number;
            }>;
          }>;
        };
      };
    }>;
  };
};

export type AssignedAssessmentDetailsFragment = {
  __typename?: 'AssessmentAssignment';
  id: string;
  score?: number | null;
  createdAt: string;
  completedAt?: string | null;
  survey?: {
    __typename?: 'Survey';
    id: string;
    surveyIdentifier: string;
    responses: Array<{
      __typename?: 'SurveyResponse';
      id: string;
      question: string;
      questionIdentifier: string;
      subcategory?: string | null;
      numericValue?: number | null;
      response:
        | {
            __typename?: 'EnumAnswer';
            choices: Array<string>;
            enumValue: Array<number>;
          }
        | { __typename?: 'NumberAnswer'; numberValue: number }
        | { __typename?: 'PhotoAnswer' }
        | { __typename?: 'StringAnswer'; stringValue: string };
    }>;
  } | null;
  surveyContent: {
    __typename?: 'InAppSurvey';
    id: string;
    title?: string | null;
    subtitle?: string | null;
    contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  };
  template: {
    __typename?: 'SurveyTemplate';
    id: string;
    name: string;
    questions: Array<{
      __typename?: 'Question';
      id: string;
      text: string;
      questionType: QuestionType;
      questionSubtype?: QuestionSubtype | null;
      required: boolean;
      answer?:
        | {
            __typename: 'EnumAnswer';
            choices: Array<string>;
            enumValue: Array<number>;
          }
        | { __typename: 'NumberAnswer'; numberValue: number }
        | { __typename: 'PhotoAnswer' }
        | { __typename: 'StringAnswer'; stringValue: string }
        | null;
      choices: Array<{
        __typename?: 'MultipleChoiceOption';
        text: string;
        description: string;
        optionIdentifier: string;
        textEntry: boolean;
        score: number;
      }>;
    }>;
  };
};

export type ChatQueryVariables = Exact<{
  chatId?: InputMaybe<Scalars['ID']['input']>;
  userIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  input?: InputMaybe<ConnectionInput>;
  skipSelf?: Scalars['Boolean']['input'];
}>;

export type ChatQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    birthday?: string | null;
    pronouns?: string | null;
    identity?: string | null;
    weight?: number | null;
    height?: number | null;
    workingStyle?: WorkingStyle | null;
    isCoach: boolean;
    clientRelationship?: UserClientRelationship | null;
    hasHighPerformanceCoaching: boolean;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    createdAt: string;
    botKind?: BotKind | null;
    officeLocation?: {
      __typename?: 'CoachLocation';
      id: string;
      name?: string | null;
    } | null;
    botChat?: { __typename?: 'ChatRoom'; id: string } | null;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
  chatRoom?: {
    __typename?: 'ChatRoom';
    id: string;
    name?: string | null;
    kind: ChatRoomKind;
    app?: App | null;
    lastMessageRead?: string | null;
    lastMessageReceivedAt?: string | null;
    users: {
      __typename?: 'ChatRoomUsersConnection';
      count: number;
      edges: Array<{
        __typename?: 'ChatRoomUsersEdge';
        lastMessageRead?: string | null;
        node: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
          createdAt: string;
          botKind?: BotKind | null;
          lastActivity: {
            __typename?: 'LastActivity';
            heart?: string | null;
            heartWeb?: string | null;
          };
          avatar?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
        };
      }>;
    };
    messages: {
      __typename?: 'ChatRoomMessagesConnection';
      unreadMessageCount: number;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'ChatRoomMessagesEdge';
        node: {
          __typename?: 'Message';
          id: string;
          text: string;
          createdAt: string;
          automated: boolean;
          previews: Array<{
            __typename?: 'MessagePreview';
            title: string;
            description?: string | null;
            url: string;
            image?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
          }>;
          reactions: Array<{
            __typename?: 'Reaction';
            id: string;
            kind: ReactionKind;
            user: { __typename?: 'User'; id: string };
          }>;
          user: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            botKind?: BotKind | null;
            lastActivity: {
              __typename?: 'LastActivity';
              heart?: string | null;
              heartWeb?: string | null;
            };
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
          };
        };
      }>;
    };
  } | null;
};

export type ChatsQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filters?: InputMaybe<ChatRoomsFilter>;
  orderBy?: InputMaybe<Array<ChatRoomsOrderBy> | ChatRoomsOrderBy>;
}>;

export type ChatsQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    chatRooms: {
      __typename?: 'UserChatRoomsConnection';
      count: number;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'UserChatRoomsEdge';
        node: {
          __typename?: 'ChatRoom';
          id: string;
          name?: string | null;
          kind: ChatRoomKind;
          app?: App | null;
          lastMessageRead?: string | null;
          lastMessageReceivedAt?: string | null;
          users: {
            __typename?: 'ChatRoomUsersConnection';
            count: number;
            edges: Array<{
              __typename?: 'ChatRoomUsersEdge';
              lastMessageRead?: string | null;
              node: {
                __typename?: 'User';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
                createdAt: string;
                botKind?: BotKind | null;
                lastActivity: {
                  __typename?: 'LastActivity';
                  heart?: string | null;
                  heartWeb?: string | null;
                };
                avatar?: {
                  __typename?: 'Photo';
                  id: string;
                  basePath: string;
                  preview?: string | null;
                } | null;
              };
            }>;
          };
          messages: {
            __typename?: 'ChatRoomMessagesConnection';
            unreadMessageCount: number;
            pageInfo: {
              __typename?: 'PageInfo';
              hasNextPage: boolean;
              endCursor: string;
            };
            edges: Array<{
              __typename?: 'ChatRoomMessagesEdge';
              node: {
                __typename?: 'Message';
                id: string;
                text: string;
                createdAt: string;
                automated: boolean;
                previews: Array<{
                  __typename?: 'MessagePreview';
                  title: string;
                  description?: string | null;
                  url: string;
                  image?: {
                    __typename?: 'Photo';
                    id: string;
                    basePath: string;
                    preview?: string | null;
                  } | null;
                }>;
                reactions: Array<{
                  __typename?: 'Reaction';
                  id: string;
                  kind: ReactionKind;
                  user: { __typename?: 'User'; id: string };
                }>;
                user: {
                  __typename?: 'User';
                  id: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  botKind?: BotKind | null;
                  lastActivity: {
                    __typename?: 'LastActivity';
                    heart?: string | null;
                    heartWeb?: string | null;
                  };
                  avatar?: {
                    __typename?: 'Photo';
                    id: string;
                    basePath: string;
                    preview?: string | null;
                  } | null;
                };
              };
            }>;
          };
        };
      }>;
    };
  } | null;
};

export type CreateChatMutationVariables = Exact<{
  input: CreateChatRoomInput;
}>;

export type CreateChatMutation = {
  __typename?: 'Mutation';
  createChatRoom: {
    __typename?: 'MutateChatRoomPayload';
    chatRoom?: {
      __typename?: 'ChatRoom';
      id: string;
      name?: string | null;
      kind: ChatRoomKind;
      app?: App | null;
      lastMessageRead?: string | null;
      lastMessageReceivedAt?: string | null;
    } | null;
  };
};

export type ChatListDetailsFragment = {
  __typename?: 'User';
  id: string;
  chatRooms: {
    __typename?: 'UserChatRoomsConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      __typename?: 'UserChatRoomsEdge';
      node: {
        __typename?: 'ChatRoom';
        id: string;
        name?: string | null;
        kind: ChatRoomKind;
        app?: App | null;
        lastMessageRead?: string | null;
        lastMessageReceivedAt?: string | null;
        users: {
          __typename?: 'ChatRoomUsersConnection';
          count: number;
          edges: Array<{
            __typename?: 'ChatRoomUsersEdge';
            lastMessageRead?: string | null;
            node: {
              __typename?: 'User';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
              createdAt: string;
              botKind?: BotKind | null;
              lastActivity: {
                __typename?: 'LastActivity';
                heart?: string | null;
                heartWeb?: string | null;
              };
              avatar?: {
                __typename?: 'Photo';
                id: string;
                basePath: string;
                preview?: string | null;
              } | null;
            };
          }>;
        };
        messages: {
          __typename?: 'ChatRoomMessagesConnection';
          unreadMessageCount: number;
          pageInfo: {
            __typename?: 'PageInfo';
            hasNextPage: boolean;
            endCursor: string;
          };
          edges: Array<{
            __typename?: 'ChatRoomMessagesEdge';
            node: {
              __typename?: 'Message';
              id: string;
              text: string;
              createdAt: string;
              automated: boolean;
              previews: Array<{
                __typename?: 'MessagePreview';
                title: string;
                description?: string | null;
                url: string;
                image?: {
                  __typename?: 'Photo';
                  id: string;
                  basePath: string;
                  preview?: string | null;
                } | null;
              }>;
              reactions: Array<{
                __typename?: 'Reaction';
                id: string;
                kind: ReactionKind;
                user: { __typename?: 'User'; id: string };
              }>;
              user: {
                __typename?: 'User';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                botKind?: BotKind | null;
                lastActivity: {
                  __typename?: 'LastActivity';
                  heart?: string | null;
                  heartWeb?: string | null;
                };
                avatar?: {
                  __typename?: 'Photo';
                  id: string;
                  basePath: string;
                  preview?: string | null;
                } | null;
              };
            };
          }>;
        };
      };
    }>;
  };
};

export type MethodologyBotChatQueryVariables = Exact<{ [key: string]: never }>;

export type MethodologyBotChatQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    botChat?: { __typename?: 'ChatRoom'; id: string } | null;
  } | null;
};

export type NewMessagesSubscriptionVariables = Exact<{
  chatId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type NewMessagesSubscription = {
  __typename?: 'Subscription';
  newMessages: {
    __typename?: 'NewMessagePayload';
    optimisticId?: string | null;
    chatRoom: {
      __typename?: 'ChatRoom';
      id: string;
      lastMessageRead?: string | null;
      lastMessageReceivedAt?: string | null;
      messages: {
        __typename?: 'ChatRoomMessagesConnection';
        unreadMessageCount: number;
      };
    };
    message: {
      __typename?: 'Message';
      id: string;
      text: string;
      createdAt: string;
      automated: boolean;
      previews: Array<{
        __typename?: 'MessagePreview';
        title: string;
        description?: string | null;
        url: string;
        image?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      }>;
      reactions: Array<{
        __typename?: 'Reaction';
        id: string;
        kind: ReactionKind;
        user: { __typename?: 'User'; id: string };
      }>;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        botKind?: BotKind | null;
        lastActivity: {
          __typename?: 'LastActivity';
          heart?: string | null;
          heartWeb?: string | null;
        };
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      };
    };
  };
};

export type NewMessageDetailsFragment = {
  __typename?: 'NewMessagePayload';
  optimisticId?: string | null;
  chatRoom: {
    __typename?: 'ChatRoom';
    id: string;
    lastMessageRead?: string | null;
    lastMessageReceivedAt?: string | null;
    messages: {
      __typename?: 'ChatRoomMessagesConnection';
      unreadMessageCount: number;
    };
  };
  message: {
    __typename?: 'Message';
    id: string;
    text: string;
    createdAt: string;
    automated: boolean;
    previews: Array<{
      __typename?: 'MessagePreview';
      title: string;
      description?: string | null;
      url: string;
      image?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    }>;
    reactions: Array<{
      __typename?: 'Reaction';
      id: string;
      kind: ReactionKind;
      user: { __typename?: 'User'; id: string };
    }>;
    user: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      botKind?: BotKind | null;
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    };
  };
};

export type ChatRoomUpdateSubscriptionVariables = Exact<{
  chatRoomId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ChatRoomUpdateSubscription = {
  __typename?: 'Subscription';
  chatRoomUpdate: {
    __typename?: 'ChatRoom';
    id: string;
    lastMessageRead?: string | null;
    app?: App | null;
    messages: {
      __typename?: 'ChatRoomMessagesConnection';
      unreadMessageCount: number;
    };
  };
};

export type NewTotalUnreadMessageCountSubscriptionVariables = Exact<{
  kinds?: InputMaybe<Array<ChatRoomKind> | ChatRoomKind>;
  apps?: InputMaybe<Array<App> | App>;
}>;

export type NewTotalUnreadMessageCountSubscription = {
  __typename?: 'Subscription';
  newTotalUnreadMessageCount: number;
};

export type SendMessageMutationVariables = Exact<{
  chatId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
  optimisticId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SendMessageMutation = {
  __typename?: 'Mutation';
  sendMessage: {
    __typename?: 'Message';
    id: string;
    text: string;
    createdAt: string;
    automated: boolean;
    previews: Array<{
      __typename?: 'MessagePreview';
      title: string;
      description?: string | null;
      url: string;
      image?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    }>;
    reactions: Array<{
      __typename?: 'Reaction';
      id: string;
      kind: ReactionKind;
      user: { __typename?: 'User'; id: string };
    }>;
    user: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      botKind?: BotKind | null;
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    };
  };
};

export type AddReactionMutationVariables = Exact<{
  messageId: Scalars['ID']['input'];
  kind: ReactionKind;
}>;

export type AddReactionMutation = {
  __typename?: 'Mutation';
  addReaction: {
    __typename?: 'Message';
    id: string;
    reactions: Array<{
      __typename?: 'Reaction';
      id: string;
      kind: ReactionKind;
      user: { __typename?: 'User'; id: string };
    }>;
  };
};

export type RemoveReactionMutationVariables = Exact<{
  reactionId: Scalars['ID']['input'];
}>;

export type RemoveReactionMutation = {
  __typename?: 'Mutation';
  removeReaction: {
    __typename?: 'Message';
    id: string;
    reactions: Array<{
      __typename?: 'Reaction';
      id: string;
      kind: ReactionKind;
      user: { __typename?: 'User'; id: string };
    }>;
  };
};

export type ChatRoomBasicsFragment = {
  __typename?: 'ChatRoom';
  id: string;
  name?: string | null;
  kind: ChatRoomKind;
  app?: App | null;
  lastMessageRead?: string | null;
  lastMessageReceivedAt?: string | null;
};

export type ChatRoomDetailsFragment = {
  __typename?: 'ChatRoom';
  id: string;
  name?: string | null;
  kind: ChatRoomKind;
  app?: App | null;
  lastMessageRead?: string | null;
  lastMessageReceivedAt?: string | null;
  users: {
    __typename?: 'ChatRoomUsersConnection';
    count: number;
    edges: Array<{
      __typename?: 'ChatRoomUsersEdge';
      lastMessageRead?: string | null;
      node: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
        createdAt: string;
        botKind?: BotKind | null;
        lastActivity: {
          __typename?: 'LastActivity';
          heart?: string | null;
          heartWeb?: string | null;
        };
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      };
    }>;
  };
  messages: {
    __typename?: 'ChatRoomMessagesConnection';
    unreadMessageCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      __typename?: 'ChatRoomMessagesEdge';
      node: {
        __typename?: 'Message';
        id: string;
        text: string;
        createdAt: string;
        automated: boolean;
        previews: Array<{
          __typename?: 'MessagePreview';
          title: string;
          description?: string | null;
          url: string;
          image?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
        }>;
        reactions: Array<{
          __typename?: 'Reaction';
          id: string;
          kind: ReactionKind;
          user: { __typename?: 'User'; id: string };
        }>;
        user: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          botKind?: BotKind | null;
          lastActivity: {
            __typename?: 'LastActivity';
            heart?: string | null;
            heartWeb?: string | null;
          };
          avatar?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
        };
      };
    }>;
  };
};

export type MarkChatReadMutationVariables = Exact<{
  chatId: Scalars['ID']['input'];
  lastMessageRead: Scalars['ZonedDateTime']['input'];
}>;

export type MarkChatReadMutation = {
  __typename?: 'Mutation';
  markChatRead: {
    __typename?: 'ChatRoom';
    id: string;
    lastMessageRead?: string | null;
    lastMessageReceivedAt?: string | null;
    messages: {
      __typename?: 'ChatRoomMessagesConnection';
      unreadMessageCount: number;
    };
  };
};

export type ChatRoomUsersConnFragment = {
  __typename?: 'ChatRoomUsersConnection';
  count: number;
  edges: Array<{
    __typename?: 'ChatRoomUsersEdge';
    lastMessageRead?: string | null;
    node: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      createdAt: string;
      botKind?: BotKind | null;
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    };
  }>;
};

export type MessageConnFragment = {
  __typename?: 'ChatRoomMessagesConnection';
  unreadMessageCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    hasNextPage: boolean;
    endCursor: string;
  };
  edges: Array<{
    __typename?: 'ChatRoomMessagesEdge';
    node: {
      __typename?: 'Message';
      id: string;
      text: string;
      createdAt: string;
      automated: boolean;
      previews: Array<{
        __typename?: 'MessagePreview';
        title: string;
        description?: string | null;
        url: string;
        image?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      }>;
      reactions: Array<{
        __typename?: 'Reaction';
        id: string;
        kind: ReactionKind;
        user: { __typename?: 'User'; id: string };
      }>;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        botKind?: BotKind | null;
        lastActivity: {
          __typename?: 'LastActivity';
          heart?: string | null;
          heartWeb?: string | null;
        };
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      };
    };
  }>;
};

export type MessageDetailsFragment = {
  __typename?: 'Message';
  id: string;
  text: string;
  createdAt: string;
  automated: boolean;
  previews: Array<{
    __typename?: 'MessagePreview';
    title: string;
    description?: string | null;
    url: string;
    image?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  }>;
  reactions: Array<{
    __typename?: 'Reaction';
    id: string;
    kind: ReactionKind;
    user: { __typename?: 'User'; id: string };
  }>;
  user: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    botKind?: BotKind | null;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  };
};

export type MemberCoachSessionsQueryVariables = Exact<{
  memberId: Scalars['ID']['input'];
  orderBy?: InputMaybe<Array<CoachSessionOrderBy> | CoachSessionOrderBy>;
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<CoachSessionFilter>;
  dateFilter?: InputMaybe<Array<DateFilter> | DateFilter>;
}>;

export type MemberCoachSessionsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    sessions: {
      __typename?: 'UserCoachSessionsConnection';
      count: number;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'UserCoachSessionsEdge';
        node: {
          __typename?: 'CoachSession';
          id: string;
          sessionStyle: CoachSessionStyle;
          sessionType?: CoachSessionType | null;
          sessionSubtype?: CoachSessionSubtype | null;
          attendance?: CoachSessionAttendance | null;
          reason?: string | null;
          additionalInfo?: string | null;
          joinableTime: string;
          meetingUrl?: string | null;
          calendar?: {
            __typename?: 'Calendar';
            start: string;
            end: string;
          } | null;
          coach: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
          };
          member: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            lastActivity: {
              __typename?: 'LastActivity';
              heart?: string | null;
              heartWeb?: string | null;
            };
            fitCoach?: { __typename?: 'User'; id: string } | null;
            fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
            fitClient?: {
              __typename?: 'Client';
              id: string;
              usesPersonalTraining: boolean;
            } | null;
          };
          location?: {
            __typename?: 'CoachLocation';
            id: string;
            name?: string | null;
          } | null;
          chatRoom?: { __typename?: 'ChatRoom'; id: string } | null;
        };
      }>;
    };
  } | null;
};

export type MemberCoachSessionsCountQueryVariables = Exact<{
  memberId: Scalars['ID']['input'];
  filter?: InputMaybe<CoachSessionFilter>;
  dateFilter?: InputMaybe<Array<DateFilter> | DateFilter>;
}>;

export type MemberCoachSessionsCountQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    sessions: { __typename?: 'UserCoachSessionsConnection'; count: number };
  } | null;
};

export type PersonalTrainingInfoFragment = {
  __typename?: 'User';
  futureSessions: { __typename?: 'UserCoachSessionsConnection'; count: number };
  pastSessions: { __typename?: 'UserCoachSessionsConnection'; count: number };
  excusedSessions: {
    __typename?: 'UserCoachSessionsConnection';
    count: number;
  };
  availableSessions?: {
    __typename?: 'CoachSessionCredits';
    count: number;
    rowVersion: number;
  } | null;
};

export type MemberPersonalTrainingInfoQueryVariables = Exact<{
  memberId: Scalars['ID']['input'];
}>;

export type MemberPersonalTrainingInfoQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    futureSessions: {
      __typename?: 'UserCoachSessionsConnection';
      count: number;
    };
    pastSessions: { __typename?: 'UserCoachSessionsConnection'; count: number };
    excusedSessions: {
      __typename?: 'UserCoachSessionsConnection';
      count: number;
    };
    availableSessions?: {
      __typename?: 'CoachSessionCredits';
      count: number;
      rowVersion: number;
    } | null;
  } | null;
};

export type MemberNextAndLastCoachSessionQueryVariables = Exact<{
  memberId: Scalars['ID']['input'];
  coachId: Scalars['ID']['input'];
}>;

export type MemberNextAndLastCoachSessionQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    nextSession: {
      __typename?: 'UserCoachSessionsConnection';
      count: number;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'UserCoachSessionsEdge';
        node: {
          __typename?: 'CoachSession';
          id: string;
          sessionStyle: CoachSessionStyle;
          sessionType?: CoachSessionType | null;
          sessionSubtype?: CoachSessionSubtype | null;
          attendance?: CoachSessionAttendance | null;
          reason?: string | null;
          additionalInfo?: string | null;
          joinableTime: string;
          meetingUrl?: string | null;
          calendar?: {
            __typename?: 'Calendar';
            start: string;
            end: string;
          } | null;
          coach: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
          };
          member: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            lastActivity: {
              __typename?: 'LastActivity';
              heart?: string | null;
              heartWeb?: string | null;
            };
            fitCoach?: { __typename?: 'User'; id: string } | null;
            fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
            fitClient?: {
              __typename?: 'Client';
              id: string;
              usesPersonalTraining: boolean;
            } | null;
          };
          location?: {
            __typename?: 'CoachLocation';
            id: string;
            name?: string | null;
          } | null;
          chatRoom?: { __typename?: 'ChatRoom'; id: string } | null;
        };
      }>;
    };
    lastSession: {
      __typename?: 'UserCoachSessionsConnection';
      count: number;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'UserCoachSessionsEdge';
        node: {
          __typename?: 'CoachSession';
          id: string;
          sessionStyle: CoachSessionStyle;
          sessionType?: CoachSessionType | null;
          sessionSubtype?: CoachSessionSubtype | null;
          attendance?: CoachSessionAttendance | null;
          reason?: string | null;
          additionalInfo?: string | null;
          joinableTime: string;
          meetingUrl?: string | null;
          calendar?: {
            __typename?: 'Calendar';
            start: string;
            end: string;
          } | null;
          coach: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
          };
          member: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            lastActivity: {
              __typename?: 'LastActivity';
              heart?: string | null;
              heartWeb?: string | null;
            };
            fitCoach?: { __typename?: 'User'; id: string } | null;
            fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
            fitClient?: {
              __typename?: 'Client';
              id: string;
              usesPersonalTraining: boolean;
            } | null;
          };
          location?: {
            __typename?: 'CoachLocation';
            id: string;
            name?: string | null;
          } | null;
          chatRoom?: { __typename?: 'ChatRoom'; id: string } | null;
        };
      }>;
    };
  } | null;
};

export type CoachSessionBasicsFragment = {
  __typename?: 'CoachSession';
  id: string;
  sessionStyle: CoachSessionStyle;
  sessionType?: CoachSessionType | null;
  sessionSubtype?: CoachSessionSubtype | null;
  attendance?: CoachSessionAttendance | null;
  reason?: string | null;
  additionalInfo?: string | null;
  joinableTime: string;
  meetingUrl?: string | null;
  calendar?: { __typename?: 'Calendar'; start: string; end: string } | null;
  coach: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  };
  member: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    fitCoach?: { __typename?: 'User'; id: string } | null;
    fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
    fitClient?: {
      __typename?: 'Client';
      id: string;
      usesPersonalTraining: boolean;
    } | null;
  };
  location?: {
    __typename?: 'CoachLocation';
    id: string;
    name?: string | null;
  } | null;
  chatRoom?: { __typename?: 'ChatRoom'; id: string } | null;
};

export type MemberBookingDetailsFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  avatar?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  lastActivity: {
    __typename?: 'LastActivity';
    heart?: string | null;
    heartWeb?: string | null;
  };
  fitCoach?: { __typename?: 'User'; id: string } | null;
  fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
  fitClient?: {
    __typename?: 'Client';
    id: string;
    usesPersonalTraining: boolean;
  } | null;
};

export type CancelSessionMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
}>;

export type CancelSessionMutation = {
  __typename?: 'Mutation';
  cancelSession: boolean;
};

export type MarkSessionAttendanceMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
  attendance: CoachSessionAttendance;
  fetchPersonalTrainingInfo?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type MarkSessionAttendanceMutation = {
  __typename?: 'Mutation';
  updateAttendance: {
    __typename?: 'CoachSession';
    id: string;
    attendance?: CoachSessionAttendance | null;
    user?: {
      __typename?: 'User';
      id: string;
      futureSessions: {
        __typename?: 'UserCoachSessionsConnection';
        count: number;
      };
      pastSessions: {
        __typename?: 'UserCoachSessionsConnection';
        count: number;
      };
      excusedSessions: {
        __typename?: 'UserCoachSessionsConnection';
        count: number;
      };
      availableSessions?: {
        __typename?: 'CoachSessionCredits';
        count: number;
        rowVersion: number;
      } | null;
    };
  };
};

export type SetSessionCreditsMutationVariables = Exact<{
  input: SessionCreditsInput;
}>;

export type SetSessionCreditsMutation = {
  __typename?: 'Mutation';
  setSessionCredits: {
    __typename?: 'SessionCreditsPayload';
    count: number;
    rowVersion: number;
  };
};

export type CoachSessionsQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<CoachSessionFilter>;
  orderBy?: InputMaybe<Array<CoachSessionOrderBy> | CoachSessionOrderBy>;
}>;

export type CoachSessionsQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      allSessions: {
        __typename?: 'UserCoachSessionsConnection';
        count: number;
      };
      sessions: {
        __typename?: 'UserCoachSessionsConnection';
        count: number;
        pageInfo: {
          __typename?: 'PageInfo';
          endCursor: string;
          hasNextPage: boolean;
        };
        edges: Array<{
          __typename?: 'UserCoachSessionsEdge';
          node: {
            __typename?: 'CoachSession';
            id: string;
            sessionStyle: CoachSessionStyle;
            sessionType?: CoachSessionType | null;
            sessionSubtype?: CoachSessionSubtype | null;
            attendance?: CoachSessionAttendance | null;
            reason?: string | null;
            additionalInfo?: string | null;
            joinableTime: string;
            meetingUrl?: string | null;
            calendar?: {
              __typename?: 'Calendar';
              start: string;
              end: string;
            } | null;
            coach: {
              __typename?: 'User';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
              avatar?: {
                __typename?: 'Photo';
                id: string;
                basePath: string;
                preview?: string | null;
              } | null;
            };
            member: {
              __typename?: 'User';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
              avatar?: {
                __typename?: 'Photo';
                id: string;
                basePath: string;
                preview?: string | null;
              } | null;
              lastActivity: {
                __typename?: 'LastActivity';
                heart?: string | null;
                heartWeb?: string | null;
              };
              fitCoach?: { __typename?: 'User'; id: string } | null;
              fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
              fitClient?: {
                __typename?: 'Client';
                id: string;
                usesPersonalTraining: boolean;
              } | null;
            };
            location?: {
              __typename?: 'CoachLocation';
              id: string;
              name?: string | null;
            } | null;
            chatRoom?: { __typename?: 'ChatRoom'; id: string } | null;
          };
        }>;
      };
    } | null;
  } | null;
};

export type GetPreferencesDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPreferencesDetailsQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      cronofyTimezone?: string | null;
      dailySessionLimit?: number | null;
      calendarBuffer: {
        __typename?: 'CalendarBuffer';
        beforeInMinutes: number;
      };
    } | null;
  } | null;
};

export type PreferenceDetailsFragment = {
  __typename?: 'CoachData';
  cronofyTimezone?: string | null;
  dailySessionLimit?: number | null;
  calendarBuffer: { __typename?: 'CalendarBuffer'; beforeInMinutes: number };
};

export type CalendarBufferFragment = {
  __typename?: 'CoachData';
  calendarBuffer: { __typename?: 'CalendarBuffer'; beforeInMinutes: number };
};

export type UpdateCoachPreferencesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  enableCalendarBuffer?: InputMaybe<Scalars['Boolean']['input']>;
  dailySessionLimit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type UpdateCoachPreferencesMutation = {
  __typename?: 'Mutation';
  updateCoachData: {
    __typename?: 'UpdateCoachDataPayload';
    user: {
      __typename?: 'User';
      id: string;
      coachData?: {
        __typename?: 'CoachData';
        dailySessionLimit?: number | null;
        calendarBuffer: {
          __typename?: 'CalendarBuffer';
          beforeInMinutes: number;
        };
      } | null;
    };
  };
};

export type UpdateCoachCronofyTimezoneMutationVariables = Exact<{
  input: UpdateCoachCronofyTimezoneInput;
}>;

export type UpdateCoachCronofyTimezoneMutation = {
  __typename?: 'Mutation';
  updateCoachCronofyTimezone: {
    __typename?: 'UpdateCoachCronofyTimezoneResponse';
    user: {
      __typename?: 'User';
      id: string;
      coachData?: {
        __typename?: 'CoachData';
        id: string;
        cronofyTimezone?: string | null;
      } | null;
    };
  };
};

export type GetConnectionDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetConnectionDetailsQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      type?: CoachType | null;
      coachingStyle?: CoachingStyle | null;
      inOfficeDays: Array<number>;
      cronofyTimezone?: string | null;
      calendarConnected: boolean;
      clients: {
        __typename?: 'RootClientsConnection';
        edges: Array<{
          __typename?: 'RootClientsEdge';
          node: {
            __typename?: 'Client';
            id: string;
            displayName: string;
            usesPersonalTraining: boolean;
          };
        }>;
      };
      clientRequiredCalendarProfiles: {
        __typename?: 'RootClientsConnection';
        edges: Array<{
          __typename?: 'RootClientsEdge';
          node: {
            __typename?: 'Client';
            id: string;
            displayName: string;
            calendarPlatform?: ClientCalendarPlatform | null;
          };
        }>;
      };
      clientRequiredConferencingProfiles: {
        __typename?: 'RootClientsConnection';
        edges: Array<{
          __typename?: 'RootClientsEdge';
          node: {
            __typename?: 'Client';
            id: string;
            displayName: string;
            calendarPlatform?: ClientCalendarPlatform | null;
            videoPlatform?: ClientVideoPlatform | null;
          };
        }>;
      };
      calendarProfiles: Array<{
        __typename?: 'CalendarProfile';
        profileId: string;
        profileName: string;
        profileConnected: boolean;
        platform?: ClientCalendarPlatform | null;
        providerService?: string | null;
      }>;
      conferencingProfiles: Array<{
        __typename?: 'ConferencingProfile';
        providerName: string;
        profileId: string;
        profileName: string;
        profileConnected: boolean;
        platform?: ClientVideoPlatform | null;
      }>;
    } | null;
  } | null;
};

export type CronofyElementTokenQueryVariables = Exact<{
  permissions: Array<CronofyElementPermission> | CronofyElementPermission;
}>;

export type CronofyElementTokenQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      cronofyElementToken?: string | null;
    } | null;
  } | null;
};

export type GetCronofyAuthUrlsQueryVariables = Exact<{
  returnUrl: Scalars['String']['input'];
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  includeCalendarPlatform: Scalars['Boolean']['input'];
  conferencingPlatform?: InputMaybe<ClientVideoPlatform>;
  includeConferencingPlatform: Scalars['Boolean']['input'];
}>;

export type GetCronofyAuthUrlsQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      cronofyAuthUrl?: string | null;
      cronofyExternalCalendarUrl?: string | null;
      cronofyConferencingUrl?: string | null;
    } | null;
  } | null;
};

export type GetCronofyCalendarAuthUrlQueryVariables = Exact<{
  returnUrl: Scalars['String']['input'];
  calendarPlatform: ClientCalendarPlatform;
}>;

export type GetCronofyCalendarAuthUrlQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      cronofyExternalCalendarUrl?: string | null;
    } | null;
  } | null;
};

export type ConnectionDetailsFragment = {
  __typename?: 'CoachData';
  id: string;
  cronofyTimezone?: string | null;
  calendarConnected: boolean;
  clientRequiredCalendarProfiles: {
    __typename?: 'RootClientsConnection';
    edges: Array<{
      __typename?: 'RootClientsEdge';
      node: {
        __typename?: 'Client';
        id: string;
        displayName: string;
        calendarPlatform?: ClientCalendarPlatform | null;
      };
    }>;
  };
  clientRequiredConferencingProfiles: {
    __typename?: 'RootClientsConnection';
    edges: Array<{
      __typename?: 'RootClientsEdge';
      node: {
        __typename?: 'Client';
        id: string;
        displayName: string;
        calendarPlatform?: ClientCalendarPlatform | null;
        videoPlatform?: ClientVideoPlatform | null;
      };
    }>;
  };
  calendarProfiles: Array<{
    __typename?: 'CalendarProfile';
    profileId: string;
    profileName: string;
    profileConnected: boolean;
    platform?: ClientCalendarPlatform | null;
    providerService?: string | null;
  }>;
  conferencingProfiles: Array<{
    __typename?: 'ConferencingProfile';
    providerName: string;
    profileId: string;
    profileName: string;
    profileConnected: boolean;
    platform?: ClientVideoPlatform | null;
  }>;
};

export type ClientCalendarProfilesBasicsFragment = {
  __typename?: 'Client';
  id: string;
  displayName: string;
  calendarPlatform?: ClientCalendarPlatform | null;
};

export type ClientConferencingProfilesBasicsFragment = {
  __typename?: 'Client';
  id: string;
  displayName: string;
  calendarPlatform?: ClientCalendarPlatform | null;
  videoPlatform?: ClientVideoPlatform | null;
};

export type CalendarProfileDetailsFragment = {
  __typename?: 'CalendarProfile';
  profileId: string;
  profileName: string;
  profileConnected: boolean;
  platform?: ClientCalendarPlatform | null;
  providerService?: string | null;
};

export type ConferencingProfileDetailsFragment = {
  __typename?: 'ConferencingProfile';
  providerName: string;
  profileId: string;
  profileName: string;
  profileConnected: boolean;
  platform?: ClientVideoPlatform | null;
};

export type BookSessionMutationVariables = Exact<{
  input: BookSessionInput;
}>;

export type BookSessionMutation = {
  __typename?: 'Mutation';
  bookSession: {
    __typename?: 'BookSessionPayload';
    session: {
      __typename?: 'CoachSession';
      id: string;
      sessionStyle: CoachSessionStyle;
      sessionType?: CoachSessionType | null;
      sessionSubtype?: CoachSessionSubtype | null;
      attendance?: CoachSessionAttendance | null;
      reason?: string | null;
      additionalInfo?: string | null;
      joinableTime: string;
      meetingUrl?: string | null;
      calendar?: { __typename?: 'Calendar'; start: string; end: string } | null;
      coach: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      };
      member: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        lastActivity: {
          __typename?: 'LastActivity';
          heart?: string | null;
          heartWeb?: string | null;
        };
        fitCoach?: { __typename?: 'User'; id: string } | null;
        fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
        fitClient?: {
          __typename?: 'Client';
          id: string;
          usesPersonalTraining: boolean;
        } | null;
      };
      location?: {
        __typename?: 'CoachLocation';
        id: string;
        name?: string | null;
      } | null;
      chatRoom?: { __typename?: 'ChatRoom'; id: string } | null;
    };
  };
};

export type RebookSessionMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
  start: Scalars['ZonedDateTime']['input'];
}>;

export type RebookSessionMutation = {
  __typename?: 'Mutation';
  rebookSession: {
    __typename?: 'BookSessionPayload';
    session: {
      __typename?: 'CoachSession';
      id: string;
      sessionStyle: CoachSessionStyle;
      sessionType?: CoachSessionType | null;
      sessionSubtype?: CoachSessionSubtype | null;
      attendance?: CoachSessionAttendance | null;
      reason?: string | null;
      additionalInfo?: string | null;
      joinableTime: string;
      meetingUrl?: string | null;
      calendar?: { __typename?: 'Calendar'; start: string; end: string } | null;
      coach: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      };
      member: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        lastActivity: {
          __typename?: 'LastActivity';
          heart?: string | null;
          heartWeb?: string | null;
        };
        fitCoach?: { __typename?: 'User'; id: string } | null;
        fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
        fitClient?: {
          __typename?: 'Client';
          id: string;
          usesPersonalTraining: boolean;
        } | null;
      };
      location?: {
        __typename?: 'CoachLocation';
        id: string;
        name?: string | null;
      } | null;
      chatRoom?: { __typename?: 'ChatRoom'; id: string } | null;
    };
  };
};

export type CoachTouchPointsQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<TouchPointFilter>;
  orderBy?: InputMaybe<Array<TouchPointsOrderBy> | TouchPointsOrderBy>;
}>;

export type CoachTouchPointsQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      touchPoints: {
        __typename?: 'CoachTouchPointConnection';
        count: number;
        pageInfo: {
          __typename?: 'PageInfo';
          endCursor: string;
          hasNextPage: boolean;
        };
        edges: Array<{
          __typename?: 'CoachTouchPointEdge';
          cursor: string;
          node: {
            __typename?: 'CoachTouchPoint';
            id: string;
            due: string;
            title: string;
            description: string;
            type: CoachTouchPointType;
            completedAt?: string | null;
            member: {
              __typename?: 'User';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
              createdAt: string;
              botKind?: BotKind | null;
              fitClient?: {
                __typename?: 'Client';
                id: string;
                name?: string | null;
                usesPersonalTraining: boolean;
              } | null;
              fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
              fitCoach?: { __typename?: 'User'; id: string } | null;
              lastActivity: {
                __typename?: 'LastActivity';
                heart?: string | null;
                heartWeb?: string | null;
              };
              avatar?: {
                __typename?: 'Photo';
                id: string;
                basePath: string;
                preview?: string | null;
              } | null;
            };
            metadata?: {
              __typename?: 'CoachTouchPointSessionMetadata';
              isCancelled: boolean;
              isRebooked: boolean;
              session?: {
                __typename?: 'CoachSession';
                id: string;
                sessionType?: CoachSessionType | null;
                calendar?: {
                  __typename?: 'Calendar';
                  start: string;
                  end: string;
                } | null;
              } | null;
            } | null;
          };
        }>;
      };
    } | null;
  } | null;
};

export type CoachTouchPointIncompleteCountsQueryVariables = Exact<{
  overdueFilter?: InputMaybe<TouchPointFilter>;
  todayFilter?: InputMaybe<TouchPointFilter>;
  upcomingFilter?: InputMaybe<TouchPointFilter>;
}>;

export type CoachTouchPointIncompleteCountsQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      overdue: { __typename?: 'CoachTouchPointConnection'; count: number };
      today: { __typename?: 'CoachTouchPointConnection'; count: number };
      upcoming: { __typename?: 'CoachTouchPointConnection'; count: number };
    } | null;
  } | null;
};

export type CoachTouchPointConnectionFragment = {
  __typename?: 'CoachTouchPointConnection';
  count: number;
  pageInfo: {
    __typename?: 'PageInfo';
    endCursor: string;
    hasNextPage: boolean;
  };
  edges: Array<{
    __typename?: 'CoachTouchPointEdge';
    cursor: string;
    node: {
      __typename?: 'CoachTouchPoint';
      id: string;
      due: string;
      title: string;
      description: string;
      type: CoachTouchPointType;
      completedAt?: string | null;
      member: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
        createdAt: string;
        botKind?: BotKind | null;
        fitClient?: {
          __typename?: 'Client';
          id: string;
          name?: string | null;
          usesPersonalTraining: boolean;
        } | null;
        fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
        fitCoach?: { __typename?: 'User'; id: string } | null;
        lastActivity: {
          __typename?: 'LastActivity';
          heart?: string | null;
          heartWeb?: string | null;
        };
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      };
      metadata?: {
        __typename?: 'CoachTouchPointSessionMetadata';
        isCancelled: boolean;
        isRebooked: boolean;
        session?: {
          __typename?: 'CoachSession';
          id: string;
          sessionType?: CoachSessionType | null;
          calendar?: {
            __typename?: 'Calendar';
            start: string;
            end: string;
          } | null;
        } | null;
      } | null;
    };
  }>;
};

export type CreateCustomCoachTouchPointMutationVariables = Exact<{
  input: CreateCoachTouchPointInput;
}>;

export type CreateCustomCoachTouchPointMutation = {
  __typename?: 'Mutation';
  createCoachTouchPoint: {
    __typename?: 'CoachTouchPoint';
    id: string;
    due: string;
    title: string;
    description: string;
    type: CoachTouchPointType;
    completedAt?: string | null;
    member: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      createdAt: string;
      botKind?: BotKind | null;
      fitClient?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
        usesPersonalTraining: boolean;
      } | null;
      fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
      fitCoach?: { __typename?: 'User'; id: string } | null;
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    };
    metadata?: {
      __typename?: 'CoachTouchPointSessionMetadata';
      isCancelled: boolean;
      isRebooked: boolean;
      session?: {
        __typename?: 'CoachSession';
        id: string;
        sessionType?: CoachSessionType | null;
        calendar?: {
          __typename?: 'Calendar';
          start: string;
          end: string;
        } | null;
      } | null;
    } | null;
  };
};

export type EditCustomCoachTouchPointMutationVariables = Exact<{
  input: UpdateCoachTouchPointInput;
}>;

export type EditCustomCoachTouchPointMutation = {
  __typename?: 'Mutation';
  updateCoachTouchPoint: {
    __typename?: 'CoachTouchPoint';
    id: string;
    due: string;
    title: string;
    description: string;
    type: CoachTouchPointType;
    completedAt?: string | null;
    member: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      createdAt: string;
      botKind?: BotKind | null;
      fitClient?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
        usesPersonalTraining: boolean;
      } | null;
      fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
      fitCoach?: { __typename?: 'User'; id: string } | null;
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    };
    metadata?: {
      __typename?: 'CoachTouchPointSessionMetadata';
      isCancelled: boolean;
      isRebooked: boolean;
      session?: {
        __typename?: 'CoachSession';
        id: string;
        sessionType?: CoachSessionType | null;
        calendar?: {
          __typename?: 'Calendar';
          start: string;
          end: string;
        } | null;
      } | null;
    } | null;
  };
};

export type CoachTouchPointDetailsFragment = {
  __typename?: 'CoachTouchPoint';
  id: string;
  due: string;
  title: string;
  description: string;
  type: CoachTouchPointType;
  completedAt?: string | null;
  member: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    createdAt: string;
    botKind?: BotKind | null;
    fitClient?: {
      __typename?: 'Client';
      id: string;
      name?: string | null;
      usesPersonalTraining: boolean;
    } | null;
    fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
    fitCoach?: { __typename?: 'User'; id: string } | null;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  };
  metadata?: {
    __typename?: 'CoachTouchPointSessionMetadata';
    isCancelled: boolean;
    isRebooked: boolean;
    session?: {
      __typename?: 'CoachSession';
      id: string;
      sessionType?: CoachSessionType | null;
      calendar?: { __typename?: 'Calendar'; start: string; end: string } | null;
    } | null;
  } | null;
};

export type CoachTouchPointSessionMetadataFragment = {
  __typename?: 'CoachTouchPointSessionMetadata';
  isCancelled: boolean;
  isRebooked: boolean;
  session?: {
    __typename?: 'CoachSession';
    id: string;
    sessionType?: CoachSessionType | null;
    calendar?: { __typename?: 'Calendar'; start: string; end: string } | null;
  } | null;
};

export type ContentLibraryFilerOptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ContentLibraryFilerOptionsQuery = {
  __typename?: 'Query';
  contentGenres: Array<{
    __typename?: 'Genre';
    value: string;
    name: string;
    children: Array<{ __typename?: 'Genre'; value: string; name: string }>;
  }>;
  gameplanComponents: Array<{
    __typename?: 'ContentAttribute';
    value: string;
    name: string;
  }>;
  gameplanSubcomponents: Array<{
    __typename?: 'ContentAttribute';
    value: string;
    name: string;
  }>;
  contentProjects: Array<{
    __typename?: 'ContentAttribute';
    value: string;
    name: string;
  }>;
  contentIntensities: Array<{
    __typename?: 'ContentAttribute';
    name: string;
    value: string;
  }>;
  contentLevels: Array<{ __typename?: 'Level'; name: string; value: string }>;
  performanceQualities: Array<{
    __typename?: 'ContentAttribute';
    name: string;
    value: string;
  }>;
  contentSplits: Array<{
    __typename?: 'Split';
    value: string;
    name: string;
    children: Array<{ __typename?: 'Split'; value: string; name: string }>;
  }>;
};

export type GetContentBasicsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetContentBasicsQuery = {
  __typename?: 'Query';
  content?:
    | {
        __typename: 'Article';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Audio';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Banner';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'ClickThroughMedia';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'ContentSection';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'InAppSurvey';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Link';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'PracticeProgram';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Program';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Video';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | null;
};

export type GetContentDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetContentDetailsQuery = {
  __typename?: 'Query';
  content?:
    | {
        __typename: 'Article';
        trainingCommitment?: string | null;
        id: string;
        html?: string | null;
        brand?: string | null;
        intensity?: string | null;
        mediaType?: string | null;
        mnmrType?: string | null;
        title?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
        contentGenre?: {
          __typename?: 'Genre';
          name: string;
          parent?: { __typename?: 'Genre'; name: string } | null;
        } | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
        heroPhoto?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        gameplanMetadata?: {
          __typename?: 'GameplanMetadata';
          isExperiential?: boolean | null;
          primaryComponent?: {
            __typename?: 'ContentAttribute';
            name: string;
            value: string;
          } | null;
          primarySubcomponents: Array<{
            __typename?: 'ContentAttribute';
            name: string;
          } | null>;
          secondaryComponent?: {
            __typename?: 'ContentAttribute';
            name: string;
          } | null;
          secondarySubcomponents: Array<{
            __typename?: 'ContentAttribute';
            name: string;
          } | null>;
          associatedProject?: {
            __typename?: 'ContentAttribute';
            name: string;
          } | null;
        } | null;
        split?: { __typename?: 'Split'; name: string } | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Audio';
        trainingCommitment?: string | null;
        id: string;
        audio: string;
        html?: string | null;
        transcript?: string | null;
        brand?: string | null;
        intensity?: string | null;
        title?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
        contentGenre?: {
          __typename?: 'Genre';
          name: string;
          parent?: { __typename?: 'Genre'; name: string } | null;
        } | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
        heroPhoto?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        gameplanMetadata?: {
          __typename?: 'GameplanMetadata';
          isExperiential?: boolean | null;
          primaryComponent?: {
            __typename?: 'ContentAttribute';
            name: string;
            value: string;
          } | null;
          primarySubcomponents: Array<{
            __typename?: 'ContentAttribute';
            name: string;
          } | null>;
          secondaryComponent?: {
            __typename?: 'ContentAttribute';
            name: string;
          } | null;
          secondarySubcomponents: Array<{
            __typename?: 'ContentAttribute';
            name: string;
          } | null>;
          associatedProject?: {
            __typename?: 'ContentAttribute';
            name: string;
          } | null;
        } | null;
        split?: { __typename?: 'Split'; name: string } | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Banner';
        trainingCommitment?: string | null;
        id: string;
        title?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
        contentGenre?: {
          __typename?: 'Genre';
          name: string;
          parent?: { __typename?: 'Genre'; name: string } | null;
        } | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
        heroPhoto?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'ClickThroughMedia';
        trainingCommitment?: string | null;
        id: string;
        title?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
        contentGenre?: {
          __typename?: 'Genre';
          name: string;
          parent?: { __typename?: 'Genre'; name: string } | null;
        } | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
        heroPhoto?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'ContentSection';
        trainingCommitment?: string | null;
        id: string;
        title?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
        contentGenre?: {
          __typename?: 'Genre';
          name: string;
          parent?: { __typename?: 'Genre'; name: string } | null;
        } | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
        heroPhoto?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'InAppSurvey';
        trainingCommitment?: string | null;
        id: string;
        title?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
        contentGenre?: {
          __typename?: 'Genre';
          name: string;
          parent?: { __typename?: 'Genre'; name: string } | null;
        } | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
        heroPhoto?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Link';
        trainingCommitment?: string | null;
        id: string;
        title?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
        contentGenre?: {
          __typename?: 'Genre';
          name: string;
          parent?: { __typename?: 'Genre'; name: string } | null;
        } | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
        heroPhoto?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'PracticeProgram';
        trainingCommitment?: string | null;
        id: string;
        title?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
        contentGenre?: {
          __typename?: 'Genre';
          name: string;
          parent?: { __typename?: 'Genre'; name: string } | null;
        } | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
        heroPhoto?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Program';
        trainingCommitment?: string | null;
        id: string;
        title?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
        contentGenre?: {
          __typename?: 'Genre';
          name: string;
          parent?: { __typename?: 'Genre'; name: string } | null;
        } | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
        heroPhoto?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Video';
        trainingCommitment?: string | null;
        id: string;
        html?: string | null;
        video: string;
        brand?: string | null;
        type?: string | null;
        intensity?: string | null;
        mediaType?: string | null;
        mnmrType?: string | null;
        title?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
        contentGenre?: {
          __typename?: 'Genre';
          name: string;
          parent?: { __typename?: 'Genre'; name: string } | null;
        } | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
        heroPhoto?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        gameplanMetadata?: {
          __typename?: 'GameplanMetadata';
          isExperiential?: boolean | null;
          primaryComponent?: {
            __typename?: 'ContentAttribute';
            name: string;
            value: string;
          } | null;
          primarySubcomponents: Array<{
            __typename?: 'ContentAttribute';
            name: string;
          } | null>;
          secondaryComponent?: {
            __typename?: 'ContentAttribute';
            name: string;
          } | null;
          secondarySubcomponents: Array<{
            __typename?: 'ContentAttribute';
            name: string;
          } | null>;
          associatedProject?: {
            __typename?: 'ContentAttribute';
            name: string;
          } | null;
        } | null;
        split?: { __typename?: 'Split'; name: string } | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | null;
};

type ContentBasicsArticleFragment = {
  __typename: 'Article';
  id: string;
  title?: string | null;
  trainingCommitment?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentBasicsAudioFragment = {
  __typename: 'Audio';
  id: string;
  title?: string | null;
  trainingCommitment?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentBasicsBannerFragment = {
  __typename: 'Banner';
  id: string;
  title?: string | null;
  trainingCommitment?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentBasicsClickThroughMediaFragment = {
  __typename: 'ClickThroughMedia';
  id: string;
  title?: string | null;
  trainingCommitment?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentBasicsContentSectionFragment = {
  __typename: 'ContentSection';
  id: string;
  title?: string | null;
  trainingCommitment?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentBasicsInAppSurveyFragment = {
  __typename: 'InAppSurvey';
  id: string;
  title?: string | null;
  trainingCommitment?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentBasicsLinkFragment = {
  __typename: 'Link';
  id: string;
  title?: string | null;
  trainingCommitment?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentBasicsPracticeProgramFragment = {
  __typename: 'PracticeProgram';
  id: string;
  title?: string | null;
  trainingCommitment?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentBasicsProgramFragment = {
  __typename: 'Program';
  id: string;
  title?: string | null;
  trainingCommitment?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentBasicsVideoFragment = {
  __typename: 'Video';
  id: string;
  title?: string | null;
  trainingCommitment?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

export type ContentBasicsFragment =
  | ContentBasicsArticleFragment
  | ContentBasicsAudioFragment
  | ContentBasicsBannerFragment
  | ContentBasicsClickThroughMediaFragment
  | ContentBasicsContentSectionFragment
  | ContentBasicsInAppSurveyFragment
  | ContentBasicsLinkFragment
  | ContentBasicsPracticeProgramFragment
  | ContentBasicsProgramFragment
  | ContentBasicsVideoFragment;

export type FilterContentsQueryVariables = Exact<{
  filter?: InputMaybe<ContentFilter>;
  input?: InputMaybe<ConnectionInput>;
}>;

export type FilterContentsQuery = {
  __typename?: 'Query';
  totalContents: { __typename?: 'ContentConnection'; count: number };
  contents: {
    __typename?: 'ContentConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      __typename?: 'ContentEdge';
      node:
        | {
            __typename: 'Article';
            trainingCommitment?: string | null;
            id: string;
            html?: string | null;
            brand?: string | null;
            intensity?: string | null;
            mediaType?: string | null;
            mnmrType?: string | null;
            title?: string | null;
            author?: string | null;
            subtitle?: string | null;
            shortDescription?: string | null;
            contentLevel?: { __typename?: 'Level'; name: string } | null;
            contentGenre?: {
              __typename?: 'Genre';
              name: string;
              parent?: { __typename?: 'Genre'; name: string } | null;
            } | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
            heroPhoto?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            gameplanMetadata?: {
              __typename?: 'GameplanMetadata';
              isExperiential?: boolean | null;
              primaryComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
                value: string;
              } | null;
              primarySubcomponents: Array<{
                __typename?: 'ContentAttribute';
                name: string;
              } | null>;
              secondaryComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
              } | null;
              secondarySubcomponents: Array<{
                __typename?: 'ContentAttribute';
                name: string;
              } | null>;
              associatedProject?: {
                __typename?: 'ContentAttribute';
                name: string;
              } | null;
            } | null;
            split?: { __typename?: 'Split'; name: string } | null;
            contentTime?: { __typename?: 'ContentTime'; name: string } | null;
          }
        | {
            __typename: 'Audio';
            trainingCommitment?: string | null;
            id: string;
            audio: string;
            html?: string | null;
            transcript?: string | null;
            brand?: string | null;
            intensity?: string | null;
            title?: string | null;
            author?: string | null;
            subtitle?: string | null;
            shortDescription?: string | null;
            contentLevel?: { __typename?: 'Level'; name: string } | null;
            contentGenre?: {
              __typename?: 'Genre';
              name: string;
              parent?: { __typename?: 'Genre'; name: string } | null;
            } | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
            heroPhoto?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            gameplanMetadata?: {
              __typename?: 'GameplanMetadata';
              isExperiential?: boolean | null;
              primaryComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
                value: string;
              } | null;
              primarySubcomponents: Array<{
                __typename?: 'ContentAttribute';
                name: string;
              } | null>;
              secondaryComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
              } | null;
              secondarySubcomponents: Array<{
                __typename?: 'ContentAttribute';
                name: string;
              } | null>;
              associatedProject?: {
                __typename?: 'ContentAttribute';
                name: string;
              } | null;
            } | null;
            split?: { __typename?: 'Split'; name: string } | null;
            contentTime?: { __typename?: 'ContentTime'; name: string } | null;
          }
        | {
            __typename: 'Banner';
            trainingCommitment?: string | null;
            id: string;
            title?: string | null;
            author?: string | null;
            subtitle?: string | null;
            shortDescription?: string | null;
            contentLevel?: { __typename?: 'Level'; name: string } | null;
            contentGenre?: {
              __typename?: 'Genre';
              name: string;
              parent?: { __typename?: 'Genre'; name: string } | null;
            } | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
            heroPhoto?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            contentTime?: { __typename?: 'ContentTime'; name: string } | null;
          }
        | {
            __typename: 'ClickThroughMedia';
            trainingCommitment?: string | null;
            id: string;
            title?: string | null;
            author?: string | null;
            subtitle?: string | null;
            shortDescription?: string | null;
            contentLevel?: { __typename?: 'Level'; name: string } | null;
            contentGenre?: {
              __typename?: 'Genre';
              name: string;
              parent?: { __typename?: 'Genre'; name: string } | null;
            } | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
            heroPhoto?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            contentTime?: { __typename?: 'ContentTime'; name: string } | null;
          }
        | {
            __typename: 'ContentSection';
            trainingCommitment?: string | null;
            id: string;
            title?: string | null;
            author?: string | null;
            subtitle?: string | null;
            shortDescription?: string | null;
            contentLevel?: { __typename?: 'Level'; name: string } | null;
            contentGenre?: {
              __typename?: 'Genre';
              name: string;
              parent?: { __typename?: 'Genre'; name: string } | null;
            } | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
            heroPhoto?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            contentTime?: { __typename?: 'ContentTime'; name: string } | null;
          }
        | {
            __typename: 'InAppSurvey';
            trainingCommitment?: string | null;
            id: string;
            title?: string | null;
            author?: string | null;
            subtitle?: string | null;
            shortDescription?: string | null;
            contentLevel?: { __typename?: 'Level'; name: string } | null;
            contentGenre?: {
              __typename?: 'Genre';
              name: string;
              parent?: { __typename?: 'Genre'; name: string } | null;
            } | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
            heroPhoto?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            contentTime?: { __typename?: 'ContentTime'; name: string } | null;
          }
        | {
            __typename: 'Link';
            trainingCommitment?: string | null;
            id: string;
            title?: string | null;
            author?: string | null;
            subtitle?: string | null;
            shortDescription?: string | null;
            contentLevel?: { __typename?: 'Level'; name: string } | null;
            contentGenre?: {
              __typename?: 'Genre';
              name: string;
              parent?: { __typename?: 'Genre'; name: string } | null;
            } | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
            heroPhoto?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            contentTime?: { __typename?: 'ContentTime'; name: string } | null;
          }
        | {
            __typename: 'PracticeProgram';
            trainingCommitment?: string | null;
            id: string;
            title?: string | null;
            author?: string | null;
            subtitle?: string | null;
            shortDescription?: string | null;
            contentLevel?: { __typename?: 'Level'; name: string } | null;
            contentGenre?: {
              __typename?: 'Genre';
              name: string;
              parent?: { __typename?: 'Genre'; name: string } | null;
            } | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
            heroPhoto?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            contentTime?: { __typename?: 'ContentTime'; name: string } | null;
          }
        | {
            __typename: 'Program';
            trainingCommitment?: string | null;
            id: string;
            title?: string | null;
            author?: string | null;
            subtitle?: string | null;
            shortDescription?: string | null;
            contentLevel?: { __typename?: 'Level'; name: string } | null;
            contentGenre?: {
              __typename?: 'Genre';
              name: string;
              parent?: { __typename?: 'Genre'; name: string } | null;
            } | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
            heroPhoto?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            contentTime?: { __typename?: 'ContentTime'; name: string } | null;
          }
        | {
            __typename: 'Video';
            trainingCommitment?: string | null;
            id: string;
            html?: string | null;
            video: string;
            brand?: string | null;
            type?: string | null;
            intensity?: string | null;
            mediaType?: string | null;
            mnmrType?: string | null;
            title?: string | null;
            author?: string | null;
            subtitle?: string | null;
            shortDescription?: string | null;
            contentLevel?: { __typename?: 'Level'; name: string } | null;
            contentGenre?: {
              __typename?: 'Genre';
              name: string;
              parent?: { __typename?: 'Genre'; name: string } | null;
            } | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
            heroPhoto?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            gameplanMetadata?: {
              __typename?: 'GameplanMetadata';
              isExperiential?: boolean | null;
              primaryComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
                value: string;
              } | null;
              primarySubcomponents: Array<{
                __typename?: 'ContentAttribute';
                name: string;
              } | null>;
              secondaryComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
              } | null;
              secondarySubcomponents: Array<{
                __typename?: 'ContentAttribute';
                name: string;
              } | null>;
              associatedProject?: {
                __typename?: 'ContentAttribute';
                name: string;
              } | null;
            } | null;
            split?: { __typename?: 'Split'; name: string } | null;
            contentTime?: { __typename?: 'ContentTime'; name: string } | null;
          };
    }>;
  };
};

export type ArticleDetailsFragment = {
  __typename?: 'Article';
  id: string;
  html?: string | null;
  brand?: string | null;
  intensity?: string | null;
  mediaType?: string | null;
  mnmrType?: string | null;
  gameplanMetadata?: {
    __typename?: 'GameplanMetadata';
    isExperiential?: boolean | null;
    primaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
      value: string;
    } | null;
    primarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    secondaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
    secondarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    associatedProject?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
  } | null;
  split?: { __typename?: 'Split'; name: string } | null;
};

export type AudioDetailsFragment = {
  __typename?: 'Audio';
  id: string;
  audio: string;
  html?: string | null;
  transcript?: string | null;
  brand?: string | null;
  intensity?: string | null;
  gameplanMetadata?: {
    __typename?: 'GameplanMetadata';
    isExperiential?: boolean | null;
    primaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
      value: string;
    } | null;
    primarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    secondaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
    secondarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    associatedProject?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
  } | null;
  split?: { __typename?: 'Split'; name: string } | null;
};

export type VideoDetailsFragment = {
  __typename?: 'Video';
  id: string;
  html?: string | null;
  video: string;
  brand?: string | null;
  type?: string | null;
  intensity?: string | null;
  mediaType?: string | null;
  mnmrType?: string | null;
  gameplanMetadata?: {
    __typename?: 'GameplanMetadata';
    isExperiential?: boolean | null;
    primaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
      value: string;
    } | null;
    primarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    secondaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
    secondarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    associatedProject?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
  } | null;
  split?: { __typename?: 'Split'; name: string } | null;
};

type ContentDetailsArticleFragment = {
  __typename: 'Article';
  trainingCommitment?: string | null;
  id: string;
  html?: string | null;
  brand?: string | null;
  intensity?: string | null;
  mediaType?: string | null;
  mnmrType?: string | null;
  title?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
  contentGenre?: {
    __typename?: 'Genre';
    name: string;
    parent?: { __typename?: 'Genre'; name: string } | null;
  } | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
  heroPhoto?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  gameplanMetadata?: {
    __typename?: 'GameplanMetadata';
    isExperiential?: boolean | null;
    primaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
      value: string;
    } | null;
    primarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    secondaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
    secondarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    associatedProject?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
  } | null;
  split?: { __typename?: 'Split'; name: string } | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentDetailsAudioFragment = {
  __typename: 'Audio';
  trainingCommitment?: string | null;
  id: string;
  audio: string;
  html?: string | null;
  transcript?: string | null;
  brand?: string | null;
  intensity?: string | null;
  title?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
  contentGenre?: {
    __typename?: 'Genre';
    name: string;
    parent?: { __typename?: 'Genre'; name: string } | null;
  } | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
  heroPhoto?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  gameplanMetadata?: {
    __typename?: 'GameplanMetadata';
    isExperiential?: boolean | null;
    primaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
      value: string;
    } | null;
    primarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    secondaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
    secondarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    associatedProject?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
  } | null;
  split?: { __typename?: 'Split'; name: string } | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentDetailsBannerFragment = {
  __typename: 'Banner';
  trainingCommitment?: string | null;
  id: string;
  title?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
  contentGenre?: {
    __typename?: 'Genre';
    name: string;
    parent?: { __typename?: 'Genre'; name: string } | null;
  } | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
  heroPhoto?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentDetailsClickThroughMediaFragment = {
  __typename: 'ClickThroughMedia';
  trainingCommitment?: string | null;
  id: string;
  title?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
  contentGenre?: {
    __typename?: 'Genre';
    name: string;
    parent?: { __typename?: 'Genre'; name: string } | null;
  } | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
  heroPhoto?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentDetailsContentSectionFragment = {
  __typename: 'ContentSection';
  trainingCommitment?: string | null;
  id: string;
  title?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
  contentGenre?: {
    __typename?: 'Genre';
    name: string;
    parent?: { __typename?: 'Genre'; name: string } | null;
  } | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
  heroPhoto?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentDetailsInAppSurveyFragment = {
  __typename: 'InAppSurvey';
  trainingCommitment?: string | null;
  id: string;
  title?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
  contentGenre?: {
    __typename?: 'Genre';
    name: string;
    parent?: { __typename?: 'Genre'; name: string } | null;
  } | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
  heroPhoto?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentDetailsLinkFragment = {
  __typename: 'Link';
  trainingCommitment?: string | null;
  id: string;
  title?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
  contentGenre?: {
    __typename?: 'Genre';
    name: string;
    parent?: { __typename?: 'Genre'; name: string } | null;
  } | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
  heroPhoto?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentDetailsPracticeProgramFragment = {
  __typename: 'PracticeProgram';
  trainingCommitment?: string | null;
  id: string;
  title?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
  contentGenre?: {
    __typename?: 'Genre';
    name: string;
    parent?: { __typename?: 'Genre'; name: string } | null;
  } | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
  heroPhoto?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentDetailsProgramFragment = {
  __typename: 'Program';
  trainingCommitment?: string | null;
  id: string;
  title?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
  contentGenre?: {
    __typename?: 'Genre';
    name: string;
    parent?: { __typename?: 'Genre'; name: string } | null;
  } | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
  heroPhoto?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

type ContentDetailsVideoFragment = {
  __typename: 'Video';
  trainingCommitment?: string | null;
  id: string;
  html?: string | null;
  video: string;
  brand?: string | null;
  type?: string | null;
  intensity?: string | null;
  mediaType?: string | null;
  mnmrType?: string | null;
  title?: string | null;
  author?: string | null;
  subtitle?: string | null;
  shortDescription?: string | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
  contentGenre?: {
    __typename?: 'Genre';
    name: string;
    parent?: { __typename?: 'Genre'; name: string } | null;
  } | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
  heroPhoto?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  gameplanMetadata?: {
    __typename?: 'GameplanMetadata';
    isExperiential?: boolean | null;
    primaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
      value: string;
    } | null;
    primarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    secondaryComponent?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
    secondarySubcomponents: Array<{
      __typename?: 'ContentAttribute';
      name: string;
    } | null>;
    associatedProject?: {
      __typename?: 'ContentAttribute';
      name: string;
    } | null;
  } | null;
  split?: { __typename?: 'Split'; name: string } | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

export type ContentDetailsFragment =
  | ContentDetailsArticleFragment
  | ContentDetailsAudioFragment
  | ContentDetailsBannerFragment
  | ContentDetailsClickThroughMediaFragment
  | ContentDetailsContentSectionFragment
  | ContentDetailsInAppSurveyFragment
  | ContentDetailsLinkFragment
  | ContentDetailsPracticeProgramFragment
  | ContentDetailsProgramFragment
  | ContentDetailsVideoFragment;

export type UserPlanBasicsFragment = {
  __typename?: 'Plan';
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  targetedPerformanceQualities: Array<PerformanceQuality>;
};

export type UserPlanDetailsFragment = {
  __typename?: 'Plan';
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  targetedPerformanceQualities: Array<PerformanceQuality>;
  planItems: {
    __typename?: 'PlanItemConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      __typename?: 'PlanItemEdge';
      node:
        | {
            __typename: 'CustomTaskPlanItem';
            title: string;
            description: string;
            url?: string | null;
            id: string;
            type: PlanItemType;
            date: string;
            order: number;
            completedAt?: string | null;
            contentComponent?: {
              __typename?: 'ContentAttribute';
              name: string;
              value: string;
            } | null;
          }
        | {
            __typename: 'ExosContentPlanItem';
            id: string;
            type: PlanItemType;
            date: string;
            order: number;
            completedAt?: string | null;
            contentComponent?: {
              __typename?: 'ContentAttribute';
              name: string;
              value: string;
            } | null;
            content:
              | {
                  __typename: 'Article';
                  id: string;
                  title?: string | null;
                  trainingCommitment?: string | null;
                  author?: string | null;
                  subtitle?: string | null;
                  shortDescription?: string | null;
                  contentTime?: {
                    __typename?: 'ContentTime';
                    name: string;
                  } | null;
                }
              | {
                  __typename: 'Audio';
                  id: string;
                  title?: string | null;
                  trainingCommitment?: string | null;
                  author?: string | null;
                  subtitle?: string | null;
                  shortDescription?: string | null;
                  contentTime?: {
                    __typename?: 'ContentTime';
                    name: string;
                  } | null;
                }
              | {
                  __typename: 'Banner';
                  id: string;
                  title?: string | null;
                  trainingCommitment?: string | null;
                  author?: string | null;
                  subtitle?: string | null;
                  shortDescription?: string | null;
                  contentTime?: {
                    __typename?: 'ContentTime';
                    name: string;
                  } | null;
                }
              | {
                  __typename: 'ClickThroughMedia';
                  id: string;
                  title?: string | null;
                  trainingCommitment?: string | null;
                  author?: string | null;
                  subtitle?: string | null;
                  shortDescription?: string | null;
                  contentTime?: {
                    __typename?: 'ContentTime';
                    name: string;
                  } | null;
                }
              | {
                  __typename: 'ContentSection';
                  id: string;
                  title?: string | null;
                  trainingCommitment?: string | null;
                  author?: string | null;
                  subtitle?: string | null;
                  shortDescription?: string | null;
                  contentTime?: {
                    __typename?: 'ContentTime';
                    name: string;
                  } | null;
                }
              | {
                  __typename: 'InAppSurvey';
                  id: string;
                  title?: string | null;
                  trainingCommitment?: string | null;
                  author?: string | null;
                  subtitle?: string | null;
                  shortDescription?: string | null;
                  contentTime?: {
                    __typename?: 'ContentTime';
                    name: string;
                  } | null;
                }
              | {
                  __typename: 'Link';
                  id: string;
                  title?: string | null;
                  trainingCommitment?: string | null;
                  author?: string | null;
                  subtitle?: string | null;
                  shortDescription?: string | null;
                  contentTime?: {
                    __typename?: 'ContentTime';
                    name: string;
                  } | null;
                }
              | {
                  __typename: 'PracticeProgram';
                  id: string;
                  title?: string | null;
                  trainingCommitment?: string | null;
                  author?: string | null;
                  subtitle?: string | null;
                  shortDescription?: string | null;
                  contentTime?: {
                    __typename?: 'ContentTime';
                    name: string;
                  } | null;
                }
              | {
                  __typename: 'Program';
                  id: string;
                  title?: string | null;
                  trainingCommitment?: string | null;
                  author?: string | null;
                  subtitle?: string | null;
                  shortDescription?: string | null;
                  contentTime?: {
                    __typename?: 'ContentTime';
                    name: string;
                  } | null;
                }
              | {
                  __typename: 'Video';
                  id: string;
                  title?: string | null;
                  trainingCommitment?: string | null;
                  author?: string | null;
                  subtitle?: string | null;
                  shortDescription?: string | null;
                  contentTime?: {
                    __typename?: 'ContentTime';
                    name: string;
                  } | null;
                };
          };
    }>;
  };
};

type UserPlanItemDetailsCustomTaskPlanItemFragment = {
  __typename: 'CustomTaskPlanItem';
  title: string;
  description: string;
  url?: string | null;
  id: string;
  type: PlanItemType;
  date: string;
  order: number;
  completedAt?: string | null;
  contentComponent?: {
    __typename?: 'ContentAttribute';
    name: string;
    value: string;
  } | null;
};

type UserPlanItemDetailsExosContentPlanItemFragment = {
  __typename: 'ExosContentPlanItem';
  id: string;
  type: PlanItemType;
  date: string;
  order: number;
  completedAt?: string | null;
  contentComponent?: {
    __typename?: 'ContentAttribute';
    name: string;
    value: string;
  } | null;
  content:
    | {
        __typename: 'Article';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Audio';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Banner';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'ClickThroughMedia';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'ContentSection';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'InAppSurvey';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Link';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'PracticeProgram';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Program';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Video';
        id: string;
        title?: string | null;
        trainingCommitment?: string | null;
        author?: string | null;
        subtitle?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      };
};

export type UserPlanItemDetailsFragment =
  | UserPlanItemDetailsCustomTaskPlanItemFragment
  | UserPlanItemDetailsExosContentPlanItemFragment;

export type CustomPlanActiveDaysBasicsFragment = {
  __typename?: 'ActiveCustomPlanDay';
  date: string;
  itemCount: number;
  itemCompletedCount: number;
};

export type MemberCustomPlanActiveDaysQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  filter: CustomPlanActiveDaysFilter;
}>;

export type MemberCustomPlanActiveDaysQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    customPlansActiveDays: Array<{
      __typename?: 'ActiveCustomPlanDay';
      date: string;
      itemCount: number;
      itemCompletedCount: number;
    }>;
  } | null;
};

export type CustomPlanQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  planItemsInput?: InputMaybe<ConnectionInput>;
}>;

export type CustomPlanQuery = {
  __typename?: 'Query';
  customPlan: {
    __typename?: 'Plan';
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    targetedPerformanceQualities: Array<PerformanceQuality>;
    planItems: {
      __typename?: 'PlanItemConnection';
      count: number;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'PlanItemEdge';
        node:
          | {
              __typename: 'CustomTaskPlanItem';
              title: string;
              description: string;
              url?: string | null;
              id: string;
              type: PlanItemType;
              date: string;
              order: number;
              completedAt?: string | null;
              contentComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
                value: string;
              } | null;
            }
          | {
              __typename: 'ExosContentPlanItem';
              id: string;
              type: PlanItemType;
              date: string;
              order: number;
              completedAt?: string | null;
              contentComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
                value: string;
              } | null;
              content:
                | {
                    __typename: 'Article';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Audio';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Banner';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'ClickThroughMedia';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'ContentSection';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'InAppSurvey';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Link';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'PracticeProgram';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Program';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  };
            };
      }>;
    };
  };
};

export type MemberPlansQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<CustomPlansFilter>;
}>;

export type MemberPlansQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    fitCoach?: { __typename?: 'User'; id: string } | null;
    currentCustomPlan?: {
      __typename?: 'Plan';
      id: string;
      name: string;
      startDate: string;
      endDate: string;
      targetedPerformanceQualities: Array<PerformanceQuality>;
    } | null;
    customPlans: {
      __typename?: 'UserPlansConnection';
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'UserPlansEdge';
        node: {
          __typename?: 'Plan';
          id: string;
          name: string;
          startDate: string;
          endDate: string;
          targetedPerformanceQualities: Array<PerformanceQuality>;
        };
      }>;
    };
  } | null;
};

export type CreatePlanMutationVariables = Exact<{
  input: CreatePlanInput;
}>;

export type CreatePlanMutation = {
  __typename?: 'Mutation';
  createPlan: {
    __typename?: 'CreatePlanPayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      name: string;
      startDate: string;
      planItems: {
        __typename?: 'PlanItemConnection';
        count: number;
        nodes: Array<
          | {
              __typename: 'CustomTaskPlanItem';
              title: string;
              description: string;
              url?: string | null;
              id: string;
              type: PlanItemType;
              date: string;
              order: number;
              completedAt?: string | null;
              contentComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
                value: string;
              } | null;
            }
          | {
              __typename: 'ExosContentPlanItem';
              id: string;
              type: PlanItemType;
              date: string;
              order: number;
              completedAt?: string | null;
              contentComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
                value: string;
              } | null;
              content:
                | {
                    __typename: 'Article';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Audio';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Banner';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'ClickThroughMedia';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'ContentSection';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'InAppSurvey';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Link';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'PracticeProgram';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Program';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  };
            }
        >;
      };
    };
  };
};

export type UpdatePlanMutationVariables = Exact<{
  input: UpdatePlanInput;
}>;

export type UpdatePlanMutation = {
  __typename?: 'Mutation';
  updatePlan: {
    __typename?: 'UpdatePlanPayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      name: string;
      startDate: string;
      planItems: {
        __typename?: 'PlanItemConnection';
        count: number;
        nodes: Array<
          | {
              __typename: 'CustomTaskPlanItem';
              title: string;
              description: string;
              url?: string | null;
              id: string;
              type: PlanItemType;
              date: string;
              order: number;
              completedAt?: string | null;
              contentComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
                value: string;
              } | null;
            }
          | {
              __typename: 'ExosContentPlanItem';
              id: string;
              type: PlanItemType;
              date: string;
              order: number;
              completedAt?: string | null;
              contentComponent?: {
                __typename?: 'ContentAttribute';
                name: string;
                value: string;
              } | null;
              content:
                | {
                    __typename: 'Article';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Audio';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Banner';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'ClickThroughMedia';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'ContentSection';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'InAppSurvey';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Link';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'PracticeProgram';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Program';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    title?: string | null;
                    trainingCommitment?: string | null;
                    author?: string | null;
                    subtitle?: string | null;
                    shortDescription?: string | null;
                    contentTime?: {
                      __typename?: 'ContentTime';
                      name: string;
                    } | null;
                  };
            }
        >;
      };
    };
  };
};

export type DeletePlanMutationVariables = Exact<{
  planId: Scalars['ID']['input'];
}>;

export type DeletePlanMutation = {
  __typename?: 'Mutation';
  deletePlan: {
    __typename?: 'DeletePlanPayload';
    plan: { __typename?: 'Plan'; id: string };
  };
};

export type GetFeedbackStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFeedbackStatusQuery = {
  __typename?: 'Query';
  feedbackStatus: {
    __typename?: 'ModalStatus';
    id: string;
    givenFeedback: boolean;
  };
};

export type SetFeedbackStatusMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  givenFeedback: Scalars['Boolean']['input'];
}>;

export type SetFeedbackStatusMutation = {
  __typename?: 'Mutation';
  setModalStatus: {
    __typename?: 'ModalStatus';
    id: string;
    givenFeedback: boolean;
  };
};

export type CreateUserGoalMutationVariables = Exact<{
  input: CreateUserGoalInput;
}>;

export type CreateUserGoalMutation = {
  __typename?: 'Mutation';
  createUserGoal: {
    __typename?: 'CreateUserGoalPayload';
    user: {
      __typename?: 'User';
      id: string;
      goal?: {
        __typename?: 'UserGoal';
        id: string;
        type: UserGoalType;
        description: string;
        coachNote?: string | null;
      } | null;
    };
  };
};

export type UpdateUserGoalMutationVariables = Exact<{
  input: UpdateUserGoalInput;
}>;

export type UpdateUserGoalMutation = {
  __typename?: 'Mutation';
  updateUserGoal: {
    __typename?: 'UpdateUserGoalPayload';
    user: {
      __typename?: 'User';
      id: string;
      goal?: {
        __typename?: 'UserGoal';
        id: string;
        type: UserGoalType;
        description: string;
        coachNote?: string | null;
      } | null;
    };
  };
};

export type MemberQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  includeChat?: Scalars['Boolean']['input'];
  chatUserIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type MemberQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    birthday?: string | null;
    pronouns?: string | null;
    identity?: string | null;
    weight?: number | null;
    height?: number | null;
    workingStyle?: WorkingStyle | null;
    isCoach: boolean;
    clientRelationship?: UserClientRelationship | null;
    hasHighPerformanceCoaching: boolean;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    createdAt: string;
    botKind?: BotKind | null;
    fitCoachChat?: {
      __typename?: 'ChatRoom';
      id: string;
      name?: string | null;
      kind: ChatRoomKind;
      app?: App | null;
      lastMessageRead?: string | null;
      lastMessageReceivedAt?: string | null;
    } | null;
    officeLocation?: {
      __typename?: 'CoachLocation';
      id: string;
      name?: string | null;
    } | null;
    botChat?: { __typename?: 'ChatRoom'; id: string } | null;
    fitCoach?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    } | null;
    practicePrograms: {
      __typename?: 'UserPracticeProgramsConnection';
      edges: Array<{
        __typename?: 'UserPracticeProgramEdge';
        node: {
          __typename?: 'PracticeProgram';
          id: string;
          title?: string | null;
          progress: number;
          skills: Array<string>;
          shortDescription?: string | null;
          createdAt?: string | null;
          completedAt?: string | null;
          contentTime?: { __typename?: 'ContentTime'; name: string } | null;
        };
      }>;
    };
    notes: {
      __typename?: 'UserNotesConnection';
      nodes: Array<{
        __typename?: 'Note';
        id: string;
        title?: string | null;
        content: string;
        createdAt: string;
        updatedAt: string;
        lastUpdatedBy?: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
        } | null;
        coach?: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
        } | null;
      }>;
      edges: Array<{
        __typename?: 'UserNotesEdge';
        node: {
          __typename?: 'Note';
          id: string;
          title?: string | null;
          content: string;
          createdAt: string;
          updatedAt: string;
          lastUpdatedBy?: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
          } | null;
          coach?: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
          } | null;
        };
      }>;
    };
    challenges: {
      __typename?: 'UserChallengesConnection';
      edges: Array<{
        __typename?: 'UserChallengesEdge';
        score?: number | null;
        node: {
          __typename?: 'Challenge';
          id: string;
          title: string;
          goal: number;
          scoreUnit: ScoreUnit;
          enrolled: boolean;
          endDateTime: string;
          startDateTime: string;
          badge?: {
            __typename?: 'Photo';
            id: string;
            preview?: string | null;
            basePath: string;
          } | null;
        };
      }>;
    };
    motivations: {
      __typename?: 'UserSurveysConnection';
      edges: Array<{
        __typename?: 'UserSurveysEdge';
        node: {
          __typename?: 'Survey';
          id: string;
          surveyName?: string | null;
          surveyIdentifier: string;
          createdAt: string;
          responses: Array<{
            __typename?: 'SurveyResponse';
            id: string;
            question: string;
            questionIdentifier: string;
            subcategory?: string | null;
            numericValue?: number | null;
            response:
              | {
                  __typename?: 'EnumAnswer';
                  choices: Array<string>;
                  enumValue: Array<number>;
                }
              | { __typename?: 'NumberAnswer'; numberValue: number }
              | { __typename?: 'PhotoAnswer' }
              | { __typename?: 'StringAnswer'; stringValue: string };
          }>;
        };
      }>;
    };
    assessment: {
      __typename?: 'UserSurveysConnection';
      edges: Array<{
        __typename?: 'UserSurveysEdge';
        node: {
          __typename?: 'Survey';
          id: string;
          surveyName?: string | null;
          surveyIdentifier: string;
          createdAt: string;
          responses: Array<{
            __typename?: 'SurveyResponse';
            id: string;
            question: string;
            questionIdentifier: string;
            subcategory?: string | null;
            numericValue?: number | null;
            response:
              | {
                  __typename?: 'EnumAnswer';
                  choices: Array<string>;
                  enumValue: Array<number>;
                }
              | { __typename?: 'NumberAnswer'; numberValue: number }
              | { __typename?: 'PhotoAnswer' }
              | { __typename?: 'StringAnswer'; stringValue: string };
          }>;
        };
      }>;
    };
    userActivityTrend: Array<{
      __typename?: 'UserActivitySummary';
      date: string;
      program: number;
      classes: number;
      other: number;
      practice: number;
    }>;
    fitClient?: {
      __typename?: 'Client';
      id: string;
      name?: string | null;
      usesPersonalTraining: boolean;
      dietitians: { __typename?: 'ClientUsersConnection'; count: number };
    } | null;
    currentPractice?: {
      __typename?: 'AssignedPractice';
      createdAt: string;
      completedAt?: string | null;
      practiceProgram?: {
        __typename?: 'PracticeProgram';
        id: string;
        title?: string | null;
        progress: number;
        skills: Array<string>;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      } | null;
    } | null;
    currentProgram?: {
      __typename?: 'AssignedProgram';
      completedAt?: string | null;
      createdAt: string;
      program?: {
        __typename?: 'Program';
        id: string;
        shortDescription?: string | null;
        title?: string | null;
        progress: number;
        skills: Array<string>;
        trainingCommitment?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
        sections: {
          __typename?: 'ContentCollectionSectionsConnection';
          count: number;
        };
        currentSection?: {
          __typename?: 'ContentSection';
          id: string;
          title?: string | null;
          contents: {
            __typename?: 'ContentConnection';
            completedCount: number;
            count: number;
            edges: Array<{
              __typename?: 'ContentEdge';
              completed: boolean;
              progress?: number | null;
              node:
                | { __typename?: 'Article'; id: string }
                | { __typename?: 'Audio'; id: string }
                | { __typename?: 'Banner'; id: string }
                | { __typename?: 'ClickThroughMedia'; id: string }
                | { __typename?: 'ContentSection'; id: string }
                | { __typename?: 'InAppSurvey'; id: string }
                | { __typename?: 'Link'; id: string }
                | { __typename?: 'PracticeProgram'; id: string }
                | { __typename?: 'Program'; id: string }
                | { __typename?: 'Video'; id: string };
            }>;
          };
        } | null;
      } | null;
    } | null;
    assignedPrograms: {
      __typename?: 'UserAssignedProgramsConnection';
      edges: Array<{
        __typename?: 'UserAssignedProgramsEdge';
        node: {
          __typename?: 'AssignedProgram';
          program?: {
            __typename?: 'Program';
            id: string;
            progress: number;
          } | null;
        };
      }>;
    };
    goal?: {
      __typename?: 'UserGoal';
      id: string;
      type: UserGoalType;
      description: string;
      coachNote?: string | null;
    } | null;
    strengths: Array<{
      __typename?: 'OnboardingMotivation';
      id: string;
      label: string;
    }>;
    opportunities: Array<{
      __typename?: 'OnboardingMotivation';
      id: string;
      label: string;
    }>;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
  chatRoom?: {
    __typename?: 'ChatRoom';
    id: string;
    name?: string | null;
    kind: ChatRoomKind;
    app?: App | null;
    lastMessageRead?: string | null;
    lastMessageReceivedAt?: string | null;
  } | null;
};

export type EmergencyDetailsQueryVariables = Exact<{
  memberId: Scalars['ID']['input'];
}>;

export type EmergencyDetailsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    emergencyContactDetails?: {
      __typename?: 'EmergencyContactDetails';
      updatedAt?: string | null;
      emergencyContact?: {
        __typename?: 'EmergencyContact';
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
      } | null;
      address?: {
        __typename?: 'Address';
        addressLine1?: string | null;
        addressLine2?: string | null;
        addressLine3?: string | null;
        addressLine4?: string | null;
        localityOrCity?: string | null;
        stateOrDistrictOrProvince?: string | null;
        postalOrZipCode?: string | null;
        countryCode?: string | null;
      } | null;
    } | null;
  } | null;
};

export type MemberClientQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MemberClientQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    fitClient?: {
      __typename?: 'Client';
      id: string;
      name?: string | null;
      usesPersonalTraining: boolean;
    } | null;
  } | null;
};

export type MemberDetailsFragment = {
  __typename?: 'User';
  birthday?: string | null;
  pronouns?: string | null;
  identity?: string | null;
  weight?: number | null;
  height?: number | null;
  workingStyle?: WorkingStyle | null;
  isCoach: boolean;
  clientRelationship?: UserClientRelationship | null;
  hasHighPerformanceCoaching: boolean;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  createdAt: string;
  botKind?: BotKind | null;
  fitCoachChat?: {
    __typename?: 'ChatRoom';
    id: string;
    name?: string | null;
    kind: ChatRoomKind;
    app?: App | null;
    lastMessageRead?: string | null;
    lastMessageReceivedAt?: string | null;
  } | null;
  officeLocation?: {
    __typename?: 'CoachLocation';
    id: string;
    name?: string | null;
  } | null;
  botChat?: { __typename?: 'ChatRoom'; id: string } | null;
  fitCoach?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
  practicePrograms: {
    __typename?: 'UserPracticeProgramsConnection';
    edges: Array<{
      __typename?: 'UserPracticeProgramEdge';
      node: {
        __typename?: 'PracticeProgram';
        id: string;
        title?: string | null;
        progress: number;
        skills: Array<string>;
        shortDescription?: string | null;
        createdAt?: string | null;
        completedAt?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      };
    }>;
  };
  notes: {
    __typename?: 'UserNotesConnection';
    nodes: Array<{
      __typename?: 'Note';
      id: string;
      title?: string | null;
      content: string;
      createdAt: string;
      updatedAt: string;
      lastUpdatedBy?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      } | null;
      coach?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      } | null;
    }>;
    edges: Array<{
      __typename?: 'UserNotesEdge';
      node: {
        __typename?: 'Note';
        id: string;
        title?: string | null;
        content: string;
        createdAt: string;
        updatedAt: string;
        lastUpdatedBy?: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
        } | null;
        coach?: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
        } | null;
      };
    }>;
  };
  challenges: {
    __typename?: 'UserChallengesConnection';
    edges: Array<{
      __typename?: 'UserChallengesEdge';
      score?: number | null;
      node: {
        __typename?: 'Challenge';
        id: string;
        title: string;
        goal: number;
        scoreUnit: ScoreUnit;
        enrolled: boolean;
        endDateTime: string;
        startDateTime: string;
        badge?: {
          __typename?: 'Photo';
          id: string;
          preview?: string | null;
          basePath: string;
        } | null;
      };
    }>;
  };
  motivations: {
    __typename?: 'UserSurveysConnection';
    edges: Array<{
      __typename?: 'UserSurveysEdge';
      node: {
        __typename?: 'Survey';
        id: string;
        surveyName?: string | null;
        surveyIdentifier: string;
        createdAt: string;
        responses: Array<{
          __typename?: 'SurveyResponse';
          id: string;
          question: string;
          questionIdentifier: string;
          subcategory?: string | null;
          numericValue?: number | null;
          response:
            | {
                __typename?: 'EnumAnswer';
                choices: Array<string>;
                enumValue: Array<number>;
              }
            | { __typename?: 'NumberAnswer'; numberValue: number }
            | { __typename?: 'PhotoAnswer' }
            | { __typename?: 'StringAnswer'; stringValue: string };
        }>;
      };
    }>;
  };
  assessment: {
    __typename?: 'UserSurveysConnection';
    edges: Array<{
      __typename?: 'UserSurveysEdge';
      node: {
        __typename?: 'Survey';
        id: string;
        surveyName?: string | null;
        surveyIdentifier: string;
        createdAt: string;
        responses: Array<{
          __typename?: 'SurveyResponse';
          id: string;
          question: string;
          questionIdentifier: string;
          subcategory?: string | null;
          numericValue?: number | null;
          response:
            | {
                __typename?: 'EnumAnswer';
                choices: Array<string>;
                enumValue: Array<number>;
              }
            | { __typename?: 'NumberAnswer'; numberValue: number }
            | { __typename?: 'PhotoAnswer' }
            | { __typename?: 'StringAnswer'; stringValue: string };
        }>;
      };
    }>;
  };
  userActivityTrend: Array<{
    __typename?: 'UserActivitySummary';
    date: string;
    program: number;
    classes: number;
    other: number;
    practice: number;
  }>;
  fitClient?: {
    __typename?: 'Client';
    id: string;
    name?: string | null;
    usesPersonalTraining: boolean;
    dietitians: { __typename?: 'ClientUsersConnection'; count: number };
  } | null;
  currentPractice?: {
    __typename?: 'AssignedPractice';
    createdAt: string;
    completedAt?: string | null;
    practiceProgram?: {
      __typename?: 'PracticeProgram';
      id: string;
      title?: string | null;
      progress: number;
      skills: Array<string>;
      shortDescription?: string | null;
      contentTime?: { __typename?: 'ContentTime'; name: string } | null;
    } | null;
  } | null;
  currentProgram?: {
    __typename?: 'AssignedProgram';
    completedAt?: string | null;
    createdAt: string;
    program?: {
      __typename?: 'Program';
      id: string;
      shortDescription?: string | null;
      title?: string | null;
      progress: number;
      skills: Array<string>;
      trainingCommitment?: string | null;
      contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      contentLevel?: { __typename?: 'Level'; name: string } | null;
      sections: {
        __typename?: 'ContentCollectionSectionsConnection';
        count: number;
      };
      currentSection?: {
        __typename?: 'ContentSection';
        id: string;
        title?: string | null;
        contents: {
          __typename?: 'ContentConnection';
          completedCount: number;
          count: number;
          edges: Array<{
            __typename?: 'ContentEdge';
            completed: boolean;
            progress?: number | null;
            node:
              | { __typename?: 'Article'; id: string }
              | { __typename?: 'Audio'; id: string }
              | { __typename?: 'Banner'; id: string }
              | { __typename?: 'ClickThroughMedia'; id: string }
              | { __typename?: 'ContentSection'; id: string }
              | { __typename?: 'InAppSurvey'; id: string }
              | { __typename?: 'Link'; id: string }
              | { __typename?: 'PracticeProgram'; id: string }
              | { __typename?: 'Program'; id: string }
              | { __typename?: 'Video'; id: string };
          }>;
        };
      } | null;
    } | null;
  } | null;
  assignedPrograms: {
    __typename?: 'UserAssignedProgramsConnection';
    edges: Array<{
      __typename?: 'UserAssignedProgramsEdge';
      node: {
        __typename?: 'AssignedProgram';
        program?: {
          __typename?: 'Program';
          id: string;
          progress: number;
        } | null;
      };
    }>;
  };
  goal?: {
    __typename?: 'UserGoal';
    id: string;
    type: UserGoalType;
    description: string;
    coachNote?: string | null;
  } | null;
  strengths: Array<{
    __typename?: 'OnboardingMotivation';
    id: string;
    label: string;
  }>;
  opportunities: Array<{
    __typename?: 'OnboardingMotivation';
    id: string;
    label: string;
  }>;
  lastActivity: {
    __typename?: 'LastActivity';
    heart?: string | null;
    heartWeb?: string | null;
  };
  avatar?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
};

export type UserEmergencyDetailsFragment = {
  __typename?: 'User';
  emergencyContactDetails?: {
    __typename?: 'EmergencyContactDetails';
    updatedAt?: string | null;
    emergencyContact?: {
      __typename?: 'EmergencyContact';
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
    } | null;
    address?: {
      __typename?: 'Address';
      addressLine1?: string | null;
      addressLine2?: string | null;
      addressLine3?: string | null;
      addressLine4?: string | null;
      localityOrCity?: string | null;
      stateOrDistrictOrProvince?: string | null;
      postalOrZipCode?: string | null;
      countryCode?: string | null;
    } | null;
  } | null;
};

export type MemberUserActivityFragment = {
  __typename?: 'User';
  userActivity: {
    __typename?: 'UserActivityConnection';
    nodes: Array<{
      __typename?: 'UserActivity';
      id: string;
      genre?: string | null;
      type: UserActivityType;
      attributionType: UserActivityAttributionType;
      attributionTitle?: string | null;
      title: string;
      startTime: string;
      endTime: string;
      duration?: number | null;
      distance?: {
        __typename?: 'Distance';
        value?: number | null;
        unit?: DistanceUnit | null;
      } | null;
      source?:
        | {
            __typename: 'Article';
            id: string;
            shortDescription?: string | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
          }
        | { __typename: 'Audio'; id: string; shortDescription?: string | null }
        | {
            __typename: 'MboClass';
            id: string;
            description: string;
            staffFirstName?: string | null;
            staffLastName?: string | null;
            location?: {
              __typename?: 'CoachLocation';
              name?: string | null;
            } | null;
          }
        | { __typename: 'PhysicalActivity'; id: string }
        | { __typename: 'Survey'; id: string; surveyIdentifier: string }
        | {
            __typename: 'Video';
            id: string;
            shortDescription?: string | null;
            author?: string | null;
            contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
          }
        | { __typename: 'WearableActivity'; id: string }
        | null;
      activity?:
        | { __typename?: 'ArticleActivity' }
        | { __typename?: 'AudioActivity' }
        | { __typename?: 'MboClassActivity' }
        | { __typename?: 'PhysicalActivity' }
        | {
            __typename?: 'SleepActivity';
            id: string;
            sleepEfficiency?: number | null;
            sleepOverview: {
              __typename?: 'SleepOverview';
              inbed: number;
              awake: number;
              asleep: number;
            };
            heartRate?: { __typename?: 'HeartRate'; min: number } | null;
          }
        | { __typename?: 'VideoActivity' }
        | { __typename?: 'WearableActivity' }
        | null;
      attribution?:
        | {
            __typename?: 'AssignedPractice';
            assigningCoach?: {
              __typename?: 'User';
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          }
        | {
            __typename?: 'AssignedProgram';
            assigningCoach?: {
              __typename?: 'User';
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          }
        | null;
    }>;
  };
};

export type MemberNoteFragment = {
  __typename?: 'User';
  notes: {
    __typename?: 'UserNotesConnection';
    nodes: Array<{
      __typename?: 'Note';
      id: string;
      title?: string | null;
      content: string;
      createdAt: string;
      updatedAt: string;
      lastUpdatedBy?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      } | null;
      coach?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      } | null;
    }>;
    edges: Array<{
      __typename?: 'UserNotesEdge';
      node: {
        __typename?: 'Note';
        id: string;
        title?: string | null;
        content: string;
        createdAt: string;
        updatedAt: string;
        lastUpdatedBy?: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
        } | null;
        coach?: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
        } | null;
      };
    }>;
  };
};

export type MemberGoalFragment = {
  __typename?: 'User';
  goal?: {
    __typename?: 'UserGoal';
    id: string;
    type: UserGoalType;
    description: string;
    coachNote?: string | null;
  } | null;
};

export type MemberOnboardingMotivationsFragment = {
  __typename?: 'User';
  strengths: Array<{
    __typename?: 'OnboardingMotivation';
    id: string;
    label: string;
  }>;
  opportunities: Array<{
    __typename?: 'OnboardingMotivation';
    id: string;
    label: string;
  }>;
};

export type CreateOrUpdateMemberNoteMutationVariables = Exact<{
  input: UpdateNoteInput;
}>;

export type CreateOrUpdateMemberNoteMutation = {
  __typename?: 'Mutation';
  updateNote: {
    __typename?: 'MutateNotePayload';
    note?: {
      __typename?: 'Note';
      id: string;
      title?: string | null;
      content: string;
      createdAt: string;
      updatedAt: string;
      lastUpdatedBy?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      } | null;
      coach?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      } | null;
    } | null;
  };
};

export type FitClientBasicsFragment = {
  __typename?: 'Client';
  id: string;
  name?: string | null;
  usesPersonalTraining: boolean;
};

export type FitClientFragment = {
  __typename?: 'User';
  fitClient?: {
    __typename?: 'Client';
    id: string;
    name?: string | null;
    usesPersonalTraining: boolean;
    dietitians: { __typename?: 'ClientUsersConnection'; count: number };
  } | null;
};

export type FitCoachFragment = {
  __typename?: 'User';
  fitCoach?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
};

export type MembersLocationFragment = {
  __typename?: 'User';
  workingStyle?: WorkingStyle | null;
  officeLocation?: {
    __typename?: 'CoachLocation';
    name?: string | null;
  } | null;
};

export type MembersListQueryVariables = Exact<{
  membersFilter?: InputMaybe<CoachUsersFilter>;
  orderBy: CoachUsersOrderBy;
  input?: InputMaybe<ConnectionInput>;
}>;

export type MembersListQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    allCoachUsers?: {
      __typename?: 'CoachData';
      id: string;
      coachUsers: { __typename?: 'CoachUsersConnection'; count: number };
    } | null;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      coachUsers: {
        __typename?: 'CoachUsersConnection';
        count: number;
        edges: Array<{
          __typename?: 'CoachUsersEdge';
          node: {
            __typename?: 'User';
            isStarred: boolean;
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
            createdAt: string;
            botKind?: BotKind | null;
            fitCoachChat?: {
              __typename?: 'ChatRoom';
              id: string;
              lastMessageRead?: string | null;
              lastMessageReceivedAt?: string | null;
              kind: ChatRoomKind;
              messages: {
                __typename?: 'ChatRoomMessagesConnection';
                unreadMessageCount: number;
                pageInfo: {
                  __typename?: 'PageInfo';
                  hasNextPage: boolean;
                  endCursor: string;
                };
                edges: Array<{
                  __typename?: 'ChatRoomMessagesEdge';
                  node: {
                    __typename?: 'Message';
                    id: string;
                    text: string;
                    createdAt: string;
                    automated: boolean;
                    previews: Array<{
                      __typename?: 'MessagePreview';
                      title: string;
                      description?: string | null;
                      url: string;
                      image?: {
                        __typename?: 'Photo';
                        id: string;
                        basePath: string;
                        preview?: string | null;
                      } | null;
                    }>;
                    reactions: Array<{
                      __typename?: 'Reaction';
                      id: string;
                      kind: ReactionKind;
                      user: { __typename?: 'User'; id: string };
                    }>;
                    user: {
                      __typename?: 'User';
                      id: string;
                      firstName?: string | null;
                      lastName?: string | null;
                      botKind?: BotKind | null;
                      lastActivity: {
                        __typename?: 'LastActivity';
                        heart?: string | null;
                        heartWeb?: string | null;
                      };
                      avatar?: {
                        __typename?: 'Photo';
                        id: string;
                        basePath: string;
                        preview?: string | null;
                      } | null;
                    };
                  };
                }>;
              };
            } | null;
            lastActivity: {
              __typename?: 'LastActivity';
              heart?: string | null;
              heartWeb?: string | null;
            };
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            userActivityTrend: Array<{
              __typename?: 'UserActivitySummary';
              date: string;
              program: number;
              classes: number;
              other: number;
              practice: number;
            }>;
            lastUserActivity: {
              __typename?: 'UserActivityConnection';
              edges: Array<{
                __typename?: 'UserActivityEdge';
                node: {
                  __typename?: 'UserActivity';
                  id: string;
                  type: UserActivityType;
                  title: string;
                  startTime: string;
                  endTime: string;
                  duration?: number | null;
                  distance?: {
                    __typename?: 'Distance';
                    value?: number | null;
                    unit?: DistanceUnit | null;
                  } | null;
                  activity?:
                    | { __typename: 'ArticleActivity' }
                    | { __typename: 'AudioActivity' }
                    | { __typename: 'MboClassActivity' }
                    | { __typename: 'PhysicalActivity' }
                    | {
                        __typename: 'SleepActivity';
                        sleepOverview: {
                          __typename?: 'SleepOverview';
                          asleep: number;
                        };
                      }
                    | { __typename: 'VideoActivity' }
                    | { __typename: 'WearableActivity' }
                    | null;
                };
              }>;
            };
          };
        }>;
        pageInfo: {
          __typename?: 'PageInfo';
          hasNextPage: boolean;
          endCursor: string;
        };
      };
    } | null;
  } | null;
};

export type MemberTypeCountQueryVariables = Exact<{ [key: string]: never }>;

export type MemberTypeCountQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      hpMembers: { __typename?: 'CoachUsersConnection'; count: number };
      wellbeingMembers: { __typename?: 'CoachUsersConnection'; count: number };
    } | null;
  } | null;
};

export type CoachMemberListDetailsFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null;
  allCoachUsers?: {
    __typename?: 'CoachData';
    id: string;
    coachUsers: { __typename?: 'CoachUsersConnection'; count: number };
  } | null;
  coachData?: {
    __typename?: 'CoachData';
    id: string;
    coachUsers: {
      __typename?: 'CoachUsersConnection';
      count: number;
      edges: Array<{
        __typename?: 'CoachUsersEdge';
        node: {
          __typename?: 'User';
          isStarred: boolean;
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
          createdAt: string;
          botKind?: BotKind | null;
          fitCoachChat?: {
            __typename?: 'ChatRoom';
            id: string;
            lastMessageRead?: string | null;
            lastMessageReceivedAt?: string | null;
            kind: ChatRoomKind;
            messages: {
              __typename?: 'ChatRoomMessagesConnection';
              unreadMessageCount: number;
              pageInfo: {
                __typename?: 'PageInfo';
                hasNextPage: boolean;
                endCursor: string;
              };
              edges: Array<{
                __typename?: 'ChatRoomMessagesEdge';
                node: {
                  __typename?: 'Message';
                  id: string;
                  text: string;
                  createdAt: string;
                  automated: boolean;
                  previews: Array<{
                    __typename?: 'MessagePreview';
                    title: string;
                    description?: string | null;
                    url: string;
                    image?: {
                      __typename?: 'Photo';
                      id: string;
                      basePath: string;
                      preview?: string | null;
                    } | null;
                  }>;
                  reactions: Array<{
                    __typename?: 'Reaction';
                    id: string;
                    kind: ReactionKind;
                    user: { __typename?: 'User'; id: string };
                  }>;
                  user: {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null;
                    lastName?: string | null;
                    botKind?: BotKind | null;
                    lastActivity: {
                      __typename?: 'LastActivity';
                      heart?: string | null;
                      heartWeb?: string | null;
                    };
                    avatar?: {
                      __typename?: 'Photo';
                      id: string;
                      basePath: string;
                      preview?: string | null;
                    } | null;
                  };
                };
              }>;
            };
          } | null;
          lastActivity: {
            __typename?: 'LastActivity';
            heart?: string | null;
            heartWeb?: string | null;
          };
          avatar?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
          userActivityTrend: Array<{
            __typename?: 'UserActivitySummary';
            date: string;
            program: number;
            classes: number;
            other: number;
            practice: number;
          }>;
          lastUserActivity: {
            __typename?: 'UserActivityConnection';
            edges: Array<{
              __typename?: 'UserActivityEdge';
              node: {
                __typename?: 'UserActivity';
                id: string;
                type: UserActivityType;
                title: string;
                startTime: string;
                endTime: string;
                duration?: number | null;
                distance?: {
                  __typename?: 'Distance';
                  value?: number | null;
                  unit?: DistanceUnit | null;
                } | null;
                activity?:
                  | { __typename: 'ArticleActivity' }
                  | { __typename: 'AudioActivity' }
                  | { __typename: 'MboClassActivity' }
                  | { __typename: 'PhysicalActivity' }
                  | {
                      __typename: 'SleepActivity';
                      sleepOverview: {
                        __typename?: 'SleepOverview';
                        asleep: number;
                      };
                    }
                  | { __typename: 'VideoActivity' }
                  | { __typename: 'WearableActivity' }
                  | null;
              };
            }>;
          };
        };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
    };
  } | null;
};

export type CoachMemberDataFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  createdAt: string;
  botKind?: BotKind | null;
  fitClient?: {
    __typename?: 'Client';
    id: string;
    name?: string | null;
    usesPersonalTraining: boolean;
  } | null;
  lastActivity: {
    __typename?: 'LastActivity';
    heart?: string | null;
    heartWeb?: string | null;
  };
  avatar?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
};

export type CoachMemberListQueryVariables = Exact<{
  membersFilter?: InputMaybe<UsersFilter>;
  input?: InputMaybe<ConnectionInput>;
}>;

export type CoachMemberListQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      clients: {
        __typename?: 'RootClientsConnection';
        edges: Array<{
          __typename?: 'RootClientsEdge';
          node: {
            __typename?: 'Client';
            id: string;
            users: {
              __typename?: 'ClientUsersConnection';
              edges: Array<{
                __typename?: 'ClientUsersEdge';
                node: {
                  __typename?: 'User';
                  id: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                  createdAt: string;
                  botKind?: BotKind | null;
                  fitClient?: {
                    __typename?: 'Client';
                    id: string;
                    name?: string | null;
                    usesPersonalTraining: boolean;
                  } | null;
                  lastActivity: {
                    __typename?: 'LastActivity';
                    heart?: string | null;
                    heartWeb?: string | null;
                  };
                  avatar?: {
                    __typename?: 'Photo';
                    id: string;
                    basePath: string;
                    preview?: string | null;
                  } | null;
                };
              }>;
            };
          };
        }>;
      };
    } | null;
  } | null;
};

export type MembersListTotalsQueryVariables = Exact<{
  newChatsFilters?: InputMaybe<CoachUsersFilter>;
  starredChatsFilters?: InputMaybe<CoachUsersFilter>;
  sessionsTodayFilters?: InputMaybe<CoachSessionFilter>;
  sessionsRequiringAttendanceTrackingFilters?: InputMaybe<CoachSessionFilter>;
}>;

export type MembersListTotalsQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      totalUnreadMessageCount: number;
      newChats: { __typename?: 'CoachUsersConnection'; count: number };
      starredChats: { __typename?: 'CoachUsersConnection'; count: number };
      allMembers: { __typename?: 'CoachUsersConnection'; count: number };
      sessionsTodayCount: {
        __typename?: 'UserCoachSessionsConnection';
        count: number;
      };
      sessionsRequiringAttendanceTrackingCount: {
        __typename?: 'UserCoachSessionsConnection';
        count: number;
      };
    } | null;
  } | null;
};

export type CoachUserTotalUnreadMessageCountSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type CoachUserTotalUnreadMessageCountSubscription = {
  __typename?: 'Subscription';
  totalUnreadMessageCount: {
    __typename?: 'TotalUnreadMessageCountPayload';
    count: number;
  };
};

export type MarkMemberStarredMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  isStarred: Scalars['Boolean']['input'];
}>;

export type MarkMemberStarredMutation = {
  __typename?: 'Mutation';
  markUserStarred: { __typename?: 'User'; id: string; isStarred: boolean };
};

export type MembersConnectionFragment = {
  __typename?: 'CoachUsersConnection';
  count: number;
  edges: Array<{
    __typename?: 'CoachUsersEdge';
    node: {
      __typename?: 'User';
      isStarred: boolean;
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      createdAt: string;
      botKind?: BotKind | null;
      fitCoachChat?: {
        __typename?: 'ChatRoom';
        id: string;
        lastMessageRead?: string | null;
        lastMessageReceivedAt?: string | null;
        kind: ChatRoomKind;
        messages: {
          __typename?: 'ChatRoomMessagesConnection';
          unreadMessageCount: number;
          pageInfo: {
            __typename?: 'PageInfo';
            hasNextPage: boolean;
            endCursor: string;
          };
          edges: Array<{
            __typename?: 'ChatRoomMessagesEdge';
            node: {
              __typename?: 'Message';
              id: string;
              text: string;
              createdAt: string;
              automated: boolean;
              previews: Array<{
                __typename?: 'MessagePreview';
                title: string;
                description?: string | null;
                url: string;
                image?: {
                  __typename?: 'Photo';
                  id: string;
                  basePath: string;
                  preview?: string | null;
                } | null;
              }>;
              reactions: Array<{
                __typename?: 'Reaction';
                id: string;
                kind: ReactionKind;
                user: { __typename?: 'User'; id: string };
              }>;
              user: {
                __typename?: 'User';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                botKind?: BotKind | null;
                lastActivity: {
                  __typename?: 'LastActivity';
                  heart?: string | null;
                  heartWeb?: string | null;
                };
                avatar?: {
                  __typename?: 'Photo';
                  id: string;
                  basePath: string;
                  preview?: string | null;
                } | null;
              };
            };
          }>;
        };
      } | null;
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      userActivityTrend: Array<{
        __typename?: 'UserActivitySummary';
        date: string;
        program: number;
        classes: number;
        other: number;
        practice: number;
      }>;
      lastUserActivity: {
        __typename?: 'UserActivityConnection';
        edges: Array<{
          __typename?: 'UserActivityEdge';
          node: {
            __typename?: 'UserActivity';
            id: string;
            type: UserActivityType;
            title: string;
            startTime: string;
            endTime: string;
            duration?: number | null;
            distance?: {
              __typename?: 'Distance';
              value?: number | null;
              unit?: DistanceUnit | null;
            } | null;
            activity?:
              | { __typename: 'ArticleActivity' }
              | { __typename: 'AudioActivity' }
              | { __typename: 'MboClassActivity' }
              | { __typename: 'PhysicalActivity' }
              | {
                  __typename: 'SleepActivity';
                  sleepOverview: {
                    __typename?: 'SleepOverview';
                    asleep: number;
                  };
                }
              | { __typename: 'VideoActivity' }
              | { __typename: 'WearableActivity' }
              | null;
          };
        }>;
      };
    };
  }>;
  pageInfo: {
    __typename?: 'PageInfo';
    hasNextPage: boolean;
    endCursor: string;
  };
};

export type MemberBasicsFragment = {
  __typename?: 'User';
  isStarred: boolean;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  createdAt: string;
  botKind?: BotKind | null;
  fitCoachChat?: {
    __typename?: 'ChatRoom';
    id: string;
    lastMessageRead?: string | null;
    lastMessageReceivedAt?: string | null;
    kind: ChatRoomKind;
    messages: {
      __typename?: 'ChatRoomMessagesConnection';
      unreadMessageCount: number;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'ChatRoomMessagesEdge';
        node: {
          __typename?: 'Message';
          id: string;
          text: string;
          createdAt: string;
          automated: boolean;
          previews: Array<{
            __typename?: 'MessagePreview';
            title: string;
            description?: string | null;
            url: string;
            image?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
          }>;
          reactions: Array<{
            __typename?: 'Reaction';
            id: string;
            kind: ReactionKind;
            user: { __typename?: 'User'; id: string };
          }>;
          user: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            botKind?: BotKind | null;
            lastActivity: {
              __typename?: 'LastActivity';
              heart?: string | null;
              heartWeb?: string | null;
            };
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
          };
        };
      }>;
    };
  } | null;
  lastActivity: {
    __typename?: 'LastActivity';
    heart?: string | null;
    heartWeb?: string | null;
  };
  avatar?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  userActivityTrend: Array<{
    __typename?: 'UserActivitySummary';
    date: string;
    program: number;
    classes: number;
    other: number;
    practice: number;
  }>;
  lastUserActivity: {
    __typename?: 'UserActivityConnection';
    edges: Array<{
      __typename?: 'UserActivityEdge';
      node: {
        __typename?: 'UserActivity';
        id: string;
        type: UserActivityType;
        title: string;
        startTime: string;
        endTime: string;
        duration?: number | null;
        distance?: {
          __typename?: 'Distance';
          value?: number | null;
          unit?: DistanceUnit | null;
        } | null;
        activity?:
          | { __typename: 'ArticleActivity' }
          | { __typename: 'AudioActivity' }
          | { __typename: 'MboClassActivity' }
          | { __typename: 'PhysicalActivity' }
          | {
              __typename: 'SleepActivity';
              sleepOverview: { __typename?: 'SleepOverview'; asleep: number };
            }
          | { __typename: 'VideoActivity' }
          | { __typename: 'WearableActivity' }
          | null;
      };
    }>;
  };
};

export type CoachUserChatFragment = {
  __typename?: 'ChatRoom';
  id: string;
  lastMessageRead?: string | null;
  lastMessageReceivedAt?: string | null;
  kind: ChatRoomKind;
  messages: {
    __typename?: 'ChatRoomMessagesConnection';
    unreadMessageCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      __typename?: 'ChatRoomMessagesEdge';
      node: {
        __typename?: 'Message';
        id: string;
        text: string;
        createdAt: string;
        automated: boolean;
        previews: Array<{
          __typename?: 'MessagePreview';
          title: string;
          description?: string | null;
          url: string;
          image?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
        }>;
        reactions: Array<{
          __typename?: 'Reaction';
          id: string;
          kind: ReactionKind;
          user: { __typename?: 'User'; id: string };
        }>;
        user: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          botKind?: BotKind | null;
          lastActivity: {
            __typename?: 'LastActivity';
            heart?: string | null;
            heartWeb?: string | null;
          };
          avatar?: {
            __typename?: 'Photo';
            id: string;
            basePath: string;
            preview?: string | null;
          } | null;
        };
      };
    }>;
  };
};

export type LastUserActivityDetailFragment = {
  __typename?: 'UserActivity';
  id: string;
  type: UserActivityType;
  title: string;
  startTime: string;
  endTime: string;
  duration?: number | null;
  distance?: {
    __typename?: 'Distance';
    value?: number | null;
    unit?: DistanceUnit | null;
  } | null;
  activity?:
    | { __typename: 'ArticleActivity' }
    | { __typename: 'AudioActivity' }
    | { __typename: 'MboClassActivity' }
    | { __typename: 'PhysicalActivity' }
    | {
        __typename: 'SleepActivity';
        sleepOverview: { __typename?: 'SleepOverview'; asleep: number };
      }
    | { __typename: 'VideoActivity' }
    | { __typename: 'WearableActivity' }
    | null;
};

export type LastUserActivityFragment = {
  __typename?: 'User';
  lastUserActivity: {
    __typename?: 'UserActivityConnection';
    edges: Array<{
      __typename?: 'UserActivityEdge';
      node: {
        __typename?: 'UserActivity';
        id: string;
        type: UserActivityType;
        title: string;
        startTime: string;
        endTime: string;
        duration?: number | null;
        distance?: {
          __typename?: 'Distance';
          value?: number | null;
          unit?: DistanceUnit | null;
        } | null;
        activity?:
          | { __typename: 'ArticleActivity' }
          | { __typename: 'AudioActivity' }
          | { __typename: 'MboClassActivity' }
          | { __typename: 'PhysicalActivity' }
          | {
              __typename: 'SleepActivity';
              sleepOverview: { __typename?: 'SleepOverview'; asleep: number };
            }
          | { __typename: 'VideoActivity' }
          | { __typename: 'WearableActivity' }
          | null;
      };
    }>;
  };
};

export type MembersActivitySummaryFragment = {
  __typename?: 'UserActivitySummary';
  date: string;
  program: number;
  classes: number;
  other: number;
  practice: number;
};

export type MembersActivityTrendFragment = {
  __typename?: 'User';
  userActivityTrend: Array<{
    __typename?: 'UserActivitySummary';
    date: string;
    program: number;
    classes: number;
    other: number;
    practice: number;
  }>;
};

export type NoteByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type NoteByIdQuery = {
  __typename?: 'Query';
  note?: {
    __typename?: 'Note';
    id: string;
    title?: string | null;
    content: string;
    createdAt: string;
    updatedAt: string;
    lastUpdatedBy?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    } | null;
    coach?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    } | null;
  } | null;
};

export type NoteBasicFragment = {
  __typename?: 'Note';
  id: string;
  title?: string | null;
  content: string;
  createdAt: string;
  updatedAt: string;
  lastUpdatedBy?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
  coach?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
};

export type CreateNoteMutationVariables = Exact<{
  input: CreateNoteInput;
}>;

export type CreateNoteMutation = {
  __typename?: 'Mutation';
  createNote: {
    __typename?: 'MutateNotePayload';
    note?: {
      __typename?: 'Note';
      id: string;
      title?: string | null;
      content: string;
      createdAt: string;
      updatedAt: string;
      lastUpdatedBy?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      } | null;
      coach?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      } | null;
    } | null;
  };
};

export type UpdateNoteMutationVariables = Exact<{
  input: UpdateNoteInput;
}>;

export type UpdateNoteMutation = {
  __typename?: 'Mutation';
  updateNote: {
    __typename?: 'MutateNotePayload';
    note?: {
      __typename?: 'Note';
      id: string;
      title?: string | null;
      content: string;
      createdAt: string;
      updatedAt: string;
      lastUpdatedBy?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      } | null;
      coach?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: {
          __typename?: 'Photo';
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
      } | null;
    } | null;
  };
};

export type GenerateSignedUrlMutationVariables = Exact<{
  input: SignedUrlInput;
}>;

export type GenerateSignedUrlMutation = {
  __typename?: 'Mutation';
  generateSignedUrl: {
    __typename?: 'SignedUrlPayload';
    signedUrl: string;
    key: string;
    photo: { __typename?: 'Photo'; basePath: string; id: string };
  };
};

export type PhotoBasicsFragment = {
  __typename?: 'Photo';
  id: string;
  basePath: string;
  preview?: string | null;
};

export type PracticeProgramsDetailsFragment = {
  __typename?: 'User';
  practicePrograms: {
    __typename?: 'UserPracticeProgramsConnection';
    edges: Array<{
      __typename?: 'UserPracticeProgramEdge';
      node: {
        __typename?: 'PracticeProgram';
        id: string;
        title?: string | null;
        progress: number;
        skills: Array<string>;
        shortDescription?: string | null;
        createdAt?: string | null;
        completedAt?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      };
    }>;
  };
};

export type PracticeProgramDetailsFragment = {
  __typename?: 'PracticeProgram';
  id: string;
  title?: string | null;
  progress: number;
  skills: Array<string>;
  shortDescription?: string | null;
  createdAt?: string | null;
  completedAt?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
};

export type AssignPracticeMutationVariables = Exact<{
  practiceId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type AssignPracticeMutation = {
  __typename?: 'Mutation';
  assignPractice: {
    __typename?: 'AssignPracticePayload';
    assignedPractice: {
      __typename?: 'AssignedPractice';
      id: string;
      practiceProgram?: { __typename?: 'PracticeProgram'; id: string } | null;
    };
  };
};

export type UserCurrentPracticeFragment = {
  __typename?: 'User';
  currentPractice?: {
    __typename?: 'AssignedPractice';
    createdAt: string;
    completedAt?: string | null;
    practiceProgram?: {
      __typename?: 'PracticeProgram';
      id: string;
      title?: string | null;
      progress: number;
      skills: Array<string>;
      shortDescription?: string | null;
      contentTime?: { __typename?: 'ContentTime'; name: string } | null;
    } | null;
  } | null;
};

export type ProgramsQueryVariables = Exact<{ [key: string]: never }>;

export type ProgramsQuery = {
  __typename?: 'Query';
  programs: {
    __typename?: 'ProgramsConnection';
    edges: Array<{
      __typename?: 'ProgramsEdge';
      node: {
        __typename?: 'Program';
        skills: Array<string>;
        id: string;
        title?: string | null;
        shortDescription?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
        contentLevel?: { __typename?: 'Level'; name: string } | null;
      };
    }>;
  };
};

export type UserAssignedProgramsConnectionFragment = {
  __typename?: 'User';
  assignedPrograms: {
    __typename?: 'UserAssignedProgramsConnection';
    edges: Array<{
      __typename?: 'UserAssignedProgramsEdge';
      node: {
        __typename?: 'AssignedProgram';
        program?: {
          __typename?: 'Program';
          id: string;
          progress: number;
        } | null;
      };
    }>;
  };
};

type ProgramContentDetailsArticleFragment = {
  __typename?: 'Article';
  id: string;
  title?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
};

type ProgramContentDetailsAudioFragment = {
  __typename?: 'Audio';
  id: string;
  title?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
};

type ProgramContentDetailsBannerFragment = {
  __typename?: 'Banner';
  id: string;
  title?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
};

type ProgramContentDetailsClickThroughMediaFragment = {
  __typename?: 'ClickThroughMedia';
  id: string;
  title?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
};

type ProgramContentDetailsContentSectionFragment = {
  __typename?: 'ContentSection';
  id: string;
  title?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
};

type ProgramContentDetailsInAppSurveyFragment = {
  __typename?: 'InAppSurvey';
  id: string;
  title?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
};

type ProgramContentDetailsLinkFragment = {
  __typename?: 'Link';
  id: string;
  title?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
};

type ProgramContentDetailsPracticeProgramFragment = {
  __typename?: 'PracticeProgram';
  id: string;
  title?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
};

type ProgramContentDetailsProgramFragment = {
  __typename?: 'Program';
  skills: Array<string>;
  id: string;
  title?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
};

type ProgramContentDetailsVideoFragment = {
  __typename?: 'Video';
  id: string;
  title?: string | null;
  shortDescription?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
};

export type ProgramContentDetailsFragment =
  | ProgramContentDetailsArticleFragment
  | ProgramContentDetailsAudioFragment
  | ProgramContentDetailsBannerFragment
  | ProgramContentDetailsClickThroughMediaFragment
  | ProgramContentDetailsContentSectionFragment
  | ProgramContentDetailsInAppSurveyFragment
  | ProgramContentDetailsLinkFragment
  | ProgramContentDetailsPracticeProgramFragment
  | ProgramContentDetailsProgramFragment
  | ProgramContentDetailsVideoFragment;

export type AssignProgramMutationVariables = Exact<{
  programId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type AssignProgramMutation = {
  __typename?: 'Mutation';
  assignProgram: {
    __typename?: 'AssignProgramPayload';
    assignedProgram: {
      __typename?: 'AssignedProgram';
      id: string;
      program?: { __typename?: 'Program'; title?: string | null } | null;
    };
  };
};

export type UserCurrentProgramFragment = {
  __typename?: 'User';
  currentProgram?: {
    __typename?: 'AssignedProgram';
    completedAt?: string | null;
    createdAt: string;
    program?: {
      __typename?: 'Program';
      id: string;
      shortDescription?: string | null;
      title?: string | null;
      progress: number;
      skills: Array<string>;
      trainingCommitment?: string | null;
      contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      contentLevel?: { __typename?: 'Level'; name: string } | null;
      sections: {
        __typename?: 'ContentCollectionSectionsConnection';
        count: number;
      };
      currentSection?: {
        __typename?: 'ContentSection';
        id: string;
        title?: string | null;
        contents: {
          __typename?: 'ContentConnection';
          completedCount: number;
          count: number;
          edges: Array<{
            __typename?: 'ContentEdge';
            completed: boolean;
            progress?: number | null;
            node:
              | { __typename?: 'Article'; id: string }
              | { __typename?: 'Audio'; id: string }
              | { __typename?: 'Banner'; id: string }
              | { __typename?: 'ClickThroughMedia'; id: string }
              | { __typename?: 'ContentSection'; id: string }
              | { __typename?: 'InAppSurvey'; id: string }
              | { __typename?: 'Link'; id: string }
              | { __typename?: 'PracticeProgram'; id: string }
              | { __typename?: 'Program'; id: string }
              | { __typename?: 'Video'; id: string };
          }>;
        };
      } | null;
    } | null;
  } | null;
};

export type UserCurrentProgramDetailsFragment = {
  __typename?: 'Program';
  id: string;
  shortDescription?: string | null;
  title?: string | null;
  progress: number;
  skills: Array<string>;
  trainingCommitment?: string | null;
  contentTime?: { __typename?: 'ContentTime'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string } | null;
  sections: {
    __typename?: 'ContentCollectionSectionsConnection';
    count: number;
  };
  currentSection?: {
    __typename?: 'ContentSection';
    id: string;
    title?: string | null;
    contents: {
      __typename?: 'ContentConnection';
      completedCount: number;
      count: number;
      edges: Array<{
        __typename?: 'ContentEdge';
        completed: boolean;
        progress?: number | null;
        node:
          | { __typename?: 'Article'; id: string }
          | { __typename?: 'Audio'; id: string }
          | { __typename?: 'Banner'; id: string }
          | { __typename?: 'ClickThroughMedia'; id: string }
          | { __typename?: 'ContentSection'; id: string }
          | { __typename?: 'InAppSurvey'; id: string }
          | { __typename?: 'Link'; id: string }
          | { __typename?: 'PracticeProgram'; id: string }
          | { __typename?: 'Program'; id: string }
          | { __typename?: 'Video'; id: string };
      }>;
    };
  } | null;
};

export type UserProgramSectionDetailsFragment = {
  __typename?: 'Program';
  currentSection?: {
    __typename?: 'ContentSection';
    id: string;
    title?: string | null;
    contents: {
      __typename?: 'ContentConnection';
      completedCount: number;
      count: number;
      edges: Array<{
        __typename?: 'ContentEdge';
        completed: boolean;
        progress?: number | null;
        node:
          | { __typename?: 'Article'; id: string }
          | { __typename?: 'Audio'; id: string }
          | { __typename?: 'Banner'; id: string }
          | { __typename?: 'ClickThroughMedia'; id: string }
          | { __typename?: 'ContentSection'; id: string }
          | { __typename?: 'InAppSurvey'; id: string }
          | { __typename?: 'Link'; id: string }
          | { __typename?: 'PracticeProgram'; id: string }
          | { __typename?: 'Program'; id: string }
          | { __typename?: 'Video'; id: string };
      }>;
    };
  } | null;
};

export type UserProgramSectionContentDetailsFragment = {
  __typename?: 'ContentEdge';
  completed: boolean;
  progress?: number | null;
  node:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Audio'; id: string }
    | { __typename?: 'Banner'; id: string }
    | { __typename?: 'ClickThroughMedia'; id: string }
    | { __typename?: 'ContentSection'; id: string }
    | { __typename?: 'InAppSurvey'; id: string }
    | { __typename?: 'Link'; id: string }
    | { __typename?: 'PracticeProgram'; id: string }
    | { __typename?: 'Program'; id: string }
    | { __typename?: 'Video'; id: string };
};

export type GetSurveyQueryVariables = Exact<{
  templateId: Scalars['ID']['input'];
  surveyId: Scalars['ID']['input'];
}>;

export type GetSurveyQuery = {
  __typename?: 'Query';
  surveyTemplate?: {
    __typename?: 'SurveyTemplate';
    id: string;
    questions: Array<{
      __typename?: 'Question';
      id: string;
      text: string;
      choices: Array<{
        __typename?: 'MultipleChoiceOption';
        text: string;
        optionIdentifier: string;
      }>;
    }>;
  } | null;
  survey?: {
    __typename?: 'Survey';
    id: string;
    createdAt: string;
    surveyName?: string | null;
    responses: Array<{
      __typename?: 'SurveyResponse';
      questionIdentifier: string;
      response:
        | { __typename?: 'EnumAnswer'; enumValue: Array<number> }
        | { __typename?: 'NumberAnswer'; numberValue: number }
        | { __typename?: 'PhotoAnswer' }
        | { __typename?: 'StringAnswer'; stringValue: string };
    }>;
  } | null;
};

export type AnswerSurveyMutationVariables = Exact<{
  input: AnswerSurveyInput;
}>;

export type AnswerSurveyMutation = {
  __typename?: 'Mutation';
  answerSurvey: {
    __typename?: 'Survey';
    id: string;
    surveyName?: string | null;
    surveyIdentifier: string;
    createdAt: string;
    template?: {
      __typename?: 'SurveyTemplate';
      id: string;
      name: string;
      questions: Array<{
        __typename?: 'Question';
        id: string;
        text: string;
        questionType: QuestionType;
        questionSubtype?: QuestionSubtype | null;
        required: boolean;
        answer?:
          | {
              __typename: 'EnumAnswer';
              choices: Array<string>;
              enumValue: Array<number>;
            }
          | { __typename: 'NumberAnswer'; numberValue: number }
          | { __typename: 'PhotoAnswer' }
          | { __typename: 'StringAnswer'; stringValue: string }
          | null;
        choices: Array<{
          __typename?: 'MultipleChoiceOption';
          text: string;
          description: string;
          optionIdentifier: string;
          textEntry: boolean;
          score: number;
        }>;
      }>;
    } | null;
    responses: Array<{
      __typename?: 'SurveyResponse';
      id: string;
      question: string;
      questionIdentifier: string;
      subcategory?: string | null;
      numericValue?: number | null;
      response:
        | {
            __typename?: 'EnumAnswer';
            choices: Array<string>;
            enumValue: Array<number>;
          }
        | { __typename?: 'NumberAnswer'; numberValue: number }
        | { __typename?: 'PhotoAnswer' }
        | { __typename?: 'StringAnswer'; stringValue: string };
    }>;
  };
};

export type QuestionBasicsFragment = {
  __typename?: 'Question';
  id: string;
  text: string;
  choices: Array<{
    __typename?: 'MultipleChoiceOption';
    text: string;
    optionIdentifier: string;
  }>;
};

export type SurveyResponseDetailFragment = {
  __typename?: 'SurveyResponse';
  questionIdentifier: string;
  response:
    | { __typename?: 'EnumAnswer'; enumValue: Array<number> }
    | { __typename?: 'NumberAnswer'; numberValue: number }
    | { __typename?: 'PhotoAnswer' }
    | { __typename?: 'StringAnswer'; stringValue: string };
};

export type GetSurveyDetailsQueryVariables = Exact<{
  surveyId: Scalars['ID']['input'];
}>;

export type GetSurveyDetailsQuery = {
  __typename?: 'Query';
  survey?: {
    __typename?: 'Survey';
    id: string;
    surveyName?: string | null;
    surveyIdentifier: string;
    createdAt: string;
    responses: Array<{
      __typename?: 'SurveyResponse';
      id: string;
      question: string;
      questionIdentifier: string;
      subcategory?: string | null;
      numericValue?: number | null;
      response:
        | {
            __typename?: 'EnumAnswer';
            choices: Array<string>;
            enumValue: Array<number>;
          }
        | { __typename?: 'NumberAnswer'; numberValue: number }
        | { __typename?: 'PhotoAnswer' }
        | { __typename?: 'StringAnswer'; stringValue: string };
    }>;
  } | null;
};

export type SurveyTemplateBasicsFragment = {
  __typename?: 'SurveyTemplate';
  id: string;
  name: string;
  questions: Array<{ __typename?: 'Question'; id: string; text: string }>;
};

export type SurveyTemplateDetailsFragment = {
  __typename?: 'SurveyTemplate';
  id: string;
  name: string;
  questions: Array<{
    __typename?: 'Question';
    id: string;
    text: string;
    questionType: QuestionType;
    questionSubtype?: QuestionSubtype | null;
    required: boolean;
    answer?:
      | {
          __typename: 'EnumAnswer';
          choices: Array<string>;
          enumValue: Array<number>;
        }
      | { __typename: 'NumberAnswer'; numberValue: number }
      | { __typename: 'PhotoAnswer' }
      | { __typename: 'StringAnswer'; stringValue: string }
      | null;
    choices: Array<{
      __typename?: 'MultipleChoiceOption';
      text: string;
      description: string;
      optionIdentifier: string;
      textEntry: boolean;
      score: number;
    }>;
  }>;
};

export type QuestionDetailsFragment = {
  __typename?: 'Question';
  id: string;
  text: string;
  questionType: QuestionType;
  questionSubtype?: QuestionSubtype | null;
  required: boolean;
  answer?:
    | {
        __typename: 'EnumAnswer';
        choices: Array<string>;
        enumValue: Array<number>;
      }
    | { __typename: 'NumberAnswer'; numberValue: number }
    | { __typename: 'PhotoAnswer' }
    | { __typename: 'StringAnswer'; stringValue: string }
    | null;
  choices: Array<{
    __typename?: 'MultipleChoiceOption';
    text: string;
    description: string;
    optionIdentifier: string;
    textEntry: boolean;
    score: number;
  }>;
};

export type ChoiceDetailsFragment = {
  __typename?: 'MultipleChoiceOption';
  text: string;
  description: string;
  optionIdentifier: string;
  textEntry: boolean;
  score: number;
};

type AnswerDetailsEnumAnswerFragment = {
  __typename: 'EnumAnswer';
  choices: Array<string>;
  enumValue: Array<number>;
};

type AnswerDetailsNumberAnswerFragment = {
  __typename: 'NumberAnswer';
  numberValue: number;
};

type AnswerDetailsPhotoAnswerFragment = { __typename: 'PhotoAnswer' };

type AnswerDetailsStringAnswerFragment = {
  __typename: 'StringAnswer';
  stringValue: string;
};

export type AnswerDetailsFragment =
  | AnswerDetailsEnumAnswerFragment
  | AnswerDetailsNumberAnswerFragment
  | AnswerDetailsPhotoAnswerFragment
  | AnswerDetailsStringAnswerFragment;

export type GetUserForE2ETestsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUserForE2ETestsQuery = {
  __typename?: 'Query';
  user?: { __typename?: 'User'; token?: Record<string, unknown> | null } | null;
};

export type GetSelfForE2ETestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSelfForE2ETestsQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    fitCoach?: {
      __typename?: 'User';
      id: string;
      coachData?: {
        __typename?: 'CoachData';
        coachingStyle?: CoachingStyle | null;
      } | null;
    } | null;
    currentProgram?: {
      __typename?: 'AssignedProgram';
      createdAt: string;
      program?: {
        __typename?: 'Program';
        id: string;
        title?: string | null;
        progress: number;
        sections: {
          __typename?: 'ContentCollectionSectionsConnection';
          count: number;
          edges: Array<{
            __typename?: 'ContentCollectionSectionsEdge';
            node: {
              __typename?: 'ContentSection';
              id: string;
              contents: {
                __typename?: 'ContentConnection';
                edges: Array<{
                  __typename?: 'ContentEdge';
                  node:
                    | { __typename: 'Article'; id: string }
                    | { __typename: 'Audio'; id: string }
                    | { __typename: 'Banner'; id: string }
                    | { __typename: 'ClickThroughMedia'; id: string }
                    | { __typename: 'ContentSection'; id: string }
                    | {
                        __typename: 'InAppSurvey';
                        id: string;
                        surveyTemplate?: {
                          __typename?: 'SurveyTemplate';
                          id: string;
                          questions: Array<{
                            __typename?: 'Question';
                            id: string;
                            text: string;
                            questionType: QuestionType;
                            choices: Array<{
                              __typename?: 'MultipleChoiceOption';
                              text: string;
                              optionIdentifier: string;
                              score: number;
                            }>;
                          }>;
                        } | null;
                      }
                    | { __typename: 'Link'; id: string }
                    | { __typename: 'PracticeProgram'; id: string }
                    | { __typename: 'Program'; id: string }
                    | { __typename: 'Video'; id: string };
                }>;
              };
            };
          }>;
        };
      } | null;
    } | null;
    currentPractice?: {
      __typename?: 'AssignedPractice';
      createdAt: string;
      practiceProgram?: {
        __typename?: 'PracticeProgram';
        id: string;
        title?: string | null;
        progress: number;
        sections: {
          __typename?: 'ContentCollectionSectionsConnection';
          count: number;
          edges: Array<{
            __typename?: 'ContentCollectionSectionsEdge';
            node: {
              __typename?: 'ContentSection';
              id: string;
              contents: {
                __typename?: 'ContentConnection';
                edges: Array<{
                  __typename?: 'ContentEdge';
                  node:
                    | { __typename: 'Article'; id: string }
                    | { __typename: 'Audio'; id: string }
                    | { __typename: 'Banner'; id: string }
                    | { __typename: 'ClickThroughMedia'; id: string }
                    | { __typename: 'ContentSection'; id: string }
                    | {
                        __typename: 'InAppSurvey';
                        id: string;
                        surveyTemplate?: {
                          __typename?: 'SurveyTemplate';
                          id: string;
                          questions: Array<{
                            __typename?: 'Question';
                            id: string;
                            text: string;
                            questionType: QuestionType;
                            choices: Array<{
                              __typename?: 'MultipleChoiceOption';
                              text: string;
                              optionIdentifier: string;
                              score: number;
                            }>;
                          }>;
                        } | null;
                      }
                    | { __typename: 'Link'; id: string }
                    | { __typename: 'PracticeProgram'; id: string }
                    | { __typename: 'Program'; id: string }
                    | { __typename: 'Video'; id: string };
                }>;
              };
            };
          }>;
        };
      } | null;
    } | null;
    practicePrograms: {
      __typename?: 'UserPracticeProgramsConnection';
      edges: Array<{
        __typename?: 'UserPracticeProgramEdge';
        node: { __typename?: 'PracticeProgram'; id: string };
      }>;
    };
  } | null;
  programs: {
    __typename?: 'ProgramsConnection';
    edges: Array<{
      __typename?: 'ProgramsEdge';
      node: { __typename?: 'Program'; id: string };
    }>;
  };
};

export type ProgramDetailsFragment = {
  __typename?: 'Program';
  id: string;
  title?: string | null;
  progress: number;
  sections: {
    __typename?: 'ContentCollectionSectionsConnection';
    count: number;
    edges: Array<{
      __typename?: 'ContentCollectionSectionsEdge';
      node: {
        __typename?: 'ContentSection';
        id: string;
        contents: {
          __typename?: 'ContentConnection';
          edges: Array<{
            __typename?: 'ContentEdge';
            node:
              | { __typename: 'Article'; id: string }
              | { __typename: 'Audio'; id: string }
              | { __typename: 'Banner'; id: string }
              | { __typename: 'ClickThroughMedia'; id: string }
              | { __typename: 'ContentSection'; id: string }
              | {
                  __typename: 'InAppSurvey';
                  id: string;
                  surveyTemplate?: {
                    __typename?: 'SurveyTemplate';
                    id: string;
                    questions: Array<{
                      __typename?: 'Question';
                      id: string;
                      text: string;
                      questionType: QuestionType;
                      choices: Array<{
                        __typename?: 'MultipleChoiceOption';
                        text: string;
                        optionIdentifier: string;
                        score: number;
                      }>;
                    }>;
                  } | null;
                }
              | { __typename: 'Link'; id: string }
              | { __typename: 'PracticeProgram'; id: string }
              | { __typename: 'Program'; id: string }
              | { __typename: 'Video'; id: string };
          }>;
        };
      };
    }>;
  };
};

export type PracticeProgramContentDetailsFragment = {
  __typename?: 'PracticeProgram';
  id: string;
  title?: string | null;
  progress: number;
  sections: {
    __typename?: 'ContentCollectionSectionsConnection';
    count: number;
    edges: Array<{
      __typename?: 'ContentCollectionSectionsEdge';
      node: {
        __typename?: 'ContentSection';
        id: string;
        contents: {
          __typename?: 'ContentConnection';
          edges: Array<{
            __typename?: 'ContentEdge';
            node:
              | { __typename: 'Article'; id: string }
              | { __typename: 'Audio'; id: string }
              | { __typename: 'Banner'; id: string }
              | { __typename: 'ClickThroughMedia'; id: string }
              | { __typename: 'ContentSection'; id: string }
              | {
                  __typename: 'InAppSurvey';
                  id: string;
                  surveyTemplate?: {
                    __typename?: 'SurveyTemplate';
                    id: string;
                    questions: Array<{
                      __typename?: 'Question';
                      id: string;
                      text: string;
                      questionType: QuestionType;
                      choices: Array<{
                        __typename?: 'MultipleChoiceOption';
                        text: string;
                        optionIdentifier: string;
                        score: number;
                      }>;
                    }>;
                  } | null;
                }
              | { __typename: 'Link'; id: string }
              | { __typename: 'PracticeProgram'; id: string }
              | { __typename: 'Program'; id: string }
              | { __typename: 'Video'; id: string };
          }>;
        };
      };
    }>;
  };
};

export type SurveyDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SurveyDetailsQuery = {
  __typename?: 'Query';
  content?:
    | {
        __typename: 'Article';
        id: string;
        title?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Audio';
        id: string;
        title?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Banner';
        id: string;
        title?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'ClickThroughMedia';
        id: string;
        title?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'ContentSection';
        id: string;
        title?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'InAppSurvey';
        link: string;
        id: string;
        title?: string | null;
        surveyTemplate?: {
          __typename?: 'SurveyTemplate';
          id: string;
          name: string;
          questions: Array<{
            __typename?: 'Question';
            id: string;
            text: string;
            questionType: QuestionType;
            choices: Array<{
              __typename?: 'MultipleChoiceOption';
              text: string;
              optionIdentifier: string;
              score: number;
            }>;
          }>;
        } | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Link';
        id: string;
        title?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'PracticeProgram';
        id: string;
        title?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Program';
        id: string;
        title?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | {
        __typename: 'Video';
        id: string;
        title?: string | null;
        contentTime?: { __typename?: 'ContentTime'; name: string } | null;
      }
    | null;
};

export type SurveyTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SurveyTemplateQuery = {
  __typename?: 'Query';
  surveyTemplate?: {
    __typename?: 'SurveyTemplate';
    id: string;
    name: string;
    questions: Array<{
      __typename?: 'Question';
      id: string;
      text: string;
      questionType: QuestionType;
      choices: Array<{
        __typename?: 'MultipleChoiceOption';
        text: string;
        optionIdentifier: string;
        score: number;
      }>;
    }>;
  } | null;
};

export type InAppSurveyFragment = {
  __typename?: 'InAppSurvey';
  surveyTemplate?: {
    __typename?: 'SurveyTemplate';
    id: string;
    questions: Array<{
      __typename?: 'Question';
      id: string;
      text: string;
      questionType: QuestionType;
      choices: Array<{
        __typename?: 'MultipleChoiceOption';
        text: string;
        optionIdentifier: string;
        score: number;
      }>;
    }>;
  } | null;
};

export type SurveyQuestionFragment = {
  __typename?: 'Question';
  id: string;
  text: string;
  questionType: QuestionType;
  choices: Array<{
    __typename?: 'MultipleChoiceOption';
    text: string;
    optionIdentifier: string;
    score: number;
  }>;
};

export type ActivityGenreFragment = {
  __typename?: 'ActivityGenre';
  id: string;
  canHaveDistance: boolean;
};

export type AvailableActivityGenresQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AvailableActivityGenresQuery = {
  __typename?: 'Query';
  activityGenres: Array<{
    __typename?: 'ActivityGenre';
    id: string;
    canHaveDistance: boolean;
  }>;
};

type E2EActivityArticleActivityFragment = {
  __typename?: 'ArticleActivity';
  id: string;
  title: string;
  type: ActivityType;
};

type E2EActivityAudioActivityFragment = {
  __typename?: 'AudioActivity';
  id: string;
  title: string;
  type: ActivityType;
};

type E2EActivityMboClassActivityFragment = {
  __typename?: 'MboClassActivity';
  id: string;
  title: string;
  type: ActivityType;
};

type E2EActivityPhysicalActivityFragment = {
  __typename?: 'PhysicalActivity';
  id: string;
  title: string;
  type: ActivityType;
};

type E2EActivitySleepActivityFragment = {
  __typename?: 'SleepActivity';
  id: string;
  title: string;
  type: ActivityType;
};

type E2EActivityVideoActivityFragment = {
  __typename?: 'VideoActivity';
  id: string;
  title: string;
  type: ActivityType;
};

type E2EActivityWearableActivityFragment = {
  __typename?: 'WearableActivity';
  id: string;
  title: string;
  type: ActivityType;
};

export type E2EActivityFragment =
  | E2EActivityArticleActivityFragment
  | E2EActivityAudioActivityFragment
  | E2EActivityMboClassActivityFragment
  | E2EActivityPhysicalActivityFragment
  | E2EActivitySleepActivityFragment
  | E2EActivityVideoActivityFragment
  | E2EActivityWearableActivityFragment;

export type CreateE2EManualActivityMutationVariables = Exact<{
  activity: ManualActivityInput;
}>;

export type CreateE2EManualActivityMutation = {
  __typename?: 'Mutation';
  addManualActivity: {
    __typename?: 'ActivityPayload';
    activity:
      | {
          __typename?: 'ArticleActivity';
          id: string;
          title: string;
          type: ActivityType;
        }
      | {
          __typename?: 'AudioActivity';
          id: string;
          title: string;
          type: ActivityType;
        }
      | {
          __typename?: 'MboClassActivity';
          id: string;
          title: string;
          type: ActivityType;
        }
      | {
          __typename?: 'PhysicalActivity';
          id: string;
          title: string;
          type: ActivityType;
        }
      | {
          __typename?: 'SleepActivity';
          id: string;
          title: string;
          type: ActivityType;
        }
      | {
          __typename?: 'VideoActivity';
          id: string;
          title: string;
          type: ActivityType;
        }
      | {
          __typename?: 'WearableActivity';
          id: string;
          title: string;
          type: ActivityType;
        };
  };
};

export type E2ESurveyFragment = {
  __typename?: 'Survey';
  id: string;
  surveyName?: string | null;
  responses: Array<{ __typename?: 'SurveyResponse'; question: string }>;
};

export type CreateE2ESurveyMutationVariables = Exact<{
  input: AnswerSurveyInput;
}>;

export type CreateE2ESurveyMutation = {
  __typename?: 'Mutation';
  answerSurvey: {
    __typename?: 'Survey';
    id: string;
    surveyName?: string | null;
    responses: Array<{ __typename?: 'SurveyResponse'; question: string }>;
  };
};

export type GetAvailableVideoContentGenresQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAvailableVideoContentGenresQuery = {
  __typename?: 'Query';
  contentGenres: Array<{ __typename?: 'Genre'; value: string }>;
};

export type GetVideosQueryVariables = Exact<{
  filter?: InputMaybe<ContentFilter>;
}>;

export type GetVideosQuery = {
  __typename?: 'Query';
  contents: {
    __typename?: 'ContentConnection';
    edges: Array<{
      __typename?: 'ContentEdge';
      node:
        | { __typename?: 'Article'; id: string }
        | { __typename?: 'Audio'; id: string }
        | { __typename?: 'Banner'; id: string }
        | { __typename?: 'ClickThroughMedia'; id: string }
        | { __typename?: 'ContentSection'; id: string }
        | { __typename?: 'InAppSurvey'; id: string }
        | { __typename?: 'Link'; id: string }
        | { __typename?: 'PracticeProgram'; id: string }
        | { __typename?: 'Program'; id: string }
        | { __typename?: 'Video'; id: string };
    }>;
  };
};

export type UpdateProgressE2EMutationVariables = Exact<{
  contentId: Scalars['ID']['input'];
  sectionId?: InputMaybe<Scalars['ID']['input']>;
  progress: Scalars['Float']['input'];
}>;

export type UpdateProgressE2EMutation = {
  __typename?: 'Mutation';
  updateProgress: {
    __typename?: 'ContentEdge';
    node:
      | { __typename?: 'Article'; id: string }
      | { __typename?: 'Audio'; id: string }
      | { __typename?: 'Banner'; id: string }
      | { __typename?: 'ClickThroughMedia'; id: string }
      | { __typename?: 'ContentSection'; id: string }
      | { __typename?: 'InAppSurvey'; id: string }
      | { __typename?: 'Link'; id: string }
      | { __typename?: 'PracticeProgram'; id: string }
      | { __typename?: 'Program'; id: string }
      | { __typename?: 'Video'; id: string };
  };
};

export type AdminUpdateLastActivityMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  app: App;
  date?: InputMaybe<Scalars['ZonedDateTime']['input']>;
}>;

export type AdminUpdateLastActivityMutation = {
  __typename?: 'Mutation';
  adminUpdateLastActivity?: {
    __typename?: 'UpdateUserPayload';
    user: { __typename?: 'User'; id: string };
  } | null;
};

export type CompletePracticeMutationVariables = Exact<{
  practiceId: Scalars['ID']['input'];
}>;

export type CompletePracticeMutation = {
  __typename?: 'Mutation';
  completePractice: {
    __typename?: 'UpdatePracticePayload';
    assignedPractice: { __typename?: 'AssignedPractice'; id: string };
  };
};

export type CompleteProgramMutationVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type CompleteProgramMutation = {
  __typename?: 'Mutation';
  completeProgram: {
    __typename?: 'UpdateProgramPayload';
    assignedProgram: { __typename?: 'AssignedProgram'; id: string };
  };
};

export type E2ETestSessionFragment = {
  __typename?: 'CoachSession';
  id: string;
  calendar?: { __typename?: 'Calendar'; start: string } | null;
};

export type BookTestSessionMutationVariables = Exact<{
  input: BookSessionInput;
}>;

export type BookTestSessionMutation = {
  __typename?: 'Mutation';
  bookSession: {
    __typename?: 'BookSessionPayload';
    session: {
      __typename?: 'CoachSession';
      id: string;
      calendar?: { __typename?: 'Calendar'; start: string } | null;
    };
  };
};

export type UpdateCoachDataMutationVariables = Exact<{
  input: UpdateCoachDataInput;
}>;

export type UpdateCoachDataMutation = {
  __typename?: 'Mutation';
  updateCoachData: {
    __typename?: 'UpdateCoachDataPayload';
    user: { __typename?: 'User'; id: string };
  };
};

export type UpdateSessionAttendanceMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
  attendance: CoachSessionAttendance;
}>;

export type UpdateSessionAttendanceMutation = {
  __typename?: 'Mutation';
  updateAttendance: {
    __typename?: 'CoachSession';
    id: string;
    calendar?: { __typename?: 'Calendar'; start: string } | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'UpdateUserPayload';
    user: { __typename?: 'User'; id: string };
  };
};

export type AdminUpdateUserMutationVariables = Exact<{
  input: AdminUpdateUserInput;
}>;

export type AdminUpdateUserMutation = {
  __typename?: 'Mutation';
  adminUpdateUser: {
    __typename?: 'UpdateUserPayload';
    user: { __typename?: 'User'; id: string };
  };
};

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type GetUserByEmailQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    isCoach: boolean;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    createdAt: string;
    botKind?: BotKind | null;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
};

export type CancelTestSessionMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
}>;

export type CancelTestSessionMutation = {
  __typename?: 'Mutation';
  cancelSession: boolean;
};

export type SetUserCoachSessionCreditsMutationVariables = Exact<{
  input: SessionCreditsInput;
}>;

export type SetUserCoachSessionCreditsMutation = {
  __typename?: 'Mutation';
  setSessionCredits: {
    __typename?: 'SessionCreditsPayload';
    userId: string;
    count: number;
    rowVersion: number;
  };
};

export type GetUserCoachSessionCreditsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type GetUserCoachSessionCreditsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    coachSessionCredits?: {
      __typename?: 'CoachSessionCredits';
      count: number;
      rowVersion: number;
    } | null;
  } | null;
};

export type SendHistoricalMessageMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  createdAt: Scalars['ZonedDateTime']['input'];
  text: Scalars['String']['input'];
  chatId: Scalars['ID']['input'];
}>;

export type SendHistoricalMessageMutation = {
  __typename?: 'Mutation';
  sendHistoricalMessage: { __typename?: 'Message'; id: string };
};

export type CoachMembersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<ConnectionInput>;
}>;

export type CoachMembersQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      coachUsers: {
        __typename?: 'CoachUsersConnection';
        count: number;
        edges: Array<{
          __typename?: 'CoachUsersEdge';
          node: {
            __typename?: 'User';
            isStarred: boolean;
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
            createdAt: string;
            botKind?: BotKind | null;
            fitCoachChat?: {
              __typename?: 'ChatRoom';
              id: string;
              lastMessageRead?: string | null;
              lastMessageReceivedAt?: string | null;
              kind: ChatRoomKind;
              messages: {
                __typename?: 'ChatRoomMessagesConnection';
                unreadMessageCount: number;
                pageInfo: {
                  __typename?: 'PageInfo';
                  hasNextPage: boolean;
                  endCursor: string;
                };
                edges: Array<{
                  __typename?: 'ChatRoomMessagesEdge';
                  node: {
                    __typename?: 'Message';
                    id: string;
                    text: string;
                    createdAt: string;
                    automated: boolean;
                    previews: Array<{
                      __typename?: 'MessagePreview';
                      title: string;
                      description?: string | null;
                      url: string;
                      image?: {
                        __typename?: 'Photo';
                        id: string;
                        basePath: string;
                        preview?: string | null;
                      } | null;
                    }>;
                    reactions: Array<{
                      __typename?: 'Reaction';
                      id: string;
                      kind: ReactionKind;
                      user: { __typename?: 'User'; id: string };
                    }>;
                    user: {
                      __typename?: 'User';
                      id: string;
                      firstName?: string | null;
                      lastName?: string | null;
                      botKind?: BotKind | null;
                      lastActivity: {
                        __typename?: 'LastActivity';
                        heart?: string | null;
                        heartWeb?: string | null;
                      };
                      avatar?: {
                        __typename?: 'Photo';
                        id: string;
                        basePath: string;
                        preview?: string | null;
                      } | null;
                    };
                  };
                }>;
              };
            } | null;
            lastActivity: {
              __typename?: 'LastActivity';
              heart?: string | null;
              heartWeb?: string | null;
            };
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
            userActivityTrend: Array<{
              __typename?: 'UserActivitySummary';
              date: string;
              program: number;
              classes: number;
              other: number;
              practice: number;
            }>;
            lastUserActivity: {
              __typename?: 'UserActivityConnection';
              edges: Array<{
                __typename?: 'UserActivityEdge';
                node: {
                  __typename?: 'UserActivity';
                  id: string;
                  type: UserActivityType;
                  title: string;
                  startTime: string;
                  endTime: string;
                  duration?: number | null;
                  distance?: {
                    __typename?: 'Distance';
                    value?: number | null;
                    unit?: DistanceUnit | null;
                  } | null;
                  activity?:
                    | { __typename: 'ArticleActivity' }
                    | { __typename: 'AudioActivity' }
                    | { __typename: 'MboClassActivity' }
                    | { __typename: 'PhysicalActivity' }
                    | {
                        __typename: 'SleepActivity';
                        sleepOverview: {
                          __typename?: 'SleepOverview';
                          asleep: number;
                        };
                      }
                    | { __typename: 'VideoActivity' }
                    | { __typename: 'WearableActivity' }
                    | null;
                };
              }>;
            };
          };
        }>;
        pageInfo: {
          __typename?: 'PageInfo';
          hasNextPage: boolean;
          endCursor: string;
        };
      };
    } | null;
  } | null;
};

export type SyncWearableSamplesMutationVariables = Exact<{
  data: WearableSamplesInput;
}>;

export type SyncWearableSamplesMutation = {
  __typename?: 'Mutation';
  syncWearableSamples: boolean;
};

export type DomainBasicsFragment = {
  __typename?: 'Domain';
  id: string;
  domain: string;
  client?: { __typename?: 'Client'; id: string; name?: string | null } | null;
};

export type DomainsQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<DomainsFilter>;
}>;

export type DomainsQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'DomainConnection';
    count: number;
    nodes: Array<{
      __typename?: 'Domain';
      id: string;
      domain: string;
      client?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string;
      startCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type CreateCoachTouchPointMutationVariables = Exact<{
  input: CreateCoachTouchPointInput;
}>;

export type CreateCoachTouchPointMutation = {
  __typename?: 'Mutation';
  createCoachTouchPoint: { __typename?: 'CoachTouchPoint'; id: string };
};

export type DeleteCoachTouchPointMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCoachTouchPointMutation = {
  __typename?: 'Mutation';
  deleteCoachTouchPoint: boolean;
};

export type UpdateCoachTouchPointMutationVariables = Exact<{
  input: UpdateCoachTouchPointInput;
}>;

export type UpdateCoachTouchPointMutation = {
  __typename?: 'Mutation';
  updateCoachTouchPoint: {
    __typename?: 'CoachTouchPoint';
    id: string;
    due: string;
    title: string;
    description: string;
    type: CoachTouchPointType;
    completedAt?: string | null;
    member: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      createdAt: string;
      botKind?: BotKind | null;
      fitClient?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
        usesPersonalTraining: boolean;
      } | null;
      fitCoachChat?: { __typename?: 'ChatRoom'; id: string } | null;
      fitCoach?: { __typename?: 'User'; id: string } | null;
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    };
    metadata?: {
      __typename?: 'CoachTouchPointSessionMetadata';
      isCancelled: boolean;
      isRebooked: boolean;
      session?: {
        __typename?: 'CoachSession';
        id: string;
        sessionType?: CoachSessionType | null;
        calendar?: {
          __typename?: 'Calendar';
          start: string;
          end: string;
        } | null;
      } | null;
    } | null;
  };
};

export type SelfQueryVariables = Exact<{ [key: string]: never }>;

export type SelfQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    isAdmin: boolean;
    isCoach: boolean;
    birthday?: string | null;
    pronouns?: string | null;
    identity?: string | null;
    weight?: number | null;
    height?: number | null;
    workingStyle?: WorkingStyle | null;
    clientRelationship?: UserClientRelationship | null;
    hasHighPerformanceCoaching: boolean;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    createdAt: string;
    botKind?: BotKind | null;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      type?: CoachType | null;
      coachingStyle?: CoachingStyle | null;
      inOfficeDays: Array<number>;
      clients: {
        __typename?: 'RootClientsConnection';
        edges: Array<{
          __typename?: 'RootClientsEdge';
          node: {
            __typename?: 'Client';
            id: string;
            displayName: string;
            usesPersonalTraining: boolean;
          };
        }>;
      };
    } | null;
    officeLocation?: {
      __typename?: 'CoachLocation';
      id: string;
      name?: string | null;
    } | null;
    botChat?: { __typename?: 'ChatRoom'; id: string } | null;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
};

export type CoachSettingsQueryVariables = Exact<{
  url: Scalars['String']['input'];
}>;

export type CoachSettingsQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    isCoach: boolean;
    birthday?: string | null;
    pronouns?: string | null;
    identity?: string | null;
    weight?: number | null;
    height?: number | null;
    workingStyle?: WorkingStyle | null;
    clientRelationship?: UserClientRelationship | null;
    hasHighPerformanceCoaching: boolean;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    createdAt: string;
    botKind?: BotKind | null;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      cronofyAuthUrl?: string | null;
      calendarConnected: boolean;
      cronofyElementToken?: string | null;
      inOfficeDays: Array<number>;
      coachingStyle?: CoachingStyle | null;
      videoPlatformConnected?: ClientVideoPlatform | null;
      cronofyConferencingUrl?: string | null;
      externalCalendarConnected?: ClientCalendarPlatform | null;
      cronofyExternalCalendarUrl?: string | null;
      clients: {
        __typename?: 'RootClientsConnection';
        edges: Array<{
          __typename?: 'RootClientsEdge';
          node: {
            __typename?: 'Client';
            id: string;
            videoPlatform?: ClientVideoPlatform | null;
            calendarPlatform?: ClientCalendarPlatform | null;
            name?: string | null;
          };
        }>;
      };
    } | null;
    officeLocation?: {
      __typename?: 'CoachLocation';
      id: string;
      name?: string | null;
    } | null;
    botChat?: { __typename?: 'ChatRoom'; id: string } | null;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
};

export type CoachBookingDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  availabilityStart: Scalars['ZonedDateTime']['input'];
  coachSessionStyle?: InputMaybe<CoachSessionStyle>;
  bypassOneDayDelay?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CoachBookingDetailsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    birthday?: string | null;
    pronouns?: string | null;
    identity?: string | null;
    weight?: number | null;
    height?: number | null;
    workingStyle?: WorkingStyle | null;
    isCoach: boolean;
    clientRelationship?: UserClientRelationship | null;
    hasHighPerformanceCoaching: boolean;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    createdAt: string;
    botKind?: BotKind | null;
    coachData?: {
      __typename?: 'CoachData';
      cronofyTimezone?: string | null;
      id: string;
      type?: CoachType | null;
      coachingStyle?: CoachingStyle | null;
      inOfficeDays: Array<number>;
      clients: {
        __typename?: 'RootClientsConnection';
        edges: Array<{
          __typename?: 'RootClientsEdge';
          node: {
            __typename?: 'Client';
            id: string;
            displayName: string;
            usesPersonalTraining: boolean;
          };
        }>;
      };
      availability: {
        __typename?: 'UserAvailabilityConnection';
        edges: Array<{
          __typename?: 'UserAvailabilityEdge';
          node: { __typename?: 'Calendar'; start: string; end: string };
        }>;
      };
      locations: {
        __typename?: 'CoachLocationsConnection';
        edges: Array<{
          __typename?: 'CoachLocationsEdge';
          node: {
            __typename?: 'CoachLocation';
            id: string;
            name?: string | null;
          };
        }>;
      };
    } | null;
    officeLocation?: {
      __typename?: 'CoachLocation';
      id: string;
      name?: string | null;
    } | null;
    botChat?: { __typename?: 'ChatRoom'; id: string } | null;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
};

export type SelfBasicsFragment = {
  __typename?: 'User';
  isAdmin: boolean;
  isCoach: boolean;
  birthday?: string | null;
  pronouns?: string | null;
  identity?: string | null;
  weight?: number | null;
  height?: number | null;
  workingStyle?: WorkingStyle | null;
  clientRelationship?: UserClientRelationship | null;
  hasHighPerformanceCoaching: boolean;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  createdAt: string;
  botKind?: BotKind | null;
  coachData?: {
    __typename?: 'CoachData';
    id: string;
    type?: CoachType | null;
    coachingStyle?: CoachingStyle | null;
    inOfficeDays: Array<number>;
    clients: {
      __typename?: 'RootClientsConnection';
      edges: Array<{
        __typename?: 'RootClientsEdge';
        node: {
          __typename?: 'Client';
          id: string;
          displayName: string;
          usesPersonalTraining: boolean;
        };
      }>;
    };
  } | null;
  officeLocation?: {
    __typename?: 'CoachLocation';
    id: string;
    name?: string | null;
  } | null;
  botChat?: { __typename?: 'ChatRoom'; id: string } | null;
  lastActivity: {
    __typename?: 'LastActivity';
    heart?: string | null;
    heartWeb?: string | null;
  };
  avatar?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
};

export type CoachLocationDetailsFragment = {
  __typename?: 'CoachData';
  locations: {
    __typename?: 'CoachLocationsConnection';
    edges: Array<{
      __typename?: 'CoachLocationsEdge';
      node: { __typename?: 'CoachLocation'; id: string; name?: string | null };
    }>;
  };
};

export type GetClientCoachListQueryVariables = Exact<{
  coachName?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetClientCoachListQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      clients: {
        __typename?: 'RootClientsConnection';
        edges: Array<{
          __typename?: 'RootClientsEdge';
          node: {
            __typename?: 'Client';
            id: string;
            coaches: {
              __typename?: 'ClientUsersConnection';
              edges: Array<{
                __typename?: 'ClientUsersEdge';
                node: {
                  __typename?: 'User';
                  id: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                  createdAt: string;
                  botKind?: BotKind | null;
                  lastActivity: {
                    __typename?: 'LastActivity';
                    heart?: string | null;
                    heartWeb?: string | null;
                  };
                  avatar?: {
                    __typename?: 'Photo';
                    id: string;
                    basePath: string;
                    preview?: string | null;
                  } | null;
                };
              }>;
            };
          };
        }>;
      };
    } | null;
  } | null;
};

export type AvailabilityCalendarTimeFragment = {
  __typename?: 'Calendar';
  start: string;
  end: string;
};

export type CoachAvailabilityFragment = {
  __typename?: 'CoachData';
  availability: {
    __typename?: 'UserAvailabilityConnection';
    edges: Array<{
      __typename?: 'UserAvailabilityEdge';
      node: { __typename?: 'Calendar'; start: string; end: string };
    }>;
  };
};

export type CoachDataClientBasicsFragment = {
  __typename?: 'Client';
  id: string;
  displayName: string;
  usesPersonalTraining: boolean;
};

export type CoachDataBasicsFragment = {
  __typename?: 'CoachData';
  id: string;
  type?: CoachType | null;
  coachingStyle?: CoachingStyle | null;
  inOfficeDays: Array<number>;
  clients: {
    __typename?: 'RootClientsConnection';
    edges: Array<{
      __typename?: 'RootClientsEdge';
      node: {
        __typename?: 'Client';
        id: string;
        displayName: string;
        usesPersonalTraining: boolean;
      };
    }>;
  };
};

export type SurveyBasicsFragment = {
  __typename?: 'Survey';
  id: string;
  surveyIdentifier: string;
  surveyName?: string | null;
  createdAt: string;
};

export type SurveyDetailsFragment = {
  __typename?: 'Survey';
  id: string;
  surveyName?: string | null;
  surveyIdentifier: string;
  createdAt: string;
  responses: Array<{
    __typename?: 'SurveyResponse';
    id: string;
    question: string;
    questionIdentifier: string;
    subcategory?: string | null;
    numericValue?: number | null;
    response:
      | {
          __typename?: 'EnumAnswer';
          choices: Array<string>;
          enumValue: Array<number>;
        }
      | { __typename?: 'NumberAnswer'; numberValue: number }
      | { __typename?: 'PhotoAnswer' }
      | { __typename?: 'StringAnswer'; stringValue: string };
  }>;
};

export type ResponseDetailsFragment = {
  __typename?: 'SurveyResponse';
  id: string;
  question: string;
  questionIdentifier: string;
  subcategory?: string | null;
  numericValue?: number | null;
  response:
    | {
        __typename?: 'EnumAnswer';
        choices: Array<string>;
        enumValue: Array<number>;
      }
    | { __typename?: 'NumberAnswer'; numberValue: number }
    | { __typename?: 'PhotoAnswer' }
    | { __typename?: 'StringAnswer'; stringValue: string };
};

export type ProfileSurveysFragment = {
  __typename?: 'User';
  motivations: {
    __typename?: 'UserSurveysConnection';
    edges: Array<{
      __typename?: 'UserSurveysEdge';
      node: {
        __typename?: 'Survey';
        id: string;
        surveyName?: string | null;
        surveyIdentifier: string;
        createdAt: string;
        responses: Array<{
          __typename?: 'SurveyResponse';
          id: string;
          question: string;
          questionIdentifier: string;
          subcategory?: string | null;
          numericValue?: number | null;
          response:
            | {
                __typename?: 'EnumAnswer';
                choices: Array<string>;
                enumValue: Array<number>;
              }
            | { __typename?: 'NumberAnswer'; numberValue: number }
            | { __typename?: 'PhotoAnswer' }
            | { __typename?: 'StringAnswer'; stringValue: string };
        }>;
      };
    }>;
  };
  assessment: {
    __typename?: 'UserSurveysConnection';
    edges: Array<{
      __typename?: 'UserSurveysEdge';
      node: {
        __typename?: 'Survey';
        id: string;
        surveyName?: string | null;
        surveyIdentifier: string;
        createdAt: string;
        responses: Array<{
          __typename?: 'SurveyResponse';
          id: string;
          question: string;
          questionIdentifier: string;
          subcategory?: string | null;
          numericValue?: number | null;
          response:
            | {
                __typename?: 'EnumAnswer';
                choices: Array<string>;
                enumValue: Array<number>;
              }
            | { __typename?: 'NumberAnswer'; numberValue: number }
            | { __typename?: 'PhotoAnswer' }
            | { __typename?: 'StringAnswer'; stringValue: string };
        }>;
      };
    }>;
  };
};

export type ChallengeBasicsFragment = {
  __typename?: 'Challenge';
  id: string;
  title: string;
  goal: number;
  scoreUnit: ScoreUnit;
  enrolled: boolean;
  endDateTime: string;
  startDateTime: string;
  badge?: {
    __typename?: 'Photo';
    id: string;
    preview?: string | null;
    basePath: string;
  } | null;
};

export type UserChallengesFragment = {
  __typename?: 'User';
  challenges: {
    __typename?: 'UserChallengesConnection';
    edges: Array<{
      __typename?: 'UserChallengesEdge';
      score?: number | null;
      node: {
        __typename?: 'Challenge';
        id: string;
        title: string;
        goal: number;
        scoreUnit: ScoreUnit;
        enrolled: boolean;
        endDateTime: string;
        startDateTime: string;
        badge?: {
          __typename?: 'Photo';
          id: string;
          preview?: string | null;
          basePath: string;
        } | null;
      };
    }>;
  };
};

export type LastActivityBasicsFragment = {
  __typename?: 'LastActivity';
  heart?: string | null;
  heartWeb?: string | null;
};

export type UserBasicsFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  createdAt: string;
  botKind?: BotKind | null;
  lastActivity: {
    __typename?: 'LastActivity';
    heart?: string | null;
    heartWeb?: string | null;
  };
  avatar?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
};

export type UserDetailsFragment = {
  __typename?: 'User';
  birthday?: string | null;
  pronouns?: string | null;
  identity?: string | null;
  weight?: number | null;
  height?: number | null;
  workingStyle?: WorkingStyle | null;
  isCoach: boolean;
  clientRelationship?: UserClientRelationship | null;
  hasHighPerformanceCoaching: boolean;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  createdAt: string;
  botKind?: BotKind | null;
  officeLocation?: {
    __typename?: 'CoachLocation';
    id: string;
    name?: string | null;
  } | null;
  botChat?: { __typename?: 'ChatRoom'; id: string } | null;
  lastActivity: {
    __typename?: 'LastActivity';
    heart?: string | null;
    heartWeb?: string | null;
  };
  avatar?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
};

export type CoachClientFragment = {
  __typename?: 'Client';
  id: string;
  videoPlatform?: ClientVideoPlatform | null;
  calendarPlatform?: ClientCalendarPlatform | null;
  name?: string | null;
};

export type AvailabilitySettingsFragment = {
  __typename?: 'CoachData';
  id: string;
  cronofyAuthUrl?: string | null;
  calendarConnected: boolean;
  cronofyElementToken?: string | null;
  inOfficeDays: Array<number>;
  coachingStyle?: CoachingStyle | null;
  videoPlatformConnected?: ClientVideoPlatform | null;
  cronofyConferencingUrl?: string | null;
  externalCalendarConnected?: ClientCalendarPlatform | null;
  cronofyExternalCalendarUrl?: string | null;
  clients: {
    __typename?: 'RootClientsConnection';
    edges: Array<{
      __typename?: 'RootClientsEdge';
      node: {
        __typename?: 'Client';
        id: string;
        videoPlatform?: ClientVideoPlatform | null;
        calendarPlatform?: ClientCalendarPlatform | null;
        name?: string | null;
      };
    }>;
  };
};

export type ReactionFragment = {
  __typename?: 'Reaction';
  id: string;
  kind: ReactionKind;
  user: { __typename?: 'User'; id: string };
};

export type MessagePreviewFragment = {
  __typename?: 'MessagePreview';
  title: string;
  description?: string | null;
  url: string;
  image?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'CreateUserPayload';
    user: {
      __typename?: 'User';
      birthday?: string | null;
      pronouns?: string | null;
      identity?: string | null;
      weight?: number | null;
      height?: number | null;
      workingStyle?: WorkingStyle | null;
      isCoach: boolean;
      clientRelationship?: UserClientRelationship | null;
      hasHighPerformanceCoaching: boolean;
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      createdAt: string;
      botKind?: BotKind | null;
      coachData?: { __typename?: 'CoachData'; type?: CoachType | null } | null;
      officeLocation?: {
        __typename?: 'CoachLocation';
        id: string;
        name?: string | null;
      } | null;
      botChat?: { __typename?: 'ChatRoom'; id: string } | null;
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    };
  };
};

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser: { __typename?: 'DeleteUserPayload'; userId: string };
};

export type AssignHistoricalFitCoachMutationVariables = Exact<{
  coachId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  createdAt: Scalars['ZonedDateTime']['input'];
}>;

export type AssignHistoricalFitCoachMutation = {
  __typename?: 'Mutation';
  assignHistoricalFitCoach: {
    __typename?: 'AssignFitCoachPayload';
    user: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      fitCoachChat?: {
        __typename?: 'ChatRoom';
        id: string;
        lastMessageReceivedAt?: string | null;
      } | null;
    };
    coach: { __typename?: 'User'; id: string };
  };
};

export type UpdateCoachAvailabilityMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  inOfficeDays?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >;
}>;

export type UpdateCoachAvailabilityMutation = {
  __typename?: 'Mutation';
  updateCoachData: {
    __typename?: 'UpdateCoachDataPayload';
    user: {
      __typename?: 'User';
      id: string;
      coachData?: {
        __typename?: 'CoachData';
        inOfficeDays: Array<number>;
      } | null;
    };
  };
};

export type UserAutocompleteDetailsFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  avatar?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
};

export type UserAutocompleteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UserAutocompleteQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
};

export type UnitsPreferenceQueryVariables = Exact<{ [key: string]: never }>;

export type UnitsPreferenceQuery = {
  __typename?: 'Query';
  self?: { __typename?: 'User'; unitsPreference: UnitsPreference } | null;
};

export type SetUnitsPreferenceMutationVariables = Exact<{
  input: UnitsInput;
}>;

export type SetUnitsPreferenceMutation = {
  __typename?: 'Mutation';
  setUnitsPreference: {
    __typename?: 'UnitsPayload';
    user: { __typename?: 'User'; id: string; unitsPreference: UnitsPreference };
  };
};

export type SleepOverviewFragment = {
  __typename?: 'SleepOverview';
  inbed: number;
  awake: number;
  asleep: number;
};

export type HeartRateFragment = { __typename?: 'HeartRate'; min: number };

export type SleepActivityFragment = {
  __typename?: 'SleepActivity';
  id: string;
  sleepEfficiency?: number | null;
  sleepOverview: {
    __typename?: 'SleepOverview';
    inbed: number;
    awake: number;
    asleep: number;
  };
  heartRate?: { __typename?: 'HeartRate'; min: number } | null;
};

export type PracticeAttributionFragment = {
  __typename?: 'AssignedPractice';
  assigningCoach?: {
    __typename?: 'User';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type ProgramAttributionFragment = {
  __typename?: 'AssignedProgram';
  assigningCoach?: {
    __typename?: 'User';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type ArticleSourceFragment = {
  __typename?: 'Article';
  id: string;
  shortDescription?: string | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
};

export type AudioSourceFragment = {
  __typename?: 'Audio';
  id: string;
  shortDescription?: string | null;
};

export type VideoSourceFragment = {
  __typename?: 'Video';
  id: string;
  shortDescription?: string | null;
  author?: string | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
};

export type PhysicalActivitySourceFragment = {
  __typename?: 'PhysicalActivity';
  id: string;
};

export type MboClassSourceFragment = {
  __typename?: 'MboClass';
  id: string;
  description: string;
  staffFirstName?: string | null;
  staffLastName?: string | null;
  location?: { __typename?: 'CoachLocation'; name?: string | null } | null;
};

export type SurveySourceFragment = {
  __typename?: 'Survey';
  id: string;
  surveyIdentifier: string;
};

export type WearableActivitySourceFragment = {
  __typename?: 'WearableActivity';
  id: string;
};

type ActivitySourceArticleFragment = {
  __typename?: 'Article';
  id: string;
  shortDescription?: string | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
};

type ActivitySourceAudioFragment = {
  __typename?: 'Audio';
  id: string;
  shortDescription?: string | null;
};

type ActivitySourceMboClassFragment = {
  __typename?: 'MboClass';
  id: string;
  description: string;
  staffFirstName?: string | null;
  staffLastName?: string | null;
  location?: { __typename?: 'CoachLocation'; name?: string | null } | null;
};

type ActivitySourcePhysicalActivityFragment = {
  __typename?: 'PhysicalActivity';
  id: string;
};

type ActivitySourceSurveyFragment = {
  __typename?: 'Survey';
  id: string;
  surveyIdentifier: string;
};

type ActivitySourceVideoFragment = {
  __typename?: 'Video';
  id: string;
  shortDescription?: string | null;
  author?: string | null;
  contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
};

type ActivitySourceWearableActivityFragment = {
  __typename?: 'WearableActivity';
  id: string;
};

export type ActivitySourceFragment =
  | ActivitySourceArticleFragment
  | ActivitySourceAudioFragment
  | ActivitySourceMboClassFragment
  | ActivitySourcePhysicalActivityFragment
  | ActivitySourceSurveyFragment
  | ActivitySourceVideoFragment
  | ActivitySourceWearableActivityFragment;

export type UserActivityBasicFragment = {
  __typename?: 'UserActivity';
  id: string;
  genre?: string | null;
  type: UserActivityType;
  attributionType: UserActivityAttributionType;
  attributionTitle?: string | null;
  title: string;
  startTime: string;
  endTime: string;
  duration?: number | null;
  distance?: {
    __typename?: 'Distance';
    value?: number | null;
    unit?: DistanceUnit | null;
  } | null;
  source?:
    | {
        __typename: 'Article';
        id: string;
        shortDescription?: string | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
      }
    | { __typename: 'Audio'; id: string; shortDescription?: string | null }
    | {
        __typename: 'MboClass';
        id: string;
        description: string;
        staffFirstName?: string | null;
        staffLastName?: string | null;
        location?: {
          __typename?: 'CoachLocation';
          name?: string | null;
        } | null;
      }
    | { __typename: 'PhysicalActivity'; id: string }
    | { __typename: 'Survey'; id: string; surveyIdentifier: string }
    | {
        __typename: 'Video';
        id: string;
        shortDescription?: string | null;
        author?: string | null;
        contentEquipment: Array<{ __typename?: 'Equipment'; name: string }>;
      }
    | { __typename: 'WearableActivity'; id: string }
    | null;
  activity?:
    | { __typename?: 'ArticleActivity' }
    | { __typename?: 'AudioActivity' }
    | { __typename?: 'MboClassActivity' }
    | { __typename?: 'PhysicalActivity' }
    | {
        __typename?: 'SleepActivity';
        id: string;
        sleepEfficiency?: number | null;
        sleepOverview: {
          __typename?: 'SleepOverview';
          inbed: number;
          awake: number;
          asleep: number;
        };
        heartRate?: { __typename?: 'HeartRate'; min: number } | null;
      }
    | { __typename?: 'VideoActivity' }
    | { __typename?: 'WearableActivity' }
    | null;
  attribution?:
    | {
        __typename?: 'AssignedPractice';
        assigningCoach?: {
          __typename?: 'User';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'AssignedProgram';
        assigningCoach?: {
          __typename?: 'User';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      }
    | null;
};

export type UserActivityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<UserActivityFilter>;
}>;

export type UserActivityQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    hasHighPerformanceCoaching: boolean;
    userActivity: {
      __typename?: 'UserActivityConnection';
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'UserActivityEdge';
        node: {
          __typename?: 'UserActivity';
          id: string;
          genre?: string | null;
          type: UserActivityType;
          attributionType: UserActivityAttributionType;
          attributionTitle?: string | null;
          title: string;
          startTime: string;
          endTime: string;
          duration?: number | null;
          distance?: {
            __typename?: 'Distance';
            value?: number | null;
            unit?: DistanceUnit | null;
          } | null;
          source?:
            | {
                __typename: 'Article';
                id: string;
                shortDescription?: string | null;
                contentEquipment: Array<{
                  __typename?: 'Equipment';
                  name: string;
                }>;
              }
            | {
                __typename: 'Audio';
                id: string;
                shortDescription?: string | null;
              }
            | {
                __typename: 'MboClass';
                id: string;
                description: string;
                staffFirstName?: string | null;
                staffLastName?: string | null;
                location?: {
                  __typename?: 'CoachLocation';
                  name?: string | null;
                } | null;
              }
            | { __typename: 'PhysicalActivity'; id: string }
            | { __typename: 'Survey'; id: string; surveyIdentifier: string }
            | {
                __typename: 'Video';
                id: string;
                shortDescription?: string | null;
                author?: string | null;
                contentEquipment: Array<{
                  __typename?: 'Equipment';
                  name: string;
                }>;
              }
            | { __typename: 'WearableActivity'; id: string }
            | null;
          activity?:
            | { __typename?: 'ArticleActivity' }
            | { __typename?: 'AudioActivity' }
            | { __typename?: 'MboClassActivity' }
            | { __typename?: 'PhysicalActivity' }
            | {
                __typename?: 'SleepActivity';
                id: string;
                sleepEfficiency?: number | null;
                sleepOverview: {
                  __typename?: 'SleepOverview';
                  inbed: number;
                  awake: number;
                  asleep: number;
                };
                heartRate?: { __typename?: 'HeartRate'; min: number } | null;
              }
            | { __typename?: 'VideoActivity' }
            | { __typename?: 'WearableActivity' }
            | null;
          attribution?:
            | {
                __typename?: 'AssignedPractice';
                assigningCoach?: {
                  __typename?: 'User';
                  firstName?: string | null;
                  lastName?: string | null;
                } | null;
              }
            | {
                __typename?: 'AssignedProgram';
                assigningCoach?: {
                  __typename?: 'User';
                  firstName?: string | null;
                  lastName?: string | null;
                } | null;
              }
            | null;
        };
      }>;
    };
  } | null;
};

export type UserActivitySurveyDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UserActivitySurveyDetailsQuery = {
  __typename?: 'Query';
  userActivity?: {
    __typename?: 'UserActivity';
    id: string;
    source?:
      | { __typename?: 'Article' }
      | { __typename?: 'Audio' }
      | { __typename?: 'MboClass' }
      | { __typename?: 'PhysicalActivity' }
      | {
          __typename?: 'Survey';
          id: string;
          surveyName?: string | null;
          surveyIdentifier: string;
          createdAt: string;
          responses: Array<{
            __typename?: 'SurveyResponse';
            id: string;
            question: string;
            questionIdentifier: string;
            subcategory?: string | null;
            numericValue?: number | null;
            response:
              | {
                  __typename?: 'EnumAnswer';
                  choices: Array<string>;
                  enumValue: Array<number>;
                }
              | { __typename?: 'NumberAnswer'; numberValue: number }
              | { __typename?: 'PhotoAnswer' }
              | { __typename?: 'StringAnswer'; stringValue: string };
          }>;
        }
      | { __typename?: 'Video' }
      | { __typename?: 'WearableActivity' }
      | null;
  } | null;
};

export const SurveyTemplateBasicsFragmentDoc = gql`
  fragment SurveyTemplateBasics on SurveyTemplate {
    id
    name
    questions {
      id
      text
    }
  }
`;
export const AnswerDetailsFragmentDoc = gql`
  fragment AnswerDetails on Answer {
    __typename
    ... on StringAnswer {
      stringValue
    }
    ... on NumberAnswer {
      numberValue
    }
    ... on EnumAnswer {
      choices
      enumValue
    }
  }
`;
export const ChoiceDetailsFragmentDoc = gql`
  fragment ChoiceDetails on MultipleChoiceOption {
    text
    description
    optionIdentifier
    textEntry
    score
  }
`;
export const QuestionDetailsFragmentDoc = gql`
  fragment QuestionDetails on Question {
    id
    text
    answer {
      ...AnswerDetails
    }
    questionType
    questionSubtype
    required
    choices {
      ...ChoiceDetails
    }
  }
  ${AnswerDetailsFragmentDoc}
  ${ChoiceDetailsFragmentDoc}
`;
export const SurveyTemplateDetailsFragmentDoc = gql`
  fragment SurveyTemplateDetails on SurveyTemplate {
    ...SurveyTemplateBasics
    questions {
      ...QuestionDetails
    }
  }
  ${SurveyTemplateBasicsFragmentDoc}
  ${QuestionDetailsFragmentDoc}
`;
export const ResponseDetailsFragmentDoc = gql`
  fragment ResponseDetails on SurveyResponse {
    id
    question
    questionIdentifier
    subcategory
    numericValue
    response {
      ... on StringAnswer {
        stringValue
      }
      ... on NumberAnswer {
        numberValue
      }
      ... on EnumAnswer {
        choices
        enumValue
      }
    }
  }
`;
export const AssignedAssessmentDetailsFragmentDoc = gql`
  fragment AssignedAssessmentDetails on AssessmentAssignment {
    id
    score
    createdAt
    completedAt
    survey {
      id
      surveyIdentifier
      responses {
        ...ResponseDetails
      }
    }
    surveyContent {
      id
      title
      subtitle
      contentTime {
        name
      }
    }
    template {
      ...SurveyTemplateDetails
    }
  }
  ${ResponseDetailsFragmentDoc}
  ${SurveyTemplateDetailsFragmentDoc}
`;
export const AssessmentSurveyBasicsFragmentDoc = gql`
  fragment AssessmentSurveyBasics on UserAssessmentSurvey {
    id
    title
    mnmrType
    type
    template {
      ...SurveyTemplateDetails
    }
    assignments(
      input: $assessmentAssignmentsInput
      filter: $assessmentAssignmentsFilter
    ) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...AssignedAssessmentDetails
        }
      }
    }
  }
  ${SurveyTemplateDetailsFragmentDoc}
  ${AssignedAssessmentDetailsFragmentDoc}
`;
export const ChatRoomBasicsFragmentDoc = gql`
  fragment ChatRoomBasics on ChatRoom {
    id
    name
    kind
    app
    lastMessageRead
    lastMessageReceivedAt
  }
`;
export const LastActivityBasicsFragmentDoc = gql`
  fragment LastActivityBasics on LastActivity {
    heart
    heartWeb
  }
`;
export const PhotoBasicsFragmentDoc = gql`
  fragment PhotoBasics on Photo {
    id
    basePath
    preview
  }
`;
export const UserBasicsFragmentDoc = gql`
  fragment UserBasics on User {
    id
    firstName
    lastName
    email
    createdAt
    lastActivity {
      ...LastActivityBasics
    }
    avatar {
      ...PhotoBasics
    }
    botKind
  }
  ${LastActivityBasicsFragmentDoc}
  ${PhotoBasicsFragmentDoc}
`;
export const ChatRoomUsersConnFragmentDoc = gql`
  fragment ChatRoomUsersConn on ChatRoomUsersConnection {
    count
    edges {
      lastMessageRead
      node {
        ...UserBasics
      }
    }
  }
  ${UserBasicsFragmentDoc}
`;
export const MessagePreviewFragmentDoc = gql`
  fragment MessagePreview on MessagePreview {
    title
    description
    url
    image {
      ...PhotoBasics
    }
  }
  ${PhotoBasicsFragmentDoc}
`;
export const ReactionFragmentDoc = gql`
  fragment Reaction on Reaction {
    id
    kind
    user {
      id
    }
  }
`;
export const MessageDetailsFragmentDoc = gql`
  fragment MessageDetails on Message {
    id
    text
    createdAt
    automated
    previews {
      ...MessagePreview
    }
    reactions {
      ...Reaction
    }
    user {
      id
      firstName
      lastName
      botKind
      lastActivity {
        ...LastActivityBasics
      }
      avatar {
        ...PhotoBasics
      }
    }
  }
  ${MessagePreviewFragmentDoc}
  ${ReactionFragmentDoc}
  ${LastActivityBasicsFragmentDoc}
  ${PhotoBasicsFragmentDoc}
`;
export const MessageConnFragmentDoc = gql`
  fragment MessageConn on ChatRoomMessagesConnection {
    pageInfo {
      hasNextPage
      endCursor
    }
    unreadMessageCount
    edges {
      node {
        ...MessageDetails
      }
    }
  }
  ${MessageDetailsFragmentDoc}
`;
export const ChatListDetailsFragmentDoc = gql`
  fragment ChatListDetails on User {
    id
    chatRooms(input: $input, filters: $filters, orderBy: $orderBy) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ChatRoomBasics
          users {
            ...ChatRoomUsersConn
          }
          messages(input: { first: 1 }) {
            ...MessageConn
          }
        }
      }
    }
  }
  ${ChatRoomBasicsFragmentDoc}
  ${ChatRoomUsersConnFragmentDoc}
  ${MessageConnFragmentDoc}
`;
export const NewMessageDetailsFragmentDoc = gql`
  fragment NewMessageDetails on NewMessagePayload {
    chatRoom {
      id
      lastMessageRead
      lastMessageReceivedAt
      messages {
        unreadMessageCount
      }
    }
    message {
      ...MessageDetails
    }
    optimisticId
  }
  ${MessageDetailsFragmentDoc}
`;
export const ChatRoomDetailsFragmentDoc = gql`
  fragment ChatRoomDetails on ChatRoom {
    id
    name
    kind
    app
    lastMessageRead
    lastMessageReceivedAt
    users {
      ...ChatRoomUsersConn
    }
    messages(input: $input) {
      ...MessageConn
    }
  }
  ${ChatRoomUsersConnFragmentDoc}
  ${MessageConnFragmentDoc}
`;
export const PersonalTrainingInfoFragmentDoc = gql`
  fragment PersonalTrainingInfo on User {
    futureSessions: sessions(
      filter: { sessionType: PersonalTraining }
      dateFilter: [Future]
    ) {
      count
    }
    pastSessions: sessions(
      filter: { sessionType: PersonalTraining }
      dateFilter: [Past]
    ) {
      count
    }
    excusedSessions: sessions(
      filter: { sessionType: PersonalTraining, attendance: Excused }
      dateFilter: [Past]
    ) {
      count
    }
    availableSessions: coachSessionCredits {
      count
      rowVersion
    }
  }
`;
export const MemberBookingDetailsFragmentDoc = gql`
  fragment MemberBookingDetails on User {
    id
    avatar {
      ...PhotoBasics
    }
    firstName
    lastName
    lastActivity {
      ...LastActivityBasics
    }
    fitCoach {
      id
    }
    fitCoachChat {
      id
    }
    fitClient {
      id
      usesPersonalTraining
    }
  }
  ${PhotoBasicsFragmentDoc}
  ${LastActivityBasicsFragmentDoc}
`;
export const CoachSessionBasicsFragmentDoc = gql`
  fragment CoachSessionBasics on CoachSession {
    id
    sessionStyle
    sessionType
    sessionSubtype
    attendance
    reason
    additionalInfo
    joinableTime
    meetingUrl
    calendar {
      start
      end
    }
    coach {
      id
      firstName
      lastName
      avatar {
        ...PhotoBasics
      }
    }
    member {
      ...MemberBookingDetails
    }
    location {
      id
      name
    }
    chatRoom {
      id
    }
  }
  ${PhotoBasicsFragmentDoc}
  ${MemberBookingDetailsFragmentDoc}
`;
export const CalendarBufferFragmentDoc = gql`
  fragment CalendarBuffer on CoachData {
    calendarBuffer {
      beforeInMinutes
    }
  }
`;
export const PreferenceDetailsFragmentDoc = gql`
  fragment PreferenceDetails on CoachData {
    ...CalendarBuffer
    cronofyTimezone
    dailySessionLimit
  }
  ${CalendarBufferFragmentDoc}
`;
export const ClientCalendarProfilesBasicsFragmentDoc = gql`
  fragment ClientCalendarProfilesBasics on Client {
    id
    displayName
    calendarPlatform
  }
`;
export const ClientConferencingProfilesBasicsFragmentDoc = gql`
  fragment ClientConferencingProfilesBasics on Client {
    id
    displayName
    calendarPlatform
    videoPlatform
  }
`;
export const CalendarProfileDetailsFragmentDoc = gql`
  fragment CalendarProfileDetails on CalendarProfile {
    profileId
    profileName
    profileConnected
    platform
    providerService
  }
`;
export const ConferencingProfileDetailsFragmentDoc = gql`
  fragment ConferencingProfileDetails on ConferencingProfile {
    providerName
    profileId
    profileName
    profileConnected
    platform
  }
`;
export const ConnectionDetailsFragmentDoc = gql`
  fragment ConnectionDetails on CoachData {
    id
    cronofyTimezone
    clientRequiredCalendarProfiles: clients {
      edges {
        node {
          ...ClientCalendarProfilesBasics
        }
      }
    }
    clientRequiredConferencingProfiles: clients {
      edges {
        node {
          ...ClientConferencingProfilesBasics
        }
      }
    }
    calendarConnected
    calendarProfiles {
      ...CalendarProfileDetails
    }
    conferencingProfiles {
      ...ConferencingProfileDetails
    }
  }
  ${ClientCalendarProfilesBasicsFragmentDoc}
  ${ClientConferencingProfilesBasicsFragmentDoc}
  ${CalendarProfileDetailsFragmentDoc}
  ${ConferencingProfileDetailsFragmentDoc}
`;
export const FitClientBasicsFragmentDoc = gql`
  fragment FitClientBasics on Client {
    id
    name
    usesPersonalTraining
  }
`;
export const CoachTouchPointSessionMetadataFragmentDoc = gql`
  fragment CoachTouchPointSessionMetadata on CoachTouchPointSessionMetadata {
    isCancelled
    isRebooked
    session {
      id
      sessionType
      calendar {
        start
        end
      }
    }
  }
`;
export const CoachTouchPointDetailsFragmentDoc = gql`
  fragment CoachTouchPointDetails on CoachTouchPoint {
    id
    due
    title
    description
    type
    completedAt
    member {
      ...UserBasics
      fitClient {
        ...FitClientBasics
      }
      fitCoachChat {
        id
      }
      fitCoach {
        id
      }
    }
    metadata {
      ...CoachTouchPointSessionMetadata
    }
  }
  ${UserBasicsFragmentDoc}
  ${FitClientBasicsFragmentDoc}
  ${CoachTouchPointSessionMetadataFragmentDoc}
`;
export const CoachTouchPointConnectionFragmentDoc = gql`
  fragment CoachTouchPointConnection on CoachTouchPointConnection {
    count
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        ...CoachTouchPointDetails
      }
    }
  }
  ${CoachTouchPointDetailsFragmentDoc}
`;
export const ContentBasicsFragmentDoc = gql`
  fragment ContentBasics on Content {
    id
    title
    trainingCommitment
    author
    subtitle
    shortDescription
    contentTime {
      name
    }
    __typename
  }
`;
export const ArticleDetailsFragmentDoc = gql`
  fragment ArticleDetails on Article {
    id
    html
    brand
    intensity
    mediaType
    mnmrType
    gameplanMetadata {
      primaryComponent {
        name
        value
      }
      primarySubcomponents {
        name
      }
      secondaryComponent {
        name
      }
      secondarySubcomponents {
        name
      }
      isExperiential
      associatedProject {
        name
      }
    }
    split {
      name
    }
  }
`;
export const AudioDetailsFragmentDoc = gql`
  fragment AudioDetails on Audio {
    id
    audio
    html
    transcript
    brand
    intensity
    gameplanMetadata {
      primaryComponent {
        name
        value
      }
      primarySubcomponents {
        name
      }
      secondaryComponent {
        name
      }
      secondarySubcomponents {
        name
      }
      isExperiential
      associatedProject {
        name
      }
    }
    split {
      name
    }
  }
`;
export const VideoDetailsFragmentDoc = gql`
  fragment VideoDetails on Video {
    id
    html
    video
    brand
    type
    intensity
    intensity
    mediaType
    mnmrType
    gameplanMetadata {
      primaryComponent {
        name
        value
      }
      primarySubcomponents {
        name
      }
      secondaryComponent {
        name
      }
      secondarySubcomponents {
        name
      }
      isExperiential
      associatedProject {
        name
      }
    }
    split {
      name
    }
  }
`;
export const ContentDetailsFragmentDoc = gql`
  fragment ContentDetails on Content {
    ...ContentBasics
    contentLevel {
      name
    }
    contentGenre {
      name
      parent {
        name
      }
    }
    contentEquipment {
      name
    }
    trainingCommitment
    heroPhoto {
      ...PhotoBasics
    }
    ... on Article {
      ...ArticleDetails
    }
    ... on Audio {
      ...AudioDetails
    }
    ... on Video {
      ...VideoDetails
    }
  }
  ${ContentBasicsFragmentDoc}
  ${PhotoBasicsFragmentDoc}
  ${ArticleDetailsFragmentDoc}
  ${AudioDetailsFragmentDoc}
  ${VideoDetailsFragmentDoc}
`;
export const UserPlanBasicsFragmentDoc = gql`
  fragment UserPlanBasics on Plan {
    id
    name
    startDate
    endDate
    targetedPerformanceQualities
  }
`;
export const UserPlanItemDetailsFragmentDoc = gql`
  fragment UserPlanItemDetails on PlanItem {
    __typename
    id
    type
    date
    order
    completedAt
    ... on ExosContentPlanItem {
      contentComponent {
        name
        value
      }
      content {
        ...ContentBasics
      }
    }
    ... on CustomTaskPlanItem {
      contentComponent {
        name
        value
      }
      title
      description
      url
    }
  }
  ${ContentBasicsFragmentDoc}
`;
export const UserPlanDetailsFragmentDoc = gql`
  fragment UserPlanDetails on Plan {
    ...UserPlanBasics
    planItems(input: $planItemsInput) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...UserPlanItemDetails
        }
      }
    }
  }
  ${UserPlanBasicsFragmentDoc}
  ${UserPlanItemDetailsFragmentDoc}
`;
export const CustomPlanActiveDaysBasicsFragmentDoc = gql`
  fragment customPlanActiveDaysBasics on ActiveCustomPlanDay {
    date
    itemCount
    itemCompletedCount
  }
`;
export const UserDetailsFragmentDoc = gql`
  fragment UserDetails on User {
    ...UserBasics
    birthday
    pronouns
    identity
    weight
    height
    workingStyle
    isCoach
    clientRelationship
    officeLocation {
      id
      name
    }
    hasHighPerformanceCoaching
    botChat(bot: methodology) {
      id
    }
  }
  ${UserBasicsFragmentDoc}
`;
export const FitCoachFragmentDoc = gql`
  fragment FitCoach on User {
    fitCoach {
      id
      firstName
      lastName
      avatar {
        ...PhotoBasics
      }
    }
  }
  ${PhotoBasicsFragmentDoc}
`;
export const PracticeProgramDetailsFragmentDoc = gql`
  fragment PracticeProgramDetails on PracticeProgram {
    id
    title
    progress
    skills
    shortDescription
    contentTime {
      name
    }
    createdAt
    completedAt
  }
`;
export const PracticeProgramsDetailsFragmentDoc = gql`
  fragment PracticeProgramsDetails on User {
    practicePrograms {
      edges {
        node {
          ...PracticeProgramDetails
        }
      }
    }
  }
  ${PracticeProgramDetailsFragmentDoc}
`;
export const NoteBasicFragmentDoc = gql`
  fragment NoteBasic on Note {
    id
    title
    content
    createdAt
    updatedAt
    lastUpdatedBy {
      id
      firstName
      lastName
      avatar {
        ...PhotoBasics
      }
    }
    coach {
      id
      firstName
      lastName
      avatar {
        ...PhotoBasics
      }
    }
  }
  ${PhotoBasicsFragmentDoc}
`;
export const MemberNoteFragmentDoc = gql`
  fragment MemberNote on User {
    notes {
      nodes {
        ...NoteBasic
      }
      edges {
        node {
          ...NoteBasic
        }
      }
    }
  }
  ${NoteBasicFragmentDoc}
`;
export const ChallengeBasicsFragmentDoc = gql`
  fragment ChallengeBasics on Challenge {
    id
    title
    goal
    scoreUnit
    enrolled
    endDateTime
    startDateTime
    badge {
      id
      preview
      basePath
    }
  }
`;
export const UserChallengesFragmentDoc = gql`
  fragment UserChallenges on User {
    challenges {
      edges {
        score
        node {
          ...ChallengeBasics
        }
      }
    }
  }
  ${ChallengeBasicsFragmentDoc}
`;
export const SurveyDetailsFragmentDoc = gql`
  fragment SurveyDetails on Survey {
    id
    surveyName
    surveyIdentifier
    createdAt
    responses {
      ...ResponseDetails
    }
  }
  ${ResponseDetailsFragmentDoc}
`;
export const ProfileSurveysFragmentDoc = gql`
  fragment ProfileSurveys on User {
    motivations: surveys(
      input: { first: 1 }
      filter: { surveyGroup: motivations }
    ) {
      edges {
        node {
          ...SurveyDetails
        }
      }
    }
    assessment: surveys(
      input: { first: 1 }
      filter: { surveyGroup: assessment }
    ) {
      edges {
        node {
          ...SurveyDetails
        }
      }
    }
  }
  ${SurveyDetailsFragmentDoc}
`;
export const MembersActivitySummaryFragmentDoc = gql`
  fragment MembersActivitySummary on UserActivitySummary {
    date
    program
    classes
    other
    practice
  }
`;
export const MembersActivityTrendFragmentDoc = gql`
  fragment MembersActivityTrend on User {
    userActivityTrend {
      ...MembersActivitySummary
    }
  }
  ${MembersActivitySummaryFragmentDoc}
`;
export const FitClientFragmentDoc = gql`
  fragment FitClient on User {
    fitClient {
      ...FitClientBasics
      dietitians: coaches(type: Dietitian) {
        count
      }
    }
  }
  ${FitClientBasicsFragmentDoc}
`;
export const UserCurrentPracticeFragmentDoc = gql`
  fragment UserCurrentPractice on User {
    currentPractice {
      createdAt
      completedAt
      practiceProgram {
        id
        title
        progress
        skills
        shortDescription
        contentTime {
          name
        }
      }
    }
  }
`;
export const UserProgramSectionContentDetailsFragmentDoc = gql`
  fragment UserProgramSectionContentDetails on ContentEdge {
    completed
    progress
    node {
      id
    }
  }
`;
export const UserProgramSectionDetailsFragmentDoc = gql`
  fragment UserProgramSectionDetails on Program {
    currentSection {
      id
      title
      contents {
        completedCount
        count
        edges {
          ...UserProgramSectionContentDetails
        }
      }
    }
  }
  ${UserProgramSectionContentDetailsFragmentDoc}
`;
export const UserCurrentProgramDetailsFragmentDoc = gql`
  fragment UserCurrentProgramDetails on Program {
    id
    contentTime {
      name
    }
    shortDescription
    title
    progress
    skills
    trainingCommitment
    contentLevel {
      name
    }
    sections {
      count
    }
    ...UserProgramSectionDetails
  }
  ${UserProgramSectionDetailsFragmentDoc}
`;
export const UserCurrentProgramFragmentDoc = gql`
  fragment UserCurrentProgram on User {
    currentProgram {
      completedAt
      createdAt
      program {
        ...UserCurrentProgramDetails
      }
    }
  }
  ${UserCurrentProgramDetailsFragmentDoc}
`;
export const UserAssignedProgramsConnectionFragmentDoc = gql`
  fragment UserAssignedProgramsConnection on User {
    assignedPrograms {
      edges {
        node {
          program {
            id
            progress
          }
        }
      }
    }
  }
`;
export const MemberGoalFragmentDoc = gql`
  fragment MemberGoal on User {
    goal {
      id
      type
      description
      coachNote
    }
  }
`;
export const MemberOnboardingMotivationsFragmentDoc = gql`
  fragment MemberOnboardingMotivations on User {
    strengths {
      id
      label
    }
    opportunities {
      id
      label
    }
  }
`;
export const MemberDetailsFragmentDoc = gql`
  fragment MemberDetails on User {
    ...UserDetails
    ...FitCoach
    ...PracticeProgramsDetails
    ...MemberNote
    ...UserChallenges
    ...ProfileSurveys
    ...MembersActivityTrend
    ...FitClient
    ...UserCurrentPractice
    ...UserCurrentProgram
    ...UserAssignedProgramsConnection
    ...MemberGoal
    ...MemberOnboardingMotivations
    fitCoachChat {
      ...ChatRoomBasics
    }
  }
  ${UserDetailsFragmentDoc}
  ${FitCoachFragmentDoc}
  ${PracticeProgramsDetailsFragmentDoc}
  ${MemberNoteFragmentDoc}
  ${UserChallengesFragmentDoc}
  ${ProfileSurveysFragmentDoc}
  ${MembersActivityTrendFragmentDoc}
  ${FitClientFragmentDoc}
  ${UserCurrentPracticeFragmentDoc}
  ${UserCurrentProgramFragmentDoc}
  ${UserAssignedProgramsConnectionFragmentDoc}
  ${MemberGoalFragmentDoc}
  ${MemberOnboardingMotivationsFragmentDoc}
  ${ChatRoomBasicsFragmentDoc}
`;
export const UserEmergencyDetailsFragmentDoc = gql`
  fragment UserEmergencyDetails on User {
    emergencyContactDetails {
      emergencyContact {
        firstName
        lastName
        phoneNumber
      }
      address {
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        localityOrCity
        stateOrDistrictOrProvince
        postalOrZipCode
        countryCode
      }
      updatedAt
    }
  }
`;
export const SleepOverviewFragmentDoc = gql`
  fragment SleepOverview on SleepOverview {
    inbed
    awake
    asleep
  }
`;
export const HeartRateFragmentDoc = gql`
  fragment HeartRate on HeartRate {
    min
  }
`;
export const SleepActivityFragmentDoc = gql`
  fragment SleepActivity on SleepActivity {
    id
    sleepEfficiency
    sleepOverview {
      ...SleepOverview
    }
    heartRate {
      ...HeartRate
    }
  }
  ${SleepOverviewFragmentDoc}
  ${HeartRateFragmentDoc}
`;
export const PracticeAttributionFragmentDoc = gql`
  fragment PracticeAttribution on AssignedPractice {
    assigningCoach {
      firstName
      lastName
    }
  }
`;
export const ProgramAttributionFragmentDoc = gql`
  fragment ProgramAttribution on AssignedProgram {
    assigningCoach {
      firstName
      lastName
    }
  }
`;
export const ArticleSourceFragmentDoc = gql`
  fragment ArticleSource on Article {
    id
    shortDescription
    contentEquipment {
      name
    }
  }
`;
export const AudioSourceFragmentDoc = gql`
  fragment AudioSource on Audio {
    id
    shortDescription
  }
`;
export const VideoSourceFragmentDoc = gql`
  fragment VideoSource on Video {
    id
    shortDescription
    contentEquipment {
      name
    }
    author
  }
`;
export const PhysicalActivitySourceFragmentDoc = gql`
  fragment PhysicalActivitySource on PhysicalActivity {
    id
  }
`;
export const MboClassSourceFragmentDoc = gql`
  fragment MboClassSource on MboClass {
    id
    location {
      name
    }
    description
    staffFirstName
    staffLastName
  }
`;
export const SurveySourceFragmentDoc = gql`
  fragment SurveySource on Survey {
    id
    surveyIdentifier
  }
`;
export const WearableActivitySourceFragmentDoc = gql`
  fragment WearableActivitySource on WearableActivity {
    id
  }
`;
export const ActivitySourceFragmentDoc = gql`
  fragment ActivitySource on ActivitySource {
    ...ArticleSource
    ...AudioSource
    ...VideoSource
    ...PhysicalActivitySource
    ...MboClassSource
    ...SurveySource
    ...WearableActivitySource
  }
  ${ArticleSourceFragmentDoc}
  ${AudioSourceFragmentDoc}
  ${VideoSourceFragmentDoc}
  ${PhysicalActivitySourceFragmentDoc}
  ${MboClassSourceFragmentDoc}
  ${SurveySourceFragmentDoc}
  ${WearableActivitySourceFragmentDoc}
`;
export const UserActivityBasicFragmentDoc = gql`
  fragment UserActivityBasic on UserActivity {
    id
    genre
    type
    attributionType
    attributionTitle
    title
    startTime
    endTime
    distance {
      value
      unit
    }
    duration
    source {
      __typename
      ... on Survey {
        id
        surveyIdentifier
      }
    }
    activity {
      ...SleepActivity
    }
    attribution {
      ... on AssignedPractice {
        ...PracticeAttribution
      }
      ... on AssignedProgram {
        ...ProgramAttribution
      }
    }
    source {
      ...ActivitySource
    }
  }
  ${SleepActivityFragmentDoc}
  ${PracticeAttributionFragmentDoc}
  ${ProgramAttributionFragmentDoc}
  ${ActivitySourceFragmentDoc}
`;
export const MemberUserActivityFragmentDoc = gql`
  fragment MemberUserActivity on User {
    userActivity {
      nodes {
        ...UserActivityBasic
      }
    }
  }
  ${UserActivityBasicFragmentDoc}
`;
export const MembersLocationFragmentDoc = gql`
  fragment MembersLocation on User {
    workingStyle
    officeLocation {
      name
    }
  }
`;
export const CoachUserChatFragmentDoc = gql`
  fragment CoachUserChat on ChatRoom {
    id
    lastMessageRead
    lastMessageReceivedAt
    kind
    messages(input: { first: 1 }) {
      ...MessageConn
    }
  }
  ${MessageConnFragmentDoc}
`;
export const LastUserActivityDetailFragmentDoc = gql`
  fragment LastUserActivityDetail on UserActivity {
    id
    type
    title
    startTime
    endTime
    duration
    distance {
      value
      unit
    }
    activity {
      __typename
      ... on SleepActivity {
        sleepOverview {
          asleep
        }
      }
    }
  }
`;
export const LastUserActivityFragmentDoc = gql`
  fragment LastUserActivity on User {
    lastUserActivity: userActivity(input: { first: 1 }) {
      edges {
        node {
          ...LastUserActivityDetail
        }
      }
    }
  }
  ${LastUserActivityDetailFragmentDoc}
`;
export const MemberBasicsFragmentDoc = gql`
  fragment MemberBasics on User {
    ...UserBasics
    fitCoachChat {
      ...CoachUserChat
    }
    ...MembersActivityTrend
    ...LastUserActivity
    isStarred
  }
  ${UserBasicsFragmentDoc}
  ${CoachUserChatFragmentDoc}
  ${MembersActivityTrendFragmentDoc}
  ${LastUserActivityFragmentDoc}
`;
export const MembersConnectionFragmentDoc = gql`
  fragment MembersConnection on CoachUsersConnection {
    edges {
      node {
        ...MemberBasics
      }
    }
    count
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${MemberBasicsFragmentDoc}
`;
export const CoachMemberListDetailsFragmentDoc = gql`
  fragment CoachMemberListDetails on User {
    id
    firstName
    allCoachUsers: coachData {
      id
      coachUsers {
        count
      }
    }
    coachData {
      id
      coachUsers(filter: $membersFilter, orderBy: [$orderBy], input: $input) {
        ...MembersConnection
      }
    }
  }
  ${MembersConnectionFragmentDoc}
`;
export const CoachMemberDataFragmentDoc = gql`
  fragment CoachMemberData on User {
    ...UserBasics
    fitClient {
      ...FitClientBasics
    }
  }
  ${UserBasicsFragmentDoc}
  ${FitClientBasicsFragmentDoc}
`;
export const ProgramContentDetailsFragmentDoc = gql`
  fragment ProgramContentDetails on Content {
    id
    title
    shortDescription
    contentTime {
      name
    }
    contentLevel {
      name
    }
    ... on Program {
      skills
    }
  }
`;
export const QuestionBasicsFragmentDoc = gql`
  fragment QuestionBasics on Question {
    id
    text
    choices {
      text
      optionIdentifier
    }
  }
`;
export const SurveyResponseDetailFragmentDoc = gql`
  fragment SurveyResponseDetail on SurveyResponse {
    questionIdentifier
    response {
      ... on StringAnswer {
        stringValue
      }
      ... on EnumAnswer {
        enumValue
      }
      ... on NumberAnswer {
        numberValue
      }
    }
  }
`;
export const SurveyQuestionFragmentDoc = gql`
  fragment SurveyQuestion on Question {
    id
    text
    questionType
    choices {
      text
      optionIdentifier
      score
    }
  }
`;
export const InAppSurveyFragmentDoc = gql`
  fragment InAppSurvey on InAppSurvey {
    surveyTemplate {
      id
      questions {
        ...SurveyQuestion
      }
    }
  }
  ${SurveyQuestionFragmentDoc}
`;
export const ProgramDetailsFragmentDoc = gql`
  fragment ProgramDetails on Program {
    id
    title
    progress
    sections {
      count
      edges {
        node {
          id
          contents {
            edges {
              node {
                __typename
                id
                ... on InAppSurvey {
                  ...InAppSurvey
                }
              }
            }
          }
        }
      }
    }
  }
  ${InAppSurveyFragmentDoc}
`;
export const PracticeProgramContentDetailsFragmentDoc = gql`
  fragment PracticeProgramContentDetails on PracticeProgram {
    id
    title
    progress
    sections {
      count
      edges {
        node {
          id
          contents {
            edges {
              node {
                __typename
                id
                ... on InAppSurvey {
                  ...InAppSurvey
                }
              }
            }
          }
        }
      }
    }
  }
  ${InAppSurveyFragmentDoc}
`;
export const ActivityGenreFragmentDoc = gql`
  fragment ActivityGenre on ActivityGenre {
    id
    canHaveDistance
  }
`;
export const E2EActivityFragmentDoc = gql`
  fragment E2EActivity on Activity {
    id
    title
    type
  }
`;
export const E2ESurveyFragmentDoc = gql`
  fragment E2ESurvey on Survey {
    id
    surveyName
    responses {
      question
    }
  }
`;
export const E2ETestSessionFragmentDoc = gql`
  fragment E2ETestSession on CoachSession {
    id
    calendar {
      start
    }
  }
`;
export const DomainBasicsFragmentDoc = gql`
  fragment DomainBasics on Domain {
    id
    domain
    client {
      id
      name
    }
  }
`;
export const CoachDataClientBasicsFragmentDoc = gql`
  fragment CoachDataClientBasics on Client {
    id
    displayName
    usesPersonalTraining
  }
`;
export const CoachDataBasicsFragmentDoc = gql`
  fragment CoachDataBasics on CoachData {
    id
    type
    coachingStyle
    inOfficeDays
    clients {
      edges {
        node {
          ...CoachDataClientBasics
        }
      }
    }
  }
  ${CoachDataClientBasicsFragmentDoc}
`;
export const SelfBasicsFragmentDoc = gql`
  fragment SelfBasics on User {
    ...UserDetails
    isAdmin
    isCoach
    coachData {
      ...CoachDataBasics
    }
  }
  ${UserDetailsFragmentDoc}
  ${CoachDataBasicsFragmentDoc}
`;
export const CoachLocationDetailsFragmentDoc = gql`
  fragment CoachLocationDetails on CoachData {
    locations {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
export const AvailabilityCalendarTimeFragmentDoc = gql`
  fragment AvailabilityCalendarTime on Calendar {
    start
    end
  }
`;
export const CoachAvailabilityFragmentDoc = gql`
  fragment CoachAvailability on CoachData {
    availability(
      start: $availabilityStart
      duration: 30
      interval: 30
      sessionStyle: $coachSessionStyle
      bypassOneDayDelay: $bypassOneDayDelay
    ) {
      edges {
        node {
          ...AvailabilityCalendarTime
        }
      }
    }
  }
  ${AvailabilityCalendarTimeFragmentDoc}
`;
export const SurveyBasicsFragmentDoc = gql`
  fragment SurveyBasics on Survey {
    id
    surveyIdentifier
    surveyName
    createdAt
  }
`;
export const CoachClientFragmentDoc = gql`
  fragment CoachClient on Client {
    id
    videoPlatform
    calendarPlatform
    name
  }
`;
export const AvailabilitySettingsFragmentDoc = gql`
  fragment AvailabilitySettings on CoachData {
    id
    cronofyAuthUrl(returnUrl: $url)
    calendarConnected
    cronofyElementToken
    inOfficeDays
    coachingStyle
    videoPlatformConnected
    cronofyConferencingUrl(returnUrl: $url)
    externalCalendarConnected
    cronofyExternalCalendarUrl(returnUrl: $url)
    clients {
      edges {
        node {
          ...CoachClient
        }
      }
    }
  }
  ${CoachClientFragmentDoc}
`;
export const UserAutocompleteDetailsFragmentDoc = gql`
  fragment UserAutocompleteDetails on User {
    id
    firstName
    lastName
    avatar {
      ...PhotoBasics
    }
  }
  ${PhotoBasicsFragmentDoc}
`;
export const UserAvailableAssessmentsDocument = gql`
  query userAvailableAssessments(
    $memberId: ID!
    $assessmentAssignmentsFilter: UserAssessmentAssignmentsFilter
    $assessmentAssignmentsInput: ConnectionInput
  ) {
    user(id: $memberId) {
      id
      assessmentSurveys {
        edges {
          node {
            ...AssessmentSurveyBasics
          }
        }
      }
    }
  }
  ${AssessmentSurveyBasicsFragmentDoc}
`;

/**
 * __useUserAvailableAssessmentsQuery__
 *
 * To run a query within a React component, call `useUserAvailableAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAvailableAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAvailableAssessmentsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      assessmentAssignmentsFilter: // value for 'assessmentAssignmentsFilter'
 *      assessmentAssignmentsInput: // value for 'assessmentAssignmentsInput'
 *   },
 * });
 */
export function useUserAvailableAssessmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAvailableAssessmentsQuery,
    UserAvailableAssessmentsQueryVariables
  > &
    (
      | { variables: UserAvailableAssessmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserAvailableAssessmentsQuery,
    UserAvailableAssessmentsQueryVariables
  >(UserAvailableAssessmentsDocument, options);
}
export function useUserAvailableAssessmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAvailableAssessmentsQuery,
    UserAvailableAssessmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserAvailableAssessmentsQuery,
    UserAvailableAssessmentsQueryVariables
  >(UserAvailableAssessmentsDocument, options);
}
export function useUserAvailableAssessmentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserAvailableAssessmentsQuery,
        UserAvailableAssessmentsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserAvailableAssessmentsQuery,
    UserAvailableAssessmentsQueryVariables
  >(UserAvailableAssessmentsDocument, options);
}
export type UserAvailableAssessmentsQueryHookResult = ReturnType<
  typeof useUserAvailableAssessmentsQuery
>;
export type UserAvailableAssessmentsLazyQueryHookResult = ReturnType<
  typeof useUserAvailableAssessmentsLazyQuery
>;
export type UserAvailableAssessmentsSuspenseQueryHookResult = ReturnType<
  typeof useUserAvailableAssessmentsSuspenseQuery
>;
export type UserAvailableAssessmentsQueryResult = Apollo.QueryResult<
  UserAvailableAssessmentsQuery,
  UserAvailableAssessmentsQueryVariables
>;
export const UserAssessmentSurveyDocument = gql`
  query userAssessmentSurvey(
    $surveyContentId: ID!
    $memberId: ID!
    $assessmentAssignmentsInput: ConnectionInput
    $assessmentAssignmentsFilter: UserAssessmentAssignmentsFilter
  ) {
    userAssessmentSurvey(surveyContentId: $surveyContentId, userId: $memberId) {
      ...AssessmentSurveyBasics
    }
  }
  ${AssessmentSurveyBasicsFragmentDoc}
`;

/**
 * __useUserAssessmentSurveyQuery__
 *
 * To run a query within a React component, call `useUserAssessmentSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAssessmentSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAssessmentSurveyQuery({
 *   variables: {
 *      surveyContentId: // value for 'surveyContentId'
 *      memberId: // value for 'memberId'
 *      assessmentAssignmentsInput: // value for 'assessmentAssignmentsInput'
 *      assessmentAssignmentsFilter: // value for 'assessmentAssignmentsFilter'
 *   },
 * });
 */
export function useUserAssessmentSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAssessmentSurveyQuery,
    UserAssessmentSurveyQueryVariables
  > &
    (
      | { variables: UserAssessmentSurveyQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserAssessmentSurveyQuery,
    UserAssessmentSurveyQueryVariables
  >(UserAssessmentSurveyDocument, options);
}
export function useUserAssessmentSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAssessmentSurveyQuery,
    UserAssessmentSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserAssessmentSurveyQuery,
    UserAssessmentSurveyQueryVariables
  >(UserAssessmentSurveyDocument, options);
}
export function useUserAssessmentSurveySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserAssessmentSurveyQuery,
        UserAssessmentSurveyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserAssessmentSurveyQuery,
    UserAssessmentSurveyQueryVariables
  >(UserAssessmentSurveyDocument, options);
}
export type UserAssessmentSurveyQueryHookResult = ReturnType<
  typeof useUserAssessmentSurveyQuery
>;
export type UserAssessmentSurveyLazyQueryHookResult = ReturnType<
  typeof useUserAssessmentSurveyLazyQuery
>;
export type UserAssessmentSurveySuspenseQueryHookResult = ReturnType<
  typeof useUserAssessmentSurveySuspenseQuery
>;
export type UserAssessmentSurveyQueryResult = Apollo.QueryResult<
  UserAssessmentSurveyQuery,
  UserAssessmentSurveyQueryVariables
>;
export const AssignAssessmentsDocument = gql`
  mutation assignAssessments($input: AssignAssessmentsInput!) {
    assignAssessments(input: $input) {
      assessments {
        ...AssignedAssessmentDetails
      }
    }
  }
  ${AssignedAssessmentDetailsFragmentDoc}
`;
export type AssignAssessmentsMutationFn = Apollo.MutationFunction<
  AssignAssessmentsMutation,
  AssignAssessmentsMutationVariables
>;

/**
 * __useAssignAssessmentsMutation__
 *
 * To run a mutation, you first call `useAssignAssessmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAssessmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAssessmentsMutation, { data, loading, error }] = useAssignAssessmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignAssessmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignAssessmentsMutation,
    AssignAssessmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignAssessmentsMutation,
    AssignAssessmentsMutationVariables
  >(AssignAssessmentsDocument, options);
}
export type AssignAssessmentsMutationHookResult = ReturnType<
  typeof useAssignAssessmentsMutation
>;
export type AssignAssessmentsMutationResult =
  Apollo.MutationResult<AssignAssessmentsMutation>;
export type AssignAssessmentsMutationOptions = Apollo.BaseMutationOptions<
  AssignAssessmentsMutation,
  AssignAssessmentsMutationVariables
>;
export const DeleteAssignedAssessmentDocument = gql`
  mutation deleteAssignedAssessment($assignmentId: ID!) {
    deleteAssignedAssessment(assignmentId: $assignmentId) {
      id
    }
  }
`;
export type DeleteAssignedAssessmentMutationFn = Apollo.MutationFunction<
  DeleteAssignedAssessmentMutation,
  DeleteAssignedAssessmentMutationVariables
>;

/**
 * __useDeleteAssignedAssessmentMutation__
 *
 * To run a mutation, you first call `useDeleteAssignedAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssignedAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssignedAssessmentMutation, { data, loading, error }] = useDeleteAssignedAssessmentMutation({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useDeleteAssignedAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssignedAssessmentMutation,
    DeleteAssignedAssessmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAssignedAssessmentMutation,
    DeleteAssignedAssessmentMutationVariables
  >(DeleteAssignedAssessmentDocument, options);
}
export type DeleteAssignedAssessmentMutationHookResult = ReturnType<
  typeof useDeleteAssignedAssessmentMutation
>;
export type DeleteAssignedAssessmentMutationResult =
  Apollo.MutationResult<DeleteAssignedAssessmentMutation>;
export type DeleteAssignedAssessmentMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteAssignedAssessmentMutation,
    DeleteAssignedAssessmentMutationVariables
  >;
export const ChatDocument = gql`
  query chat(
    $chatId: ID
    $userIds: [String!]
    $input: ConnectionInput
    $skipSelf: Boolean! = false
  ) {
    self @skip(if: $skipSelf) {
      ...UserDetails
    }
    chatRoom(id: $chatId, userIds: $userIds) {
      ...ChatRoomDetails
    }
  }
  ${UserDetailsFragmentDoc}
  ${ChatRoomDetailsFragmentDoc}
`;

/**
 * __useChatQuery__
 *
 * To run a query within a React component, call `useChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      userIds: // value for 'userIds'
 *      input: // value for 'input'
 *      skipSelf: // value for 'skipSelf'
 *   },
 * });
 */
export function useChatQuery(
  baseOptions?: Apollo.QueryHookOptions<ChatQuery, ChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatQuery, ChatQueryVariables>(ChatDocument, options);
}
export function useChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatQuery, ChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatQuery, ChatQueryVariables>(
    ChatDocument,
    options,
  );
}
export function useChatSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ChatQuery, ChatQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ChatQuery, ChatQueryVariables>(
    ChatDocument,
    options,
  );
}
export type ChatQueryHookResult = ReturnType<typeof useChatQuery>;
export type ChatLazyQueryHookResult = ReturnType<typeof useChatLazyQuery>;
export type ChatSuspenseQueryHookResult = ReturnType<
  typeof useChatSuspenseQuery
>;
export type ChatQueryResult = Apollo.QueryResult<ChatQuery, ChatQueryVariables>;
export const ChatsDocument = gql`
  query chats(
    $input: ConnectionInput
    $filters: ChatRoomsFilter
    $orderBy: [ChatRoomsOrderBy!]
  ) {
    self {
      ...ChatListDetails
    }
  }
  ${ChatListDetailsFragmentDoc}
`;

/**
 * __useChatsQuery__
 *
 * To run a query within a React component, call `useChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useChatsQuery(
  baseOptions?: Apollo.QueryHookOptions<ChatsQuery, ChatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatsQuery, ChatsQueryVariables>(
    ChatsDocument,
    options,
  );
}
export function useChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatsQuery, ChatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatsQuery, ChatsQueryVariables>(
    ChatsDocument,
    options,
  );
}
export function useChatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ChatsQuery, ChatsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ChatsQuery, ChatsQueryVariables>(
    ChatsDocument,
    options,
  );
}
export type ChatsQueryHookResult = ReturnType<typeof useChatsQuery>;
export type ChatsLazyQueryHookResult = ReturnType<typeof useChatsLazyQuery>;
export type ChatsSuspenseQueryHookResult = ReturnType<
  typeof useChatsSuspenseQuery
>;
export type ChatsQueryResult = Apollo.QueryResult<
  ChatsQuery,
  ChatsQueryVariables
>;
export const CreateChatDocument = gql`
  mutation createChat($input: CreateChatRoomInput!) {
    createChatRoom(input: $input) {
      chatRoom {
        ...ChatRoomBasics
      }
    }
  }
  ${ChatRoomBasicsFragmentDoc}
`;
export type CreateChatMutationFn = Apollo.MutationFunction<
  CreateChatMutation,
  CreateChatMutationVariables
>;

/**
 * __useCreateChatMutation__
 *
 * To run a mutation, you first call `useCreateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMutation, { data, loading, error }] = useCreateChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChatMutation,
    CreateChatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChatMutation, CreateChatMutationVariables>(
    CreateChatDocument,
    options,
  );
}
export type CreateChatMutationHookResult = ReturnType<
  typeof useCreateChatMutation
>;
export type CreateChatMutationResult =
  Apollo.MutationResult<CreateChatMutation>;
export type CreateChatMutationOptions = Apollo.BaseMutationOptions<
  CreateChatMutation,
  CreateChatMutationVariables
>;
export const MethodologyBotChatDocument = gql`
  query methodologyBotChat {
    self {
      id
      botChat(bot: methodology) {
        id
      }
    }
  }
`;

/**
 * __useMethodologyBotChatQuery__
 *
 * To run a query within a React component, call `useMethodologyBotChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useMethodologyBotChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMethodologyBotChatQuery({
 *   variables: {
 *   },
 * });
 */
export function useMethodologyBotChatQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MethodologyBotChatQuery,
    MethodologyBotChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MethodologyBotChatQuery,
    MethodologyBotChatQueryVariables
  >(MethodologyBotChatDocument, options);
}
export function useMethodologyBotChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MethodologyBotChatQuery,
    MethodologyBotChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MethodologyBotChatQuery,
    MethodologyBotChatQueryVariables
  >(MethodologyBotChatDocument, options);
}
export function useMethodologyBotChatSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MethodologyBotChatQuery,
        MethodologyBotChatQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MethodologyBotChatQuery,
    MethodologyBotChatQueryVariables
  >(MethodologyBotChatDocument, options);
}
export type MethodologyBotChatQueryHookResult = ReturnType<
  typeof useMethodologyBotChatQuery
>;
export type MethodologyBotChatLazyQueryHookResult = ReturnType<
  typeof useMethodologyBotChatLazyQuery
>;
export type MethodologyBotChatSuspenseQueryHookResult = ReturnType<
  typeof useMethodologyBotChatSuspenseQuery
>;
export type MethodologyBotChatQueryResult = Apollo.QueryResult<
  MethodologyBotChatQuery,
  MethodologyBotChatQueryVariables
>;
export const NewMessagesDocument = gql`
  subscription newMessages($chatId: ID) {
    newMessages(chatId: $chatId) {
      ...NewMessageDetails
    }
  }
  ${NewMessageDetailsFragmentDoc}
`;

/**
 * __useNewMessagesSubscription__
 *
 * To run a query within a React component, call `useNewMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMessagesSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useNewMessagesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewMessagesSubscription,
    NewMessagesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewMessagesSubscription,
    NewMessagesSubscriptionVariables
  >(NewMessagesDocument, options);
}
export type NewMessagesSubscriptionHookResult = ReturnType<
  typeof useNewMessagesSubscription
>;
export type NewMessagesSubscriptionResult =
  Apollo.SubscriptionResult<NewMessagesSubscription>;
export const ChatRoomUpdateDocument = gql`
  subscription chatRoomUpdate($chatRoomId: ID) {
    chatRoomUpdate(chatId: $chatRoomId) {
      id
      lastMessageRead
      app
      messages {
        unreadMessageCount
      }
    }
  }
`;

/**
 * __useChatRoomUpdateSubscription__
 *
 * To run a query within a React component, call `useChatRoomUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatRoomUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatRoomUpdateSubscription({
 *   variables: {
 *      chatRoomId: // value for 'chatRoomId'
 *   },
 * });
 */
export function useChatRoomUpdateSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ChatRoomUpdateSubscription,
    ChatRoomUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ChatRoomUpdateSubscription,
    ChatRoomUpdateSubscriptionVariables
  >(ChatRoomUpdateDocument, options);
}
export type ChatRoomUpdateSubscriptionHookResult = ReturnType<
  typeof useChatRoomUpdateSubscription
>;
export type ChatRoomUpdateSubscriptionResult =
  Apollo.SubscriptionResult<ChatRoomUpdateSubscription>;
export const NewTotalUnreadMessageCountDocument = gql`
  subscription newTotalUnreadMessageCount(
    $kinds: [ChatRoomKind!]
    $apps: [App!]
  ) {
    newTotalUnreadMessageCount(kinds: $kinds, apps: $apps)
  }
`;

/**
 * __useNewTotalUnreadMessageCountSubscription__
 *
 * To run a query within a React component, call `useNewTotalUnreadMessageCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewTotalUnreadMessageCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewTotalUnreadMessageCountSubscription({
 *   variables: {
 *      kinds: // value for 'kinds'
 *      apps: // value for 'apps'
 *   },
 * });
 */
export function useNewTotalUnreadMessageCountSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewTotalUnreadMessageCountSubscription,
    NewTotalUnreadMessageCountSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewTotalUnreadMessageCountSubscription,
    NewTotalUnreadMessageCountSubscriptionVariables
  >(NewTotalUnreadMessageCountDocument, options);
}
export type NewTotalUnreadMessageCountSubscriptionHookResult = ReturnType<
  typeof useNewTotalUnreadMessageCountSubscription
>;
export type NewTotalUnreadMessageCountSubscriptionResult =
  Apollo.SubscriptionResult<NewTotalUnreadMessageCountSubscription>;
export const SendMessageDocument = gql`
  mutation sendMessage($chatId: ID!, $text: String!, $optimisticId: ID) {
    sendMessage(chatId: $chatId, text: $text, optimisticId: $optimisticId) {
      ...MessageDetails
    }
  }
  ${MessageDetailsFragmentDoc}
`;
export type SendMessageMutationFn = Apollo.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      text: // value for 'text'
 *      optimisticId: // value for 'optimisticId'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMessageMutation,
    SendMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(
    SendMessageDocument,
    options,
  );
}
export type SendMessageMutationHookResult = ReturnType<
  typeof useSendMessageMutation
>;
export type SendMessageMutationResult =
  Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export const AddReactionDocument = gql`
  mutation addReaction($messageId: ID!, $kind: ReactionKind!) {
    addReaction(input: { messageId: $messageId, kind: $kind }) {
      id
      reactions {
        ...Reaction
      }
    }
  }
  ${ReactionFragmentDoc}
`;
export type AddReactionMutationFn = Apollo.MutationFunction<
  AddReactionMutation,
  AddReactionMutationVariables
>;

/**
 * __useAddReactionMutation__
 *
 * To run a mutation, you first call `useAddReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReactionMutation, { data, loading, error }] = useAddReactionMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useAddReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddReactionMutation,
    AddReactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddReactionMutation, AddReactionMutationVariables>(
    AddReactionDocument,
    options,
  );
}
export type AddReactionMutationHookResult = ReturnType<
  typeof useAddReactionMutation
>;
export type AddReactionMutationResult =
  Apollo.MutationResult<AddReactionMutation>;
export type AddReactionMutationOptions = Apollo.BaseMutationOptions<
  AddReactionMutation,
  AddReactionMutationVariables
>;
export const RemoveReactionDocument = gql`
  mutation removeReaction($reactionId: ID!) {
    removeReaction(reactionId: $reactionId) {
      id
      reactions {
        ...Reaction
      }
    }
  }
  ${ReactionFragmentDoc}
`;
export type RemoveReactionMutationFn = Apollo.MutationFunction<
  RemoveReactionMutation,
  RemoveReactionMutationVariables
>;

/**
 * __useRemoveReactionMutation__
 *
 * To run a mutation, you first call `useRemoveReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReactionMutation, { data, loading, error }] = useRemoveReactionMutation({
 *   variables: {
 *      reactionId: // value for 'reactionId'
 *   },
 * });
 */
export function useRemoveReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveReactionMutation,
    RemoveReactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveReactionMutation,
    RemoveReactionMutationVariables
  >(RemoveReactionDocument, options);
}
export type RemoveReactionMutationHookResult = ReturnType<
  typeof useRemoveReactionMutation
>;
export type RemoveReactionMutationResult =
  Apollo.MutationResult<RemoveReactionMutation>;
export type RemoveReactionMutationOptions = Apollo.BaseMutationOptions<
  RemoveReactionMutation,
  RemoveReactionMutationVariables
>;
export const MarkChatReadDocument = gql`
  mutation markChatRead($chatId: ID!, $lastMessageRead: ZonedDateTime!) {
    markChatRead(chatId: $chatId, lastMessageRead: $lastMessageRead) {
      id
      lastMessageRead
      lastMessageReceivedAt
      messages {
        unreadMessageCount
      }
    }
  }
`;
export type MarkChatReadMutationFn = Apollo.MutationFunction<
  MarkChatReadMutation,
  MarkChatReadMutationVariables
>;

/**
 * __useMarkChatReadMutation__
 *
 * To run a mutation, you first call `useMarkChatReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkChatReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markChatReadMutation, { data, loading, error }] = useMarkChatReadMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      lastMessageRead: // value for 'lastMessageRead'
 *   },
 * });
 */
export function useMarkChatReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkChatReadMutation,
    MarkChatReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkChatReadMutation,
    MarkChatReadMutationVariables
  >(MarkChatReadDocument, options);
}
export type MarkChatReadMutationHookResult = ReturnType<
  typeof useMarkChatReadMutation
>;
export type MarkChatReadMutationResult =
  Apollo.MutationResult<MarkChatReadMutation>;
export type MarkChatReadMutationOptions = Apollo.BaseMutationOptions<
  MarkChatReadMutation,
  MarkChatReadMutationVariables
>;
export const MemberCoachSessionsDocument = gql`
  query memberCoachSessions(
    $memberId: ID!
    $orderBy: [CoachSessionOrderBy!]
    $input: ConnectionInput
    $filter: CoachSessionFilter
    $dateFilter: [DateFilter!]
  ) {
    user(id: $memberId) {
      id
      sessions(
        orderBy: $orderBy
        input: $input
        filter: $filter
        dateFilter: $dateFilter
      ) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...CoachSessionBasics
          }
        }
      }
    }
  }
  ${CoachSessionBasicsFragmentDoc}
`;

/**
 * __useMemberCoachSessionsQuery__
 *
 * To run a query within a React component, call `useMemberCoachSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberCoachSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberCoachSessionsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      orderBy: // value for 'orderBy'
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *      dateFilter: // value for 'dateFilter'
 *   },
 * });
 */
export function useMemberCoachSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberCoachSessionsQuery,
    MemberCoachSessionsQueryVariables
  > &
    (
      | { variables: MemberCoachSessionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberCoachSessionsQuery,
    MemberCoachSessionsQueryVariables
  >(MemberCoachSessionsDocument, options);
}
export function useMemberCoachSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberCoachSessionsQuery,
    MemberCoachSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberCoachSessionsQuery,
    MemberCoachSessionsQueryVariables
  >(MemberCoachSessionsDocument, options);
}
export function useMemberCoachSessionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MemberCoachSessionsQuery,
        MemberCoachSessionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MemberCoachSessionsQuery,
    MemberCoachSessionsQueryVariables
  >(MemberCoachSessionsDocument, options);
}
export type MemberCoachSessionsQueryHookResult = ReturnType<
  typeof useMemberCoachSessionsQuery
>;
export type MemberCoachSessionsLazyQueryHookResult = ReturnType<
  typeof useMemberCoachSessionsLazyQuery
>;
export type MemberCoachSessionsSuspenseQueryHookResult = ReturnType<
  typeof useMemberCoachSessionsSuspenseQuery
>;
export type MemberCoachSessionsQueryResult = Apollo.QueryResult<
  MemberCoachSessionsQuery,
  MemberCoachSessionsQueryVariables
>;
export const MemberCoachSessionsCountDocument = gql`
  query memberCoachSessionsCount(
    $memberId: ID!
    $filter: CoachSessionFilter
    $dateFilter: [DateFilter!]
  ) {
    user(id: $memberId) {
      id
      sessions(filter: $filter, dateFilter: $dateFilter) {
        count
      }
    }
  }
`;

/**
 * __useMemberCoachSessionsCountQuery__
 *
 * To run a query within a React component, call `useMemberCoachSessionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberCoachSessionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberCoachSessionsCountQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      filter: // value for 'filter'
 *      dateFilter: // value for 'dateFilter'
 *   },
 * });
 */
export function useMemberCoachSessionsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberCoachSessionsCountQuery,
    MemberCoachSessionsCountQueryVariables
  > &
    (
      | { variables: MemberCoachSessionsCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberCoachSessionsCountQuery,
    MemberCoachSessionsCountQueryVariables
  >(MemberCoachSessionsCountDocument, options);
}
export function useMemberCoachSessionsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberCoachSessionsCountQuery,
    MemberCoachSessionsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberCoachSessionsCountQuery,
    MemberCoachSessionsCountQueryVariables
  >(MemberCoachSessionsCountDocument, options);
}
export function useMemberCoachSessionsCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MemberCoachSessionsCountQuery,
        MemberCoachSessionsCountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MemberCoachSessionsCountQuery,
    MemberCoachSessionsCountQueryVariables
  >(MemberCoachSessionsCountDocument, options);
}
export type MemberCoachSessionsCountQueryHookResult = ReturnType<
  typeof useMemberCoachSessionsCountQuery
>;
export type MemberCoachSessionsCountLazyQueryHookResult = ReturnType<
  typeof useMemberCoachSessionsCountLazyQuery
>;
export type MemberCoachSessionsCountSuspenseQueryHookResult = ReturnType<
  typeof useMemberCoachSessionsCountSuspenseQuery
>;
export type MemberCoachSessionsCountQueryResult = Apollo.QueryResult<
  MemberCoachSessionsCountQuery,
  MemberCoachSessionsCountQueryVariables
>;
export const MemberPersonalTrainingInfoDocument = gql`
  query memberPersonalTrainingInfo($memberId: ID!) {
    user(id: $memberId) {
      id
      ...PersonalTrainingInfo
    }
  }
  ${PersonalTrainingInfoFragmentDoc}
`;

/**
 * __useMemberPersonalTrainingInfoQuery__
 *
 * To run a query within a React component, call `useMemberPersonalTrainingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPersonalTrainingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPersonalTrainingInfoQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useMemberPersonalTrainingInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberPersonalTrainingInfoQuery,
    MemberPersonalTrainingInfoQueryVariables
  > &
    (
      | { variables: MemberPersonalTrainingInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberPersonalTrainingInfoQuery,
    MemberPersonalTrainingInfoQueryVariables
  >(MemberPersonalTrainingInfoDocument, options);
}
export function useMemberPersonalTrainingInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberPersonalTrainingInfoQuery,
    MemberPersonalTrainingInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberPersonalTrainingInfoQuery,
    MemberPersonalTrainingInfoQueryVariables
  >(MemberPersonalTrainingInfoDocument, options);
}
export function useMemberPersonalTrainingInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MemberPersonalTrainingInfoQuery,
        MemberPersonalTrainingInfoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MemberPersonalTrainingInfoQuery,
    MemberPersonalTrainingInfoQueryVariables
  >(MemberPersonalTrainingInfoDocument, options);
}
export type MemberPersonalTrainingInfoQueryHookResult = ReturnType<
  typeof useMemberPersonalTrainingInfoQuery
>;
export type MemberPersonalTrainingInfoLazyQueryHookResult = ReturnType<
  typeof useMemberPersonalTrainingInfoLazyQuery
>;
export type MemberPersonalTrainingInfoSuspenseQueryHookResult = ReturnType<
  typeof useMemberPersonalTrainingInfoSuspenseQuery
>;
export type MemberPersonalTrainingInfoQueryResult = Apollo.QueryResult<
  MemberPersonalTrainingInfoQuery,
  MemberPersonalTrainingInfoQueryVariables
>;
export const MemberNextAndLastCoachSessionDocument = gql`
  query memberNextAndLastCoachSession($memberId: ID!, $coachId: ID!) {
    user(id: $memberId) {
      id
      nextSession: sessions(
        orderBy: { direction: desc, order: sessionTime }
        input: { first: 1 }
        filter: { attendance: null, coachId: $coachId }
        dateFilter: [Future]
      ) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...CoachSessionBasics
          }
        }
      }
      lastSession: sessions(
        orderBy: { direction: desc, order: sessionTime }
        input: { first: 1 }
        filter: { attendance: null, coachId: $coachId }
        dateFilter: [Past]
      ) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...CoachSessionBasics
          }
        }
      }
    }
  }
  ${CoachSessionBasicsFragmentDoc}
`;

/**
 * __useMemberNextAndLastCoachSessionQuery__
 *
 * To run a query within a React component, call `useMemberNextAndLastCoachSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberNextAndLastCoachSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberNextAndLastCoachSessionQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      coachId: // value for 'coachId'
 *   },
 * });
 */
export function useMemberNextAndLastCoachSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberNextAndLastCoachSessionQuery,
    MemberNextAndLastCoachSessionQueryVariables
  > &
    (
      | {
          variables: MemberNextAndLastCoachSessionQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberNextAndLastCoachSessionQuery,
    MemberNextAndLastCoachSessionQueryVariables
  >(MemberNextAndLastCoachSessionDocument, options);
}
export function useMemberNextAndLastCoachSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberNextAndLastCoachSessionQuery,
    MemberNextAndLastCoachSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberNextAndLastCoachSessionQuery,
    MemberNextAndLastCoachSessionQueryVariables
  >(MemberNextAndLastCoachSessionDocument, options);
}
export function useMemberNextAndLastCoachSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MemberNextAndLastCoachSessionQuery,
        MemberNextAndLastCoachSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MemberNextAndLastCoachSessionQuery,
    MemberNextAndLastCoachSessionQueryVariables
  >(MemberNextAndLastCoachSessionDocument, options);
}
export type MemberNextAndLastCoachSessionQueryHookResult = ReturnType<
  typeof useMemberNextAndLastCoachSessionQuery
>;
export type MemberNextAndLastCoachSessionLazyQueryHookResult = ReturnType<
  typeof useMemberNextAndLastCoachSessionLazyQuery
>;
export type MemberNextAndLastCoachSessionSuspenseQueryHookResult = ReturnType<
  typeof useMemberNextAndLastCoachSessionSuspenseQuery
>;
export type MemberNextAndLastCoachSessionQueryResult = Apollo.QueryResult<
  MemberNextAndLastCoachSessionQuery,
  MemberNextAndLastCoachSessionQueryVariables
>;
export const CancelSessionDocument = gql`
  mutation cancelSession($sessionId: ID!) {
    cancelSession(sessionId: $sessionId)
  }
`;
export type CancelSessionMutationFn = Apollo.MutationFunction<
  CancelSessionMutation,
  CancelSessionMutationVariables
>;

/**
 * __useCancelSessionMutation__
 *
 * To run a mutation, you first call `useCancelSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSessionMutation, { data, loading, error }] = useCancelSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useCancelSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSessionMutation,
    CancelSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelSessionMutation,
    CancelSessionMutationVariables
  >(CancelSessionDocument, options);
}
export type CancelSessionMutationHookResult = ReturnType<
  typeof useCancelSessionMutation
>;
export type CancelSessionMutationResult =
  Apollo.MutationResult<CancelSessionMutation>;
export type CancelSessionMutationOptions = Apollo.BaseMutationOptions<
  CancelSessionMutation,
  CancelSessionMutationVariables
>;
export const MarkSessionAttendanceDocument = gql`
  mutation markSessionAttendance(
    $sessionId: ID!
    $attendance: CoachSessionAttendance!
    $fetchPersonalTrainingInfo: Boolean = false
  ) {
    updateAttendance(sessionId: $sessionId, attendance: $attendance) {
      id
      attendance
      user: member @include(if: $fetchPersonalTrainingInfo) {
        id
        ...PersonalTrainingInfo
      }
    }
  }
  ${PersonalTrainingInfoFragmentDoc}
`;
export type MarkSessionAttendanceMutationFn = Apollo.MutationFunction<
  MarkSessionAttendanceMutation,
  MarkSessionAttendanceMutationVariables
>;

/**
 * __useMarkSessionAttendanceMutation__
 *
 * To run a mutation, you first call `useMarkSessionAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkSessionAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markSessionAttendanceMutation, { data, loading, error }] = useMarkSessionAttendanceMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      attendance: // value for 'attendance'
 *      fetchPersonalTrainingInfo: // value for 'fetchPersonalTrainingInfo'
 *   },
 * });
 */
export function useMarkSessionAttendanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkSessionAttendanceMutation,
    MarkSessionAttendanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkSessionAttendanceMutation,
    MarkSessionAttendanceMutationVariables
  >(MarkSessionAttendanceDocument, options);
}
export type MarkSessionAttendanceMutationHookResult = ReturnType<
  typeof useMarkSessionAttendanceMutation
>;
export type MarkSessionAttendanceMutationResult =
  Apollo.MutationResult<MarkSessionAttendanceMutation>;
export type MarkSessionAttendanceMutationOptions = Apollo.BaseMutationOptions<
  MarkSessionAttendanceMutation,
  MarkSessionAttendanceMutationVariables
>;
export const SetSessionCreditsDocument = gql`
  mutation setSessionCredits($input: SessionCreditsInput!) {
    setSessionCredits(input: $input) {
      count
      rowVersion
    }
  }
`;
export type SetSessionCreditsMutationFn = Apollo.MutationFunction<
  SetSessionCreditsMutation,
  SetSessionCreditsMutationVariables
>;

/**
 * __useSetSessionCreditsMutation__
 *
 * To run a mutation, you first call `useSetSessionCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSessionCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSessionCreditsMutation, { data, loading, error }] = useSetSessionCreditsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSessionCreditsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSessionCreditsMutation,
    SetSessionCreditsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetSessionCreditsMutation,
    SetSessionCreditsMutationVariables
  >(SetSessionCreditsDocument, options);
}
export type SetSessionCreditsMutationHookResult = ReturnType<
  typeof useSetSessionCreditsMutation
>;
export type SetSessionCreditsMutationResult =
  Apollo.MutationResult<SetSessionCreditsMutation>;
export type SetSessionCreditsMutationOptions = Apollo.BaseMutationOptions<
  SetSessionCreditsMutation,
  SetSessionCreditsMutationVariables
>;
export const CoachSessionsDocument = gql`
  query coachSessions(
    $input: ConnectionInput
    $filter: CoachSessionFilter
    $orderBy: [CoachSessionOrderBy!]
  ) {
    self {
      id
      coachData {
        allSessions: sessions {
          count
        }
        sessions(input: $input, filter: $filter, orderBy: $orderBy) {
          count
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...CoachSessionBasics
            }
          }
        }
      }
    }
  }
  ${CoachSessionBasicsFragmentDoc}
`;

/**
 * __useCoachSessionsQuery__
 *
 * To run a query within a React component, call `useCoachSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachSessionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCoachSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachSessionsQuery,
    CoachSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachSessionsQuery, CoachSessionsQueryVariables>(
    CoachSessionsDocument,
    options,
  );
}
export function useCoachSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachSessionsQuery,
    CoachSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachSessionsQuery, CoachSessionsQueryVariables>(
    CoachSessionsDocument,
    options,
  );
}
export function useCoachSessionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachSessionsQuery,
        CoachSessionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachSessionsQuery,
    CoachSessionsQueryVariables
  >(CoachSessionsDocument, options);
}
export type CoachSessionsQueryHookResult = ReturnType<
  typeof useCoachSessionsQuery
>;
export type CoachSessionsLazyQueryHookResult = ReturnType<
  typeof useCoachSessionsLazyQuery
>;
export type CoachSessionsSuspenseQueryHookResult = ReturnType<
  typeof useCoachSessionsSuspenseQuery
>;
export type CoachSessionsQueryResult = Apollo.QueryResult<
  CoachSessionsQuery,
  CoachSessionsQueryVariables
>;
export const GetPreferencesDetailsDocument = gql`
  query getPreferencesDetails {
    self {
      id
      coachData {
        id
        ...PreferenceDetails
      }
    }
  }
  ${PreferenceDetailsFragmentDoc}
`;

/**
 * __useGetPreferencesDetailsQuery__
 *
 * To run a query within a React component, call `useGetPreferencesDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreferencesDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreferencesDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPreferencesDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPreferencesDetailsQuery,
    GetPreferencesDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPreferencesDetailsQuery,
    GetPreferencesDetailsQueryVariables
  >(GetPreferencesDetailsDocument, options);
}
export function useGetPreferencesDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPreferencesDetailsQuery,
    GetPreferencesDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPreferencesDetailsQuery,
    GetPreferencesDetailsQueryVariables
  >(GetPreferencesDetailsDocument, options);
}
export function useGetPreferencesDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPreferencesDetailsQuery,
        GetPreferencesDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPreferencesDetailsQuery,
    GetPreferencesDetailsQueryVariables
  >(GetPreferencesDetailsDocument, options);
}
export type GetPreferencesDetailsQueryHookResult = ReturnType<
  typeof useGetPreferencesDetailsQuery
>;
export type GetPreferencesDetailsLazyQueryHookResult = ReturnType<
  typeof useGetPreferencesDetailsLazyQuery
>;
export type GetPreferencesDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetPreferencesDetailsSuspenseQuery
>;
export type GetPreferencesDetailsQueryResult = Apollo.QueryResult<
  GetPreferencesDetailsQuery,
  GetPreferencesDetailsQueryVariables
>;
export const UpdateCoachPreferencesDocument = gql`
  mutation updateCoachPreferences(
    $id: ID!
    $enableCalendarBuffer: Boolean
    $dailySessionLimit: Int
  ) {
    updateCoachData(
      input: {
        id: $id
        enableCalendarBuffer: $enableCalendarBuffer
        dailySessionLimit: $dailySessionLimit
      }
    ) {
      user {
        id
        coachData {
          ...CalendarBuffer
          dailySessionLimit
        }
      }
    }
  }
  ${CalendarBufferFragmentDoc}
`;
export type UpdateCoachPreferencesMutationFn = Apollo.MutationFunction<
  UpdateCoachPreferencesMutation,
  UpdateCoachPreferencesMutationVariables
>;

/**
 * __useUpdateCoachPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateCoachPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachPreferencesMutation, { data, loading, error }] = useUpdateCoachPreferencesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enableCalendarBuffer: // value for 'enableCalendarBuffer'
 *      dailySessionLimit: // value for 'dailySessionLimit'
 *   },
 * });
 */
export function useUpdateCoachPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoachPreferencesMutation,
    UpdateCoachPreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCoachPreferencesMutation,
    UpdateCoachPreferencesMutationVariables
  >(UpdateCoachPreferencesDocument, options);
}
export type UpdateCoachPreferencesMutationHookResult = ReturnType<
  typeof useUpdateCoachPreferencesMutation
>;
export type UpdateCoachPreferencesMutationResult =
  Apollo.MutationResult<UpdateCoachPreferencesMutation>;
export type UpdateCoachPreferencesMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoachPreferencesMutation,
  UpdateCoachPreferencesMutationVariables
>;
export const UpdateCoachCronofyTimezoneDocument = gql`
  mutation updateCoachCronofyTimezone(
    $input: UpdateCoachCronofyTimezoneInput!
  ) {
    updateCoachCronofyTimezone(input: $input) {
      user {
        id
        coachData {
          id
          cronofyTimezone
        }
      }
    }
  }
`;
export type UpdateCoachCronofyTimezoneMutationFn = Apollo.MutationFunction<
  UpdateCoachCronofyTimezoneMutation,
  UpdateCoachCronofyTimezoneMutationVariables
>;

/**
 * __useUpdateCoachCronofyTimezoneMutation__
 *
 * To run a mutation, you first call `useUpdateCoachCronofyTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachCronofyTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachCronofyTimezoneMutation, { data, loading, error }] = useUpdateCoachCronofyTimezoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoachCronofyTimezoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoachCronofyTimezoneMutation,
    UpdateCoachCronofyTimezoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCoachCronofyTimezoneMutation,
    UpdateCoachCronofyTimezoneMutationVariables
  >(UpdateCoachCronofyTimezoneDocument, options);
}
export type UpdateCoachCronofyTimezoneMutationHookResult = ReturnType<
  typeof useUpdateCoachCronofyTimezoneMutation
>;
export type UpdateCoachCronofyTimezoneMutationResult =
  Apollo.MutationResult<UpdateCoachCronofyTimezoneMutation>;
export type UpdateCoachCronofyTimezoneMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCoachCronofyTimezoneMutation,
    UpdateCoachCronofyTimezoneMutationVariables
  >;
export const GetConnectionDetailsDocument = gql`
  query getConnectionDetails {
    self {
      id
      coachData {
        ...CoachDataBasics
        ...ConnectionDetails
      }
    }
  }
  ${CoachDataBasicsFragmentDoc}
  ${ConnectionDetailsFragmentDoc}
`;

/**
 * __useGetConnectionDetailsQuery__
 *
 * To run a query within a React component, call `useGetConnectionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectionDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConnectionDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetConnectionDetailsQuery,
    GetConnectionDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConnectionDetailsQuery,
    GetConnectionDetailsQueryVariables
  >(GetConnectionDetailsDocument, options);
}
export function useGetConnectionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConnectionDetailsQuery,
    GetConnectionDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConnectionDetailsQuery,
    GetConnectionDetailsQueryVariables
  >(GetConnectionDetailsDocument, options);
}
export function useGetConnectionDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetConnectionDetailsQuery,
        GetConnectionDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetConnectionDetailsQuery,
    GetConnectionDetailsQueryVariables
  >(GetConnectionDetailsDocument, options);
}
export type GetConnectionDetailsQueryHookResult = ReturnType<
  typeof useGetConnectionDetailsQuery
>;
export type GetConnectionDetailsLazyQueryHookResult = ReturnType<
  typeof useGetConnectionDetailsLazyQuery
>;
export type GetConnectionDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetConnectionDetailsSuspenseQuery
>;
export type GetConnectionDetailsQueryResult = Apollo.QueryResult<
  GetConnectionDetailsQuery,
  GetConnectionDetailsQueryVariables
>;
export const CronofyElementTokenDocument = gql`
  query cronofyElementToken($permissions: [CronofyElementPermission!]!) {
    self {
      id
      coachData {
        cronofyElementToken(permissions: $permissions)
      }
    }
  }
`;

/**
 * __useCronofyElementTokenQuery__
 *
 * To run a query within a React component, call `useCronofyElementTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCronofyElementTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCronofyElementTokenQuery({
 *   variables: {
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useCronofyElementTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    CronofyElementTokenQuery,
    CronofyElementTokenQueryVariables
  > &
    (
      | { variables: CronofyElementTokenQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CronofyElementTokenQuery,
    CronofyElementTokenQueryVariables
  >(CronofyElementTokenDocument, options);
}
export function useCronofyElementTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CronofyElementTokenQuery,
    CronofyElementTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CronofyElementTokenQuery,
    CronofyElementTokenQueryVariables
  >(CronofyElementTokenDocument, options);
}
export function useCronofyElementTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CronofyElementTokenQuery,
        CronofyElementTokenQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CronofyElementTokenQuery,
    CronofyElementTokenQueryVariables
  >(CronofyElementTokenDocument, options);
}
export type CronofyElementTokenQueryHookResult = ReturnType<
  typeof useCronofyElementTokenQuery
>;
export type CronofyElementTokenLazyQueryHookResult = ReturnType<
  typeof useCronofyElementTokenLazyQuery
>;
export type CronofyElementTokenSuspenseQueryHookResult = ReturnType<
  typeof useCronofyElementTokenSuspenseQuery
>;
export type CronofyElementTokenQueryResult = Apollo.QueryResult<
  CronofyElementTokenQuery,
  CronofyElementTokenQueryVariables
>;
export const GetCronofyAuthUrlsDocument = gql`
  query getCronofyAuthUrls(
    $returnUrl: String!
    $calendarPlatform: ClientCalendarPlatform
    $includeCalendarPlatform: Boolean!
    $conferencingPlatform: ClientVideoPlatform
    $includeConferencingPlatform: Boolean!
  ) {
    self {
      id
      coachData {
        cronofyAuthUrl(returnUrl: $returnUrl)
        cronofyExternalCalendarUrl(
          returnUrl: $returnUrl
          calendarPlatform: $calendarPlatform
        ) @include(if: $includeCalendarPlatform)
        cronofyConferencingUrl(
          returnUrl: $returnUrl
          videoPlatform: $conferencingPlatform
        ) @include(if: $includeConferencingPlatform)
      }
    }
  }
`;

/**
 * __useGetCronofyAuthUrlsQuery__
 *
 * To run a query within a React component, call `useGetCronofyAuthUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCronofyAuthUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCronofyAuthUrlsQuery({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *      calendarPlatform: // value for 'calendarPlatform'
 *      includeCalendarPlatform: // value for 'includeCalendarPlatform'
 *      conferencingPlatform: // value for 'conferencingPlatform'
 *      includeConferencingPlatform: // value for 'includeConferencingPlatform'
 *   },
 * });
 */
export function useGetCronofyAuthUrlsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCronofyAuthUrlsQuery,
    GetCronofyAuthUrlsQueryVariables
  > &
    (
      | { variables: GetCronofyAuthUrlsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCronofyAuthUrlsQuery,
    GetCronofyAuthUrlsQueryVariables
  >(GetCronofyAuthUrlsDocument, options);
}
export function useGetCronofyAuthUrlsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCronofyAuthUrlsQuery,
    GetCronofyAuthUrlsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCronofyAuthUrlsQuery,
    GetCronofyAuthUrlsQueryVariables
  >(GetCronofyAuthUrlsDocument, options);
}
export function useGetCronofyAuthUrlsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCronofyAuthUrlsQuery,
        GetCronofyAuthUrlsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCronofyAuthUrlsQuery,
    GetCronofyAuthUrlsQueryVariables
  >(GetCronofyAuthUrlsDocument, options);
}
export type GetCronofyAuthUrlsQueryHookResult = ReturnType<
  typeof useGetCronofyAuthUrlsQuery
>;
export type GetCronofyAuthUrlsLazyQueryHookResult = ReturnType<
  typeof useGetCronofyAuthUrlsLazyQuery
>;
export type GetCronofyAuthUrlsSuspenseQueryHookResult = ReturnType<
  typeof useGetCronofyAuthUrlsSuspenseQuery
>;
export type GetCronofyAuthUrlsQueryResult = Apollo.QueryResult<
  GetCronofyAuthUrlsQuery,
  GetCronofyAuthUrlsQueryVariables
>;
export const GetCronofyCalendarAuthUrlDocument = gql`
  query getCronofyCalendarAuthUrl(
    $returnUrl: String!
    $calendarPlatform: ClientCalendarPlatform!
  ) {
    self {
      id
      coachData {
        id
        cronofyExternalCalendarUrl(
          returnUrl: $returnUrl
          calendarPlatform: $calendarPlatform
        )
      }
    }
  }
`;

/**
 * __useGetCronofyCalendarAuthUrlQuery__
 *
 * To run a query within a React component, call `useGetCronofyCalendarAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCronofyCalendarAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCronofyCalendarAuthUrlQuery({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *      calendarPlatform: // value for 'calendarPlatform'
 *   },
 * });
 */
export function useGetCronofyCalendarAuthUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCronofyCalendarAuthUrlQuery,
    GetCronofyCalendarAuthUrlQueryVariables
  > &
    (
      | { variables: GetCronofyCalendarAuthUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCronofyCalendarAuthUrlQuery,
    GetCronofyCalendarAuthUrlQueryVariables
  >(GetCronofyCalendarAuthUrlDocument, options);
}
export function useGetCronofyCalendarAuthUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCronofyCalendarAuthUrlQuery,
    GetCronofyCalendarAuthUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCronofyCalendarAuthUrlQuery,
    GetCronofyCalendarAuthUrlQueryVariables
  >(GetCronofyCalendarAuthUrlDocument, options);
}
export function useGetCronofyCalendarAuthUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCronofyCalendarAuthUrlQuery,
        GetCronofyCalendarAuthUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCronofyCalendarAuthUrlQuery,
    GetCronofyCalendarAuthUrlQueryVariables
  >(GetCronofyCalendarAuthUrlDocument, options);
}
export type GetCronofyCalendarAuthUrlQueryHookResult = ReturnType<
  typeof useGetCronofyCalendarAuthUrlQuery
>;
export type GetCronofyCalendarAuthUrlLazyQueryHookResult = ReturnType<
  typeof useGetCronofyCalendarAuthUrlLazyQuery
>;
export type GetCronofyCalendarAuthUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetCronofyCalendarAuthUrlSuspenseQuery
>;
export type GetCronofyCalendarAuthUrlQueryResult = Apollo.QueryResult<
  GetCronofyCalendarAuthUrlQuery,
  GetCronofyCalendarAuthUrlQueryVariables
>;
export const BookSessionDocument = gql`
  mutation bookSession($input: BookSessionInput!) {
    bookSession(input: $input) {
      session {
        ...CoachSessionBasics
      }
    }
  }
  ${CoachSessionBasicsFragmentDoc}
`;
export type BookSessionMutationFn = Apollo.MutationFunction<
  BookSessionMutation,
  BookSessionMutationVariables
>;

/**
 * __useBookSessionMutation__
 *
 * To run a mutation, you first call `useBookSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookSessionMutation, { data, loading, error }] = useBookSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookSessionMutation,
    BookSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BookSessionMutation, BookSessionMutationVariables>(
    BookSessionDocument,
    options,
  );
}
export type BookSessionMutationHookResult = ReturnType<
  typeof useBookSessionMutation
>;
export type BookSessionMutationResult =
  Apollo.MutationResult<BookSessionMutation>;
export type BookSessionMutationOptions = Apollo.BaseMutationOptions<
  BookSessionMutation,
  BookSessionMutationVariables
>;
export const RebookSessionDocument = gql`
  mutation rebookSession($sessionId: ID!, $start: ZonedDateTime!) {
    rebookSession(sessionId: $sessionId, start: $start) {
      session {
        ...CoachSessionBasics
      }
    }
  }
  ${CoachSessionBasicsFragmentDoc}
`;
export type RebookSessionMutationFn = Apollo.MutationFunction<
  RebookSessionMutation,
  RebookSessionMutationVariables
>;

/**
 * __useRebookSessionMutation__
 *
 * To run a mutation, you first call `useRebookSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebookSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebookSessionMutation, { data, loading, error }] = useRebookSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useRebookSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RebookSessionMutation,
    RebookSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RebookSessionMutation,
    RebookSessionMutationVariables
  >(RebookSessionDocument, options);
}
export type RebookSessionMutationHookResult = ReturnType<
  typeof useRebookSessionMutation
>;
export type RebookSessionMutationResult =
  Apollo.MutationResult<RebookSessionMutation>;
export type RebookSessionMutationOptions = Apollo.BaseMutationOptions<
  RebookSessionMutation,
  RebookSessionMutationVariables
>;
export const CoachTouchPointsDocument = gql`
  query coachTouchPoints(
    $input: ConnectionInput
    $filter: TouchPointFilter
    $orderBy: [TouchPointsOrderBy!]
  ) {
    self {
      id
      coachData {
        id
        touchPoints(input: $input, filter: $filter, orderBy: $orderBy) {
          ...CoachTouchPointConnection
        }
      }
    }
  }
  ${CoachTouchPointConnectionFragmentDoc}
`;

/**
 * __useCoachTouchPointsQuery__
 *
 * To run a query within a React component, call `useCoachTouchPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachTouchPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachTouchPointsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCoachTouchPointsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachTouchPointsQuery,
    CoachTouchPointsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachTouchPointsQuery, CoachTouchPointsQueryVariables>(
    CoachTouchPointsDocument,
    options,
  );
}
export function useCoachTouchPointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachTouchPointsQuery,
    CoachTouchPointsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachTouchPointsQuery,
    CoachTouchPointsQueryVariables
  >(CoachTouchPointsDocument, options);
}
export function useCoachTouchPointsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachTouchPointsQuery,
        CoachTouchPointsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachTouchPointsQuery,
    CoachTouchPointsQueryVariables
  >(CoachTouchPointsDocument, options);
}
export type CoachTouchPointsQueryHookResult = ReturnType<
  typeof useCoachTouchPointsQuery
>;
export type CoachTouchPointsLazyQueryHookResult = ReturnType<
  typeof useCoachTouchPointsLazyQuery
>;
export type CoachTouchPointsSuspenseQueryHookResult = ReturnType<
  typeof useCoachTouchPointsSuspenseQuery
>;
export type CoachTouchPointsQueryResult = Apollo.QueryResult<
  CoachTouchPointsQuery,
  CoachTouchPointsQueryVariables
>;
export const CoachTouchPointIncompleteCountsDocument = gql`
  query coachTouchPointIncompleteCounts(
    $overdueFilter: TouchPointFilter
    $todayFilter: TouchPointFilter
    $upcomingFilter: TouchPointFilter
  ) {
    self {
      id
      coachData {
        id
        overdue: touchPoints(filter: $overdueFilter) {
          count
        }
        today: touchPoints(filter: $todayFilter) {
          count
        }
        upcoming: touchPoints(filter: $upcomingFilter) {
          count
        }
      }
    }
  }
`;

/**
 * __useCoachTouchPointIncompleteCountsQuery__
 *
 * To run a query within a React component, call `useCoachTouchPointIncompleteCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachTouchPointIncompleteCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachTouchPointIncompleteCountsQuery({
 *   variables: {
 *      overdueFilter: // value for 'overdueFilter'
 *      todayFilter: // value for 'todayFilter'
 *      upcomingFilter: // value for 'upcomingFilter'
 *   },
 * });
 */
export function useCoachTouchPointIncompleteCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachTouchPointIncompleteCountsQuery,
    CoachTouchPointIncompleteCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoachTouchPointIncompleteCountsQuery,
    CoachTouchPointIncompleteCountsQueryVariables
  >(CoachTouchPointIncompleteCountsDocument, options);
}
export function useCoachTouchPointIncompleteCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachTouchPointIncompleteCountsQuery,
    CoachTouchPointIncompleteCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachTouchPointIncompleteCountsQuery,
    CoachTouchPointIncompleteCountsQueryVariables
  >(CoachTouchPointIncompleteCountsDocument, options);
}
export function useCoachTouchPointIncompleteCountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachTouchPointIncompleteCountsQuery,
        CoachTouchPointIncompleteCountsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachTouchPointIncompleteCountsQuery,
    CoachTouchPointIncompleteCountsQueryVariables
  >(CoachTouchPointIncompleteCountsDocument, options);
}
export type CoachTouchPointIncompleteCountsQueryHookResult = ReturnType<
  typeof useCoachTouchPointIncompleteCountsQuery
>;
export type CoachTouchPointIncompleteCountsLazyQueryHookResult = ReturnType<
  typeof useCoachTouchPointIncompleteCountsLazyQuery
>;
export type CoachTouchPointIncompleteCountsSuspenseQueryHookResult = ReturnType<
  typeof useCoachTouchPointIncompleteCountsSuspenseQuery
>;
export type CoachTouchPointIncompleteCountsQueryResult = Apollo.QueryResult<
  CoachTouchPointIncompleteCountsQuery,
  CoachTouchPointIncompleteCountsQueryVariables
>;
export const CreateCustomCoachTouchPointDocument = gql`
  mutation CreateCustomCoachTouchPoint($input: CreateCoachTouchPointInput!) {
    createCoachTouchPoint(input: $input) {
      ...CoachTouchPointDetails
    }
  }
  ${CoachTouchPointDetailsFragmentDoc}
`;
export type CreateCustomCoachTouchPointMutationFn = Apollo.MutationFunction<
  CreateCustomCoachTouchPointMutation,
  CreateCustomCoachTouchPointMutationVariables
>;

/**
 * __useCreateCustomCoachTouchPointMutation__
 *
 * To run a mutation, you first call `useCreateCustomCoachTouchPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomCoachTouchPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomCoachTouchPointMutation, { data, loading, error }] = useCreateCustomCoachTouchPointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomCoachTouchPointMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomCoachTouchPointMutation,
    CreateCustomCoachTouchPointMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomCoachTouchPointMutation,
    CreateCustomCoachTouchPointMutationVariables
  >(CreateCustomCoachTouchPointDocument, options);
}
export type CreateCustomCoachTouchPointMutationHookResult = ReturnType<
  typeof useCreateCustomCoachTouchPointMutation
>;
export type CreateCustomCoachTouchPointMutationResult =
  Apollo.MutationResult<CreateCustomCoachTouchPointMutation>;
export type CreateCustomCoachTouchPointMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCustomCoachTouchPointMutation,
    CreateCustomCoachTouchPointMutationVariables
  >;
export const EditCustomCoachTouchPointDocument = gql`
  mutation EditCustomCoachTouchPoint($input: UpdateCoachTouchPointInput!) {
    updateCoachTouchPoint(input: $input) {
      ...CoachTouchPointDetails
    }
  }
  ${CoachTouchPointDetailsFragmentDoc}
`;
export type EditCustomCoachTouchPointMutationFn = Apollo.MutationFunction<
  EditCustomCoachTouchPointMutation,
  EditCustomCoachTouchPointMutationVariables
>;

/**
 * __useEditCustomCoachTouchPointMutation__
 *
 * To run a mutation, you first call `useEditCustomCoachTouchPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomCoachTouchPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomCoachTouchPointMutation, { data, loading, error }] = useEditCustomCoachTouchPointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCustomCoachTouchPointMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditCustomCoachTouchPointMutation,
    EditCustomCoachTouchPointMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditCustomCoachTouchPointMutation,
    EditCustomCoachTouchPointMutationVariables
  >(EditCustomCoachTouchPointDocument, options);
}
export type EditCustomCoachTouchPointMutationHookResult = ReturnType<
  typeof useEditCustomCoachTouchPointMutation
>;
export type EditCustomCoachTouchPointMutationResult =
  Apollo.MutationResult<EditCustomCoachTouchPointMutation>;
export type EditCustomCoachTouchPointMutationOptions =
  Apollo.BaseMutationOptions<
    EditCustomCoachTouchPointMutation,
    EditCustomCoachTouchPointMutationVariables
  >;
export const ContentLibraryFilerOptionsDocument = gql`
  query contentLibraryFilerOptions {
    contentGenres {
      value
      name
      children {
        value
        name
      }
    }
    gameplanComponents {
      value
      name
    }
    gameplanSubcomponents {
      value
      name
    }
    contentProjects {
      value
      name
    }
    contentIntensities {
      name
      value
    }
    contentLevels {
      name
      value
    }
    contentProjects {
      value
      name
    }
    performanceQualities {
      name
      value
    }
    contentSplits {
      value
      name
      children {
        value
        name
      }
    }
  }
`;

/**
 * __useContentLibraryFilerOptionsQuery__
 *
 * To run a query within a React component, call `useContentLibraryFilerOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentLibraryFilerOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentLibraryFilerOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContentLibraryFilerOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContentLibraryFilerOptionsQuery,
    ContentLibraryFilerOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContentLibraryFilerOptionsQuery,
    ContentLibraryFilerOptionsQueryVariables
  >(ContentLibraryFilerOptionsDocument, options);
}
export function useContentLibraryFilerOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContentLibraryFilerOptionsQuery,
    ContentLibraryFilerOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContentLibraryFilerOptionsQuery,
    ContentLibraryFilerOptionsQueryVariables
  >(ContentLibraryFilerOptionsDocument, options);
}
export function useContentLibraryFilerOptionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContentLibraryFilerOptionsQuery,
        ContentLibraryFilerOptionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ContentLibraryFilerOptionsQuery,
    ContentLibraryFilerOptionsQueryVariables
  >(ContentLibraryFilerOptionsDocument, options);
}
export type ContentLibraryFilerOptionsQueryHookResult = ReturnType<
  typeof useContentLibraryFilerOptionsQuery
>;
export type ContentLibraryFilerOptionsLazyQueryHookResult = ReturnType<
  typeof useContentLibraryFilerOptionsLazyQuery
>;
export type ContentLibraryFilerOptionsSuspenseQueryHookResult = ReturnType<
  typeof useContentLibraryFilerOptionsSuspenseQuery
>;
export type ContentLibraryFilerOptionsQueryResult = Apollo.QueryResult<
  ContentLibraryFilerOptionsQuery,
  ContentLibraryFilerOptionsQueryVariables
>;
export const GetContentBasicsDocument = gql`
  query getContentBasics($id: ID!) {
    content(id: $id) {
      ...ContentBasics
    }
  }
  ${ContentBasicsFragmentDoc}
`;

/**
 * __useGetContentBasicsQuery__
 *
 * To run a query within a React component, call `useGetContentBasicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentBasicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentBasicsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentBasicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContentBasicsQuery,
    GetContentBasicsQueryVariables
  > &
    (
      | { variables: GetContentBasicsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContentBasicsQuery, GetContentBasicsQueryVariables>(
    GetContentBasicsDocument,
    options,
  );
}
export function useGetContentBasicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentBasicsQuery,
    GetContentBasicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContentBasicsQuery,
    GetContentBasicsQueryVariables
  >(GetContentBasicsDocument, options);
}
export function useGetContentBasicsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContentBasicsQuery,
        GetContentBasicsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContentBasicsQuery,
    GetContentBasicsQueryVariables
  >(GetContentBasicsDocument, options);
}
export type GetContentBasicsQueryHookResult = ReturnType<
  typeof useGetContentBasicsQuery
>;
export type GetContentBasicsLazyQueryHookResult = ReturnType<
  typeof useGetContentBasicsLazyQuery
>;
export type GetContentBasicsSuspenseQueryHookResult = ReturnType<
  typeof useGetContentBasicsSuspenseQuery
>;
export type GetContentBasicsQueryResult = Apollo.QueryResult<
  GetContentBasicsQuery,
  GetContentBasicsQueryVariables
>;
export const GetContentDetailsDocument = gql`
  query getContentDetails($id: ID!) {
    content(id: $id) {
      ...ContentDetails
    }
  }
  ${ContentDetailsFragmentDoc}
`;

/**
 * __useGetContentDetailsQuery__
 *
 * To run a query within a React component, call `useGetContentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContentDetailsQuery,
    GetContentDetailsQueryVariables
  > &
    (
      | { variables: GetContentDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContentDetailsQuery,
    GetContentDetailsQueryVariables
  >(GetContentDetailsDocument, options);
}
export function useGetContentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentDetailsQuery,
    GetContentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContentDetailsQuery,
    GetContentDetailsQueryVariables
  >(GetContentDetailsDocument, options);
}
export function useGetContentDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContentDetailsQuery,
        GetContentDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContentDetailsQuery,
    GetContentDetailsQueryVariables
  >(GetContentDetailsDocument, options);
}
export type GetContentDetailsQueryHookResult = ReturnType<
  typeof useGetContentDetailsQuery
>;
export type GetContentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetContentDetailsLazyQuery
>;
export type GetContentDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetContentDetailsSuspenseQuery
>;
export type GetContentDetailsQueryResult = Apollo.QueryResult<
  GetContentDetailsQuery,
  GetContentDetailsQueryVariables
>;
export const FilterContentsDocument = gql`
  query filterContents($filter: ContentFilter, $input: ConnectionInput) {
    totalContents: contents(filter: {}, input: $input) {
      count
    }
    contents(filter: $filter, input: $input) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ContentDetails
        }
      }
    }
  }
  ${ContentDetailsFragmentDoc}
`;

/**
 * __useFilterContentsQuery__
 *
 * To run a query within a React component, call `useFilterContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterContentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFilterContentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterContentsQuery,
    FilterContentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterContentsQuery, FilterContentsQueryVariables>(
    FilterContentsDocument,
    options,
  );
}
export function useFilterContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterContentsQuery,
    FilterContentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterContentsQuery, FilterContentsQueryVariables>(
    FilterContentsDocument,
    options,
  );
}
export function useFilterContentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FilterContentsQuery,
        FilterContentsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FilterContentsQuery,
    FilterContentsQueryVariables
  >(FilterContentsDocument, options);
}
export type FilterContentsQueryHookResult = ReturnType<
  typeof useFilterContentsQuery
>;
export type FilterContentsLazyQueryHookResult = ReturnType<
  typeof useFilterContentsLazyQuery
>;
export type FilterContentsSuspenseQueryHookResult = ReturnType<
  typeof useFilterContentsSuspenseQuery
>;
export type FilterContentsQueryResult = Apollo.QueryResult<
  FilterContentsQuery,
  FilterContentsQueryVariables
>;
export const MemberCustomPlanActiveDaysDocument = gql`
  query memberCustomPlanActiveDays(
    $id: ID!
    $filter: CustomPlanActiveDaysFilter!
  ) {
    user(id: $id) {
      id
      customPlansActiveDays(filter: $filter) {
        ...customPlanActiveDaysBasics
      }
    }
  }
  ${CustomPlanActiveDaysBasicsFragmentDoc}
`;

/**
 * __useMemberCustomPlanActiveDaysQuery__
 *
 * To run a query within a React component, call `useMemberCustomPlanActiveDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberCustomPlanActiveDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberCustomPlanActiveDaysQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMemberCustomPlanActiveDaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberCustomPlanActiveDaysQuery,
    MemberCustomPlanActiveDaysQueryVariables
  > &
    (
      | { variables: MemberCustomPlanActiveDaysQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberCustomPlanActiveDaysQuery,
    MemberCustomPlanActiveDaysQueryVariables
  >(MemberCustomPlanActiveDaysDocument, options);
}
export function useMemberCustomPlanActiveDaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberCustomPlanActiveDaysQuery,
    MemberCustomPlanActiveDaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberCustomPlanActiveDaysQuery,
    MemberCustomPlanActiveDaysQueryVariables
  >(MemberCustomPlanActiveDaysDocument, options);
}
export function useMemberCustomPlanActiveDaysSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MemberCustomPlanActiveDaysQuery,
        MemberCustomPlanActiveDaysQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MemberCustomPlanActiveDaysQuery,
    MemberCustomPlanActiveDaysQueryVariables
  >(MemberCustomPlanActiveDaysDocument, options);
}
export type MemberCustomPlanActiveDaysQueryHookResult = ReturnType<
  typeof useMemberCustomPlanActiveDaysQuery
>;
export type MemberCustomPlanActiveDaysLazyQueryHookResult = ReturnType<
  typeof useMemberCustomPlanActiveDaysLazyQuery
>;
export type MemberCustomPlanActiveDaysSuspenseQueryHookResult = ReturnType<
  typeof useMemberCustomPlanActiveDaysSuspenseQuery
>;
export type MemberCustomPlanActiveDaysQueryResult = Apollo.QueryResult<
  MemberCustomPlanActiveDaysQuery,
  MemberCustomPlanActiveDaysQueryVariables
>;
export const CustomPlanDocument = gql`
  query customPlan($id: ID!, $planItemsInput: ConnectionInput) {
    customPlan(id: $id) {
      ...UserPlanDetails
    }
  }
  ${UserPlanDetailsFragmentDoc}
`;

/**
 * __useCustomPlanQuery__
 *
 * To run a query within a React component, call `useCustomPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *      planItemsInput: // value for 'planItemsInput'
 *   },
 * });
 */
export function useCustomPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomPlanQuery,
    CustomPlanQueryVariables
  > &
    (
      | { variables: CustomPlanQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomPlanQuery, CustomPlanQueryVariables>(
    CustomPlanDocument,
    options,
  );
}
export function useCustomPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomPlanQuery,
    CustomPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomPlanQuery, CustomPlanQueryVariables>(
    CustomPlanDocument,
    options,
  );
}
export function useCustomPlanSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CustomPlanQuery,
        CustomPlanQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CustomPlanQuery, CustomPlanQueryVariables>(
    CustomPlanDocument,
    options,
  );
}
export type CustomPlanQueryHookResult = ReturnType<typeof useCustomPlanQuery>;
export type CustomPlanLazyQueryHookResult = ReturnType<
  typeof useCustomPlanLazyQuery
>;
export type CustomPlanSuspenseQueryHookResult = ReturnType<
  typeof useCustomPlanSuspenseQuery
>;
export type CustomPlanQueryResult = Apollo.QueryResult<
  CustomPlanQuery,
  CustomPlanQueryVariables
>;
export const MemberPlansDocument = gql`
  query memberPlans(
    $id: ID!
    $input: ConnectionInput
    $filter: CustomPlansFilter
  ) {
    user(id: $id) {
      id
      firstName
      lastName
      fitCoach {
        id
      }
      currentCustomPlan {
        ...UserPlanBasics
      }
      customPlans(input: $input, filter: $filter) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...UserPlanBasics
          }
        }
      }
    }
  }
  ${UserPlanBasicsFragmentDoc}
`;

/**
 * __useMemberPlansQuery__
 *
 * To run a query within a React component, call `useMemberPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPlansQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMemberPlansQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberPlansQuery,
    MemberPlansQueryVariables
  > &
    (
      | { variables: MemberPlansQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberPlansQuery, MemberPlansQueryVariables>(
    MemberPlansDocument,
    options,
  );
}
export function useMemberPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberPlansQuery,
    MemberPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberPlansQuery, MemberPlansQueryVariables>(
    MemberPlansDocument,
    options,
  );
}
export function useMemberPlansSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MemberPlansQuery,
        MemberPlansQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberPlansQuery, MemberPlansQueryVariables>(
    MemberPlansDocument,
    options,
  );
}
export type MemberPlansQueryHookResult = ReturnType<typeof useMemberPlansQuery>;
export type MemberPlansLazyQueryHookResult = ReturnType<
  typeof useMemberPlansLazyQuery
>;
export type MemberPlansSuspenseQueryHookResult = ReturnType<
  typeof useMemberPlansSuspenseQuery
>;
export type MemberPlansQueryResult = Apollo.QueryResult<
  MemberPlansQuery,
  MemberPlansQueryVariables
>;
export const CreatePlanDocument = gql`
  mutation createPlan($input: CreatePlanInput!) {
    createPlan(input: $input) {
      plan {
        id
        name
        startDate
        planItems {
          count
          nodes {
            ...UserPlanItemDetails
          }
        }
      }
    }
  }
  ${UserPlanItemDetailsFragmentDoc}
`;
export type CreatePlanMutationFn = Apollo.MutationFunction<
  CreatePlanMutation,
  CreatePlanMutationVariables
>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlanMutation,
    CreatePlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(
    CreatePlanDocument,
    options,
  );
}
export type CreatePlanMutationHookResult = ReturnType<
  typeof useCreatePlanMutation
>;
export type CreatePlanMutationResult =
  Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<
  CreatePlanMutation,
  CreatePlanMutationVariables
>;
export const UpdatePlanDocument = gql`
  mutation updatePlan($input: UpdatePlanInput!) {
    updatePlan(input: $input) {
      plan {
        id
        name
        startDate
        planItems {
          count
          nodes {
            ...UserPlanItemDetails
          }
        }
      }
    }
  }
  ${UserPlanItemDetailsFragmentDoc}
`;
export type UpdatePlanMutationFn = Apollo.MutationFunction<
  UpdatePlanMutation,
  UpdatePlanMutationVariables
>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlanMutation,
    UpdatePlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(
    UpdatePlanDocument,
    options,
  );
}
export type UpdatePlanMutationHookResult = ReturnType<
  typeof useUpdatePlanMutation
>;
export type UpdatePlanMutationResult =
  Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlanMutation,
  UpdatePlanMutationVariables
>;
export const DeletePlanDocument = gql`
  mutation deletePlan($planId: ID!) {
    deletePlan(planId: $planId) {
      plan {
        id
      }
    }
  }
`;
export type DeletePlanMutationFn = Apollo.MutationFunction<
  DeletePlanMutation,
  DeletePlanMutationVariables
>;

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useDeletePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePlanMutation,
    DeletePlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(
    DeletePlanDocument,
    options,
  );
}
export type DeletePlanMutationHookResult = ReturnType<
  typeof useDeletePlanMutation
>;
export type DeletePlanMutationResult =
  Apollo.MutationResult<DeletePlanMutation>;
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<
  DeletePlanMutation,
  DeletePlanMutationVariables
>;
export const GetFeedbackStatusDocument = gql`
  query getFeedbackStatus($id: ID!) {
    feedbackStatus: getModalStatus(id: $id) {
      id
      givenFeedback: modalSeen
    }
  }
`;

/**
 * __useGetFeedbackStatusQuery__
 *
 * To run a query within a React component, call `useGetFeedbackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeedbackStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeedbackStatusQuery,
    GetFeedbackStatusQueryVariables
  > &
    (
      | { variables: GetFeedbackStatusQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFeedbackStatusQuery,
    GetFeedbackStatusQueryVariables
  >(GetFeedbackStatusDocument, options);
}
export function useGetFeedbackStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedbackStatusQuery,
    GetFeedbackStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFeedbackStatusQuery,
    GetFeedbackStatusQueryVariables
  >(GetFeedbackStatusDocument, options);
}
export function useGetFeedbackStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetFeedbackStatusQuery,
        GetFeedbackStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFeedbackStatusQuery,
    GetFeedbackStatusQueryVariables
  >(GetFeedbackStatusDocument, options);
}
export type GetFeedbackStatusQueryHookResult = ReturnType<
  typeof useGetFeedbackStatusQuery
>;
export type GetFeedbackStatusLazyQueryHookResult = ReturnType<
  typeof useGetFeedbackStatusLazyQuery
>;
export type GetFeedbackStatusSuspenseQueryHookResult = ReturnType<
  typeof useGetFeedbackStatusSuspenseQuery
>;
export type GetFeedbackStatusQueryResult = Apollo.QueryResult<
  GetFeedbackStatusQuery,
  GetFeedbackStatusQueryVariables
>;
export const SetFeedbackStatusDocument = gql`
  mutation setFeedbackStatus($id: ID!, $givenFeedback: Boolean!) {
    setModalStatus(status: { id: $id, modalSeen: $givenFeedback }) {
      id
      givenFeedback: modalSeen
    }
  }
`;
export type SetFeedbackStatusMutationFn = Apollo.MutationFunction<
  SetFeedbackStatusMutation,
  SetFeedbackStatusMutationVariables
>;

/**
 * __useSetFeedbackStatusMutation__
 *
 * To run a mutation, you first call `useSetFeedbackStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFeedbackStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFeedbackStatusMutation, { data, loading, error }] = useSetFeedbackStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      givenFeedback: // value for 'givenFeedback'
 *   },
 * });
 */
export function useSetFeedbackStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetFeedbackStatusMutation,
    SetFeedbackStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetFeedbackStatusMutation,
    SetFeedbackStatusMutationVariables
  >(SetFeedbackStatusDocument, options);
}
export type SetFeedbackStatusMutationHookResult = ReturnType<
  typeof useSetFeedbackStatusMutation
>;
export type SetFeedbackStatusMutationResult =
  Apollo.MutationResult<SetFeedbackStatusMutation>;
export type SetFeedbackStatusMutationOptions = Apollo.BaseMutationOptions<
  SetFeedbackStatusMutation,
  SetFeedbackStatusMutationVariables
>;
export const CreateUserGoalDocument = gql`
  mutation createUserGoal($input: CreateUserGoalInput!) {
    createUserGoal(input: $input) {
      user {
        id
        ...MemberGoal
      }
    }
  }
  ${MemberGoalFragmentDoc}
`;
export type CreateUserGoalMutationFn = Apollo.MutationFunction<
  CreateUserGoalMutation,
  CreateUserGoalMutationVariables
>;

/**
 * __useCreateUserGoalMutation__
 *
 * To run a mutation, you first call `useCreateUserGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGoalMutation, { data, loading, error }] = useCreateUserGoalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserGoalMutation,
    CreateUserGoalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserGoalMutation,
    CreateUserGoalMutationVariables
  >(CreateUserGoalDocument, options);
}
export type CreateUserGoalMutationHookResult = ReturnType<
  typeof useCreateUserGoalMutation
>;
export type CreateUserGoalMutationResult =
  Apollo.MutationResult<CreateUserGoalMutation>;
export type CreateUserGoalMutationOptions = Apollo.BaseMutationOptions<
  CreateUserGoalMutation,
  CreateUserGoalMutationVariables
>;
export const UpdateUserGoalDocument = gql`
  mutation updateUserGoal($input: UpdateUserGoalInput!) {
    updateUserGoal(input: $input) {
      user {
        id
        ...MemberGoal
      }
    }
  }
  ${MemberGoalFragmentDoc}
`;
export type UpdateUserGoalMutationFn = Apollo.MutationFunction<
  UpdateUserGoalMutation,
  UpdateUserGoalMutationVariables
>;

/**
 * __useUpdateUserGoalMutation__
 *
 * To run a mutation, you first call `useUpdateUserGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGoalMutation, { data, loading, error }] = useUpdateUserGoalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserGoalMutation,
    UpdateUserGoalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserGoalMutation,
    UpdateUserGoalMutationVariables
  >(UpdateUserGoalDocument, options);
}
export type UpdateUserGoalMutationHookResult = ReturnType<
  typeof useUpdateUserGoalMutation
>;
export type UpdateUserGoalMutationResult =
  Apollo.MutationResult<UpdateUserGoalMutation>;
export type UpdateUserGoalMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserGoalMutation,
  UpdateUserGoalMutationVariables
>;
export const MemberDocument = gql`
  query member(
    $id: ID!
    $includeChat: Boolean! = false
    $chatUserIds: [String!]
  ) {
    user(id: $id) {
      ...MemberDetails
    }
    chatRoom(userIds: $chatUserIds) @include(if: $includeChat) {
      ...ChatRoomBasics
    }
  }
  ${MemberDetailsFragmentDoc}
  ${ChatRoomBasicsFragmentDoc}
`;

/**
 * __useMemberQuery__
 *
 * To run a query within a React component, call `useMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeChat: // value for 'includeChat'
 *      chatUserIds: // value for 'chatUserIds'
 *   },
 * });
 */
export function useMemberQuery(
  baseOptions: Apollo.QueryHookOptions<MemberQuery, MemberQueryVariables> &
    ({ variables: MemberQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberQuery, MemberQueryVariables>(
    MemberDocument,
    options,
  );
}
export function useMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberQuery, MemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberQuery, MemberQueryVariables>(
    MemberDocument,
    options,
  );
}
export function useMemberSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MemberQuery, MemberQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberQuery, MemberQueryVariables>(
    MemberDocument,
    options,
  );
}
export type MemberQueryHookResult = ReturnType<typeof useMemberQuery>;
export type MemberLazyQueryHookResult = ReturnType<typeof useMemberLazyQuery>;
export type MemberSuspenseQueryHookResult = ReturnType<
  typeof useMemberSuspenseQuery
>;
export type MemberQueryResult = Apollo.QueryResult<
  MemberQuery,
  MemberQueryVariables
>;
export const EmergencyDetailsDocument = gql`
  query emergencyDetails($memberId: ID!) {
    user(id: $memberId) {
      id
      ...UserEmergencyDetails
    }
  }
  ${UserEmergencyDetailsFragmentDoc}
`;

/**
 * __useEmergencyDetailsQuery__
 *
 * To run a query within a React component, call `useEmergencyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmergencyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmergencyDetailsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useEmergencyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmergencyDetailsQuery,
    EmergencyDetailsQueryVariables
  > &
    (
      | { variables: EmergencyDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmergencyDetailsQuery, EmergencyDetailsQueryVariables>(
    EmergencyDetailsDocument,
    options,
  );
}
export function useEmergencyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmergencyDetailsQuery,
    EmergencyDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmergencyDetailsQuery,
    EmergencyDetailsQueryVariables
  >(EmergencyDetailsDocument, options);
}
export function useEmergencyDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EmergencyDetailsQuery,
        EmergencyDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EmergencyDetailsQuery,
    EmergencyDetailsQueryVariables
  >(EmergencyDetailsDocument, options);
}
export type EmergencyDetailsQueryHookResult = ReturnType<
  typeof useEmergencyDetailsQuery
>;
export type EmergencyDetailsLazyQueryHookResult = ReturnType<
  typeof useEmergencyDetailsLazyQuery
>;
export type EmergencyDetailsSuspenseQueryHookResult = ReturnType<
  typeof useEmergencyDetailsSuspenseQuery
>;
export type EmergencyDetailsQueryResult = Apollo.QueryResult<
  EmergencyDetailsQuery,
  EmergencyDetailsQueryVariables
>;
export const MemberClientDocument = gql`
  query memberClient($id: ID!) {
    user(id: $id) {
      id
      fitClient {
        ...FitClientBasics
      }
    }
  }
  ${FitClientBasicsFragmentDoc}
`;

/**
 * __useMemberClientQuery__
 *
 * To run a query within a React component, call `useMemberClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberClientQuery,
    MemberClientQueryVariables
  > &
    (
      | { variables: MemberClientQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberClientQuery, MemberClientQueryVariables>(
    MemberClientDocument,
    options,
  );
}
export function useMemberClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberClientQuery,
    MemberClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberClientQuery, MemberClientQueryVariables>(
    MemberClientDocument,
    options,
  );
}
export function useMemberClientSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MemberClientQuery,
        MemberClientQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberClientQuery, MemberClientQueryVariables>(
    MemberClientDocument,
    options,
  );
}
export type MemberClientQueryHookResult = ReturnType<
  typeof useMemberClientQuery
>;
export type MemberClientLazyQueryHookResult = ReturnType<
  typeof useMemberClientLazyQuery
>;
export type MemberClientSuspenseQueryHookResult = ReturnType<
  typeof useMemberClientSuspenseQuery
>;
export type MemberClientQueryResult = Apollo.QueryResult<
  MemberClientQuery,
  MemberClientQueryVariables
>;
export const CreateOrUpdateMemberNoteDocument = gql`
  mutation createOrUpdateMemberNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      note {
        ...NoteBasic
      }
    }
  }
  ${NoteBasicFragmentDoc}
`;
export type CreateOrUpdateMemberNoteMutationFn = Apollo.MutationFunction<
  CreateOrUpdateMemberNoteMutation,
  CreateOrUpdateMemberNoteMutationVariables
>;

/**
 * __useCreateOrUpdateMemberNoteMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateMemberNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateMemberNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateMemberNoteMutation, { data, loading, error }] = useCreateOrUpdateMemberNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateMemberNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateMemberNoteMutation,
    CreateOrUpdateMemberNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateMemberNoteMutation,
    CreateOrUpdateMemberNoteMutationVariables
  >(CreateOrUpdateMemberNoteDocument, options);
}
export type CreateOrUpdateMemberNoteMutationHookResult = ReturnType<
  typeof useCreateOrUpdateMemberNoteMutation
>;
export type CreateOrUpdateMemberNoteMutationResult =
  Apollo.MutationResult<CreateOrUpdateMemberNoteMutation>;
export type CreateOrUpdateMemberNoteMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateMemberNoteMutation,
    CreateOrUpdateMemberNoteMutationVariables
  >;
export const MembersListDocument = gql`
  query membersList(
    $membersFilter: CoachUsersFilter
    $orderBy: CoachUsersOrderBy!
    $input: ConnectionInput
  ) {
    self {
      ...CoachMemberListDetails
    }
  }
  ${CoachMemberListDetailsFragmentDoc}
`;

/**
 * __useMembersListQuery__
 *
 * To run a query within a React component, call `useMembersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersListQuery({
 *   variables: {
 *      membersFilter: // value for 'membersFilter'
 *      orderBy: // value for 'orderBy'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMembersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    MembersListQuery,
    MembersListQueryVariables
  > &
    (
      | { variables: MembersListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MembersListQuery, MembersListQueryVariables>(
    MembersListDocument,
    options,
  );
}
export function useMembersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MembersListQuery,
    MembersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MembersListQuery, MembersListQueryVariables>(
    MembersListDocument,
    options,
  );
}
export function useMembersListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MembersListQuery,
        MembersListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MembersListQuery, MembersListQueryVariables>(
    MembersListDocument,
    options,
  );
}
export type MembersListQueryHookResult = ReturnType<typeof useMembersListQuery>;
export type MembersListLazyQueryHookResult = ReturnType<
  typeof useMembersListLazyQuery
>;
export type MembersListSuspenseQueryHookResult = ReturnType<
  typeof useMembersListSuspenseQuery
>;
export type MembersListQueryResult = Apollo.QueryResult<
  MembersListQuery,
  MembersListQueryVariables
>;
export const MemberTypeCountDocument = gql`
  query memberTypeCount {
    self {
      coachData {
        id
        hpMembers: coachUsers(
          input: { first: 1 }
          filter: { coachingExperienceType: [highPerformance] }
        ) {
          count
        }
        wellbeingMembers: coachUsers(
          input: { first: 1 }
          filter: { coachingExperienceType: [wellbeing] }
        ) {
          count
        }
      }
    }
  }
`;

/**
 * __useMemberTypeCountQuery__
 *
 * To run a query within a React component, call `useMemberTypeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberTypeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberTypeCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberTypeCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MemberTypeCountQuery,
    MemberTypeCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberTypeCountQuery, MemberTypeCountQueryVariables>(
    MemberTypeCountDocument,
    options,
  );
}
export function useMemberTypeCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberTypeCountQuery,
    MemberTypeCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberTypeCountQuery,
    MemberTypeCountQueryVariables
  >(MemberTypeCountDocument, options);
}
export function useMemberTypeCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MemberTypeCountQuery,
        MemberTypeCountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MemberTypeCountQuery,
    MemberTypeCountQueryVariables
  >(MemberTypeCountDocument, options);
}
export type MemberTypeCountQueryHookResult = ReturnType<
  typeof useMemberTypeCountQuery
>;
export type MemberTypeCountLazyQueryHookResult = ReturnType<
  typeof useMemberTypeCountLazyQuery
>;
export type MemberTypeCountSuspenseQueryHookResult = ReturnType<
  typeof useMemberTypeCountSuspenseQuery
>;
export type MemberTypeCountQueryResult = Apollo.QueryResult<
  MemberTypeCountQuery,
  MemberTypeCountQueryVariables
>;
export const CoachMemberListDocument = gql`
  query coachMemberList($membersFilter: UsersFilter, $input: ConnectionInput) {
    self {
      id
      coachData {
        id
        clients {
          edges {
            node {
              id
              users(filter: $membersFilter, input: $input) {
                edges {
                  node {
                    ...CoachMemberData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoachMemberDataFragmentDoc}
`;

/**
 * __useCoachMemberListQuery__
 *
 * To run a query within a React component, call `useCoachMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachMemberListQuery({
 *   variables: {
 *      membersFilter: // value for 'membersFilter'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoachMemberListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachMemberListQuery,
    CoachMemberListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachMemberListQuery, CoachMemberListQueryVariables>(
    CoachMemberListDocument,
    options,
  );
}
export function useCoachMemberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachMemberListQuery,
    CoachMemberListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachMemberListQuery,
    CoachMemberListQueryVariables
  >(CoachMemberListDocument, options);
}
export function useCoachMemberListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachMemberListQuery,
        CoachMemberListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachMemberListQuery,
    CoachMemberListQueryVariables
  >(CoachMemberListDocument, options);
}
export type CoachMemberListQueryHookResult = ReturnType<
  typeof useCoachMemberListQuery
>;
export type CoachMemberListLazyQueryHookResult = ReturnType<
  typeof useCoachMemberListLazyQuery
>;
export type CoachMemberListSuspenseQueryHookResult = ReturnType<
  typeof useCoachMemberListSuspenseQuery
>;
export type CoachMemberListQueryResult = Apollo.QueryResult<
  CoachMemberListQuery,
  CoachMemberListQueryVariables
>;
export const MembersListTotalsDocument = gql`
  query membersListTotals(
    $newChatsFilters: CoachUsersFilter
    $starredChatsFilters: CoachUsersFilter
    $sessionsTodayFilters: CoachSessionFilter
    $sessionsRequiringAttendanceTrackingFilters: CoachSessionFilter
  ) {
    self {
      id
      coachData {
        id
        newChats: coachUsers(filter: $newChatsFilters) {
          count
        }
        starredChats: coachUsers(filter: $starredChatsFilters) {
          count
        }
        allMembers: coachUsers {
          count
        }
        totalUnreadMessageCount
        sessionsTodayCount: sessions(filter: $sessionsTodayFilters) {
          count
        }
        sessionsRequiringAttendanceTrackingCount: sessions(
          filter: $sessionsRequiringAttendanceTrackingFilters
        ) {
          count
        }
      }
    }
  }
`;

/**
 * __useMembersListTotalsQuery__
 *
 * To run a query within a React component, call `useMembersListTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersListTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersListTotalsQuery({
 *   variables: {
 *      newChatsFilters: // value for 'newChatsFilters'
 *      starredChatsFilters: // value for 'starredChatsFilters'
 *      sessionsTodayFilters: // value for 'sessionsTodayFilters'
 *      sessionsRequiringAttendanceTrackingFilters: // value for 'sessionsRequiringAttendanceTrackingFilters'
 *   },
 * });
 */
export function useMembersListTotalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MembersListTotalsQuery,
    MembersListTotalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MembersListTotalsQuery,
    MembersListTotalsQueryVariables
  >(MembersListTotalsDocument, options);
}
export function useMembersListTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MembersListTotalsQuery,
    MembersListTotalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MembersListTotalsQuery,
    MembersListTotalsQueryVariables
  >(MembersListTotalsDocument, options);
}
export function useMembersListTotalsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MembersListTotalsQuery,
        MembersListTotalsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MembersListTotalsQuery,
    MembersListTotalsQueryVariables
  >(MembersListTotalsDocument, options);
}
export type MembersListTotalsQueryHookResult = ReturnType<
  typeof useMembersListTotalsQuery
>;
export type MembersListTotalsLazyQueryHookResult = ReturnType<
  typeof useMembersListTotalsLazyQuery
>;
export type MembersListTotalsSuspenseQueryHookResult = ReturnType<
  typeof useMembersListTotalsSuspenseQuery
>;
export type MembersListTotalsQueryResult = Apollo.QueryResult<
  MembersListTotalsQuery,
  MembersListTotalsQueryVariables
>;
export const CoachUserTotalUnreadMessageCountDocument = gql`
  subscription coachUserTotalUnreadMessageCount {
    totalUnreadMessageCount(fromCoachedUsers: true) {
      count
    }
  }
`;

/**
 * __useCoachUserTotalUnreadMessageCountSubscription__
 *
 * To run a query within a React component, call `useCoachUserTotalUnreadMessageCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCoachUserTotalUnreadMessageCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachUserTotalUnreadMessageCountSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCoachUserTotalUnreadMessageCountSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CoachUserTotalUnreadMessageCountSubscription,
    CoachUserTotalUnreadMessageCountSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CoachUserTotalUnreadMessageCountSubscription,
    CoachUserTotalUnreadMessageCountSubscriptionVariables
  >(CoachUserTotalUnreadMessageCountDocument, options);
}
export type CoachUserTotalUnreadMessageCountSubscriptionHookResult = ReturnType<
  typeof useCoachUserTotalUnreadMessageCountSubscription
>;
export type CoachUserTotalUnreadMessageCountSubscriptionResult =
  Apollo.SubscriptionResult<CoachUserTotalUnreadMessageCountSubscription>;
export const MarkMemberStarredDocument = gql`
  mutation markMemberStarred($userId: ID!, $isStarred: Boolean!) {
    markUserStarred(userId: $userId, isStarred: $isStarred) {
      id
      isStarred
    }
  }
`;
export type MarkMemberStarredMutationFn = Apollo.MutationFunction<
  MarkMemberStarredMutation,
  MarkMemberStarredMutationVariables
>;

/**
 * __useMarkMemberStarredMutation__
 *
 * To run a mutation, you first call `useMarkMemberStarredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMemberStarredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMemberStarredMutation, { data, loading, error }] = useMarkMemberStarredMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      isStarred: // value for 'isStarred'
 *   },
 * });
 */
export function useMarkMemberStarredMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkMemberStarredMutation,
    MarkMemberStarredMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkMemberStarredMutation,
    MarkMemberStarredMutationVariables
  >(MarkMemberStarredDocument, options);
}
export type MarkMemberStarredMutationHookResult = ReturnType<
  typeof useMarkMemberStarredMutation
>;
export type MarkMemberStarredMutationResult =
  Apollo.MutationResult<MarkMemberStarredMutation>;
export type MarkMemberStarredMutationOptions = Apollo.BaseMutationOptions<
  MarkMemberStarredMutation,
  MarkMemberStarredMutationVariables
>;
export const NoteByIdDocument = gql`
  query NoteById($id: ID!) {
    note(id: $id) {
      ...NoteBasic
    }
  }
  ${NoteBasicFragmentDoc}
`;

/**
 * __useNoteByIdQuery__
 *
 * To run a query within a React component, call `useNoteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteByIdQuery(
  baseOptions: Apollo.QueryHookOptions<NoteByIdQuery, NoteByIdQueryVariables> &
    ({ variables: NoteByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NoteByIdQuery, NoteByIdQueryVariables>(
    NoteByIdDocument,
    options,
  );
}
export function useNoteByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NoteByIdQuery,
    NoteByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NoteByIdQuery, NoteByIdQueryVariables>(
    NoteByIdDocument,
    options,
  );
}
export function useNoteByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<NoteByIdQuery, NoteByIdQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NoteByIdQuery, NoteByIdQueryVariables>(
    NoteByIdDocument,
    options,
  );
}
export type NoteByIdQueryHookResult = ReturnType<typeof useNoteByIdQuery>;
export type NoteByIdLazyQueryHookResult = ReturnType<
  typeof useNoteByIdLazyQuery
>;
export type NoteByIdSuspenseQueryHookResult = ReturnType<
  typeof useNoteByIdSuspenseQuery
>;
export type NoteByIdQueryResult = Apollo.QueryResult<
  NoteByIdQuery,
  NoteByIdQueryVariables
>;
export const CreateNoteDocument = gql`
  mutation createNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      note {
        ...NoteBasic
      }
    }
  }
  ${NoteBasicFragmentDoc}
`;
export type CreateNoteMutationFn = Apollo.MutationFunction<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNoteMutation,
    CreateNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(
    CreateNoteDocument,
    options,
  );
}
export type CreateNoteMutationHookResult = ReturnType<
  typeof useCreateNoteMutation
>;
export type CreateNoteMutationResult =
  Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;
export const UpdateNoteDocument = gql`
  mutation updateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      note {
        ...NoteBasic
      }
    }
  }
  ${NoteBasicFragmentDoc}
`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNoteMutation,
    UpdateNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(
    UpdateNoteDocument,
    options,
  );
}
export type UpdateNoteMutationHookResult = ReturnType<
  typeof useUpdateNoteMutation
>;
export type UpdateNoteMutationResult =
  Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;
export const GenerateSignedUrlDocument = gql`
  mutation generateSignedUrl($input: SignedUrlInput!) {
    generateSignedUrl(input: $input) {
      signedUrl
      key
      photo {
        basePath
        id
      }
    }
  }
`;
export type GenerateSignedUrlMutationFn = Apollo.MutationFunction<
  GenerateSignedUrlMutation,
  GenerateSignedUrlMutationVariables
>;

/**
 * __useGenerateSignedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSignedUrlMutation, { data, loading, error }] = useGenerateSignedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSignedUrlMutation,
    GenerateSignedUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateSignedUrlMutation,
    GenerateSignedUrlMutationVariables
  >(GenerateSignedUrlDocument, options);
}
export type GenerateSignedUrlMutationHookResult = ReturnType<
  typeof useGenerateSignedUrlMutation
>;
export type GenerateSignedUrlMutationResult =
  Apollo.MutationResult<GenerateSignedUrlMutation>;
export type GenerateSignedUrlMutationOptions = Apollo.BaseMutationOptions<
  GenerateSignedUrlMutation,
  GenerateSignedUrlMutationVariables
>;
export const AssignPracticeDocument = gql`
  mutation assignPractice($practiceId: ID!, $userId: ID!) {
    assignPractice(practiceId: $practiceId, userId: $userId) {
      assignedPractice {
        id
        practiceProgram {
          id
        }
      }
    }
  }
`;
export type AssignPracticeMutationFn = Apollo.MutationFunction<
  AssignPracticeMutation,
  AssignPracticeMutationVariables
>;

/**
 * __useAssignPracticeMutation__
 *
 * To run a mutation, you first call `useAssignPracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPracticeMutation, { data, loading, error }] = useAssignPracticeMutation({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssignPracticeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignPracticeMutation,
    AssignPracticeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignPracticeMutation,
    AssignPracticeMutationVariables
  >(AssignPracticeDocument, options);
}
export type AssignPracticeMutationHookResult = ReturnType<
  typeof useAssignPracticeMutation
>;
export type AssignPracticeMutationResult =
  Apollo.MutationResult<AssignPracticeMutation>;
export type AssignPracticeMutationOptions = Apollo.BaseMutationOptions<
  AssignPracticeMutation,
  AssignPracticeMutationVariables
>;
export const ProgramsDocument = gql`
  query programs {
    programs {
      edges {
        node {
          ...ProgramContentDetails
        }
      }
    }
  }
  ${ProgramContentDetailsFragmentDoc}
`;

/**
 * __useProgramsQuery__
 *
 * To run a query within a React component, call `useProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProgramsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProgramsQuery, ProgramsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProgramsQuery, ProgramsQueryVariables>(
    ProgramsDocument,
    options,
  );
}
export function useProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramsQuery,
    ProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProgramsQuery, ProgramsQueryVariables>(
    ProgramsDocument,
    options,
  );
}
export function useProgramsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ProgramsQuery, ProgramsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProgramsQuery, ProgramsQueryVariables>(
    ProgramsDocument,
    options,
  );
}
export type ProgramsQueryHookResult = ReturnType<typeof useProgramsQuery>;
export type ProgramsLazyQueryHookResult = ReturnType<
  typeof useProgramsLazyQuery
>;
export type ProgramsSuspenseQueryHookResult = ReturnType<
  typeof useProgramsSuspenseQuery
>;
export type ProgramsQueryResult = Apollo.QueryResult<
  ProgramsQuery,
  ProgramsQueryVariables
>;
export const AssignProgramDocument = gql`
  mutation assignProgram($programId: ID!, $userId: ID!) {
    assignProgram(programId: $programId, userId: $userId) {
      assignedProgram {
        id
        program {
          title
        }
      }
    }
  }
`;
export type AssignProgramMutationFn = Apollo.MutationFunction<
  AssignProgramMutation,
  AssignProgramMutationVariables
>;

/**
 * __useAssignProgramMutation__
 *
 * To run a mutation, you first call `useAssignProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignProgramMutation, { data, loading, error }] = useAssignProgramMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssignProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignProgramMutation,
    AssignProgramMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignProgramMutation,
    AssignProgramMutationVariables
  >(AssignProgramDocument, options);
}
export type AssignProgramMutationHookResult = ReturnType<
  typeof useAssignProgramMutation
>;
export type AssignProgramMutationResult =
  Apollo.MutationResult<AssignProgramMutation>;
export type AssignProgramMutationOptions = Apollo.BaseMutationOptions<
  AssignProgramMutation,
  AssignProgramMutationVariables
>;
export const GetSurveyDocument = gql`
  query getSurvey($templateId: ID!, $surveyId: ID!) {
    surveyTemplate(id: $templateId) {
      id
      questions {
        ...QuestionBasics
      }
    }
    survey(id: $surveyId) {
      id
      createdAt
      surveyName
      responses {
        ...SurveyResponseDetail
      }
    }
  }
  ${QuestionBasicsFragmentDoc}
  ${SurveyResponseDetailFragmentDoc}
`;

/**
 * __useGetSurveyQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useGetSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSurveyQuery,
    GetSurveyQueryVariables
  > &
    (
      | { variables: GetSurveyQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSurveyQuery, GetSurveyQueryVariables>(
    GetSurveyDocument,
    options,
  );
}
export function useGetSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSurveyQuery,
    GetSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSurveyQuery, GetSurveyQueryVariables>(
    GetSurveyDocument,
    options,
  );
}
export function useGetSurveySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSurveyQuery, GetSurveyQueryVariables>(
    GetSurveyDocument,
    options,
  );
}
export type GetSurveyQueryHookResult = ReturnType<typeof useGetSurveyQuery>;
export type GetSurveyLazyQueryHookResult = ReturnType<
  typeof useGetSurveyLazyQuery
>;
export type GetSurveySuspenseQueryHookResult = ReturnType<
  typeof useGetSurveySuspenseQuery
>;
export type GetSurveyQueryResult = Apollo.QueryResult<
  GetSurveyQuery,
  GetSurveyQueryVariables
>;
export const AnswerSurveyDocument = gql`
  mutation AnswerSurvey($input: AnswerSurveyInput!) {
    answerSurvey(input: $input) {
      ...SurveyDetails
      template {
        ...SurveyTemplateDetails
      }
    }
  }
  ${SurveyDetailsFragmentDoc}
  ${SurveyTemplateDetailsFragmentDoc}
`;
export type AnswerSurveyMutationFn = Apollo.MutationFunction<
  AnswerSurveyMutation,
  AnswerSurveyMutationVariables
>;

/**
 * __useAnswerSurveyMutation__
 *
 * To run a mutation, you first call `useAnswerSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerSurveyMutation, { data, loading, error }] = useAnswerSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnswerSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnswerSurveyMutation,
    AnswerSurveyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnswerSurveyMutation,
    AnswerSurveyMutationVariables
  >(AnswerSurveyDocument, options);
}
export type AnswerSurveyMutationHookResult = ReturnType<
  typeof useAnswerSurveyMutation
>;
export type AnswerSurveyMutationResult =
  Apollo.MutationResult<AnswerSurveyMutation>;
export type AnswerSurveyMutationOptions = Apollo.BaseMutationOptions<
  AnswerSurveyMutation,
  AnswerSurveyMutationVariables
>;
export const GetSurveyDetailsDocument = gql`
  query getSurveyDetails($surveyId: ID!) {
    survey(id: $surveyId) {
      ...SurveyDetails
    }
  }
  ${SurveyDetailsFragmentDoc}
`;

/**
 * __useGetSurveyDetailsQuery__
 *
 * To run a query within a React component, call `useGetSurveyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyDetailsQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useGetSurveyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSurveyDetailsQuery,
    GetSurveyDetailsQueryVariables
  > &
    (
      | { variables: GetSurveyDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSurveyDetailsQuery, GetSurveyDetailsQueryVariables>(
    GetSurveyDetailsDocument,
    options,
  );
}
export function useGetSurveyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSurveyDetailsQuery,
    GetSurveyDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSurveyDetailsQuery,
    GetSurveyDetailsQueryVariables
  >(GetSurveyDetailsDocument, options);
}
export function useGetSurveyDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetSurveyDetailsQuery,
        GetSurveyDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSurveyDetailsQuery,
    GetSurveyDetailsQueryVariables
  >(GetSurveyDetailsDocument, options);
}
export type GetSurveyDetailsQueryHookResult = ReturnType<
  typeof useGetSurveyDetailsQuery
>;
export type GetSurveyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetSurveyDetailsLazyQuery
>;
export type GetSurveyDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetSurveyDetailsSuspenseQuery
>;
export type GetSurveyDetailsQueryResult = Apollo.QueryResult<
  GetSurveyDetailsQuery,
  GetSurveyDetailsQueryVariables
>;
export const GetUserForE2ETestsDocument = gql`
  query getUserForE2ETests($id: ID!) {
    user(id: $id) {
      token
    }
  }
`;

/**
 * __useGetUserForE2ETestsQuery__
 *
 * To run a query within a React component, call `useGetUserForE2ETestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForE2ETestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForE2ETestsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserForE2ETestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserForE2ETestsQuery,
    GetUserForE2ETestsQueryVariables
  > &
    (
      | { variables: GetUserForE2ETestsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserForE2ETestsQuery,
    GetUserForE2ETestsQueryVariables
  >(GetUserForE2ETestsDocument, options);
}
export function useGetUserForE2ETestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserForE2ETestsQuery,
    GetUserForE2ETestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserForE2ETestsQuery,
    GetUserForE2ETestsQueryVariables
  >(GetUserForE2ETestsDocument, options);
}
export function useGetUserForE2ETestsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUserForE2ETestsQuery,
        GetUserForE2ETestsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserForE2ETestsQuery,
    GetUserForE2ETestsQueryVariables
  >(GetUserForE2ETestsDocument, options);
}
export type GetUserForE2ETestsQueryHookResult = ReturnType<
  typeof useGetUserForE2ETestsQuery
>;
export type GetUserForE2ETestsLazyQueryHookResult = ReturnType<
  typeof useGetUserForE2ETestsLazyQuery
>;
export type GetUserForE2ETestsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserForE2ETestsSuspenseQuery
>;
export type GetUserForE2ETestsQueryResult = Apollo.QueryResult<
  GetUserForE2ETestsQuery,
  GetUserForE2ETestsQueryVariables
>;
export const GetSelfForE2ETestsDocument = gql`
  query getSelfForE2ETests {
    self {
      id
      fitCoach {
        id
        coachData {
          coachingStyle
        }
      }
      currentProgram {
        createdAt
        program {
          ...ProgramDetails
        }
      }
      currentPractice {
        createdAt
        practiceProgram {
          ...PracticeProgramContentDetails
        }
      }
      practicePrograms {
        edges {
          node {
            id
          }
        }
      }
    }
    programs {
      edges {
        node {
          id
        }
      }
    }
  }
  ${ProgramDetailsFragmentDoc}
  ${PracticeProgramContentDetailsFragmentDoc}
`;

/**
 * __useGetSelfForE2ETestsQuery__
 *
 * To run a query within a React component, call `useGetSelfForE2ETestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfForE2ETestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfForE2ETestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfForE2ETestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSelfForE2ETestsQuery,
    GetSelfForE2ETestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSelfForE2ETestsQuery,
    GetSelfForE2ETestsQueryVariables
  >(GetSelfForE2ETestsDocument, options);
}
export function useGetSelfForE2ETestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSelfForE2ETestsQuery,
    GetSelfForE2ETestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSelfForE2ETestsQuery,
    GetSelfForE2ETestsQueryVariables
  >(GetSelfForE2ETestsDocument, options);
}
export function useGetSelfForE2ETestsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetSelfForE2ETestsQuery,
        GetSelfForE2ETestsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSelfForE2ETestsQuery,
    GetSelfForE2ETestsQueryVariables
  >(GetSelfForE2ETestsDocument, options);
}
export type GetSelfForE2ETestsQueryHookResult = ReturnType<
  typeof useGetSelfForE2ETestsQuery
>;
export type GetSelfForE2ETestsLazyQueryHookResult = ReturnType<
  typeof useGetSelfForE2ETestsLazyQuery
>;
export type GetSelfForE2ETestsSuspenseQueryHookResult = ReturnType<
  typeof useGetSelfForE2ETestsSuspenseQuery
>;
export type GetSelfForE2ETestsQueryResult = Apollo.QueryResult<
  GetSelfForE2ETestsQuery,
  GetSelfForE2ETestsQueryVariables
>;
export const SurveyDetailsDocument = gql`
  query surveyDetails($id: ID!) {
    content(id: $id) {
      id
      title
      contentTime {
        name
      }
      __typename
      ... on InAppSurvey {
        link
        surveyTemplate {
          id
          name
          questions {
            ...SurveyQuestion
          }
        }
      }
    }
  }
  ${SurveyQuestionFragmentDoc}
`;

/**
 * __useSurveyDetailsQuery__
 *
 * To run a query within a React component, call `useSurveyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSurveyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SurveyDetailsQuery,
    SurveyDetailsQueryVariables
  > &
    (
      | { variables: SurveyDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SurveyDetailsQuery, SurveyDetailsQueryVariables>(
    SurveyDetailsDocument,
    options,
  );
}
export function useSurveyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyDetailsQuery,
    SurveyDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SurveyDetailsQuery, SurveyDetailsQueryVariables>(
    SurveyDetailsDocument,
    options,
  );
}
export function useSurveyDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SurveyDetailsQuery,
        SurveyDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SurveyDetailsQuery,
    SurveyDetailsQueryVariables
  >(SurveyDetailsDocument, options);
}
export type SurveyDetailsQueryHookResult = ReturnType<
  typeof useSurveyDetailsQuery
>;
export type SurveyDetailsLazyQueryHookResult = ReturnType<
  typeof useSurveyDetailsLazyQuery
>;
export type SurveyDetailsSuspenseQueryHookResult = ReturnType<
  typeof useSurveyDetailsSuspenseQuery
>;
export type SurveyDetailsQueryResult = Apollo.QueryResult<
  SurveyDetailsQuery,
  SurveyDetailsQueryVariables
>;
export const SurveyTemplateDocument = gql`
  query surveyTemplate($id: ID!) {
    surveyTemplate(id: $id) {
      id
      name
      questions {
        ...SurveyQuestion
      }
    }
  }
  ${SurveyQuestionFragmentDoc}
`;

/**
 * __useSurveyTemplateQuery__
 *
 * To run a query within a React component, call `useSurveyTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSurveyTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    SurveyTemplateQuery,
    SurveyTemplateQueryVariables
  > &
    (
      | { variables: SurveyTemplateQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SurveyTemplateQuery, SurveyTemplateQueryVariables>(
    SurveyTemplateDocument,
    options,
  );
}
export function useSurveyTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyTemplateQuery,
    SurveyTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SurveyTemplateQuery, SurveyTemplateQueryVariables>(
    SurveyTemplateDocument,
    options,
  );
}
export function useSurveyTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SurveyTemplateQuery,
        SurveyTemplateQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SurveyTemplateQuery,
    SurveyTemplateQueryVariables
  >(SurveyTemplateDocument, options);
}
export type SurveyTemplateQueryHookResult = ReturnType<
  typeof useSurveyTemplateQuery
>;
export type SurveyTemplateLazyQueryHookResult = ReturnType<
  typeof useSurveyTemplateLazyQuery
>;
export type SurveyTemplateSuspenseQueryHookResult = ReturnType<
  typeof useSurveyTemplateSuspenseQuery
>;
export type SurveyTemplateQueryResult = Apollo.QueryResult<
  SurveyTemplateQuery,
  SurveyTemplateQueryVariables
>;
export const AvailableActivityGenresDocument = gql`
  query availableActivityGenres {
    activityGenres(filter: { canBeAddedManually: true }) {
      ...ActivityGenre
    }
  }
  ${ActivityGenreFragmentDoc}
`;

/**
 * __useAvailableActivityGenresQuery__
 *
 * To run a query within a React component, call `useAvailableActivityGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableActivityGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableActivityGenresQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableActivityGenresQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableActivityGenresQuery,
    AvailableActivityGenresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableActivityGenresQuery,
    AvailableActivityGenresQueryVariables
  >(AvailableActivityGenresDocument, options);
}
export function useAvailableActivityGenresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableActivityGenresQuery,
    AvailableActivityGenresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableActivityGenresQuery,
    AvailableActivityGenresQueryVariables
  >(AvailableActivityGenresDocument, options);
}
export function useAvailableActivityGenresSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AvailableActivityGenresQuery,
        AvailableActivityGenresQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AvailableActivityGenresQuery,
    AvailableActivityGenresQueryVariables
  >(AvailableActivityGenresDocument, options);
}
export type AvailableActivityGenresQueryHookResult = ReturnType<
  typeof useAvailableActivityGenresQuery
>;
export type AvailableActivityGenresLazyQueryHookResult = ReturnType<
  typeof useAvailableActivityGenresLazyQuery
>;
export type AvailableActivityGenresSuspenseQueryHookResult = ReturnType<
  typeof useAvailableActivityGenresSuspenseQuery
>;
export type AvailableActivityGenresQueryResult = Apollo.QueryResult<
  AvailableActivityGenresQuery,
  AvailableActivityGenresQueryVariables
>;
export const CreateE2EManualActivityDocument = gql`
  mutation createE2EManualActivity($activity: ManualActivityInput!) {
    addManualActivity(activity: $activity) {
      activity {
        ...E2EActivity
      }
    }
  }
  ${E2EActivityFragmentDoc}
`;
export type CreateE2EManualActivityMutationFn = Apollo.MutationFunction<
  CreateE2EManualActivityMutation,
  CreateE2EManualActivityMutationVariables
>;

/**
 * __useCreateE2EManualActivityMutation__
 *
 * To run a mutation, you first call `useCreateE2EManualActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateE2EManualActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createE2EManualActivityMutation, { data, loading, error }] = useCreateE2EManualActivityMutation({
 *   variables: {
 *      activity: // value for 'activity'
 *   },
 * });
 */
export function useCreateE2EManualActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateE2EManualActivityMutation,
    CreateE2EManualActivityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateE2EManualActivityMutation,
    CreateE2EManualActivityMutationVariables
  >(CreateE2EManualActivityDocument, options);
}
export type CreateE2EManualActivityMutationHookResult = ReturnType<
  typeof useCreateE2EManualActivityMutation
>;
export type CreateE2EManualActivityMutationResult =
  Apollo.MutationResult<CreateE2EManualActivityMutation>;
export type CreateE2EManualActivityMutationOptions = Apollo.BaseMutationOptions<
  CreateE2EManualActivityMutation,
  CreateE2EManualActivityMutationVariables
>;
export const CreateE2ESurveyDocument = gql`
  mutation createE2ESurvey($input: AnswerSurveyInput!) {
    answerSurvey(input: $input) {
      ...E2ESurvey
    }
  }
  ${E2ESurveyFragmentDoc}
`;
export type CreateE2ESurveyMutationFn = Apollo.MutationFunction<
  CreateE2ESurveyMutation,
  CreateE2ESurveyMutationVariables
>;

/**
 * __useCreateE2ESurveyMutation__
 *
 * To run a mutation, you first call `useCreateE2ESurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateE2ESurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createE2ESurveyMutation, { data, loading, error }] = useCreateE2ESurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateE2ESurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateE2ESurveyMutation,
    CreateE2ESurveyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateE2ESurveyMutation,
    CreateE2ESurveyMutationVariables
  >(CreateE2ESurveyDocument, options);
}
export type CreateE2ESurveyMutationHookResult = ReturnType<
  typeof useCreateE2ESurveyMutation
>;
export type CreateE2ESurveyMutationResult =
  Apollo.MutationResult<CreateE2ESurveyMutation>;
export type CreateE2ESurveyMutationOptions = Apollo.BaseMutationOptions<
  CreateE2ESurveyMutation,
  CreateE2ESurveyMutationVariables
>;
export const GetAvailableVideoContentGenresDocument = gql`
  query getAvailableVideoContentGenres {
    contentGenres {
      value
    }
  }
`;

/**
 * __useGetAvailableVideoContentGenresQuery__
 *
 * To run a query within a React component, call `useGetAvailableVideoContentGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableVideoContentGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableVideoContentGenresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableVideoContentGenresQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAvailableVideoContentGenresQuery,
    GetAvailableVideoContentGenresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableVideoContentGenresQuery,
    GetAvailableVideoContentGenresQueryVariables
  >(GetAvailableVideoContentGenresDocument, options);
}
export function useGetAvailableVideoContentGenresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableVideoContentGenresQuery,
    GetAvailableVideoContentGenresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableVideoContentGenresQuery,
    GetAvailableVideoContentGenresQueryVariables
  >(GetAvailableVideoContentGenresDocument, options);
}
export function useGetAvailableVideoContentGenresSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAvailableVideoContentGenresQuery,
        GetAvailableVideoContentGenresQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAvailableVideoContentGenresQuery,
    GetAvailableVideoContentGenresQueryVariables
  >(GetAvailableVideoContentGenresDocument, options);
}
export type GetAvailableVideoContentGenresQueryHookResult = ReturnType<
  typeof useGetAvailableVideoContentGenresQuery
>;
export type GetAvailableVideoContentGenresLazyQueryHookResult = ReturnType<
  typeof useGetAvailableVideoContentGenresLazyQuery
>;
export type GetAvailableVideoContentGenresSuspenseQueryHookResult = ReturnType<
  typeof useGetAvailableVideoContentGenresSuspenseQuery
>;
export type GetAvailableVideoContentGenresQueryResult = Apollo.QueryResult<
  GetAvailableVideoContentGenresQuery,
  GetAvailableVideoContentGenresQueryVariables
>;
export const GetVideosDocument = gql`
  query getVideos($filter: ContentFilter) {
    contents(filter: $filter) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

/**
 * __useGetVideosQuery__
 *
 * To run a query within a React component, call `useGetVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVideosQuery,
    GetVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVideosQuery, GetVideosQueryVariables>(
    GetVideosDocument,
    options,
  );
}
export function useGetVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVideosQuery,
    GetVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVideosQuery, GetVideosQueryVariables>(
    GetVideosDocument,
    options,
  );
}
export function useGetVideosSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetVideosQuery, GetVideosQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetVideosQuery, GetVideosQueryVariables>(
    GetVideosDocument,
    options,
  );
}
export type GetVideosQueryHookResult = ReturnType<typeof useGetVideosQuery>;
export type GetVideosLazyQueryHookResult = ReturnType<
  typeof useGetVideosLazyQuery
>;
export type GetVideosSuspenseQueryHookResult = ReturnType<
  typeof useGetVideosSuspenseQuery
>;
export type GetVideosQueryResult = Apollo.QueryResult<
  GetVideosQuery,
  GetVideosQueryVariables
>;
export const UpdateProgressE2EDocument = gql`
  mutation updateProgressE2E(
    $contentId: ID!
    $sectionId: ID
    $progress: Float!
  ) {
    updateProgress(
      contentId: $contentId
      sectionId: $sectionId
      progress: $progress
    ) {
      node {
        id
      }
    }
  }
`;
export type UpdateProgressE2EMutationFn = Apollo.MutationFunction<
  UpdateProgressE2EMutation,
  UpdateProgressE2EMutationVariables
>;

/**
 * __useUpdateProgressE2EMutation__
 *
 * To run a mutation, you first call `useUpdateProgressE2EMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgressE2EMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgressE2EMutation, { data, loading, error }] = useUpdateProgressE2EMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      sectionId: // value for 'sectionId'
 *      progress: // value for 'progress'
 *   },
 * });
 */
export function useUpdateProgressE2EMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProgressE2EMutation,
    UpdateProgressE2EMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProgressE2EMutation,
    UpdateProgressE2EMutationVariables
  >(UpdateProgressE2EDocument, options);
}
export type UpdateProgressE2EMutationHookResult = ReturnType<
  typeof useUpdateProgressE2EMutation
>;
export type UpdateProgressE2EMutationResult =
  Apollo.MutationResult<UpdateProgressE2EMutation>;
export type UpdateProgressE2EMutationOptions = Apollo.BaseMutationOptions<
  UpdateProgressE2EMutation,
  UpdateProgressE2EMutationVariables
>;
export const AdminUpdateLastActivityDocument = gql`
  mutation adminUpdateLastActivity(
    $userId: ID!
    $app: App!
    $date: ZonedDateTime
  ) {
    adminUpdateLastActivity(userId: $userId, app: $app, date: $date) {
      user {
        id
      }
    }
  }
`;
export type AdminUpdateLastActivityMutationFn = Apollo.MutationFunction<
  AdminUpdateLastActivityMutation,
  AdminUpdateLastActivityMutationVariables
>;

/**
 * __useAdminUpdateLastActivityMutation__
 *
 * To run a mutation, you first call `useAdminUpdateLastActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateLastActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateLastActivityMutation, { data, loading, error }] = useAdminUpdateLastActivityMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      app: // value for 'app'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAdminUpdateLastActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateLastActivityMutation,
    AdminUpdateLastActivityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateLastActivityMutation,
    AdminUpdateLastActivityMutationVariables
  >(AdminUpdateLastActivityDocument, options);
}
export type AdminUpdateLastActivityMutationHookResult = ReturnType<
  typeof useAdminUpdateLastActivityMutation
>;
export type AdminUpdateLastActivityMutationResult =
  Apollo.MutationResult<AdminUpdateLastActivityMutation>;
export type AdminUpdateLastActivityMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateLastActivityMutation,
  AdminUpdateLastActivityMutationVariables
>;
export const CompletePracticeDocument = gql`
  mutation completePractice($practiceId: ID!) {
    completePractice(practiceId: $practiceId) {
      assignedPractice {
        id
      }
    }
  }
`;
export type CompletePracticeMutationFn = Apollo.MutationFunction<
  CompletePracticeMutation,
  CompletePracticeMutationVariables
>;

/**
 * __useCompletePracticeMutation__
 *
 * To run a mutation, you first call `useCompletePracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePracticeMutation, { data, loading, error }] = useCompletePracticeMutation({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *   },
 * });
 */
export function useCompletePracticeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompletePracticeMutation,
    CompletePracticeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompletePracticeMutation,
    CompletePracticeMutationVariables
  >(CompletePracticeDocument, options);
}
export type CompletePracticeMutationHookResult = ReturnType<
  typeof useCompletePracticeMutation
>;
export type CompletePracticeMutationResult =
  Apollo.MutationResult<CompletePracticeMutation>;
export type CompletePracticeMutationOptions = Apollo.BaseMutationOptions<
  CompletePracticeMutation,
  CompletePracticeMutationVariables
>;
export const CompleteProgramDocument = gql`
  mutation completeProgram($programId: ID!) {
    completeProgram(programId: $programId) {
      assignedProgram {
        id
      }
    }
  }
`;
export type CompleteProgramMutationFn = Apollo.MutationFunction<
  CompleteProgramMutation,
  CompleteProgramMutationVariables
>;

/**
 * __useCompleteProgramMutation__
 *
 * To run a mutation, you first call `useCompleteProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProgramMutation, { data, loading, error }] = useCompleteProgramMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useCompleteProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteProgramMutation,
    CompleteProgramMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteProgramMutation,
    CompleteProgramMutationVariables
  >(CompleteProgramDocument, options);
}
export type CompleteProgramMutationHookResult = ReturnType<
  typeof useCompleteProgramMutation
>;
export type CompleteProgramMutationResult =
  Apollo.MutationResult<CompleteProgramMutation>;
export type CompleteProgramMutationOptions = Apollo.BaseMutationOptions<
  CompleteProgramMutation,
  CompleteProgramMutationVariables
>;
export const BookTestSessionDocument = gql`
  mutation bookTestSession($input: BookSessionInput!) {
    bookSession(input: $input) {
      session {
        ...E2ETestSession
      }
    }
  }
  ${E2ETestSessionFragmentDoc}
`;
export type BookTestSessionMutationFn = Apollo.MutationFunction<
  BookTestSessionMutation,
  BookTestSessionMutationVariables
>;

/**
 * __useBookTestSessionMutation__
 *
 * To run a mutation, you first call `useBookTestSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookTestSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookTestSessionMutation, { data, loading, error }] = useBookTestSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookTestSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookTestSessionMutation,
    BookTestSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookTestSessionMutation,
    BookTestSessionMutationVariables
  >(BookTestSessionDocument, options);
}
export type BookTestSessionMutationHookResult = ReturnType<
  typeof useBookTestSessionMutation
>;
export type BookTestSessionMutationResult =
  Apollo.MutationResult<BookTestSessionMutation>;
export type BookTestSessionMutationOptions = Apollo.BaseMutationOptions<
  BookTestSessionMutation,
  BookTestSessionMutationVariables
>;
export const UpdateCoachDataDocument = gql`
  mutation updateCoachData($input: UpdateCoachDataInput!) {
    updateCoachData(input: $input) {
      user {
        id
      }
    }
  }
`;
export type UpdateCoachDataMutationFn = Apollo.MutationFunction<
  UpdateCoachDataMutation,
  UpdateCoachDataMutationVariables
>;

/**
 * __useUpdateCoachDataMutation__
 *
 * To run a mutation, you first call `useUpdateCoachDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachDataMutation, { data, loading, error }] = useUpdateCoachDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoachDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoachDataMutation,
    UpdateCoachDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCoachDataMutation,
    UpdateCoachDataMutationVariables
  >(UpdateCoachDataDocument, options);
}
export type UpdateCoachDataMutationHookResult = ReturnType<
  typeof useUpdateCoachDataMutation
>;
export type UpdateCoachDataMutationResult =
  Apollo.MutationResult<UpdateCoachDataMutation>;
export type UpdateCoachDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoachDataMutation,
  UpdateCoachDataMutationVariables
>;
export const UpdateSessionAttendanceDocument = gql`
  mutation updateSessionAttendance(
    $sessionId: ID!
    $attendance: CoachSessionAttendance!
  ) {
    updateAttendance(sessionId: $sessionId, attendance: $attendance) {
      ...E2ETestSession
    }
  }
  ${E2ETestSessionFragmentDoc}
`;
export type UpdateSessionAttendanceMutationFn = Apollo.MutationFunction<
  UpdateSessionAttendanceMutation,
  UpdateSessionAttendanceMutationVariables
>;

/**
 * __useUpdateSessionAttendanceMutation__
 *
 * To run a mutation, you first call `useUpdateSessionAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionAttendanceMutation, { data, loading, error }] = useUpdateSessionAttendanceMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      attendance: // value for 'attendance'
 *   },
 * });
 */
export function useUpdateSessionAttendanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSessionAttendanceMutation,
    UpdateSessionAttendanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSessionAttendanceMutation,
    UpdateSessionAttendanceMutationVariables
  >(UpdateSessionAttendanceDocument, options);
}
export type UpdateSessionAttendanceMutationHookResult = ReturnType<
  typeof useUpdateSessionAttendanceMutation
>;
export type UpdateSessionAttendanceMutationResult =
  Apollo.MutationResult<UpdateSessionAttendanceMutation>;
export type UpdateSessionAttendanceMutationOptions = Apollo.BaseMutationOptions<
  UpdateSessionAttendanceMutation,
  UpdateSessionAttendanceMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const AdminUpdateUserDocument = gql`
  mutation adminUpdateUser($input: AdminUpdateUserInput!) {
    adminUpdateUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type AdminUpdateUserMutationFn = Apollo.MutationFunction<
  AdminUpdateUserMutation,
  AdminUpdateUserMutationVariables
>;

/**
 * __useAdminUpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUserMutation, { data, loading, error }] = useAdminUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateUserMutation,
    AdminUpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateUserMutation,
    AdminUpdateUserMutationVariables
  >(AdminUpdateUserDocument, options);
}
export type AdminUpdateUserMutationHookResult = ReturnType<
  typeof useAdminUpdateUserMutation
>;
export type AdminUpdateUserMutationResult =
  Apollo.MutationResult<AdminUpdateUserMutation>;
export type AdminUpdateUserMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateUserMutation,
  AdminUpdateUserMutationVariables
>;
export const GetUserByEmailDocument = gql`
  query getUserByEmail($email: String!) {
    user(email: $email) {
      ...UserBasics
      isCoach
    }
  }
  ${UserBasicsFragmentDoc}
`;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  > &
    (
      | { variables: GetUserByEmailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(
    GetUserByEmailDocument,
    options,
  );
}
export function useGetUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(
    GetUserByEmailDocument,
    options,
  );
}
export function useGetUserByEmailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUserByEmailQuery,
        GetUserByEmailQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  >(GetUserByEmailDocument, options);
}
export type GetUserByEmailQueryHookResult = ReturnType<
  typeof useGetUserByEmailQuery
>;
export type GetUserByEmailLazyQueryHookResult = ReturnType<
  typeof useGetUserByEmailLazyQuery
>;
export type GetUserByEmailSuspenseQueryHookResult = ReturnType<
  typeof useGetUserByEmailSuspenseQuery
>;
export type GetUserByEmailQueryResult = Apollo.QueryResult<
  GetUserByEmailQuery,
  GetUserByEmailQueryVariables
>;
export const CancelTestSessionDocument = gql`
  mutation cancelTestSession($sessionId: ID!) {
    cancelSession(sessionId: $sessionId, useCronofy: false)
  }
`;
export type CancelTestSessionMutationFn = Apollo.MutationFunction<
  CancelTestSessionMutation,
  CancelTestSessionMutationVariables
>;

/**
 * __useCancelTestSessionMutation__
 *
 * To run a mutation, you first call `useCancelTestSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTestSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTestSessionMutation, { data, loading, error }] = useCancelTestSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useCancelTestSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelTestSessionMutation,
    CancelTestSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelTestSessionMutation,
    CancelTestSessionMutationVariables
  >(CancelTestSessionDocument, options);
}
export type CancelTestSessionMutationHookResult = ReturnType<
  typeof useCancelTestSessionMutation
>;
export type CancelTestSessionMutationResult =
  Apollo.MutationResult<CancelTestSessionMutation>;
export type CancelTestSessionMutationOptions = Apollo.BaseMutationOptions<
  CancelTestSessionMutation,
  CancelTestSessionMutationVariables
>;
export const SetUserCoachSessionCreditsDocument = gql`
  mutation setUserCoachSessionCredits($input: SessionCreditsInput!) {
    setSessionCredits(input: $input) {
      userId
      count
      rowVersion
    }
  }
`;
export type SetUserCoachSessionCreditsMutationFn = Apollo.MutationFunction<
  SetUserCoachSessionCreditsMutation,
  SetUserCoachSessionCreditsMutationVariables
>;

/**
 * __useSetUserCoachSessionCreditsMutation__
 *
 * To run a mutation, you first call `useSetUserCoachSessionCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserCoachSessionCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserCoachSessionCreditsMutation, { data, loading, error }] = useSetUserCoachSessionCreditsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserCoachSessionCreditsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserCoachSessionCreditsMutation,
    SetUserCoachSessionCreditsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetUserCoachSessionCreditsMutation,
    SetUserCoachSessionCreditsMutationVariables
  >(SetUserCoachSessionCreditsDocument, options);
}
export type SetUserCoachSessionCreditsMutationHookResult = ReturnType<
  typeof useSetUserCoachSessionCreditsMutation
>;
export type SetUserCoachSessionCreditsMutationResult =
  Apollo.MutationResult<SetUserCoachSessionCreditsMutation>;
export type SetUserCoachSessionCreditsMutationOptions =
  Apollo.BaseMutationOptions<
    SetUserCoachSessionCreditsMutation,
    SetUserCoachSessionCreditsMutationVariables
  >;
export const GetUserCoachSessionCreditsDocument = gql`
  query getUserCoachSessionCredits($userId: ID!) {
    user(id: $userId) {
      id
      coachSessionCredits {
        count
        rowVersion
      }
    }
  }
`;

/**
 * __useGetUserCoachSessionCreditsQuery__
 *
 * To run a query within a React component, call `useGetUserCoachSessionCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCoachSessionCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCoachSessionCreditsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserCoachSessionCreditsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCoachSessionCreditsQuery,
    GetUserCoachSessionCreditsQueryVariables
  > &
    (
      | { variables: GetUserCoachSessionCreditsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserCoachSessionCreditsQuery,
    GetUserCoachSessionCreditsQueryVariables
  >(GetUserCoachSessionCreditsDocument, options);
}
export function useGetUserCoachSessionCreditsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCoachSessionCreditsQuery,
    GetUserCoachSessionCreditsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserCoachSessionCreditsQuery,
    GetUserCoachSessionCreditsQueryVariables
  >(GetUserCoachSessionCreditsDocument, options);
}
export function useGetUserCoachSessionCreditsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUserCoachSessionCreditsQuery,
        GetUserCoachSessionCreditsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserCoachSessionCreditsQuery,
    GetUserCoachSessionCreditsQueryVariables
  >(GetUserCoachSessionCreditsDocument, options);
}
export type GetUserCoachSessionCreditsQueryHookResult = ReturnType<
  typeof useGetUserCoachSessionCreditsQuery
>;
export type GetUserCoachSessionCreditsLazyQueryHookResult = ReturnType<
  typeof useGetUserCoachSessionCreditsLazyQuery
>;
export type GetUserCoachSessionCreditsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserCoachSessionCreditsSuspenseQuery
>;
export type GetUserCoachSessionCreditsQueryResult = Apollo.QueryResult<
  GetUserCoachSessionCreditsQuery,
  GetUserCoachSessionCreditsQueryVariables
>;
export const SendHistoricalMessageDocument = gql`
  mutation sendHistoricalMessage(
    $userId: ID!
    $createdAt: ZonedDateTime!
    $text: String!
    $chatId: ID!
  ) {
    sendHistoricalMessage(
      userId: $userId
      createdAt: $createdAt
      text: $text
      chatId: $chatId
    ) {
      id
    }
  }
`;
export type SendHistoricalMessageMutationFn = Apollo.MutationFunction<
  SendHistoricalMessageMutation,
  SendHistoricalMessageMutationVariables
>;

/**
 * __useSendHistoricalMessageMutation__
 *
 * To run a mutation, you first call `useSendHistoricalMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendHistoricalMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendHistoricalMessageMutation, { data, loading, error }] = useSendHistoricalMessageMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      createdAt: // value for 'createdAt'
 *      text: // value for 'text'
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useSendHistoricalMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendHistoricalMessageMutation,
    SendHistoricalMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendHistoricalMessageMutation,
    SendHistoricalMessageMutationVariables
  >(SendHistoricalMessageDocument, options);
}
export type SendHistoricalMessageMutationHookResult = ReturnType<
  typeof useSendHistoricalMessageMutation
>;
export type SendHistoricalMessageMutationResult =
  Apollo.MutationResult<SendHistoricalMessageMutation>;
export type SendHistoricalMessageMutationOptions = Apollo.BaseMutationOptions<
  SendHistoricalMessageMutation,
  SendHistoricalMessageMutationVariables
>;
export const CoachMembersDocument = gql`
  query coachMembers($id: ID!, $input: ConnectionInput) {
    user(id: $id) {
      id
      coachData {
        id
        coachUsers(input: $input) {
          ...MembersConnection
        }
      }
    }
  }
  ${MembersConnectionFragmentDoc}
`;

/**
 * __useCoachMembersQuery__
 *
 * To run a query within a React component, call `useCoachMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoachMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachMembersQuery,
    CoachMembersQueryVariables
  > &
    (
      | { variables: CoachMembersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachMembersQuery, CoachMembersQueryVariables>(
    CoachMembersDocument,
    options,
  );
}
export function useCoachMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachMembersQuery,
    CoachMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachMembersQuery, CoachMembersQueryVariables>(
    CoachMembersDocument,
    options,
  );
}
export function useCoachMembersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachMembersQuery,
        CoachMembersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CoachMembersQuery, CoachMembersQueryVariables>(
    CoachMembersDocument,
    options,
  );
}
export type CoachMembersQueryHookResult = ReturnType<
  typeof useCoachMembersQuery
>;
export type CoachMembersLazyQueryHookResult = ReturnType<
  typeof useCoachMembersLazyQuery
>;
export type CoachMembersSuspenseQueryHookResult = ReturnType<
  typeof useCoachMembersSuspenseQuery
>;
export type CoachMembersQueryResult = Apollo.QueryResult<
  CoachMembersQuery,
  CoachMembersQueryVariables
>;
export const SyncWearableSamplesDocument = gql`
  mutation syncWearableSamples($data: WearableSamplesInput!) {
    syncWearableSamples(data: $data)
  }
`;
export type SyncWearableSamplesMutationFn = Apollo.MutationFunction<
  SyncWearableSamplesMutation,
  SyncWearableSamplesMutationVariables
>;

/**
 * __useSyncWearableSamplesMutation__
 *
 * To run a mutation, you first call `useSyncWearableSamplesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncWearableSamplesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncWearableSamplesMutation, { data, loading, error }] = useSyncWearableSamplesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSyncWearableSamplesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncWearableSamplesMutation,
    SyncWearableSamplesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncWearableSamplesMutation,
    SyncWearableSamplesMutationVariables
  >(SyncWearableSamplesDocument, options);
}
export type SyncWearableSamplesMutationHookResult = ReturnType<
  typeof useSyncWearableSamplesMutation
>;
export type SyncWearableSamplesMutationResult =
  Apollo.MutationResult<SyncWearableSamplesMutation>;
export type SyncWearableSamplesMutationOptions = Apollo.BaseMutationOptions<
  SyncWearableSamplesMutation,
  SyncWearableSamplesMutationVariables
>;
export const DomainsDocument = gql`
  query domains($input: ConnectionInput, $filter: DomainsFilter) {
    data: domains(input: $input, filter: $filter) {
      nodes {
        ...DomainBasics
      }
      count
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${DomainBasicsFragmentDoc}
`;

/**
 * __useDomainsQuery__
 *
 * To run a query within a React component, call `useDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDomainsQuery(
  baseOptions?: Apollo.QueryHookOptions<DomainsQuery, DomainsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DomainsQuery, DomainsQueryVariables>(
    DomainsDocument,
    options,
  );
}
export function useDomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DomainsQuery,
    DomainsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DomainsQuery, DomainsQueryVariables>(
    DomainsDocument,
    options,
  );
}
export function useDomainsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DomainsQuery, DomainsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DomainsQuery, DomainsQueryVariables>(
    DomainsDocument,
    options,
  );
}
export type DomainsQueryHookResult = ReturnType<typeof useDomainsQuery>;
export type DomainsLazyQueryHookResult = ReturnType<typeof useDomainsLazyQuery>;
export type DomainsSuspenseQueryHookResult = ReturnType<
  typeof useDomainsSuspenseQuery
>;
export type DomainsQueryResult = Apollo.QueryResult<
  DomainsQuery,
  DomainsQueryVariables
>;
export const CreateCoachTouchPointDocument = gql`
  mutation createCoachTouchPoint($input: CreateCoachTouchPointInput!) {
    createCoachTouchPoint(input: $input) {
      id
    }
  }
`;
export type CreateCoachTouchPointMutationFn = Apollo.MutationFunction<
  CreateCoachTouchPointMutation,
  CreateCoachTouchPointMutationVariables
>;

/**
 * __useCreateCoachTouchPointMutation__
 *
 * To run a mutation, you first call `useCreateCoachTouchPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCoachTouchPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCoachTouchPointMutation, { data, loading, error }] = useCreateCoachTouchPointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCoachTouchPointMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCoachTouchPointMutation,
    CreateCoachTouchPointMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCoachTouchPointMutation,
    CreateCoachTouchPointMutationVariables
  >(CreateCoachTouchPointDocument, options);
}
export type CreateCoachTouchPointMutationHookResult = ReturnType<
  typeof useCreateCoachTouchPointMutation
>;
export type CreateCoachTouchPointMutationResult =
  Apollo.MutationResult<CreateCoachTouchPointMutation>;
export type CreateCoachTouchPointMutationOptions = Apollo.BaseMutationOptions<
  CreateCoachTouchPointMutation,
  CreateCoachTouchPointMutationVariables
>;
export const DeleteCoachTouchPointDocument = gql`
  mutation deleteCoachTouchPoint($id: ID!) {
    deleteCoachTouchPoint(id: $id)
  }
`;
export type DeleteCoachTouchPointMutationFn = Apollo.MutationFunction<
  DeleteCoachTouchPointMutation,
  DeleteCoachTouchPointMutationVariables
>;

/**
 * __useDeleteCoachTouchPointMutation__
 *
 * To run a mutation, you first call `useDeleteCoachTouchPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCoachTouchPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCoachTouchPointMutation, { data, loading, error }] = useDeleteCoachTouchPointMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCoachTouchPointMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCoachTouchPointMutation,
    DeleteCoachTouchPointMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCoachTouchPointMutation,
    DeleteCoachTouchPointMutationVariables
  >(DeleteCoachTouchPointDocument, options);
}
export type DeleteCoachTouchPointMutationHookResult = ReturnType<
  typeof useDeleteCoachTouchPointMutation
>;
export type DeleteCoachTouchPointMutationResult =
  Apollo.MutationResult<DeleteCoachTouchPointMutation>;
export type DeleteCoachTouchPointMutationOptions = Apollo.BaseMutationOptions<
  DeleteCoachTouchPointMutation,
  DeleteCoachTouchPointMutationVariables
>;
export const UpdateCoachTouchPointDocument = gql`
  mutation updateCoachTouchPoint($input: UpdateCoachTouchPointInput!) {
    updateCoachTouchPoint(input: $input) {
      ...CoachTouchPointDetails
    }
  }
  ${CoachTouchPointDetailsFragmentDoc}
`;
export type UpdateCoachTouchPointMutationFn = Apollo.MutationFunction<
  UpdateCoachTouchPointMutation,
  UpdateCoachTouchPointMutationVariables
>;

/**
 * __useUpdateCoachTouchPointMutation__
 *
 * To run a mutation, you first call `useUpdateCoachTouchPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachTouchPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachTouchPointMutation, { data, loading, error }] = useUpdateCoachTouchPointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoachTouchPointMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoachTouchPointMutation,
    UpdateCoachTouchPointMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCoachTouchPointMutation,
    UpdateCoachTouchPointMutationVariables
  >(UpdateCoachTouchPointDocument, options);
}
export type UpdateCoachTouchPointMutationHookResult = ReturnType<
  typeof useUpdateCoachTouchPointMutation
>;
export type UpdateCoachTouchPointMutationResult =
  Apollo.MutationResult<UpdateCoachTouchPointMutation>;
export type UpdateCoachTouchPointMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoachTouchPointMutation,
  UpdateCoachTouchPointMutationVariables
>;
export const SelfDocument = gql`
  query self {
    self {
      ...SelfBasics
    }
  }
  ${SelfBasicsFragmentDoc}
`;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(
  baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
}
export function useSelfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(
    SelfDocument,
    options,
  );
}
export function useSelfSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SelfQuery, SelfQueryVariables>(
    SelfDocument,
    options,
  );
}
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfSuspenseQueryHookResult = ReturnType<
  typeof useSelfSuspenseQuery
>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;
export const CoachSettingsDocument = gql`
  query coachSettings($url: String!) {
    self {
      ...UserDetails
      isCoach
      coachData {
        ...AvailabilitySettings
      }
    }
  }
  ${UserDetailsFragmentDoc}
  ${AvailabilitySettingsFragmentDoc}
`;

/**
 * __useCoachSettingsQuery__
 *
 * To run a query within a React component, call `useCoachSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachSettingsQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCoachSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachSettingsQuery,
    CoachSettingsQueryVariables
  > &
    (
      | { variables: CoachSettingsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachSettingsQuery, CoachSettingsQueryVariables>(
    CoachSettingsDocument,
    options,
  );
}
export function useCoachSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachSettingsQuery,
    CoachSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachSettingsQuery, CoachSettingsQueryVariables>(
    CoachSettingsDocument,
    options,
  );
}
export function useCoachSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachSettingsQuery,
        CoachSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachSettingsQuery,
    CoachSettingsQueryVariables
  >(CoachSettingsDocument, options);
}
export type CoachSettingsQueryHookResult = ReturnType<
  typeof useCoachSettingsQuery
>;
export type CoachSettingsLazyQueryHookResult = ReturnType<
  typeof useCoachSettingsLazyQuery
>;
export type CoachSettingsSuspenseQueryHookResult = ReturnType<
  typeof useCoachSettingsSuspenseQuery
>;
export type CoachSettingsQueryResult = Apollo.QueryResult<
  CoachSettingsQuery,
  CoachSettingsQueryVariables
>;
export const CoachBookingDetailsDocument = gql`
  query coachBookingDetails(
    $id: ID!
    $availabilityStart: ZonedDateTime!
    $coachSessionStyle: CoachSessionStyle
    $bypassOneDayDelay: Boolean
  ) {
    user(id: $id) {
      ...UserDetails
      coachData {
        cronofyTimezone
        ...CoachDataBasics
        ...CoachAvailability
        ...CoachLocationDetails
      }
    }
  }
  ${UserDetailsFragmentDoc}
  ${CoachDataBasicsFragmentDoc}
  ${CoachAvailabilityFragmentDoc}
  ${CoachLocationDetailsFragmentDoc}
`;

/**
 * __useCoachBookingDetailsQuery__
 *
 * To run a query within a React component, call `useCoachBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachBookingDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      availabilityStart: // value for 'availabilityStart'
 *      coachSessionStyle: // value for 'coachSessionStyle'
 *      bypassOneDayDelay: // value for 'bypassOneDayDelay'
 *   },
 * });
 */
export function useCoachBookingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachBookingDetailsQuery,
    CoachBookingDetailsQueryVariables
  > &
    (
      | { variables: CoachBookingDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoachBookingDetailsQuery,
    CoachBookingDetailsQueryVariables
  >(CoachBookingDetailsDocument, options);
}
export function useCoachBookingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachBookingDetailsQuery,
    CoachBookingDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachBookingDetailsQuery,
    CoachBookingDetailsQueryVariables
  >(CoachBookingDetailsDocument, options);
}
export function useCoachBookingDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachBookingDetailsQuery,
        CoachBookingDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachBookingDetailsQuery,
    CoachBookingDetailsQueryVariables
  >(CoachBookingDetailsDocument, options);
}
export type CoachBookingDetailsQueryHookResult = ReturnType<
  typeof useCoachBookingDetailsQuery
>;
export type CoachBookingDetailsLazyQueryHookResult = ReturnType<
  typeof useCoachBookingDetailsLazyQuery
>;
export type CoachBookingDetailsSuspenseQueryHookResult = ReturnType<
  typeof useCoachBookingDetailsSuspenseQuery
>;
export type CoachBookingDetailsQueryResult = Apollo.QueryResult<
  CoachBookingDetailsQuery,
  CoachBookingDetailsQueryVariables
>;
export const GetClientCoachListDocument = gql`
  query getClientCoachList($coachName: String) {
    self {
      id
      coachData {
        clients {
          edges {
            node {
              id
              coaches(coachName: $coachName) {
                edges {
                  node {
                    ...UserBasics
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${UserBasicsFragmentDoc}
`;

/**
 * __useGetClientCoachListQuery__
 *
 * To run a query within a React component, call `useGetClientCoachListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCoachListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientCoachListQuery({
 *   variables: {
 *      coachName: // value for 'coachName'
 *   },
 * });
 */
export function useGetClientCoachListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientCoachListQuery,
    GetClientCoachListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientCoachListQuery,
    GetClientCoachListQueryVariables
  >(GetClientCoachListDocument, options);
}
export function useGetClientCoachListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientCoachListQuery,
    GetClientCoachListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientCoachListQuery,
    GetClientCoachListQueryVariables
  >(GetClientCoachListDocument, options);
}
export function useGetClientCoachListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetClientCoachListQuery,
        GetClientCoachListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientCoachListQuery,
    GetClientCoachListQueryVariables
  >(GetClientCoachListDocument, options);
}
export type GetClientCoachListQueryHookResult = ReturnType<
  typeof useGetClientCoachListQuery
>;
export type GetClientCoachListLazyQueryHookResult = ReturnType<
  typeof useGetClientCoachListLazyQuery
>;
export type GetClientCoachListSuspenseQueryHookResult = ReturnType<
  typeof useGetClientCoachListSuspenseQuery
>;
export type GetClientCoachListQueryResult = Apollo.QueryResult<
  GetClientCoachListQuery,
  GetClientCoachListQueryVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($input: CreateUserInput!) {
    data: createUser(input: $input) {
      user {
        ...UserDetails
        coachData {
          type
        }
      }
    }
  }
  ${UserDetailsFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      userId
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const AssignHistoricalFitCoachDocument = gql`
  mutation assignHistoricalFitCoach(
    $coachId: ID!
    $userId: ID!
    $createdAt: ZonedDateTime!
  ) {
    assignHistoricalFitCoach(
      coachId: $coachId
      userId: $userId
      createdAt: $createdAt
    ) {
      user {
        id
        firstName
        lastName
        fitCoachChat {
          id
          lastMessageReceivedAt
        }
      }
      coach {
        id
      }
    }
  }
`;
export type AssignHistoricalFitCoachMutationFn = Apollo.MutationFunction<
  AssignHistoricalFitCoachMutation,
  AssignHistoricalFitCoachMutationVariables
>;

/**
 * __useAssignHistoricalFitCoachMutation__
 *
 * To run a mutation, you first call `useAssignHistoricalFitCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignHistoricalFitCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignHistoricalFitCoachMutation, { data, loading, error }] = useAssignHistoricalFitCoachMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      userId: // value for 'userId'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useAssignHistoricalFitCoachMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignHistoricalFitCoachMutation,
    AssignHistoricalFitCoachMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignHistoricalFitCoachMutation,
    AssignHistoricalFitCoachMutationVariables
  >(AssignHistoricalFitCoachDocument, options);
}
export type AssignHistoricalFitCoachMutationHookResult = ReturnType<
  typeof useAssignHistoricalFitCoachMutation
>;
export type AssignHistoricalFitCoachMutationResult =
  Apollo.MutationResult<AssignHistoricalFitCoachMutation>;
export type AssignHistoricalFitCoachMutationOptions =
  Apollo.BaseMutationOptions<
    AssignHistoricalFitCoachMutation,
    AssignHistoricalFitCoachMutationVariables
  >;
export const UpdateCoachAvailabilityDocument = gql`
  mutation updateCoachAvailability($id: ID!, $inOfficeDays: [Int!]) {
    updateCoachData(input: { id: $id, inOfficeDays: $inOfficeDays }) {
      user {
        id
        coachData {
          inOfficeDays
        }
      }
    }
  }
`;
export type UpdateCoachAvailabilityMutationFn = Apollo.MutationFunction<
  UpdateCoachAvailabilityMutation,
  UpdateCoachAvailabilityMutationVariables
>;

/**
 * __useUpdateCoachAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateCoachAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachAvailabilityMutation, { data, loading, error }] = useUpdateCoachAvailabilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inOfficeDays: // value for 'inOfficeDays'
 *   },
 * });
 */
export function useUpdateCoachAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoachAvailabilityMutation,
    UpdateCoachAvailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCoachAvailabilityMutation,
    UpdateCoachAvailabilityMutationVariables
  >(UpdateCoachAvailabilityDocument, options);
}
export type UpdateCoachAvailabilityMutationHookResult = ReturnType<
  typeof useUpdateCoachAvailabilityMutation
>;
export type UpdateCoachAvailabilityMutationResult =
  Apollo.MutationResult<UpdateCoachAvailabilityMutation>;
export type UpdateCoachAvailabilityMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoachAvailabilityMutation,
  UpdateCoachAvailabilityMutationVariables
>;
export const UserAutocompleteDocument = gql`
  query userAutocomplete($id: ID!) {
    user(id: $id) {
      ...UserAutocompleteDetails
    }
  }
  ${UserAutocompleteDetailsFragmentDoc}
`;

/**
 * __useUserAutocompleteQuery__
 *
 * To run a query within a React component, call `useUserAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAutocompleteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAutocompleteQuery,
    UserAutocompleteQueryVariables
  > &
    (
      | { variables: UserAutocompleteQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAutocompleteQuery, UserAutocompleteQueryVariables>(
    UserAutocompleteDocument,
    options,
  );
}
export function useUserAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAutocompleteQuery,
    UserAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserAutocompleteQuery,
    UserAutocompleteQueryVariables
  >(UserAutocompleteDocument, options);
}
export function useUserAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserAutocompleteQuery,
        UserAutocompleteQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserAutocompleteQuery,
    UserAutocompleteQueryVariables
  >(UserAutocompleteDocument, options);
}
export type UserAutocompleteQueryHookResult = ReturnType<
  typeof useUserAutocompleteQuery
>;
export type UserAutocompleteLazyQueryHookResult = ReturnType<
  typeof useUserAutocompleteLazyQuery
>;
export type UserAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useUserAutocompleteSuspenseQuery
>;
export type UserAutocompleteQueryResult = Apollo.QueryResult<
  UserAutocompleteQuery,
  UserAutocompleteQueryVariables
>;
export const UnitsPreferenceDocument = gql`
  query unitsPreference {
    self {
      unitsPreference
    }
  }
`;

/**
 * __useUnitsPreferenceQuery__
 *
 * To run a query within a React component, call `useUnitsPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsPreferenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitsPreferenceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UnitsPreferenceQuery,
    UnitsPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnitsPreferenceQuery, UnitsPreferenceQueryVariables>(
    UnitsPreferenceDocument,
    options,
  );
}
export function useUnitsPreferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnitsPreferenceQuery,
    UnitsPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UnitsPreferenceQuery,
    UnitsPreferenceQueryVariables
  >(UnitsPreferenceDocument, options);
}
export function useUnitsPreferenceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UnitsPreferenceQuery,
        UnitsPreferenceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UnitsPreferenceQuery,
    UnitsPreferenceQueryVariables
  >(UnitsPreferenceDocument, options);
}
export type UnitsPreferenceQueryHookResult = ReturnType<
  typeof useUnitsPreferenceQuery
>;
export type UnitsPreferenceLazyQueryHookResult = ReturnType<
  typeof useUnitsPreferenceLazyQuery
>;
export type UnitsPreferenceSuspenseQueryHookResult = ReturnType<
  typeof useUnitsPreferenceSuspenseQuery
>;
export type UnitsPreferenceQueryResult = Apollo.QueryResult<
  UnitsPreferenceQuery,
  UnitsPreferenceQueryVariables
>;
export const SetUnitsPreferenceDocument = gql`
  mutation setUnitsPreference($input: UnitsInput!) {
    setUnitsPreference(input: $input) {
      user {
        id
        unitsPreference
      }
    }
  }
`;
export type SetUnitsPreferenceMutationFn = Apollo.MutationFunction<
  SetUnitsPreferenceMutation,
  SetUnitsPreferenceMutationVariables
>;

/**
 * __useSetUnitsPreferenceMutation__
 *
 * To run a mutation, you first call `useSetUnitsPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUnitsPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUnitsPreferenceMutation, { data, loading, error }] = useSetUnitsPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUnitsPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUnitsPreferenceMutation,
    SetUnitsPreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetUnitsPreferenceMutation,
    SetUnitsPreferenceMutationVariables
  >(SetUnitsPreferenceDocument, options);
}
export type SetUnitsPreferenceMutationHookResult = ReturnType<
  typeof useSetUnitsPreferenceMutation
>;
export type SetUnitsPreferenceMutationResult =
  Apollo.MutationResult<SetUnitsPreferenceMutation>;
export type SetUnitsPreferenceMutationOptions = Apollo.BaseMutationOptions<
  SetUnitsPreferenceMutation,
  SetUnitsPreferenceMutationVariables
>;
export const UserActivityDocument = gql`
  query userActivity(
    $id: ID!
    $input: ConnectionInput
    $filter: UserActivityFilter
  ) {
    user(id: $id) {
      id
      hasHighPerformanceCoaching
      userActivity(input: $input, filter: $filter) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...UserActivityBasic
          }
        }
      }
    }
  }
  ${UserActivityBasicFragmentDoc}
`;

/**
 * __useUserActivityQuery__
 *
 * To run a query within a React component, call `useUserActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserActivityQuery,
    UserActivityQueryVariables
  > &
    (
      | { variables: UserActivityQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserActivityQuery, UserActivityQueryVariables>(
    UserActivityDocument,
    options,
  );
}
export function useUserActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserActivityQuery,
    UserActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserActivityQuery, UserActivityQueryVariables>(
    UserActivityDocument,
    options,
  );
}
export function useUserActivitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserActivityQuery,
        UserActivityQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserActivityQuery, UserActivityQueryVariables>(
    UserActivityDocument,
    options,
  );
}
export type UserActivityQueryHookResult = ReturnType<
  typeof useUserActivityQuery
>;
export type UserActivityLazyQueryHookResult = ReturnType<
  typeof useUserActivityLazyQuery
>;
export type UserActivitySuspenseQueryHookResult = ReturnType<
  typeof useUserActivitySuspenseQuery
>;
export type UserActivityQueryResult = Apollo.QueryResult<
  UserActivityQuery,
  UserActivityQueryVariables
>;
export const UserActivitySurveyDetailsDocument = gql`
  query userActivitySurveyDetails($id: ID!) {
    userActivity(id: $id) {
      id
      source {
        ... on Survey {
          id
          ...SurveyDetails
        }
      }
    }
  }
  ${SurveyDetailsFragmentDoc}
`;

/**
 * __useUserActivitySurveyDetailsQuery__
 *
 * To run a query within a React component, call `useUserActivitySurveyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActivitySurveyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActivitySurveyDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserActivitySurveyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserActivitySurveyDetailsQuery,
    UserActivitySurveyDetailsQueryVariables
  > &
    (
      | { variables: UserActivitySurveyDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserActivitySurveyDetailsQuery,
    UserActivitySurveyDetailsQueryVariables
  >(UserActivitySurveyDetailsDocument, options);
}
export function useUserActivitySurveyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserActivitySurveyDetailsQuery,
    UserActivitySurveyDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserActivitySurveyDetailsQuery,
    UserActivitySurveyDetailsQueryVariables
  >(UserActivitySurveyDetailsDocument, options);
}
export function useUserActivitySurveyDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserActivitySurveyDetailsQuery,
        UserActivitySurveyDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserActivitySurveyDetailsQuery,
    UserActivitySurveyDetailsQueryVariables
  >(UserActivitySurveyDetailsDocument, options);
}
export type UserActivitySurveyDetailsQueryHookResult = ReturnType<
  typeof useUserActivitySurveyDetailsQuery
>;
export type UserActivitySurveyDetailsLazyQueryHookResult = ReturnType<
  typeof useUserActivitySurveyDetailsLazyQuery
>;
export type UserActivitySurveyDetailsSuspenseQueryHookResult = ReturnType<
  typeof useUserActivitySurveyDetailsSuspenseQuery
>;
export type UserActivitySurveyDetailsQueryResult = Apollo.QueryResult<
  UserActivitySurveyDetailsQuery,
  UserActivitySurveyDetailsQueryVariables
>;
