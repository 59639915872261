import React from 'react';
import { ThemeProvider } from '@mui/system';
import { SplitFactoryProvider, SplitSdk } from '@splitsoftware/splitio-react';
import { AuthProvider } from '@teamexos/fit-shared';
import { ConfettiContextProvider } from '../components/reusable/Confetti';
import { GlobalAlerts } from '../components/reusable/Snackbar/GlobalAlert';
import SnackbarContextProvider from '../components/reusable/Snackbar/SnackbarContextProvider';
import theme from '../theme';
import PrinceSDKProvider from './PrinceSDK';
import TrackingEventListProvider from './TrackingEventListProvider';

interface Props {
  children?: React.ReactNode;
}

const splitFactory = SplitSdk({
  core: {
    // These are client side keys
    authorizationKey:
      process.env.GATSBY_SPLIT_IO_AUTH_KEY ||
      'p3lk1ck4kcvinevkr0s8as31d07cspgkujv7',
    key:
      process.env.GATSBY_SPLIT_IO_KEY || '713d52a0-dadb-11ec-add4-420273f55afa',
  },
});

/**
 * P R O V I D E R S
 */
const Providers: React.FC<Props> = ({ children }: Props) => {
  const sharedPlatformDomain = process.env.GATSBY_API_URL;

  if (!sharedPlatformDomain) {
    throw new Error('Missing sharedPlatformDomain');
  }

  return (
    <AuthProvider
      sharedPlatformDomain={sharedPlatformDomain}
      localSignOut={async () => {}}
    >
      <PrinceSDKProvider>
        <SplitFactoryProvider factory={splitFactory} updateOnSdkUpdate>
          <ThemeProvider theme={theme}>
            <SnackbarContextProvider>
              <ConfettiContextProvider>
                <TrackingEventListProvider>
                  {children}
                </TrackingEventListProvider>
                <GlobalAlerts />
              </ConfettiContextProvider>
            </SnackbarContextProvider>
          </ThemeProvider>
        </SplitFactoryProvider>
      </PrinceSDKProvider>
    </AuthProvider>
  );
};

export default Providers;
